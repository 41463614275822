.modal {
    display: none;
    position: fixed;
    z-index: 9999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: var(--modal-background-color);
}

.modal-content {
    margin: 10% auto;
    width: 60%;
    box-shadow: 0 5px 8px 0 var(--modal-content-hdw-lr), 0 7px 20px 0 var(--modal-content-hdw-tb);
    animation-name: modalopen;
    animation-duration: 1s;
    border-radius: 3px;
    overflow: hidden;
    display: flex;
    flex-flow: column;
    background: var(--modal-content-background-color);
}
.sm {
    width: 40%;
}
.md {
    width: 60%;
}
.lg {
    margin: 2% auto;
    box-sizing: border-box;
    height: 92%;
}
.modal-header h3 {
    margin: 0;
}

.modal-header {
    background: var(--modal-content-background-color);
    padding: 15px;
    color: var(--modal-header-color);
    line-height: 20px;
}

.modal-body {
    background: var(--modal-content-background-color);
    padding: 10px 20px;
}
.modal-body.grow {
    padding: unset;
}
.modal-body.grow .modal-body-content {
    padding: 10px 20px;
}
.grow {
    min-height: 400px;
    max-height: 100%;
    overflow-y: auto;
    width: 100%;
    box-sizing: border-box;
    flex: 1;
}
.modal-footer {
    background: var(--modal-content-background-color);
    padding: 14px;
    text-align: center;
}

.close {
    color: var(--modal-close-icn);
    float: right;
    font-size: 30px;
}

.close:hover,
.close:focus {
    color: var(--modal-close-icn-hover);
    text-decoration: none;
    cursor: pointer;
}

.modal-button {
    background-color: var(--modal-button-bg-color);
    color: var(--modal-button-color);
    text-transform: uppercase;
    font-weight: 400;
    font-size: 16px;
    border: none;
    cursor: pointer;
    border-radius: 7px;
    padding: 10px 15px;
    opacity: 0.9;
    outline: none;
}

.modal-button:hover {
    opacity: 1;
}

.disabled {
    opacity: 0.7;
    cursor: not-allowed;
}
.disabled:hover {
    opacity: 0.7;
}
@keyframes modalopen {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
