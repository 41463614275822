.bdy {
    position: absolute;
    top: 70px;
    left: 72px;
    right: 0;
    bottom: 0;
    background: var(--lynx-primarary-bg);
    padding: 8px 24px;
    overflow-y: auto;
    padding-bottom: 40px;
}

.bdy.nw-pl{
    padding-left: 1%;
}

.bdy.mgmt{
    padding-top: unset;
    padding-bottom: unset;
    overflow: hidden;
}

.bdy.ex{
    left: 260px;
}

.up-row-1{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.up-hdr-btns{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 8px;
    border-bottom: 1px solid var(--primary-blue-hover);
}

.up-hdr{
    font-size: 40px;
    line-height: 48px;
    font-weight: 600;
    font-family: 'Gibson-600', sans-serif;
    color: var(--primary-neutral-600);
}

.btn{
    border-radius: 4px;
    padding: 12px 22px;
    font-size: 17px;
    font-weight: 600;
    cursor: pointer;
    border: none;
    outline: none;
}

.btn.rc{
    color: var(--secondary-black-26-per);
    background-color: var(--secondary-black-12-per);
    margin-right: 40px;
    cursor: not-allowed;
}

.btn.rc.e{
    color: var(--primary-neutral-500);
    pointer-events: all;
    cursor: pointer;
}

.btn.s{
    color: var(--primary-white);
    background-color: var(--primary-blue-500);
}
.btn.l:before {
    content: 'Save';
    opacity: 0;
}
/* .btn.s.d{
    color: var(--primary-neutral-500);
    background-color: var(--primary-blue-200);
    pointer-events: none;
} */
.btn:disabled {
    cursor: not-allowed;
    background: var(--primary-blue-200);
}

.up-row-2{
    font-size: 14px;
    font-weight: 600;
    margin-top: 12px;
    color: var(--primary-neutral-400);
}

.up-row-3{
    background-color: var(--primary-white);
    box-shadow: 0px 0px 10px 0px #00000014;
    padding: 24px 24px 40px 24px;
}
.up-row-3.admin{
    background-color: unset;
    box-shadow: unset;
    padding-left: unset;
    padding-right: unset;
    padding-top: unset;
}

.up-row-3.admin .redesign-srch{
    margin-left: unset;
}

.up-row-3.scrl-wthn{
    position: absolute;
    left: 24px;
    right: 24px;
    top: 32px;
    bottom: 0px;
}

.up-row-3.scrl-wthn.t-0{
    top: 0;
}

.d-hdr{
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    font-family: 'Gibson-600', sans-serif;
    color: var(--primary-neutral-600);
    padding: 40px 0 24px 0;
}

.user-info{
    margin-top: 45px;
}

.df-input{
    display: flex;
    justify-content: flex-start;
}

.ip-fld.m{
    margin-bottom: 20px;
}

.ip-fld.w-col-3{
    width: 26%;
    margin-right: 4%;
    flex-grow: 1;
}

.ip-fld.w-100-80px{
    width: calc(100% - 80px);
}

.ip-fld .ip.w{
    width: 260px;
}

.ip-fld .ip.w-100{
    width: 100%;
}

.ip-fld .ip.w.ex{
    width: 200px;
}

.ip-fld .ip.disable{
    color: var(--primary-neutral-400);
    background-color: var(--primary-neutral-200);
    pointer-events: none;
}

.ip-fld .ip.up-w{
    
}

.btn.s.w{
    width: fit-content;
}

.pwd div{
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 10px;
}

.btn-s-out{
    border-radius: 4px;
    padding: 12px 22px 11px;
    font-weight: 600;
    cursor: pointer;
    font-size: 15px;
    color: var(--primary-blue-500);
    border: 1px solid var(--primary-blue-500);
    width: fit-content;
}

.pwd ul li { 
    display: list-item;
    list-style-type: disc;
    margin-left: 25px;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
}

@media screen and (max-width: 1024px) {
    .df-input div{
        flex-grow: 1;
    }

    .sel-box.i-fld.p-dc.wl{
        padding: 5px 5px;
    }

    .ip-fld.w-100-80px{
        width: calc(100% - 65px);
    }
}