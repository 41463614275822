.iframe_loader {
    width: 100%;
    height: calc(100% - 70px);
    position: 'absolute';
    border: none;
    top: 70px;
}

#my-test-frame {
    width: 100%;
    height: calc(100% - 70px);
    position: 'absolute';
    border: none;
    top: 70px;
}
iframe {
    width: 100%;
    height: 100%;
}

.superset-pos{
    width: calc(100% - 72px);
    position: absolute;
    height: calc(100% - 70px);
    border: none;
    top: 130px;
    left: 72px;
}

.superset-pos.ex{
    left: 260px;
}

.superset-pos iframe{
    border: none;
}