.toggle-btn {
    position: absolute;
    display: block;
    width: 32px;
    height: 16px;
    background: var(--primary-neutral-200);
    border: 1px solid var(--primary-neutral-300);
    border-radius: 20px;
    transition: 0.5s ease background-color;
    cursor: pointer;
    margin-left: 10px;
}

.toggle-btn.ckd.ds{
    cursor: not-allowed;
}

.toggle-btn:hover {
    background: var(--primary-blue-hover);
}

.toggle-btn.ds {
    background: var(--primary-neutral-300);
    cursor: not-allowed;
}

.toggle-btn::before {
    content: "";
    position: absolute;
    top: -5px;
    width: 24px;
    height: 24px;
    background-color: var(--primary-white);
    border: 1px solid var(--primary-neutral-300);
    border-radius: 50%;
    transform: translate(-10px, 0px);
    transition: 0.6s ease transform;
}

.toggle-btn:hover::before {
    background: var(--primary-blue-hover);
}

.toggle-btn.ds::before {
    background: var(--primary-neutral-300);
    box-shadow: 0px 0px 4px 0px #0000003D;
    cursor: not-allowed;
}

.toggle-btn::after {
    content: "";
    position: absolute;
    top: 2px;
    left: 0px;
    width: 0px;
    border: 6px solid transparent;
    border-radius: 20px;
    transform: translate(2%, 6%);
    transition: 0.4s ease width, 0.4s ease border-radius, 0.62s ease transform;
}

.toggle-btn.ckd::before {
    transform: translate(20px, 0px);
    border: 1px solid var(--primary-blue-500);
}

.toggle-btn.ckd.ds::before {
    border: 1px solid var(--primary-neutral-300);
}

.toggle-btn.ckd::after {
    background: var(--primary-blue-500);
    transform: translate(27px, 0.5px);
}

.toggle-btn.ckd.ds::after {
    background: var(--primary-neutral-200);
}

.toggle-btn.ckd {
    background: var(--primary-blue-500);
    border: 1px solid var(--primary-blue-500);
    margin-left: unset;
}

.toggle-btn.ckd.ds {
    background: var(--primary-neutral-300);
    border: 1px solid var(--primary-neutral-300);
}

.toggle-btn.ckd span::after{
    content: 'Yes';
    position: absolute;
    top: -3px;
    margin-left: 54px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: var(--primary-neutral-600);
    text-transform: capitalize !important;
}

.redesign-list-itm .toggle-btn.ckd span::after{
    content: 'Shown';
}

.toggle-btn span::after{
    content: 'No';
    position: absolute;
    margin-left: 44px;
    top: -3px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: var(--primary-neutral-600);
    text-transform: capitalize !important;
}

.redesign-list-itm .toggle-btn span::after{
    content: 'Hidden';
}

.toggle-btn.y-n span::after{
    content: 'No';
}
.toggle-btn.y-n.ckd span::after{
    content: 'Yes';
}

@media screen and (max-width: 1024px) {
    .redesign-list-itm .toggle-btn.ckd span::after,
    .redesign-list-itm .toggle-btn span::after{
        content: '';
    }
}