/* .h-menu-icon {
    display: none;
    cursor: pointer;
    margin-top: 25px;
    position: absolute;
    left: 10px;
}
.nav-links .icon{
    display: none;
} */
@media only screen and (max-width : 1439px) {
    
    .redesign-lbl-fltrs.l-fltr {
        display: block;
    }
    .redesign-lbl-fltrs.l-fltr .redesign-fltr {
        justify-content: flex-start;
        margin-top: 10px;
        width: 60%;
    }
    .redesign-lbl-fltrs.l-fltr .redesign-fltr > div {
        width: 44%;
    }
    .redesign-lbl-fltrs.l-fltr .redesign-fltr .ip-fld.lbl.sel-lbl {
        width: unset;
    }

    
    
}
@media only screen and (max-width: 63.96em) {
    .cwl-alert-box.info{
        display: block !important;
        right: 10px;
        bottom: 10px;
        left: 10px;
        position: fixed;        
    }
}
@media only screen and (max-width: 1150px) {
	
}
@media screen and (max-width: 1200px) {
    .sel-ori-des > div.in-btwn{
        width: 4%;
    }

    .riq-col-5 .ril-lbl {
        max-width: 116px;
    }
    .riq-col-5 .extn::before {
        content: '.';
        width: 2px;
    }
/* 
    .riq-col-1, .riq-col-2, .riq-col-6 { 
        width: 13%;
    }

    .riq-col-4 {
        width: 18%;

    }
    .riq-col-5 {
        width: 25%;
    }

    .riq-col-0 {
        width: 10%;
    }

    .riq-col-5 .ril-lbl {
        max-width: 116px;
    }
    .riq-col-5 .extn::before {
        content: '.';
    } */
       /* .riq-col-5 .ril-lbl {
        max-width: 210px !important;
    } */
    /* .riq-col-5 .extn::before {
        content: none !important;
    } */

}
@media only screen and (max-width: 440px) {
	
}

@media only screen and (max-width: 374px) {
   
    
}
@media only screen and (max-width: 600px) {
   
}
@media only screen and (max-width: 330px) {
   
}

@media only screen and (max-width: 319px) {
   
}

@media only screen and (min-height:1000px){
   
}
@media only screen and (max-height:900px){
    
}
@media only screen and (max-height:800px){
   
}
@media only screen and (max-height:700px){
   
}
@media only screen and (max-height: 600px) {
   
}
@media only screen and (max-height: 500px) {
   
}
@media only screen and (max-height: 400px) {
    
}