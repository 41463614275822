:root {
    /* Login Page */
    --base: #181818;
    --primary-blue-75: #F0F1F9;
    --lgn-img-back: #041d29;
    --primary-white: #ffffff;
    --grey-300: #E0E0E0;
    --grey-200: #949494;
    --primary-neutral-100: #f8f8f8;
    --primary-neutral-200: #e8e8e8;
    --primary-neutral-300: #999999;
    --primary-neutral-400: #525252;
    --primary-neutral-500: #333333;
    --primary-neutral-600: #181818;
    --label-color:  #525252;
    --primary-blue-200: #AEA5E4;
    --primary-blue-300: #887ADE;
    --primary-blue-400: #533CDC;
    --primary-blue-500: #270ADC;
    --primary-rose-500: #FDDBD4;
    --primary-blue-600: #1B079C;
    --primary-blue-hover: #DEDBF0;
    --primary-blue-500-2-per: rgb(39, 10, 220, 0.02);
    --secondary-black-12-per: rgba(0,0,0,.12);
    --secondary-black-26-per: rgba(0,0,0,.26);
    --loading-bar-blue: #1891F6;
    --secondary-sky: #0093E5;
    --secondary-teal: #00C7A3;
    --feedback-success: #E3FCEF;
    --user-deactivate: #BE442A;
    --selected-itm: #F0F1F9;
    --more-options: #533CDC;
    --lynx-overlay-bg: rgba(82, 82, 82, 0.40);
    --error-color: #DF5031;
    --error-bg-color: #FDDBD4;
    --success-color: #008C34;
    --success-color-bg: #E3FCEF;
    --warning-color: #D99100;
    --warning-color-bg: #FFF1D6;
    --product-ai-color: #00c7a3;
    --default-profile: #A4D0F9;
    --info-color: #015B8D;

    --data-viz-teal: #00c7a3;
    --data-viz-sky: #00A4FF;
    --data-viz-lime: #CDE838;
    --data-viz-plum: #955196;
    --data-viz-orange: #FF8A00;
    --data-viz-violet: #6161FF;

    --bar-graph-1: #0D3097;
    --bar-graph-10: #BA6500;
    --bar-graph-2: #922852;
    --bar-graph-3: #4292E2;
    --bar-graph-4: #DC5F96;
    --bar-graph-5: #459B99;
    --bar-graph-6: #AB862B;
    --bar-graph-7: #3079C3;

    --lynx-primarary-bg: #FBFAFE;

    --lgn-backgroud: #F0F1F9;
    --lgn-card-background: #fff;
    --lgn-card-shadow: #6e666620;
    --lgn-primary-font-color: #2F3044;
    --lgn-sec-font-color: #999999;
    --lgn-forgot-pwd: #1B079C;
    --lgn-forgot-pwd-hover: #270ADC;
    --lgn-input-box-background: #fff;
    --lgn-input-box-border: #999999;
    --lgn-input-box-err: #f0525a;
    --lgn-remember-check-border: #999999;
    --lgn-remember-check-shadow: #6e666633;
    --lgn-remember-checked: #0093E5;
    --lgn-submit-color: #fff;
    --lgn-submit-background: #270ADC;
    --lgn-request-demo-color: #1B079C;
    --lgn-info-hover-color: #1B079C;
    --lgn-modal-hint-color: #aaaaaa;
    --lgn-modal-input-b-border: #e0e0e0;
    --lgn-modal-input-focus: #0093E5;

    --grey-1: #C8C7D1;
    --risk-c1: #FF8A00;
    --risk-c2: #2C5882;
    --risk-c3: #47769D;
    --risk-c4: #6292B7;
    --risk-c5: #7DB0D2;
    --risk-c6: #99CEED;
    

    /* App.css */
    --app-primary-color: #fff;
    --app-secondary-color: #272727;
    --app-third-color: #000;
    --app-fourth-color: #595959;
    --app-fifth-color: #3b3b3b;
    --app-primary-bg: #fff;
    --app-secondary-bg: #272727;
    --app-third-bg: #000;
    --app-primary-box-shdw: #666;
    --app-secondary-box-shdw: #000;
    --app-nav-ls-li-bg-hvr: #ffffff40;
    --app-nav-ls-li-a: #181818;
    --app-nav-ls-li-a-hvr: #270ADC;
    --app-nav-ls-li-hvr: #F0F1F9;
    --app-nav-ls-li-a-b-border: #F0F1F9;
    --app-nv-ls-grand-child-nodes-li-a: #181818;
    --app-nv-ls-grand-child-nodes-li-a-hvr: #270ADC;
    --app-nv-ls-grand-child-nodes-li-bg: #fff;
    --app-primary-border: #fff;
    --app-secondary-border: #272727;
    --app-thrid-border: rgb(151, 151, 151);
    --app-profile-bg: #374387;
    --app-light-blue-color: #0093E5;
    --app-view-bg: #1B079C;
    --app-comp-name: #181818;
    --app-notify-sec-bg: #7cbbe0;
    --app-service-msg-bg: rgb(69, 69, 69);
    --app-service-msg-box-shdw: #333;
    --app-close-msg-bg: rgba(255, 255, 255, 0.75);
    --app-close-msg-bg-hvr: rgba(255, 255, 255, 0.85);
    --app-left-bar-bg: #f6f6f6;
    --app-left-bar-r-border: #9d9ea1;
    --app-left-bar-box-shdw: #aaa;
    --app-right-bar-bg: #cad4d5;
    --app-text-i: #2b2b2b;
    --app-search-btn-bg: #270ADC;
    --app-search-txt: #ffffff;
    --app-search-cat-pop-li-no-item: #999;
    --app-greet-msg: #3b3b3b;
    --app-greet-msg-view-all: #4e97c2;
    --app-config-list-ship-status: #52575a;
    --app-config-list-ship-status-bg-before: #929292;
    --app-config-list-ship-status-inventory-before: #62b7dd;
    --app-config-list-ship-status-new-before: #919191;
    --app-config-list-ship-status-transit-before: #4eea79;
    --app-config-list-ship-status-delivered-before: #515861;
    --app-config-list-ship-status-completed-before: #4eea79;
    --app-config-list-ship-status-at-risk-before: #ffce00;
    --app-config-list-ship-status-critical-before: #f0525a;
    --app-config-list-ship-status-ontime-before: #5FFB10;
    --app-config-list-ship-status-delayed-before: #d32f2f;
    --app-config-list-ship-status-h-risk-before: #d32f2f;
    --app-config-list-ship-status-m-risk-before: #ffce00;
    --app-config-list-ship-status-l-risk-before: #4eea79;
    --app-config-list-ship-cnt: #4b4b4b;
    --app-config-page-lnk-txt: rgb(75, 75, 75);
    --app-config-page-lnk-txt-hvr: rgb(101, 101, 101);
    --app-config-list-li-border: #d9d9d9;
    --app-config-list-li: rgb(82, 87, 90);
    --app-config-list-li-handle: rgba(151, 151, 151, 0.45);
    --app-config-list-li-status-bar-bg: rgb(216, 216, 216);
    --app-config-list-li-status-bar-a-bg: #0093E5;
    --app-back-lnk-bg: rgb(216, 216, 216);
    --app-mapboxgl-popup-content-box-shdw: #333;
    --app-shipment-details-pop-t-border: #eee;
    --app-shipment-details-pop: #3c4858;
    --app-shipment-details-pop-lbl-id: rgb(75, 75, 75);
    --app-lbl-stat-new: #919191;
    --app-lbl-stat-transit: #00e275;
    --app-lbl-stat-destination: #515861;
    --app-lbl-stat-at-risk: rgb(255, 203, 25);
    --app-lbl-stat-critical: #f0525a;
    --app-lbl-stat-delayed: #d32f2f;
    --app-shipment-list-box-path: rgb(75, 75, 75);
    --app-fltr-a-btn-border: #979797;
    --app-fltr-a-btn-o-bg: rgba(43, 43, 43, 0.3);
    --app-fltr-a-btn-a-bg: #1B079C;
    --app-fltr-a-btn-a-boc-shdw: rgb(0 0 0 / 35%);
    --app-fltr-pop-border: #979797;
    --app-fltr-pop-box-shdw: #00000025;
    --app-fltr-pop-fltr-btn-bg: #2B2B2B;
    --app-fltr-pop-fltr-btn-bg-hvr: #00000050;
    --app-sel-box-bg: rgb(254, 255, 254);
    --app-sel-box-sel-value-after-border: rgb(59, 59, 59);
    --app-ship-fltrs-bg: rgb(216, 216, 216);
    --app-stat-cont-list-li: rgb(75, 75, 75);
    --app-stat-cont-list-li-hvr: #d0cfcf;
    --app-stat-cont-list-li-a: #bbbbbb;
    --app-shipment-list-box-shipment-list-bg: rgb(216, 216, 216);
    --app-shipment-list-ship-item-a-bg: #DEDBF0;
    --app-f-j-s-b-address-info: #585858;
    --app-ship-item-i-info: #4b4b4b;
    --app-ship-item-ship-desc: #4f4f4f;
    --app-ship-item-c-s-i-not-before: #4f3f3f80;
    --app-ship-item-i-stat-lbl: #02c362;
    --app-ship-item-i-stat-lbl-border: rgba(1, 196, 102, 0.5);
    --app-ship-item-inactive-i-stat-lbl: rgb(43, 43, 43);
    --app-ship-item-inactive-i-stat-lbl-border: rgb(43, 43, 43, 0.5);
    --app-ship-item-major-i-stat-icn: rgb(255, 203, 25);
    --app-ship-item-minor-i-stat-icn: rgb(211, 47, 47);
    --app-ship-item-i-prog-bar-t-border: rgb(216, 216, 216);
    --app-ship-item-i-prog-bar-bg-before: rgb(0, 195, 100);
    --app-ship-item-i-prog-bar-bg-after: rgb(216, 216, 216);
    --app-ship-item-c-pro-bg: #99999496;
    --app-ship-details-bg: rgb(216, 216, 216);
    --app-ship-details-s-d-logo-border: rgb(45, 44, 44);
    --app-ship-details-copy-icn-border: rgb(43, 43, 43);
    --app-ship-details-copy-icn-bg: rgb(216, 216, 216);
    --app-ship-details-copy-icn-box-shdw: #999;
    --app-ship-details-tooltip-bg: #272727b0;
    --app-ship-details-ship-det-info-s-d-i-val: rgb(84, 155, 246);
    --app-ship-details-ship-det-info-s-d-action-icn: #666;
    --app-ship-details-ship-det-info-s-d-action-icn-span: rgb(87, 179, 217);
    --app-i-export-border: #666;
    --app-i-export-border-after: rgb(216, 216, 216);
    --app-i-export-bg-after: #979797;
    --app-i-export-border-before: #f00;
    --app-ship-src-dest-ship-src-border: #979797;
    --app-ship-src-dest-ship-src-bg-before: rgb(75, 75, 75);
    --app-ship-src-dest-ship-path-t-border: rgb(111, 111, 111);
    --app-ship-src-dest-ship-dest: rgb(75, 75, 75);
    --app-ship-c-loc-box-shdw: rgba(0, 0, 0, 0.5);
    --app-ship-c-loc-dev-loc-border-after: #d8d8d8;
    --app-c-loc-icn-bg: rgb(0, 195, 100);
    --app-c-loc-icn-border: rgb(207, 255, 232);
    --app-ship-eve-icn-bg: rgb(75, 75, 75);
    --app-metric-loc-icn-bg: #ffce00;
    --app-metric-major-loc-icn-bg: #f0525a;
    --app-c-loc-lnl-befoe: #999;
    --app-switch-btn-li-a-bg: #1B079C;
    --app-switch-btn-li-a-box-shdw: rgba(0, 0, 0, 0.5);
    --app-tl-eve-i-l-border-before: rgb(177, 177, 177);
    --app-eve-i-stat: #02c362;
    --app-eve-i-stat-border: rgba(1, 196, 102, 0.5);
    --app-eve-i-stat-at-risk-not-completed: rgb(255, 203, 25);
    --app-eve-i-stat-at-risk-not-completed-border: rgb(255, 206, 0);
    --app-eve-i-stat-critical-not-completed: rgb(211, 47, 47);
    --app-mark-as-complete-after: #525252;
    --app-eve-i-stat-change-border: rgb(151, 151, 151, 0.9);
    --app-eve-i-stat-change-border-hvr: rgb(151, 151, 151, 1);
    --app-eve-i-stat-change-bg-hvr: #eee;
    --app-eve-i-stat-change-bg-active: #eaeaea;
    --app-ship-completed-success-bg: #fafafa;
    --app-ship-completed-success: #02c362;
    --app-ship-completed-border: rgba(1, 196, 102, 0.5);
    --app-tl-eve-eta-stops-border: rgb(216, 216, 216);
    --app-e-s-stat: #02c362;
    --app-e-s-stat-low: rgb(255, 203, 25);
    --app-e-s-stat-high: rgb(240, 82, 90);
    --app-track-time-info-tt-l-lbl: rgb(180, 180, 180);
    --app-track-time-info-tt-l-lbl-d: #d32f2f;
    --app-track-time-info-tt-l-lbl-o: #02c362;
    --app-track-time-data: rgb(43, 43, 43);
    --app-tl-eve-i-ship-user-l-border: rgba(0, 0, 0, 0.7);
    --app-eve-info-i-list-eve-info-i-bg-before: rgb(75, 75, 75);
    --app-eve-info-i-list-eve-info-i-has-child-l-border-after: #B1B1B1;
    --app-eve-info-i-val-b-b-border: #aaa;
    --app-tl-eve-i-time-above-bg: #f0525a;
    --app-tl-eve-i-time-below-bg: #0052cc;
    --app-eve-exc-details-border: rgb(255, 152, 37);
    --app-eve-exc-details-heat: rgb(255, 152, 37);
    --app-eve-exc-details-cold: #7ddaff;
    --app-eve-exc-details-critical: #f0525a;
    --app-s-m-sec-after: #aaa;
    --app-e-t-sec: rgb(78, 151, 194);
    --app-s-m-cnts-m-c-border: #7f7f7f;
    --app-s-m-cnts-m-c-a-border: #f0525a;
    --app-s-m-cnts-m-c-minor-a-bg: #ffce00;
    --app-s-m-cnts-m-c-heat: #ff9825;
    --app-s-m-cnts-m-c-cold: #7ddaff;
    --app-s-m-cnts-m-c-temp-minor-below: #0052cc;
    --app-s-m-cnts-m-c-ping-rate: #fe9e42;
    --app-s-m-cnts-m-c-risk-alarm: #f0525a;
    --app-s-m-cnts-m-c-low-battery: #ffce00;
    --app-px-spin-border: rgba(82, 187, 235, 0.15);
    --app-px-spin-t-border: rgba(82, 187, 235, 0.8);
    --app-px-g-spin-border: rgba(0, 0, 0, 0.15);
    --app-px-g-spin-t-border: rgba(255, 255, 255, 0.8);
    --app-icn-container-border: rgba(45, 44, 44, 0.75);
    --app-count-txt: #585858;
    --app-selbox-ls-bg: #1B079C;
    --app-company-detail-box-bg: #f0f0f0;
    --app-company-detail-box-box-shdw: rgba(0, 0, 0, 0.5);
    --app-p-o-c: #585858;
    --app-comapny-name: #2b2b2b;
    --app-company-edit-btn: #549bf6;
    --app-company-edit-btn-bg: #f0f0f0;
    --app-input-with-icn: #3b3b3b;
    --app-comp-search-i-b-bottom: #979797;
    --app-comp-search-i: #3b3b3b;
    --app-invite-user-bg: #57b3e8;
    --app-invite-user-c-bg: #999;
    --app-user-tem-b-bottom: rgba(151, 151, 151, 0.5);
    --app-user-details: #2b2b2b;
    --app-usr-role: #02c362;
    --app-usr-role-border: rgba(1, 196, 102, 0.5);
    --app-user-btns: #2b2b2b;
    --app-admin-role-btn: #0093E5;
    --app-m-back-nav-btn-bg: #d8d8d890;
    --app-m-back-nav-btn-box-shdw: #999;
    --app-role-btns-activate-hvr: #00c364;
    --app-role-btns-deactivate-hvr: #d32f2f;
    --app-contxt-overlay-bg: rgba(0, 0, 0, 25%);
    --app-contxt-overlay-close-icn: #2b2b2b;
    --app-ship-det-i-fld: #aaa;
    --app-change-carrier-lnk: rgb(78, 151, 194);
    --app-c-btn: rgb(255, 255, 255);
    --app-c-btn-bg: #270ADC;
    --app-c-btn-box-shdw: rgba(0, 0, 0, 0.5);
    --app-c-btn-invalid-bg: rgba(78, 151, 194, 0.5);
    --app-carrier-auto-auto-item-bg-hvr: rgba(200, 200, 200, 0.2);
    --app-plus-link-bg: #0093E5;
    --app-plus-link-box-shdw: rgba(0, 0, 0, 0.5);
    --app-right-contxt-bar-r-border: #9d9ea1;
    --app-err-msg-comp: #f0525a;
    --app-devices-list-hdr-b-bottom: rgba(151, 151, 151, 0.5);
    --app-device-status-lbl: #02c362;
    --app-device-status-lbl-border: rgba(1, 196, 102, 0.5);
    --app-device-status-i-lbl: rgb(43, 43, 43);
    --app-device-status-lbl-i-border: rgba(52, 52, 52, 0.5);
    --app-device-item-more-info: rgb(84, 155, 246);
    --app-n-form-device-status: #02c362;
    --app-n-form-device-status-border: rgba(1, 196, 102, 0.5);
    --app-i-sec-device-element-hd: #3b3b3b;
    --app-comp-delete: #d32f2f;
    --app-comp-delete-bg: #d8d8d8;
    --app-chartdiv-data-not-availble-bg-before: rgba(0, 0, 0, 0.1);
    --app-i-radio-grp-cir-border: rgb(88, 88, 88, 0.8);
    --app-i-radio-grp-cir-box-shdw: #aaa;
    --app-i-radio-grp-checked-cir-border: rgb(87, 179, 217);
    --app-cube-face-bg: rgba(200, 200, 200, 0.4);
    --app-cube-face-border: rgba(0, 0, 0, 0.9);
    --app-d-met-data-bg: rgb(43, 43, 43, 0);
    --app-d-met-data-d-met-lbl: rgb(43, 43, 43);
    --app-user-comp-dtl-bg: #f0f0f0;
    --app-user-email-phn: #2b2b2b;
    --app-device-super-hdr-bg: #d8d8d8;
    --app-left-device-hdr-border: #9d9ea1;
    --app-left-device-hdr-box-shdw: #aaa;
    --app-right-device-hdr-heading: #2b2b2b;
    --app-select-company-label: #3b3b3b;
    --app-device-btns-action-btn: #2b2b2b;
    --app-device-btns-action-btn-bg: #d8d8d8;
    --app-device-btns-action-btn-add-bg: #57b3e8;
    --app-device-table-th-b-bottom: rgba(151, 151, 151, 0.5);
    --app-device-table-dev-status-inventory: #2b2b2b;
    --app-device-table-dev-status-inventory-border: rgba(52, 52, 52, 0.5);
    --app-device-table-td-info: #549bf6;
    --app-device-table-td-b-bottom: rgb(151, 151, 151, 0.5);
    --app-comp-item-input-label-box-shdw-before: rgba(0, 0, 0, 0.5);
    --app-comp-item-input-label-checked-bg-before: gb(87, 179, 232);
    --app-bulk-upload-btn: #57b3e8;
    --app-comp-edit-button: #549bf6;
    --app-anim-pulse-0-box-shdw: rgba(2, 195, 98, 0.7);
    --app-anim-pulse-70-box-shdw: rgba(2, 195, 98, 0);
    --app-anim-pulse-100-box-shdw: rgba(2, 195, 98, 0);
    --app-mapboxgl-popup-close-button-bg: #e2e2e2;
    --app-mapboxgl-popup-content-pop-hdr: rgb(43, 43, 43);
    --app-mapboxgl-popup-content-pop-hdr-b-border: rgb(216, 216, 216);
    --app-mapboxgl-marker-active-circle-path: #549bf6;
    --app-mapboxgl-popup-content-leg-stat-bg: rgba(2, 195, 98);
    --app-mapboxgl-popup-content-o-d-loc: rgb(43, 43, 43);
    --app-mapboxgl-popup-content-o-d-loc-path: rgba(151, 151, 151, 0.6);
    --app-mapboxgl-popup-content-f-t-s-l-border: rgb(216, 216, 216);
    --app-overlay-bg: rgb(216, 216, 216, 0.83);
    --app-overlay-share-modal-box-shdw: rgba(0, 0, 0, 0.5);
    --app-share-modal-share-header-b-bottom: rgba(216, 216, 216, 0.5);
    --app-share-modal-share-header-share-txt: rgb(43, 43, 43);
    --app-share-modal-input-pop: rgb(161, 161, 161);
    --app-share-modal-input-pop-border: rgba(151, 151, 151, 0.5);
    --app-share-modal-share-body-btn-bg: rgb(78, 151, 194);
    --app-share-modal-share-body-btn-bg-hvr: rgb(78, 151, 220);
    --app-share-modal-share-body-btn-box-shdw-active: #999;
    --app-share-modal-share-msg-e: #f0525a;
    --app-share-modal-share-msg-s: #02c362;
    --app-rc-slider-tooltip-arrow-t-border: #efefef;
    --app-rc-slider-rail-bg: #e9e9e9;
    --app-eye-icon: #495057;
    --app-loc-btn-prev-border1-before: #f00;
    --app-loc-btn-prev-border2-before: #999;
    --app-loc-btn-prev-not-i-hvr-before: #111;
    --app-manage-geo: #8090a8;
    --app-geo-dist-ip-bg: rgb(255, 255, 255);
    --app-geo-dist-ip-border: rgb(153, 153, 153);
    --app-add-geo-after: #222;
    --app-add-geo-bg-after: #efefef;
    --app-geo-close-border: rgba(0, 0, 0, 0.2);
    --app-ship-completed-success-geo-bg: rgb(255, 255, 255);
    --app-ship-completed-success-geo-box-shdw: #02c362;
    --app-leaflet-draw-actions-a: rgba(39, 39, 39, 0.8);
    --app-leaflet-draw-actions-a-hvr: rgba(39, 39, 39, 0.9);
    --app-select-check-label-border-before: #ccc;
    --app-radio-group-bg: #efefef;
    --app-radio-group-select-check-checked: rgb(0 0 0 / 50%);
    --app-select-check-device-label-border-before: #979797;
    --app-select-check-device-label-box-shdw-before: #00000080;
    --app-select-check-device-disabled-bg: #efefef;
    --app-slider-bg: #ccc;
    --app-input-checked-slider: #00e275;
    --app-comapny-status-0: #02c362;
    --app-company-status-0-border: rgba(1, 196, 102, 0.5);
    --app-comapny-status-1: rgb(43, 43, 43);
    --app-company-status-1-border: rgb(43, 43, 43, 0.5);
    --app-group-selected-companies-li-bg: #eee;
    --app-group-selected-companies-li-border: #999;
    --app-group-selected-companies-li-span: rgba(0, 0, 0, 0.7);
    --app-group-selected-companies-li-span-hvr: rgba(0, 0, 0, 1);
    --app-ship-src-dest-ship-path-l-border: rgb(111, 111, 111);
    --app-a-a-f-show-bg: #fafafa;
    --app-a-a-f-show-box-shdw: #999;
    --app-c-a-a-t-hvr: #aaa;
    --app-stop-box-bg: #d8d8d8;
    --app-stop-box-border: #999;
    --app-stop-box-n-bg: #eee;
    --app-i-sec-stat-b-bottom: #efefef;
    --app-as-icn-bg: rgb(239, 239, 239);
    --app-as-icn-border: rgb(170, 170, 170);
    --app-a-s-btn-hover-as-lbl-txt-shdw: #ddd;
    --app-del-icn-l-border: #999;
    --app-del-icn-l-border-hvr: #666;
    --app-done-icn-l-border: #999;
    --app-n-form-btm-t-border: #ddd;
    --app-ship-path-stop-bg: rgb(75, 75, 75);
    --app-loc-s-list-border: #aaa;
    --app-loc-s-list-box-shdw: #999;
    --app-loc-s-list-li-hover: #fafafa;
    --app-sel-box: #333;

    /* Kit Status */
    --ks-primary-color: #fff;
    --ks-primary-bg: #fff;
    --ks-ref-logo-sep: #efefef;
    --ks-reject: #ea3223;
    --ks-approve: #75f94d;
    --ks-assess: rgb(255, 152, 37);
    --ks-contact: #55bceb;
    --ks-sb-h-2: #676767;
    --ks-sb-bar-border: #ccc;
    --ks-sb-fill-bg: #00ddb3;
    --ks-sb-check-box-shdw: #999;
    --ks-sb-check-icn: #fff;
    --ks-sb-check-green: #00ddb3;
    --ks-sb-check-yellow: rgb(255, 203, 25);
    --ks-sb-check-orange: #FAA356;
    --ks-sb-check-red: rgb(211, 47, 47);
    --ks-sb-last-update: #aaa;
    --ks-sb-det-sec: #bbb;
    --ks-sb-det-sec-v: #272727;
    --ks-percentage-v-l-border: #272727;
    --ks-temp-frame-sec-b-bottom: #efefef;
    --ks-temp-frame-border: #ddd;
    --ks-temp-frame-box-shdw: #444;
    --ks-temp-frame-border-i-p-border: #fff;
    --ks-select-role: rgb(151, 151, 151);
    --ks-temp-frame-m-ip: #f0525a;
    --ks-p-p-name-bg: #02c362;
    --ks-add-c-logo: #57b3e8;

    /* Pdf Parser Page */
    --pp-left-bar-background: #f8f8f8;
    --pp-right-bar-background: #fff;
    --pp-primary-font-color: #181818;
    --pp-sec-font-color: #fff;
    --pp-thrid-font-color: #000;
    --pp-header-b-border: #272727;
    --pp-selectbox-txt-shadow: #999;
    --pp-primary-border-color: #272727;
    --pp-primary-background-color: #fff;
    --pp-pdf-upload-background: #270ADC;
    --pp-fltr-sec-background: #fefefe;
    --pp-fltr-row-t-border: #fefefe;
    --pp-fltr-box-shadow: rgba(0, 0, 0, 0.5);
    --pp-fltr-box-shadow-hover: rgba(0, 0, 0, 0.30);
    --pp-fltr-box-selected: #DEDBF0;
    --pp-fltr-box-val-l-border: #d8d8d8;
    --pp-sel-fltr-lbl-color: #fff;
    --pp-sel-fltr-lbl-bacground: #1B079C;
    --pp-sel-fltr-lbl-hvr-background: #1B079C;
    --pp-sel-fltr-lbl-hvr-color: #fff;
    --pp-clr-fltr-color: #270ADC;
    --pp-resize-panel-border: #bdbdbd;
    --pp-resize-panel-background: #efefef;
    --pp-pdf-list-row-b-border: rgba(0, 0, 0, 0.25);
    --pp-pdf-list-row-bg: #fff;
    --pp-alt-pdf-list-row-bg: #fdfdfd;
    --pp-pdf-list-row-bg-hover: #fcfcfc;
    --pp-pdf-list-row-a-box-shadow: rgba(85, 178, 231, 1); /* Are We Using This ?? */
    --pp-pdf-failed-logo-color: rgb(235, 50, 35);
    --pp-pdf-err-color: #EB3223;
    --pp-failed-pdf-cnt: rgba(235, 50, 35, 1);
    --pp-success-pdf-cnt: #02C362;
    --pp-progress-bar-background: #D9D9D9;
    --pp-progress-bar-bg-processing: #02C362;
    --pp-download-icon-backgound: #272727;
    --pp-download-icon-color: #fff;
    --pp-download-icon-processed-sdw-hover: #666;
    --pp-download-icon-processed-sdw-active: #333;
    --pp-brand-c: rgba(0, 226, 117, 0.5); /* Are We Using This ?? */
    --pp-i-stat-icn: rgba(235, 50, 35, 1); /* Are We Using This ?? */
    --pp-alarm-box-background: #00AF41;
    --pp-alarm-box-hover-shadow: #999;
    --pp-alarm-box-alarm-background: #DF5031;
    --pp-alarm-box-assess-background: #FFAB00;
    --pp-alarm-status-box-r-border: #787676;
    --pp-selected-batch-pdf-bg: #CFDCE4;
    --pp-pdf-list-row-l-backgorund: #fefefe;
    --pp-pdf-failed-process-row-bg: rgba(235, 50, 35, 0.05);
    --pp-pdf-process-row-disable-bg: rgba(210, 210, 210, 0.1);
    --pp-pdf-p-list-row-col1-color: rgba(120, 118, 118, 1);
    --pp-pdf-details-close-icn-shdw-hover: #999;
    --pp-pdf-details-hdr-bg: #efefef;
    --pp-pdf-details-sec-r-border: #fff;
    --pp-pdf-details-upper-sec-bg: #C4C4C4;
    --pp-pdf-details-sec-font-color: #000;
    --pp-export-csv-border: rgb(151, 151, 151);
    --pp-export-csv-sdw-active: #666;
    --pp-export-csv-bg-hover: #1B079C;
    --pp-export-csv-icn-stroke-hover: #fff;
    --pp-pdf-temp-table-border: #333;
    --pp-pdf-temp-table-hdr-color: #3B3B3B;
    --pp-pdf-temp-table-hdr-background: #c4c4c4;
    --pp-str-config-hdr-background: #efefef;
    --pp-str-config-hdr-color: #3B3B3B;
    --pp-alarm-info-bar-shadow: #999;
    --pp-alarm-info-bar-background: #efefef;
    --pp-ptr-strength-color: #474747;
    --pp-ptr-active-ing-color: #4b4b4b;
    --pp-ptr-active-ing-background: #FDE6AB;
    --pp-view-alarm: #45A1D6;
    --pp-collapse-alram-background: rgba(135, 135, 135, 0.33);
    --pp-collapse-alram-shadow: rgba(135, 135, 135, 0.1);
    --pp-collapse-alram-background-hover: rgba(135, 135, 135, 0.75);
    --pp-collapse-alram-shadow-hover: rgba(135, 135, 135, 0.75);

    /* Modal Component */
    --modal-background-color: rgba(0, 0, 0, 0.6);
    --modal-content-hdw-lr: rgba(0, 0, 0, 0.2);
    --modal-content-hdw-tb: rgba(0, 0, 0, 0.17);
    --modal-content-background-color: #fff;
    --modal-close-icn: #ccc;
    --modal-close-icn-hover: #181818;
    --modal-button-bg-color: #270ADC;
    --modal-button-color: #ffffff;
    --modal-header-color: #181818;

    /* Help Page */
    --help-ext-link-box-a: rgb(87, 179, 232);
    --help-ext-link-box-bg-hover: rgba(87, 179, 232, 0.3);

    /* Notification Pop Up */
    --notifi-pop-up-bg: #fff;
    --notifi-pop-up-box-shdw: #666;
    --notifi-pop-up-color: rgb(88, 88, 88);
    --notifi-item-b-border: #efefef;
    --notifi-item-icn-bg: rgba(0, 0, 0, 0.3);
    --notifi-item-icn-box-shdw: #aaa;
    --notifi-item-icn-close-bg-hover: rgba(100, 100, 100, 0.1);
    --notifi-item-icn-close-box-shdw-hover: rgba(100, 100, 100, 0.6);
    --notifi-item-icn-close-bg-active: rgba(100, 100, 100, 0.2);
    --notifi-fltr-t-border: #eaeaea; /* Are We Using This ?? */
    --notifi-item-time-ago: rgb(88, 88, 88);
    --notifi-item-dot-time-ago-bg: #55b2e7;
    --notifi-item-dot-time-ago-box-shdw: #666;
    --notifi-item-icn-danger-bg: #f0525a;
    --notifi-item-icn-warning-bg: #ffce00;
    --notifi-item-icn-success-bg: #4eea79;
    --notifi-item-icn-primary-bg: #55b2e7;
    --notifi-item-color-heat-danger: #ff9825;
    --notifi-item-color-heat-warning: #ffcb6a;
    --notifi-item-color-cold-danger: #7ddaff;
    --notifi-item-color-cold-warning: #7ddaff;
    --notifi-item-color-dry-danger: #ff9825;
    --notifi-item-color-dry-warning: #ff9825;
    --notifi-item-color-wet-danger: #7ddaff;
    --notifi-item-color-wet-warning: #7ddaff;
    --notifi-contxt-err-pop-bg: #fff;
    --notify-item-box-shdw: #54b96a;
    --notify-item-bg: #fff;
    --notify-box-c-icn-color: #54b96a;
    --notify-item-c-icn-color: #272727;

    /* Create Shipment Component */
    --create-ship-close-icn-hover-txt-shdw: #aaa;
    --ship-profile-config-det-border: #efefef;
    --ship-profile-config-det-box-shdw: #999;

    /* Date Time Picker Component -- Used Classnames */
    --d-t-p-shadow-box-shadow: #999;
    --d-t-p-shadow-bg: #fff;
    --react-datepicker-top-bg: #fff;
    --react-datepicker-top-color: #272727;
    --react-datepicker-top-week-days-color: #272727;
    --d-t-p-r-sec-l-border: #efefef;
    --sel-date-sel-day-color: #55b2e7;
    --d-t-p-r-sec-ip-box-border: #ccc;
    --d-t-p-r-sec-ip-box-border-err: #f0525a;
    --d-t-p-r-sec-i-fld-color: #272727;
    --d-t-p-ftr-b-top: #efefef;
    --d-t-p-ftr-cancel-bg: #fff;
    --d-t-p-ftr-cancel-color: #999;

    /* Asset Tracking */
    --at-primary-bg: #fff;
    --at-list-fiter-section-bg: #d8d8d8;
    --at-list-fiter-section-box-shdw: #aaa;
    --at-list-fiter-section-b-border: #9d9ea1;
    --at-list-item-bg: #fff;
    --at-list-item-selected-bg: #E1F6FF;
    --at-list-item-icn-color: #aaa;
    --at-primary-color: #272727;
    --at-secondary-color: #aaa;
    --at-thrid-color: #000;
    --at-list-item-loc-icn-color: #585858;
    --at-active-status-bg: #D32F2F;
    --at-inactive-status-bg: #00E275;
    --at-active-status-box-shdw: rgba(0, 0, 0, 0.4);
    --at-p-type-x-bg: #da939360;
    --at-p-type-0-bg: #00E27550;
    --at-p-type-1-bg: #FAA35658;
    --at-p-type-2-bg: #A2B2FB58;
    --at-p-type-3-bg: #3B7BF550;
    --at-p-type-4-bg: #A2B2FB50;
    --at-d-info-sec-x-bg: #da9393;
    --at-d-info-sec-0-bg: #00E275;
    --at-d-info-sec-1-bg: #FAA356;
    --at-d-info-sec-2-bg: #A2B2FB;
    --at-d-info-sec-3-bg: #3B7BF5;
    --at-d-info-sec-4-bg: #A2B2FB;
    --at-box-shadow-color: #999;
    --at-scondary-box-shdw: #333;
    --at-close-icon-color: #777;
    --at-active-loc-color: #585858;
    --at-a-pop-switch-color: #474747;
    --at-a-pop-timeline-color: #585858;
    --at-a-pop-timeline-evnt-t-color: #474747;
    --at-close-pop-btn: #474747;
    --at-d-info-fld-lbl: rgb(59, 59, 59);
    --at-map-a-t-icn: #3EDF62;
    --at-map-a-t-icn-shdw: #000;
    --at-map-receter-border: rgba(0, 0, 0, 0.2);
    --at-fltr-sec-bg: #d8d8d8;
    --at-fltr-sec-b-bottom: #9d9ea1;
    --at-fltr-sec-box-shdw: #aaa;
    --at-fltr-sec-b-left: rgb(170, 170, 170);
    --at-device-cnt-txt-shdw: #efefef;
    --at-bar-red: rgba(254, 0, 0, 0.65);
    --at-bar-yellow: rgba(255, 235, 60, 0.65);
    --at-bar-green: rgba(76, 175, 80, 0.65);
    --at-bar-bar-leg-l: #efefef;
    --at-p-c-b-ox-shdw: #ddd;
    --at-p-c-b-ox-shdw-hvr: #aaa;
    --at-prod-item-a: #fafafa;
    --at-prod-action-svg: #676767;
    --at-add-prod-type-bg: #efefef;
    --at-add-prod-type-bg-hvr: #d8d8d8;
    --at-add-prod-type-box-shdw-hvr: #efefef;

    /* Configuration */
    --c-ext-lnk-box-colr: rgb(87, 179, 232);
    --c-ext-lnk-box-colr-hvr: rgba(87, 179, 232, 0.3);
    --c-config-enable-creation: rgb(153, 153, 153);
    --c-temp-thumb-sec-border: #efefef;
    --c-temp-thumb-sec-box-shdw: #999;
    --c-temp-thumb-sec-box-shdw-hvr: #666;
    --c-temp-thumb-bg: #02c362;
    --c-pop-config-header-b-border: #979797;
    --c-pop-config-header-p-type-bg: #96d796;
    --c-slider-bg: #ccc;
    --C-slider-bg-before: #fff;
    --c-slider-input-checked: #0093E5;
    --c-line-b-bottom: #efefef;
    --c-line1-b-bottom: #ccc;
    --c-metrics-checkbox-bg: white;
    --c-metrics-checkbox-border: #ccc;
    --c-metrics-checkbox-bg-checked: #55b2e7;
    --c-invite-user-bg: #270ADC;
    --c-invite-user-color: #fff;
    --c-rc-slider-rail-bg: #abe2fb;
    --c-rc-track-rail-bg: #55B2E7;
    --c-rc-track-tooltip-color: #000;
    --c-i-sec-color: rgb(59, 59, 59);
    --c-i-fld-border: rgb(151, 151, 151);
    --c-tooltip-color: #b5b7b7;
    --c-tooltip-txt-color: #fff;
    --c-tooltip-txt-bg: #444;
    --c-tooltip-txt-box-shdw: #999;
    --c-right-bar-config: #fff;
    --c-fld-sec-b-bottom: #efefef;
    --c-fld-sec-r-o-bg: #fdfdfd;
    --c-fld-sec-tol-ip: #979797;
    --c-i-sec-b-border: #efefef;
    --c-rc-slider-border: #55b2e7;
    --c-eye-icon-color: #787676;
    --c-a-lbl-h-icn: rgba(0, 0, 0, 0.2);
    --c-fld-lbl-2-color: #575757;
    --c-communication-interval-l-border: rgba(0, 0, 0, 0.4);
    --c-metrics-checkbox-major-border: #828282;
    --c-fld-sec-dhm-border: #ddd;
    --c-info-color: #999;
    --c-visibility-color: #999;
    --c-visibility-color-hvr: #666;
    --c-btn-sec-t-border: #efefef;
    --c-btn-cancel-bg: #aaa;
    --c-geo-list-item-icn-color: #666;
    --c-geo-list-item-icn-color-hvr: #272727;
    --c-geo-list-item-rem: #f0525a;
    --c-geofence-popup-bg: #ffffff;
    --c-geofence-popup-box-shdw: rgb(0 0 0 / 50%);
    --c-geofence-popup-hdr-bg: #D8D8D8;
    --c-geofence-popup-hdr-box-shdw: #999;
    --c-geofence-popup-hdr-txt: rgb(43, 43, 43);
    --c-geofence-popup-c-btn: #aaa;
    --c-prod-info-2: #C9D2FD;
    --c-prod-info-3: #FCCA9D;
    --c-ptr-config-border: #ddd;
    --c-ptr-config-close: #474747;
    --c-ptr-config-close-hvr: #272727;
    --c-ip-p-m-border: #979797;
    --c-ip-p-m-bg: #efefef;
    --c-ip-p-m-bg-not-d-active-bg: #DFDFDF;
    --c-ip-p-m-bg-not-d-active-box-shdw: #DFDFDF;
    --c-d-sel-box-border: #ddd;
    --c-d-sel-box-bg: rgb(254, 255, 254);
    --c-d-sel-box-border-after: rgb(59, 59, 59);
    --c-template-hdr: rgb(43, 43, 43);
    --c-template-hdr-bg: rgb(216, 216, 216);
    --c-template-hdr-txt-shdw: #fff;
    --c-template-hdr-box-shdw: rgba(0, 0, 0, 0.5);
    --c-ship-reports-hdr: #6A6A6A;
    --c-ship-reports-data: #272727;
    --c-ship-reports-data-b-border: #999999;
    --c-ship-reports-actv-icn: #666;
    --c-ship-reports-i-sec-b-border: #C4C4C4;
    --c-i-fld-lbl: #272727;
    --c-sensor-item-bg-chkbox: #efefef;
    --c-sensor-item-border-chkbox: #ccc;
    --c-sensor-item-border-chkbox-hvr: #aaa;
    --c-sensor-item-bg-chkbox-a: #55B2E7;
    --c-sensor-item-chkbox: #272727;
    --c-radio-box-bg: #fff;
    --c-radio-box-box-shdw: #ccc;
    --c-radio-box-box-shdw-hvr-before: #aaa;
    --c-radio-box-a: #55B2E7;
    --c-radio-box: #272727;
    --c-notify-users: #979797;
    --c-add-new-u: #57B3E8;
    --c-custom-date-range-box-border: #999;
    --c-custom-date-range-box-shdw: #999;
    --c-custom-date-range-box-bg: #fff;
    --c-s-r-m-box: #54b96a;
    --c-s-p-edit-config-bg: #fff;
    --c-s-p-edit-config-icn: #888787;
    --c-s-p-edit-config-icn-hvr: #aaa;
    --c-s-p-edit-config-lbl: #57B3E8;
    --c-i-config-icn: #fff;
    --c-i-config-border-before: #3B3B3B;
    --c-leg-sec-ip: #979797;
    --c-pdf-pro-ip-border: #ddd;

    /* Sweet Alert */
    --sa-primary-bg: #fff;
    --sa-alert-bg: rgba(0, 0, 0, 0.2);
    --sa-alert-content-box-shdw1: rgba(0, 0, 0, 0.2);
    --sa-alert-content-box-shdw2: rgba(0, 0, 0, 0.17);
    --sa-alert-hdr: #2b2b2b;
    --sa-alert-body: #999999;
    --sa-alert-footer: #2b2b2b;
    --sa-alert-btn: #57b3e8;
    --sa-alert-color: #ffffff;
    --sa-alert-btn-cancel: #999;
    --sa-alert-body-warning: #f8bb86;

    /* Live Risk Iq */
    --lriq-primary-color: #fff;
    --lriq-primary-bg: #fff;
    --lriq-pdf-hdr-b-bottom: #272727;
    --lriq-fltr-sec-bg: #d9d9d9;
    --lriq-pdf-list-row-b-bottom: #DEDEDE;
    --lriq-pdf-list-row-alt: #fdfdfd;
    --lriq-pdf-list-row-box-shdw-active: rgba(85, 178, 231, 1);
    --lriq-up-err: rgba(235, 50, 35, 1);
    --lriq-ship-riq-prog-border: rgba(102, 102, 102, 0.2);
    --lriq-ship-riq-prog-bg: rgba(230, 230, 230, 0.2);
    --lriq-ship-stat-red-bg: #EB3223;
    --lriq-ship-stat-yellow-bg: #FFCE00;
    --lriq-ship-stat-green-bg: #04DE70;
    --lriq-ship-stat: #fff;
    --lriq-config-displ-list-bg: #ddd;
    --lriq-config-displ-list-box-shdw: #333;
    --lriq-ship-list-row-b-bottom: #dedede;
    --lriq-ship-list-hdr-b-bottom: #272727;
    --lriq-ship-list-view-before-border: #bdbdbd;
    --lriq-ship-list-view-before-bg: #efefef;
    --lriq-ship-list-view-after-color: #272727;
    --lriq-risk-box-shdw: #aaa;
    --lriq-risk-box-yellow-color: #272727;
    --lriq-logo-i: #b5b7b7;
    --lriq-alarm-highlights-li: #E2E2E2;
    --lriq-alarm-highlights-li-box-shdw: rgb(0 0 0 / 2%);
    --lriq-alarm-highlights-li-bg: #D8D8D8;
    --lriq-alarm-highlights-li-bg-hover: #f6f6f6;
    --lriq-modal-box-shdw: rgba(0, 0, 0, 0.75);
    --lriq-cir-bg: rgb(153, 159, 161);
    --lriq-ah-pop-close: #b7b7b7;
    --lriq-ah-pop-close-hvr: #272727;
    --lriq-config-page-bg: rgba(255, 255, 255, 0.6);
    --lriq-eve-rt: rgb(88, 88, 88);
    --lriq-selecetd-fltr: #333;
    --lriq-selecetd-fltr-bg: #d9d9d9;
    --lriq-selecetd-fltr-after: #373737;
    --lriq-selecetd-fltr-bg-hvr: #C9C9C9;
    --lriq-mapboxgl-popup: rgb(43, 43, 43);
    --lriq-m-r-box-shdw: #aaa;
    --lriq-r-d-info-bg: rgba(255, 0, 13, 10%);
    --lriq-r-d-info: #272727;
    --lriq-r-d-info-bg-y: rgba(255, 206, 0, 10%);

    /* Aggregated RisK Iq */
    --riq-primary-color: #fff;
    --riq-secondary-color: #181818;
    --riq-third-color: #000;
    --riq-primary-bg: #fff;
    --riq-pdf-hdr-b-bottom: #272727;
    --riq-view-bg: #1B079C;
    --riq-fltr-sec-bg: #f8f8f8;
    --riq-pdf-list-row: #181818;
    --riq-pdf-list-row-b-bottom: #e8e8e8;
    --riq-pdf-list-row-hdr: #525252;
    --riq-pdf-list-row-alt: #f8f8f8;
    --riq-pdf-list-row-box-shdw-active: rgba(85, 178, 231, 1);
    --riq-up-err: rgba(235, 50, 35, 1);
    --riq-ship-riq-prog-border: rgba(102, 102, 102, 0.2);
    --riq-ship-riq-prog-bg: rgba(230, 230, 230, 0.2);
    --riq-ship-stat-red-bg: #DF5031;
    --riq-ship-stat-yellow-bg: #FFAB00;
    --riq-ship-stat-green-bg: #00AF41;
    --riq-filter-sep-line: #999;
    --riq-hdr-name: #3b3b3b;
    --riq-first-bar-bg: #f8f8f8;
    --riq-first-bar-r-border: #9d9ea1;
    --riq-first-bar-box-shdw: #aaa;
    --riq-sec-bar-r-border: #aaa;
    --riq-chart-display-bg: #f3f0f0;
    --riq-right-bar-bg: #cad4d5;
    --riq-top-box-bg: #FBFBFE;
    --riq-top-box-shdw: #999;
    --riq-pdf-list-row-a: #DEDBF0;
    --riq-clr-sel-bg: #efefef;
    --riq-clr-sel-bg-hvr: #dedede;
    --riq-compare-bg: #270ADC;
    --riq-compare-box-shdw-hvr: #aaa;
    --riq-i-progress-bar-befr-aftr-bg: #272727;
    --riq-i-progress-t-border: #4B4B4B;
    --riq-i-progress-border: #fff;
    --riq-risk-score-box-shdw: #aaa;
    --riq-risk-score-box-r-after: #666;
    --riq-fact-lbl-color: #666;
    --riq-checkbox-border: #828282;
    --riq-checkbox-chkd-bg: #0093E5;
    --riq-rdr-leg-li-leg-sc: #555;
    --riq-rdr-leg-li-leg-sc-bg: #eaeaea;
    --riq-rdr-leg-li-leg-clr-border: #aaa;
    --riq-rdr-leg-li-leg-clr-box-shdw: #666;
    --riq-chart-legend-border-0: #aaa;
    --riq-chart-legend-border-1: #00C7A3;
    --riq-chart-legend-border-2: #CDE838;
    --riq-chart-legend-border-3: #955196;
    --riq-chart-legend-border-4: #FF8A00;
    --riq-chart-legend-border-5: #6161FF;
    --riq-i-prog-bar-border: #b3b3b3;
    --riq-i-prog-bar-bg-after: #b3b3b3;
    --riq-risk-t-border: #979797;
    --riq-r-fact-col-g: #4FCC9810;
    --riq-l-c-fltr-li: #270ADC;
    --riq-l-c-fltr-li-border: #270ADC;
    --riq-l-c-fltr-li-bg: #fff;
    --riq-l-c-fltr-li-box-shdw-hvr: #270ADC;
    --riq-l-c-fltr-li-a-bg: #270ADC;
    --riq-l-c-fltr-li-a-sltd-color: #fff;
    --riq-top-box-riq-box-shdw: #ddd;
    --riq-top-box-fltr-box-shdw: rgba(0, 0, 0, 0.25);
    --riq-top-box-fltr-box-shdw-hvr: rgba(0, 0, 0, 0.30);
    --riq-top-box-fltr-box-sel-border: #b6b6b6;
    --riq-summary-sec-li-border: #E2E2E2;
    --riq-summary-sec-li-l-border: #989898;
    --riq-summary-sec-li-box-shdw: rgba(0, 0, 0, 0.02);
    --riq-summary-sec-li-border-before: #aaa;
    --riq-summary-sec-li-box-shdw-before: #666;
    --riq-summary-sec-hdr-leg-r-sc-bg: #efefef;
    --riq-sum-c-li-before: #4b4b4b;
    --riq-l-o-d-span: #4b4b4b;
    --riq-clr-94: #949494;
    --riq-l-p-before: #4b4b4b;
    --riq-l-r-s-bg: #eaeaea;
    --riq-l-r-s-n: #555;
    --riq-r-fact-col-r-name-border: #dfdfdf;
    --riq-r-fact-col-r-name-bg: rgba(0, 0, 0, 0.05);
    --riq-r-f-v-bg: #aaa;
    --riq-risk-fact-insights: #fcfcfc;
    --riq-data-not-availble-before-bg: #E0E0E0;
    --riq-riq-l-share: #270ADC;
    --riq-riq-l-share-border: #270ADC;
    --riq-riq-l-hvr-box-shdw: #999;

    /* User Profile */
    --up-primary-color: #fff;
    --up-secondary-color: rgb(43, 43, 43);
    --up-thrid-color: rgb(59, 59, 59);
    --up-fourth-color: rgb(118, 118, 118);
    --up-hdr-bg: rgb(216, 216, 216);
    --up-hdr-box-shdw: rgba(0, 0, 0, 0.5);
    --up-sup-box-bg: rgb(241, 241, 241);
    --up-sup-box-s-b-f-a: rgb(84, 155, 246);
    --up-input-sec-err: #f0525a;
    --up-alert-profile-select-bg: #ddd;
    --up-alert-profile-select: #000;
    --up-primary-border: rgb(151, 151, 151);
    --up-i-u-p: #2b2b2b;
    --up-u-p-role-border: rgba(1, 196, 102, 0.5);
    --up-u-p-role: rgb(2, 195, 98);

    /* App Mobile View CSS */
    --amv-primary-color: #fff;
    --amv-primary-bg: #fff;
    --amv-hdr-bg: #000;
    --amv-mapview: #4e97c2;
    --amv-nav-list-li-a: #55B2E7;
    --amv-child-nodes: #666;
    --amv-slider-bg: #ccc;
    --amv-input-checked: #00e275;
    --amv-dashboard-switch: #efefef;
    --amv-togge-btn: #d9d9d9;
    --amv-togge-btn-a: #55B2E7;
    --amv-hdr-f-border-after: #272727;
    --amv-geo-list-item-t-border: #efefef;

    /* Src Index.scss */
    --i-mapbox-recenter: #8090a8;
    --i-mapbox-recenter-bg: #272727;
    --i-mapbox-recenter-box-shdw: #000;
    --i-mapbox-recenter-bg-hvr: #222;
    --i-mapboxgl-map-style-box-shdw: #333;
    --i-mapboxgl-map-style-div: #efefef;
    --i-mapboxgl-map-style-div-bg: #535353;
    --i-mapboxgl-map-style-div-a: #bfb675;
    --i-mapboxgl-map-style-div-a-bg: #333;
    --i-mapboxgl-no-data-popup-txt-shdw: #ccc;
    --i-mapboxgl-no-data-popup-bg: rgba(0, 0, 0, 0.2);
    --i-ui-err: #f0525a;
    --i-ui-err-border: #efefef;

    /* Insights */
    --in-primary-color: #fff;
    --in-sec-color: #272727;
    --in-primary-bg: #fff;
    --in-sec-bg: #272727;
    --in-thrid-bg: #efefef;
    --in-insights-blue: #55b2e7;
    --in-primary-border: #ccc;
    --in-left-bar-bg: #d8d8d8;
    --in-d-f-sec-bg: #f6f6f6;
    --in-hdr-f-box-shdw: #666;
    --in-date-range-rep-border: #b0b0b0;
    --in-c-l-box-shdw: #999;
    --in-c-l-temp: #d14449;
    --in-c-l-shock: #ff8f00;
    --in-c-l-hum: #00568b;
    --in-c-desc: #878b8d;
    --in-chart-empty: #c1c1c1;
    --in-c-code-0: #00568b;
    --in-c-code-1: #367aa4;
    --in-c-code-2: #7fa4bf;
    --in-c-code-3: #e1c4a1;
    --in-c-code-4: #efaf61;
    --in-c-code-5: #f7931d;
}