.help-details {
    padding: 3% 40px;
}

.help-details .sup-box {
    width: 64%;
}

.ext-lnk-box {
    width: 90%;
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
}

.ext-lnk-box a {
    display: block;
    padding: 10px 50px;
    border: 1px solid var(--c-ext-lnk-box-colr);
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 0.64px;
    color: var(--c-ext-lnk-box-colr);
    border-radius: 3px;
}
.ext-lnk-box a:hover {
    background: var(--c-ext-lnk-box-colr-hvr);
}
.config-enable-creation {
    margin: 0 0 20px 46px;
    display: flex;
    align-items: center;
}
.config-enable-creation .fld-lbl {
    margin-left: 10px;
    font-size: 16px;
    font-weight: 700;
    flex-grow: 1;
}
.config-enable-creation .label {
    padding-bottom: 2px;
}
.config-enable-creation .desc {
    font-size: 10px;
    color: var(--c-config-enable-creation);
    font-weight: 400;
}
.config-enable-creation.child-sec {
    margin: -6px 0 20px 100px;
    display: flex;
}
.config-enable-creation.child-sec .label {
    font-weight: 400;
    font-size: 14px;
    padding-left: 0px;
}
.edit-temp-sec {
    margin-top: 6px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}
.temp-thumb-sec {
    width: 50px;
    height: 60px;
    border: 1px solid var(--c-temp-thumb-sec-border);
    box-shadow: 0 1px 2px var(--c-temp-thumb-sec-box-shdw);
    border-radius: 2px;
    cursor: pointer;
    box-shadow: 0 1px 2px var(--c-temp-thumb-sec-box-shdw);
    font-weight: 400;
}
.temp-thumb-sec:hover {
    box-shadow: 0 1px 2px var(--c-temp-thumb-sec-box-shdw-hvr);
}
.temp-thumb {
    position: relative;
    border-radius: 2px;
    margin: 24% 4%;
    background: var(--c-temp-thumb-bg);
    height: 60%;
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 4px;  
}
.temp-thumb::before {
    content: 'KIT STATUS INDICATOR';
    border-radius: 2px;
    font-size: 3px;
    text-align: center;
    width: 92%;
    margin: 6%;
    position: absolute;
    top: -9px;
}
.temp-thumb::after {
    content: 'For any questions contact';
    border-radius: 2px;
    font-size: 3px;
    text-align: center;
    width: 92%;
    margin: 4%;
    position: absolute;
    bottom: -10px;
}

.config-enable-alerts {
    margin-left: 46px;
    font-weight: 700;
    font-size: 16px;
}
.config-enable-alert-types {
    margin-left: 46px;
    font-weight: 500;
    font-size: 14px;
}
.fld-lbl {
    font-weight: 600;
    font-size: 14px;
}
.fld-lbl.config{
    font-weight: 400;
    font-size: 24px;
}
.fld-lbl-r-o {
    font-weight: 700;
    font-size: 14px;
    padding: 0 4px 4px;
    border-bottom: 1px solid var(--c-pop-config-header-b-border);
}
.fld-lbl-r-o.p-t {
    border: none;
    background: var(--c-pop-config-header-p-type-bg);
    padding: 2px 6px;
    border-radius: 3px;
}

.dist-slider-comp {
    display: flex;
    margin-top: 16px;
}
.dist-slider-comp .d-slider {
    width: 150px;
}
.dist-slider-comp .d-slider .ip-p-m {
    margin: 0 auto 14px;
}
.dist-slider-comp .dist-rep {
    margin-left: 48px;
}
.dist-slider-comp .dist-rep.mi {
    width: 140px;
}
.dist-slider-comp .dist-rep.m {
    width: 180px;
}
.dist-slider-comp .dist-rep.km {
    width: 200px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 24px;
    margin-right: 10px;
}
.select-shipment-late {
    margin-left: 10px;
    border: none;
    border-bottom: 1px solid #ccc;
}
/* .sel-box {
    margin-left: 10px;
    border: none;
    width: 180px;
    font-size: 14px;
    border-bottom: 1px solid black;
    text-transform: capitalize;
    background-color: #d8d8d8;
} */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.switch.sm {
    width: 28px;
    height: 16px;
}

/*switch small*/
.switch.s {
    width: 28px;
    height: 16px;
}
.switch.s .slider:before {
    height: 12px;
    width: 12px;
    left: 2px;
    top: 2px;
}
.switch.s input:checked + .slider:before {
    transform: translateX(12px)
}
.switch.s input:disabled + .slider {
    cursor: not-allowed;
    opacity: 0.75;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--primary-neutral-300);
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: '';
    height: 18px;
    width: 18px;
    left: 3px;
    top: 3px;
    bottom: 4px;
    /* background-color: var(--c-slider-bg-before); */
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: var(--primary-blue-500);
}

input:focus + .slider {
    box-shadow: 0 0 1px var(--primary-blue-500);
}

input:checked + .slider:before {
    -webkit-transform: translateX(21px);
    -ms-transform: translateX(21px);
    transform: translateX(21px);
}

.switch.sm .slider::before {
    height: 12px;
    width: 12px;
    left: 2px;
    top: 2px;
}
.switch.sm input:checked + .slider:before {
    -webkit-transform: translateX(12px);
    -ms-transform: translateX(12px);
    transform: translateX(12px);
}
/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
.line {
    width: 100%;
    border-bottom: 1px solid var(--c-line-b-bottom);
    margin-top: 16px;
    margin-bottom: 16px;
}
.line1 {
    width: 100%;
    border-bottom: 2px solid var(--c-line1-b-bottom);
    margin-top: 30px;
    margin-bottom: 30px;
}
.metrics-checkbox {
    display: none;
}

.metrics-label {
    cursor: pointer;
}
.d .metrics-label {
    cursor: not-allowed;
}

.metrics-checkbox + .metrics-label:before {
    width: 10px;
    height: 10px;
    border: 1px solid var(--c-metrics-checkbox-border);
    border-radius: 2px;
    background: var(--c-metrics-checkbox-bg);
    position: relative;
    display: inline-block;
    margin-right: 1ex;
    content: '';
}

.metrics-checkbox:checked + .metrics-label:before {
    background: var(--c-metrics-checkbox-bg-checked);
}
.metrics-box {
    height: calc(100% - 46px);
    margin-top: 44px;
    display: flex;
    flex-flow: column;
}
/* .scrollbar-container{
    height:800px !important;
} */
.invite-user {
    background: var(--c-invite-user-bg);
    margin-left: 46px;
    color: var(--c-invite-user-color);
    font-size: 14px;
    font-weight: 600;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 10px 30px;
    border-radius: 3px;
    font-family: Montserrat, sans-serif;
}
.rc-slider-rail{
    background-color:var(--c-rc-slider-rail-bg) !important;
}
.rc-slider-track{
    background-color: var(--c-rc-track-rail-bg) !important;
}
.rc-slider::before{
    position:absolute; bottom:2px; left:-8px; height:10px; width:10px; background:var(--c-rc-slider-rail-bg); content:""; border-radius:5px;
}
.rc-slider::after{
    position:absolute; bottom:2px; left:-8px; height:10px; width:10px; background:var(--c-rc-slider-rail-bg); content:""; border-radius:5px;
}
.rc-slider:after{
    right: 0;
    left: auto;
}
.rc-slider-handle{
    border: 2px solid var(--c-rc-track-rail-bg);
    height: 16px !important;
    width: 16px !important;
}
.rc-slider-tooltip-inner{
    background-color: transparent;
    color: var(--c-rc-track-tooltip-color) !important;
    box-shadow: unset;
}
.i-sec label,
div.i-fld[value=''] + label {
    position: absolute;
    font-weight: 300;
    font-size: 18px;
    color: var(--c-i-sec-color);
    /* padding: 8px 4px; */
    top: 0;
    left: 0;
    transition: all ease-out 250ms;
    width: 100%;
}
.i-sec label span {
    font-size: 10px;
}
label.optional::after {
    content: 'optional';
    position: absolute;
    right: 10px;
    font-size: 12px;
}
.i-fld {
    border: 1px solid var(--c-i-fld-border);
    border-width: 0 0 1px 0;
    font-weight: 500;
    font-size: 18px;
    color: var(--c-i-sec-color);
    width: 100%;
    padding: 0px 4px;
    outline: none;
}
.i-carrier {
    height: 32px;
}
.i-fld:focus + label,
input.i-fld:not(:placeholder-shown) + label,
div.i-fld[value] + label,
div.i-fld + label.t{
    top: -18px;
    font-size: 12px;
}
div.i-fld[value='false'] + label, div.i-fld + label.b {
    top: 10px;
    font-size: 18px;
}
.rc-slider-handle-1::before{
    content: 'Min';
    margin-top: -14px;
    font-size: 10px;
    display: block;
    margin-left: -4px;
 }
.temperature-slider .rc-slider-handle-2::before{
    content: 'Max';
    margin-top: -14px;
    font-size: 10px;
    display: block;
    margin-left: -4px;
 }
 .geofence-slider{
     width: 400px;
     margin-top: -16px;
     margin-left: 80px;
 }
 .geofence-slider .rc-slider-rail::before{
    content: '15 minutes';
    margin-top: -14px;
    font-size: 10px;
    display: block;
    margin-left: -4px;
 }
 .geofence-slider .rc-slider-rail::after{
    content: '180 minutes';
    margin-top: -14px;
    font-size: 10px;
    display: block;
    margin-left: 330px;
 }
 /* .geofenceAlert{
     display: block;
 } */
.config .temp-slider {
    margin-top: 20px;
    width: 400px;
}

 .temp-slider .rc-slider-handle.rc-slider-handle-2 {
    z-index: 100;
    margin-left: -3px;
 }

 /*TOOL TIP*/
 .t-tip {
    position: relative;
    cursor: pointer;
    font-size: 18px !important;
    color: var(--primary-neutral-500);
}

.t-tip.ml{
    margin-left: 6px;
}

.t-tip .t-txt {
    display: none;
    position: absolute;
    background: var(--c-tooltip-txt-bg);
    color: var(--c-tooltip-txt-color);
    font-size: 12px;
    border-radius: 4px;
    padding: 10px;
    top: 50%;
    transform: translateY(-50%);
    left: 30px;
	z-index: 9999999;
    white-space: normal;
    min-width: 240px;
    box-shadow: 0 1px 3px var(--c-tooltip-txt-box-shdw);
    line-height: 18px;
    cursor: auto;
}
/* .t-tip .t-txt::before {
    content: ' ';
    position: absolute;
    border: 4px solid #f00;
    border-color: #666 transparent transparent;
    top: 23px;
    left: 50%;
    margin-left: -2px;
} */
.t-tip .t-txt:before {
    content: ' ';
    border: 5px solid var(--c-tooltip-txt-bg);
    border-color: transparent var(--c-tooltip-txt-bg) transparent transparent;
    left: -9px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.t-tip:hover > .t-txt {
    display: block;
}
.t-tip.t .t-txt {
    min-width: auto;
    left: unset;
    top: unset;
    bottom: 28px;
    font-size: 10px;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    transform: unset;
}
.t-tip.t .t-txt:before {
    content: unset;
}
.t-tip.t .t-txt:after {
    content: ' ';
    border: 5px solid var(--c-tooltip-txt-bg);
    border-color: var(--c-tooltip-txt-bg) transparent transparent transparent;
    left: calc(50% - 5px);
    bottom: -10px;
    position: absolute;
}


.t-tip.l .t-txt {
    left: unset;
    right: 100%;
}
.t-tip.l .t-txt:before {
    border-color: transparent transparent transparent var(--c-tooltip-txt-bg);
    left: unset;
    right: -9px;
}

 /*NEW*/
 .config .company-detail-box {
     padding: 2% 2.6% 2% 2.6%;
     height: 100px;
     box-sizing: border-box;
 }
 .right-bar.config {
     background: var(--c-right-bar-config);
 }
 .right-bar.config::after {
    content: '';
    height: 0;
    visibility: hidden;
    display: none;
    opacity: 0;
    font-size: 0;
    line-height: 0;
 }

.form-sec {
    position: absolute; 
    top: 102px;
    width: 100%;
    bottom: 0;
    overflow: auto;
}
.form-sec .fld-sec {
    border-bottom: 1px solid var(--c-fld-sec-b-bottom);
    padding: 20px 40px;
}
.form-sec .fld-sec.a {
    padding: 20px 0px;
    margin: 0 40px;
}
.fld-sec.r-o, .fld-sec.r-o input {
    background-color: var(--c-fld-sec-r-o-bg);
}

.fld-sec.bb{
    border-bottom: 1px solid var(--primary-neutral-200);
}

.check-sec {
    margin-left: 46px;
}
.config .form-sec .fld-sec .temperature-details {
    display: flex;
    padding-bottom: 20px;
}

.config .form-sec .fld-sec.slider-sec input.tol {
    border: 1px solid var(--c-fld-sec-tol-ip);
    border-width: 0 0 1px 0;
    outline: none;
    font-size: 16px;
    padding: 0 4px;
    font-weight: 500;
    width: 100px;
}
.config .form-sec .i-sec, 
.geo-modal .geo-body .config .form-sec .i-sec {
    padding: 10px 0;
    margin: 0 !important;
}
.config .form-sec .i-sec.time-sec {
    overflow: unset;
    padding: 10px 0 0;
}
.config .form-sec .i-sec.sec-hdr {
    padding: 20px 0;
}
.config .form-sec .i-sec label {
    font-weight: 400;
    margin-top: 10px;
}
.config .i-sec .i-fld, .config .i-sec label{
    font-size: 16px;
    box-sizing: border-box;
    padding: 8px 4px 4px;
}

.config .ship-det .i-sec label, 
.config .ship-det .i-sec input.i-fld[value=''] + label {
    top: -10px;
}
.config .ship-det .i-sec .i-fld:focus + label, 
.config .ship-det .i-sec input.i-fld:not(:placeholder-shown) + label, 
.i-sec div.i-fld[value] + label {
    top: -26px;
}

.config .r-o .select-cc, .config .r-o .i-fld {
    cursor: not-allowed;
    font-weight: 500;
}

.config .v-scroll {
    position: relative;
    top: unset;
    bottom: unset;
}
.config .form-sec .i-sec.b-b {
    border-bottom: 1px solid var(--c-i-sec-b-border);
    padding-bottom: 0;
}
.config .form-sec .i-sec .r-t{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 4px;
    padding-right: 4px;
    padding-bottom: 18px;
}

.config .form-sec .fld-sec .error_msg {
    position: static;
    top: unset;
    right: unset;
    margin-top: unset;
    font-size: 10px;
    text-align: left;
}
.config .form-sec .fld-sec .i-sec .r-t + .error_msg {
    margin: -18px 0 18px 4px;
}
.config .form-sec .fld-sec .i-sec .error_msg.i-fld-c.e{
    margin: -20px 0 0 20px;
 }
.config .ship-details-sec {
    width: 60%;
    border-width: 0;
}
.config .rc-slider {
    margin-left: 8px;
}

.config .rc-slider.simple-slider {
    margin: 0px;
}

.rc-slider.rc-slider-disabled {
    background: none;
}
.rc-slider.rc-slider-disabled .rc-slider-handle, .rc-slider-disabled .rc-slider-dot {
    border-color: var(--c-rc-slider-border);
}


.fld-sec.collapse .a-sec:not(.c-a-fld){
    display: none;
}

.c-a-hdr {
    font-size: 18px;
    font-weight: 500;
}
.c-a-hdr, .c-a-fld {
    display: flex;
    justify-content: space-between;
}
.a-col-0 {
    width: 70%;
    display: flex;
    align-items: center;
}
.a-col-1 {
    width: 30%;
    display: flex;
    justify-content: flex-end;
}
.col-1 {
    width: 100px;
    text-align: center;
}
.a-sec {
    padding: 10px 0;
}
.a-sec.d{
    opacity: 0.2;
    cursor: not-allowed;
}
.a-arr {
    margin-right: 4px;
    cursor: pointer;
    color: var(--c-eye-icon-color);
    font-size: 30px;
}
.collapse .a-arr {
    transform: rotate(90deg);
}
.a-lbl-h {
    padding: 0 6px 0 10px;
    font-size: 18px;
}
.a-lbl {
    font-size: 14px;
    padding-left: 10px;
}
.a-lbl-h+.material-icons {
    font-size: 16px;
    color: var(--c-a-lbl-h-icn);
    cursor: default;
}
.itm {
    margin-left: 34px;
    min-width: 44px;
}

.config .metrics-checkbox + .metrics-label:before,
.geo-body .metrics-checkbox + .metrics-label:before {
    width: 16px;
    height: 16px;
    top: 3px;
    cursor: pointer;
}
.metrics-checkbox[disabled] + .metrics-label {
    cursor: default;
}
.metrics-checkbox[disabled] + .metrics-label:before {
    opacity: 0.6;
    cursor: not-allowed;
}
input[disabled], select[disabled] {
    cursor: not-allowed;
    color: var(--primary-neutral-500) !important;
    background-color: var(--primary-neutral-200) !important;
    /* border-color: var(--primary-neutral-200) !important; */
}

.config .slider-sec .metrics-checkbox + .metrics-label:before {
    margin-right: 6px;
}
.config .alerts .metrics-checkbox + .metrics-label:before {
    top: unset;
    margin: unset;
}
.i-sec .f-l:first-child {
    margin-top: 16px;
}
.i-sec .f-l {
    margin: 20px 0 0 48px;
    display: flex;
    align-items: center;
}
.i-sec .f-l .fld-lbl {
    width: 100px;
}
.i-sec .fld-lbl-2 {
    margin: 20px 0 20px 48px;
    font-weight: 500;
    color: var(--c-fld-lbl-2-color);
}
.config-sec-2-col {
    display: flex;
}
.config-sec-2-col > div {
    flex-grow: 1;
}
.config-sec-col-l {}
.config-sec-col-r {
    position: relative;
}
.config-sec-col-r::before {
    position: absolute;
    content: ' ';
    border-left: 1px solid var(--c-communication-interval-l-border);
    top: 0;
    bottom: -16px;
    left: -6%;
}

.config .c-a-fld.h-1 .metrics-checkbox + .metrics-label:before {
    border-color: var(--c-metrics-checkbox-major-border);
}

.config .c-a-fld .i-fld {
    width: 60px;
    text-align: center;
}

.config .time-sec .i-flds {
    display: flex;
    align-items: baseline;
    margin-left: 20px;
}
.m-0, .config .time-sec .i-flds.m-0 {
    margin: 0;
}
.config .time-sec.d .i-flds, .config .form-sec .fld-sec .i-sec.time-sec.d .error_msg.i-fld-c.e {
    margin-left: unset;
}
.config .form-sec .fld-sec .i-sec.time-sec .error_msg.i-fld-c.e {
    margin: 0 0 0 20px;
}
.p-t-20 {
    padding-top: 20px;
}
.config .time-sec .lbl {
    width: 180px;
}
.config .time-sec .ip {
    padding-right: 24px;
}
.config .form-sec .fld-sec.slider-sec .time-sec .ip input.tol{
    width: 50px;
    margin-left: 5px;
    text-align: center;
}
.config .form-sec .fld-sec.slider-sec .time-sec .ip input.tol.dhm, .dhm {
    width: 50px;
    margin: 0px;
    text-align: center;
    border: 1px solid var(--c-fld-sec-dhm-border);
    padding: 5px 4px;
    border-radius: 2px;
    font-size: 16px;
    font-weight: 500;
    outline: none;
}

.ptr-dur-dhm{
    width: 50px !important;
    text-align: center;
    height: 13px;
    font-size: 14px;
    font-weight: 400;
    border: 1px solid var(--primary-neutral-300);
    padding: 9px 0px 8px 0px;
    border-radius: 4px;
    outline: none;
}

.ptr-dur-dhm + .metrics-label{
    cursor: default;
    /* text-transform: uppercase; */
    text-align: center !important;
    display: block;
    padding-top: 4px !important;
    text-align: center;
    font-size: 8px !important;
    position: unset !important;
    margin: 0 !important;
}

.ptr-dur-dhm + .metrics-label.ml-fw{
    font-size: 12px !important;
    font-weight: 400;
}

.dhm + .metrics-label, .dhm + .dhm-label {
    cursor: default;
    text-transform: uppercase;
    text-align: center !important;
    display: block;
    padding-top: 4px !important;
    text-align: center;
    font-size: 8px !important;
    position: unset !important;
    margin: 0 !important;
}

.config .form-sec .fld-sec.slider-sec .time-sec .ip input.tol.dhm, .dhm {
    width: 50px;
    margin: 0px;
    text-align: center;
    border: 1px solid var(--c-fld-sec-dhm-border);
    padding: 5px 4px;
    border-radius: 2px;
    font-size: 16px;
    font-weight: 500;
    outline: none;
}
.dhm + .metrics-label, .dhm + .dhm-label {
    cursor: default;
    text-transform: uppercase;
    text-align: center !important;
    display: block;
    padding-top: 4px !important;
    text-align: center;
    font-size: 8px !important;
    position: unset !important;
    margin: 0 !important;
}


.rc-slider.simple-slider {

}
.rc-slider.simple-slider:before, .rc-slider.simple-slider:after {
    content: unset;
    display: none;
    opacity: 0;
}

.c-info {
    font-weight: 400;
    color: var(--c-info-color);
    font-size: 10px;
    padding-left: 10px;
}
.visibility {
    color: var(--c-visibility-color);
    font-size: 20px;
    cursor: pointer;
}
.visibility:hover {
    color: var(--c-visibility-color-hvr);
}
.c-btn-sec {
    display: flex;
    justify-content: flex-end;
    padding: 20px 40px;
    border-top: 2px solid var(--c-btn-sec-t-border);
}
.c-btn-sec.s-b {
    justify-content: space-between;
}
.c-btn-sec .btn-sec-l, .c-btn-sec .btn-sec-r {
    display: flex;
    align-items: center;
}

.fld-sec.a + .c-btn-sec {
    margin-top: 20px;
}
.c-btn-sec .c-btn{
    font-size: 14px;
    margin-left: 60px;
    padding: 10px;
    width: 100px;  
    box-sizing: border-box;
}
.c-btn.g{
    background: var(--c-btn-cancel-bg);
}
.c-btn.s{
    font-size: 14px;
    width: 136px;
    padding: 6px 8px;
    text-transform: unset;
    border-radius: 2px;
}
.c-btn.dis {
    cursor: not-allowed;
    opacity: 0.7;
}
.geo-add {
    margin-bottom: 20px;
}
.geo-list-item {
    display: flex;
    margin-bottom: 10px;
    align-items: flex-start;
}
.geo-list-item .geo-icn {
    width: 18px;
    height: 18px;
}
.geo-list-item .gf-col-0 {
    width: 24px;
}
.geo-list-item .gf-col-1 {
    width: 20%;
    font-weight: 600;
    font-size: 14px;
}
.geo-list-item .gf-col-2 {
    width: 45%;
    font-size: 12px;
}
.geo-list-item .gf-col-3 {
    width: 100px;
    font-size: 12px;
    text-transform: capitalize;
}
.geo-list-item .gf-col-4 {
    width: 80px;
}

.geo-list-item .gf-col-4 .a-icn {
    font-size: 20px;
    cursor: pointer;
    color: var(--c-geo-list-item-icn-color);
    margin-top: -2px;
    margin-right: 10px;
}
.geo-list-item .gf-col-4 .a-icn:hover {
    color: var(--c-geo-list-item-icn-color-hvr);
}
.geo-list-item .gf-col-4 .a-icn.d {
    font-weight: 600;
}

.geo-list-item.rem {
    color: var(--c-geo-list-item-rem);
}


/*NEW GEO POP*/
.geo-modal, .cnxt-modal {
    background: var(--c-geofence-popup-bg);
    border-radius: 2px;
    width: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 9999;
    box-shadow: 0 2px 4px 0 var(--c-geofence-popup-box-shdw);
    height: 540px;
    transform: translate(-50%, -50%);
}
.geo-modal.config-modal, .cnxt-modal {
    height: 90%;
    width: 90%;
}
.geo-modal .geo-header, .cnxt-modal .c-hdr {
    display: flex;
    justify-content: space-between;
    padding: 12px 14px 8px 14px;
    border: none;
}
.cnxt-modal .c-hdr {
    background: var(--c-geofence-popup-hdr-bg);
    height: 62px;
    box-sizing: border-box;
    align-items: center;
    box-shadow: 0 1px 3px var(--c-geofence-popup-hdr-box-shdw);
}
.geo-modal .g-h-txt, .cnxt-modal .c-hdr .txt {
    color: var(--c-geofence-popup-hdr-txt);
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.33px;
    padding-left: 40px;
    padding-top: 12px;
}
.cnxt-modal .c-hdr .txt {
    font-size: 16px;
    padding-top: 0;
}
.geo-modal .geo-close-icn, .cnxt-modal .c-hdr .cls-icn{
    cursor: pointer;
    opacity: 0.8;
}
.cnxt-modal .c-hdr .cls-icn {
    margin-right: 40px;
}
.geo-modal .geo-close-icn:hover, .cnxt-modal .c-hdr .cls-icn:hover {
    opacity: 1;
}
.geo-modal .geo-body, .cnxt-modal .c-bdy {
    position: absolute;
    top: 50px;
    bottom: 50px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
}
.geo-modal .geo-body.s-c {
    top: 72px;
    bottom: 60px;
}
.cnxt-modal .c-bdy {
    top: 182px;
}
.geo-modal .geo-body .n-form {
    padding: 20px 32px 0;
}

.geo-modal .geo-body .n-form .i-sec {
    margin: 10px 0 20px;
}
.geo-modal .geo-body .n-form .i-sec.g-name {
    flex-grow: 1;
    /*margin-right: 20px;*/
}
.geo-modal .geo-body .n-form .i-sec.alert-type {
    width: 190px;
}
.geo-modal .geo-body .n-form .i-sec.alert-type .i-fld {
    width: 100%;
    padding: 4px;
}

.geo-modal .geo-body .n-form  .i-sec label,
.geo-modal .geo-body .n-form  .i-sec input.i-fld[value=''] + label {
    top: -2px;
}

.geo-modal .geo-body .n-form  .i-sec label.metrics-label {
    position: static;
    margin-left: 60px;
}
.geo-modal .geo-body .n-form .i-sec .i-fld:focus + label, 
.geo-modal .geo-body .n-form .i-sec input.i-fld:not(:placeholder-shown) + label, 
.geo-modal .geo-body .n-form .i-sec div.i-fld[value] + label, 
.geo-modal .geo-body .n-form .i-sec div.i-fld + label.t {
    top: -18px;
}

.geo-modal .loc-s-list.d {
    z-index: 99999;
    position: absolute;
    width: 100%;
}

.geo-modal .geo-ftr, .cnxt-modal .c-ftr {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 52px 24px;
    display: flex;
    justify-content: flex-end;
}
.geo-modal .geo-ftr .c-btn, .cnxt-modal .c-ftr .c-btn {
    font-size: 12px;
    width: 80px;
    margin-left: 60px;
    padding: 10px;
}
.geo-modal .geo-ftr .c-btn.g, .cnxt-modal .c-ftr .c-btn.b {
    background: var(--c-geofence-popup-c-btn);
}

.geo-body .switch-btn.map-styles {
    bottom: 20px;
}

.config-geo .leaflet-pane.leaflet-overlay-pane path.leaflet-interactive,
.config-geo .leaflet-pane.leaflet-marker-pane path.leaflet-interactive {
    fill-opacity: 0.2;
    fill: var(--c-rc-track-rail-bg);
}

.slider-ro {
    width: 100px;
    position: relative;
    height: 2px;
    background: var(--c-rc-slider-rail-bg);
    cursor: not-allowed;
}
.s-ball {
    width: 12px;
    height: 12px;
    position: absolute;
    margin-left: -5px;
    background: var(--c-rc-track-rail-bg);
    margin-top: -5px;
    border-radius: 50%;
}
.s-track {
    position: absolute;
    height: 2px;
    left: 0;
    top: 0;
    background: var(--c-rc-track-rail-bg);
}

/*Product PRofile*/
.prod-info {
    font-size: 12px;
    margin: 4px 0;
    font-weight: 500;
}
.prod-info > span {
    border-radius: 4px;
    padding: 2px 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.prod-info .p-1 {
    background: var(--product-ai-color);
    /* color: var(--primary-white); */
}
.prod-info .p-2 {
    background: var(--c-prod-info-2);
}
.prod-info .p-3 {
    background: var(--c-prod-info-3);
}
.prod-info .default {
    background: var(--default-profile);
    /* color: var(--primary-white); */
}
.product .radio-group {
    width: 221px;
}
.product .radio-group .select-check-label {
    text-align: center;
    min-width: 90px;
}

.list-t-str {
    display: flex;
}
.list-t-str div {
    /* padding: 10px 1%  !important*/
}
.list-t-ststr.n-p > div {
    padding: 0 1% !important;
}
.list-t-r.r-h {
    font-size: 14px !important;
    font-weight: 400;
}
.list-t-str .c-1,
.list-t-str .c-2 {
    width: 180px;
    /* padding: 0 1%; */
    padding: 10px 1% 0 0;
}
.list-t-str.r-h {
    font-size: 14px;
    font-weight: 400;
}

.list-t-r {
    display: flex;
    position: relative;
}
.list-t-r > div {
    padding: 10px 2% 10px 0 !important
}
.list-t-r.n-p > div {
    padding: 0 1% !important;
}
.list-t-r.r-h {
    font-size: 14px !important;
    font-weight: 400;
}
.list-t-r .c-0 {
    width: 10%;
    padding: 0 1%;
}
.list-t-r .c-1 {
    width: 22%;
    padding: 0 1%;
}
.list-t-r .c-r {
    width: 58%;
    padding: 0 1%;
    display: flex;
    justify-content: space-between;
}
.list-t-r .c-r .ip-p-m {
    width: 36%;
}
.list-t-r:not(.r-h) .c-r {
    border: 1px solid var(--c-ptr-config-border);
    border-width: 0 1px;
}
.list-t-r .c-2 {
    width: 22%;
    padding: 0 1%;
}
.list-t-r .c-3 {
    width: 18%;
    padding: 0 1%;
}
.list-t-r .c-4 {
    width: 16%;
    padding: 0 1%;
}
.list-t-r .c-5 {
    width: 18%;
    padding: 0 1%;
}
.fld-sec .list-t-r .close-icn {
    cursor: pointer;
    margin-left: 1.5%;
    top: 14px;
    position: absolute;
    color: var(--c-ptr-config-close);
}
.fld-sec .list-t-r .close-icn:hover {
    color: var(--c-ptr-config-close-hvr);
}
.fld-sec .list-t-r + .error_msg {
    margin: -10px 0 0 1%;
}

.ip-p-m {
    display: flex;
    /* border: 1px solid var(--c-ip-p-m-border); */
    border-radius: 4px;
    width: 80%;
}
.ip-p-m .ip-m {
    font-size: 34px;
    color: var(--primary-blue-500);
    user-select: none;
    width: 32px;
    height: 32px;
    background: var(--primary-neutral-100);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    /* border-right: 1px solid var(--c-ip-p-m-border); */
    cursor: pointer;
}
.ip-p-m .ip {
    width: 56px !important;
    text-align: center;
    flex-grow: 1;
    outline: none;
    border: none;
    padding: 4px 6px;
    font-weight: 500;
    font-size: 14px;
}
.ip-p-m .ip-p {
    font-size: 20px;
    font-weight: 700;
    color: var(--primary-blue-500);
    user-select: none;
    width: 32px;
    height: 32px;
    background: var(--primary-neutral-100);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    /* border-left: 1px solid var(--c-ip-p-m-border); */
    cursor: pointer;
}
.ip-p-m .ip-m:not(.d):active, .ip-p-m .ip-p:not(.d):active {
    background: var(--c-ip-p-m-bg-not-d-active-bg);
    box-shadow: inset 0 0px 3px var(--c-ip-p-m-bg-not-d-active-box-shdw);
}
.ip-p-m .ip-p.d, .ip-p-m .ip-m.d {
    opacity: 0.75;
    cursor: not-allowed;
}

.c-btn.s-m-new {
    text-transform: none;
    font-size: 12px;
    padding: 5px 10px;
}

.c-btn.s-m-new.new-css {
    position: relative;
    top: 20px;
    text-align: center;
    width: 100px;
    color: var(--primary-blue-500);
    background-color: var(--primary-white);
    border: 1px solid var(--primary-blue-500);
    box-shadow: none;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 10px;
    border-radius: 4px;
}

.d-sel-box {
    flex-grow: 1;
    border: 1px solid var(--primary-neutral-300);
    background: var(--c-d-sel-box-bg);
    border-radius: 4px;
    position: relative;
    padding: 0 3px;
    cursor: pointer;
}
.d-sel-box.no-arrow::after {
    display: none;
}
.i-sec .d-sel-box .i-fld {
    border: none;
    width: calc(100% - 32px);
    margin-right: 16px;
    cursor: pointer;
    padding: 12px 12px;
}
.d-sel-box::after {
    position: absolute;
    content: ' ';
    border-width: 7px 5px 0 5px;
    top: 14px;
    right: 12px;
    z-index: 0;
    width: 6px;
    height: 6px;
    border-top: 2px solid var(--primary-neutral-600);
    border-right: 2px solid var(--primary-neutral-600);
    transform: rotate(135deg);
}
.d-sel-box.d::after {
    content: '';
    opacity: 0;
    display: none;
    border: none;
    cursor: pointer;
}
.d-sel-box.d, .d-sel-box.d .i-fld {
    cursor: not-allowed;
}
.c-t-hdr {
    margin-top: 70px;
    font-size: 20px;
    letter-spacing: 0.86px;
    font-weight: 600;
    color: var(--c-template-hdr);
    padding: 16px 2%;
    background: var(--c-template-hdr-bg);
    text-shadow: 0 1px 0 var(--c-template-hdr-txt-shdw);
    box-shadow: 0 1px 3px var(--c-template-hdr-box-shdw);
    display: flex;
    justify-content: space-between;
}

/*Ship Reports*/
.ship-report .s-r-list:empty::before {
    content: 'No Reports available to download';
    text-align: center;
    display: block;
    position: absolute;
    left: 0;
    box-sizing: border-box;
    right: 0;
    top: 50%;
}
.c-n-r {
    position: absolute;
    font-size: 14px;
    padding: 6px 20px;
    right: 80px;
    top: 86px;
    font-weight: 500;
    text-transform: capitalize;
    cursor: pointer;
}

.s-r-row {
    display: flex;
}
.s-r-row.s-r-hdr {
    font-size: 12px;
    color: var(--c-ship-reports-hdr);
    font-weight: 300;
    padding: 72px 54px 0;
    text-align: left;
    user-select: none;
}
.s-r-row.s-r-hdr .material-icons {
    font-size: 20px;
    position: absolute;
    opacity: 0.5;
    margin-top: -2px;
}
.s-r-row.s-r-hdr .material-icons.a {
    opacity: 1;
}
.s-r-row.s-r-hdr .s-col {
    cursor: pointer;
}
.s-r-row > div, .s-r-row > div {
    text-align: left;
}
.s-r-row.s-r-data {
    font-size: 14px;
    color: var(--c-ship-reports-data);
    font-weight: 500;
    padding: 0 4px 10px;
    border-bottom: 1px solid var(--c-ship-reports-data-b-border);
    margin: 0 30px 16px;
}
.s-r-row .col-0{
    width: 30%;
}
.s-r-row .col-1{
    width: 20%;
}
.s-r-row .col-2{
    width: 12%;
}
.s-r-row .col-3{
    width: 14%;
}
.s-r-row .col-4{
    width: 10%;
}
.s-r-row.s-r-hdr .col-5 {
    width: 14%;
    text-align: left;
}
.s-r-row .col-5{
    width: 10%;
    text-align: center;
}
.s-r-row.s-r-hdr .col-6 {
    width: 0%;
}
.s-r-row .col-6{
    width: 4%;
    text-align: right;
}
.s-r-row .act-icns {
    width: 24px;
    color: var(--c-ship-reports-actv-icn);
}

.c-bdy.s-r-conf {
    top: 64px;
    bottom: 68px;
    padding: 0;
}
.s-r-conf .i-sec-box {}
.s-r-conf .i-sec {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--c-ship-reports-i-sec-b-border);
    margin: 0;
    padding: 2% 15%;
}
.s-r-conf .i-sec.n-b {
    border: none;
}
.s-r-conf .i-lbl {
    font-size: 12px;
    width: 220px;
}
.s-r-conf .i-fld {
    border: none;
}
.s-r-conf .i-fld.f {
    display: flex;
}
.i-fld-box {
    display: flex;
    margin-right: 94px;
}
.i-fld-box:last-child {
    margin: 0;
}
.i-fld-lbl {
    font-size: 14px;
    margin-left: 10px;
    color: var(--c-i-fld-lbl);
    font-weight: 500;
}
.status-bar.s {
    height: 18px;
    width: 32px;
}
.status-bar.s::after {
    width: 14px;
    height: 14px;
}
.status-bar.s.a::after {
    left: 16px;
}
.i-fld .col-0 {
    width: 240px;
}
.sensor-item {
    margin-bottom: 36px;
}
.sensor-item .check-box::before {
    content: ' ';
    width: 16px;
    height: 16px;
    background: var(--c-sensor-item-bg-chkbox);
    border: 1px solid var(--c-sensor-item-border-chkbox);
    position: absolute;
    border-radius: 2px;
    left: 0;
}
.sensor-item .check-box:hover:not(.d)::before {
    box-shadow: 0 0px 2px var(--c-sensor-item-border-chkbox-hvr);
}
.sensor-item .check-box.a::before {
    background: var(--c-sensor-item-bg-chkbox-a);
}
.sensor-item .check-box {
    position: relative;
    font-size: 14px;
    padding-left: 28px;
    color: var(--c-sensor-item-chkbox);
    font-weight: 500;
    cursor: pointer;
    display: inline-block;
}
.sensor-item .check-box.c {
    margin-left: 28px;
    margin-top: 20px;
}
.sensor-item .check-box.c::before {
    width: 14px;
    height: 14px;
    margin-top: 2px;
}
.sensor-item .check-box.d, .sensor-item .check-box.d:hover {
    cursor: not-allowed;
}

.radio-box::before {
    content: ' ';
    width: 16px;
    height: 16px;
    background: var(--c-radio-box-bg);
    border: 1px solid var(--c-radio-box-box-shdw);
    position: absolute;
    border-radius: 50%;
    left: 0;
}
.radio-box::after {
    content: ' ';
    position: absolute;
    left: 4px;
    top: 4px;
    background: var(--c-radio-box-box-shdw);
    border-radius: 50%;
    width: 10px;
    height: 10px;
}
.radio-box:hover::before {
    box-shadow: 0 0px 2px var(--c-radio-box-box-shdw-hvr-before);
}
.radio-box.a::before {
    border-color: var(--c-radio-box-a);
}
.radio-box.a::after {
    background: var(--c-radio-box-a);
}
.radio-box {
    position: relative;
    font-size: 14px;
    padding-left: 28px;
    color: var(--c-radio-box);
    font-weight: 500;
    cursor: pointer;
}
.i-fld.notify-users {
    border: 1px solid var(--c-notify-users) !important;
    position: relative;
    min-height: 100px;
}
.i-sec.users-sec .group-select-delete-all {
    margin-top: 4px;
    margin-right: -66px;
}
.notify-users .ip-fld {
    font-size: 14px;
    border: none;
    outline: none;
    margin-left: 6px;
    position: absolute;
    bottom: 6px;
    width: 65%;
}
.add-new-u {
    color: var(--c-add-new-u);
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    position: absolute;
    bottom: 3px;
    display: flex;
    align-items: center;
    left: 68%
}
.add-new-u::before {
    content: "+";
    font-size: 20px;
    margin-right: 4px;
}
.add-new-u.carrier {
    top: 0;
    left: 354px;
}
.add-new-u.carrier::before {
    content: "";
}
.add-new-u.u {
    top: unset;
    left: 68%;
}
.add-new-u.d {
    opacity: 0.5;
    cursor: not-allowed;
    user-select: none;
}
.notify-users .group-selected-companies {
    margin-bottom: 24px;
}
.i-fld .custom-date-range-box {
    border: 1px solid var(--c-custom-date-range-box-border);
    border-radius: 4px;
    position: absolute;
    margin-left: 220px;
    margin-top: -28px;
    z-index: 9999;
    box-shadow: 0 1px 3px var(--c-custom-date-range-box-shdw);
    overflow: hidden;
    background: var(--c-custom-date-range-box-bg);
}
.i-fld .rdrDateDisplayWrapper {
    display: none;
}
.i-fld .custom-date-range-box .c-btn {
    margin: 10px;
}
.c-ftr.s-r-conf {
    padding: 0 15% 24px;
}
.date-range-txt {
    font-size: 14px;
    margin-left: 20px;
    cursor: pointer;
    padding: 3px 0;
}
.s-r-conf .error_msg {
    left: calc(15% + 178px);
    bottom: 10px;
    right: 10px;
}
.custom-date-range-box .rdrCalendarWrapper .rdrMonthAndYearPickers select {
    font-size: 12px;
}
.custom-date-range-box .rdrCalendarWrapper .rdrMonthAndYearWrapper {
    height: 40px;
}
.custom-date-range-box .rdrCalendarWrapper .rdrMonth {
    padding: 0 0.8em 0 0.8em;
    width: 22.56em;
}

.col-6 .rem-icn {
    width: 18px;
    margin-top: 2px;
    cursor: pointer;
    opacity: 0.75;
}
.col-6 .rem-icn:hover {
    opacity: 1;
}

/* .custom-date-range-box .rdrCalendarWrapper .rdrEndEdge {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}
.custom-date-range-box .rdrCalendarWrapper .rdrStartEdge {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
} */

.s-r-m-box {
    display: flex;
    align-items: center;
    position: absolute;
    box-shadow: 0 1px 3px var(--c-s-r-m-box);
    padding: 20px 30px;
    border-radius: 0 0 4px 4px;
    left: 50%;
    transform: translateX(-50%);
    top: 62px;
}
.s-r-m-box .s-r-m-icn {
    width: 24px;
    color: var(--c-s-r-m-box);
    margin-right: 10px;
}
.s-r-m-box .s-r-m-msg {
    flex-grow: 1;
    font-size: 14px;
    color: var(--c-s-r-m-box);
    font-weight: 500;
}
/*Ship-Profile*/
.v-scroll.ship-det {
    padding: 0px 40px;
}
.s-p-edit-config {
    display: flex;
    align-items: center;
    margin: 38px 0 38px -12px;
    background: var(--c-s-p-edit-config-bg);
    width: 210px;
}
.s-p-edit-config .i-config-icn {
    color: var(--c-s-p-edit-config-icn);
    cursor: pointer;
}
.d-f-a-c {
    display: flex;
    align-items: center;
}
.sel-box.d-c-ip {
    width: 240px;
}

.s-p-edit-config .lbl{
    color: var(--c-s-p-edit-config-lbl);
    font-size: 12px;
    font-weight: 500;
    padding-left: 8px;
    cursor: pointer;
}
.s-p-edit-config:hover .i-config-icn, .leg-sec .i-config-icn:hover {
    text-shadow: 0 0 3px var(--c-s-p-edit-config-icn-hvr);
}
.ship-src-dest.vert .i-address.src {
    margin-bottom: 16px;
}
.leg-sec {
    margin: 16px 40px 24px;
    padding: 0px 20px;
    border-left: 3px solid var(--c-s-p-edit-config-lbl);
    position: relative;
}
.leg-sec .i-config-icn {
    color: var(--c-s-p-edit-config-lbl);
    position: absolute;
    top: calc(50% - 12px);
    left: -55px;
    background: var(--c-i-config-icn);
    cursor: pointer;
}
.leg-sec .i-config-icn:before {
    content: '';
    font-weight: 700;
    width: 8px;
    position: absolute;
    border: 2px solid var(--c-i-config-border-before);
    border-width: 2px 1px 1px;
    top: 11px;
    left: -16px;
}
.leg-sec .i-sec {
    padding: 0 0 22px;
    margin: 0;
}
.leg-sec .i-sec.i-carrier-sec {
    padding-bottom: 30px;
}
.i-sec.s-row {
    display: flex;
}
.leg-sec .i-sec.s-row .i-sec {
    padding: 0 30px 0 0;
}
.i-sec.s-row .i-sec-lbl, .leg-sec .i-sec-lbl {
    display: block;
    position: static;
    padding: 8px 4px 4px;
}
.i-sec.s-row .i-or {
    margin-top: 30px !important;
}
.i-sec input.ip {
    position: relative;
    top: 0px;
    width: 52px Imp !important;
    font-size: 14px;
    font-weight: 400;
    margin: 0px 12px 0px 12px;
    border: 1px solid var(--primary-neutral-300);
    padding: 9px 0px 8px 0px;
    border-radius: 4px;
}

.sel-box div.sel-value.new-fw{
    font-size: 14px !important;
    font-weight: 400;
}

.leg-sec .i-sec .i-sec-lbl {
    font-size: 12px;
    font-weight: 400;
    position: static;
    margin: 0;
}
.leg-sec .i-sec input.ip {
    border: 1px solid var(--c-leg-sec-ip);
    border-radius: 2px;
    outline: none;
    padding: 4px 6px;
}
.leg-sec .config-sec-col-r::before {
    left: 0%;
}
.i-sec.i-carrier-sec .ip {
    width: 440px;
    padding-right: 88px !important;
    box-sizing: border-box;
}
.leg-sec .sel-box div.sel-value {
    padding: 7px 20px 6px 10px;
}
.leg-sec .sel-box .sel-value::after {
    top: 10px;
}
.leg-sec .pro-type.search-cat-pop {
    top: 56px;
    left: -3px;
    width: 452px;
}

.config .shipment .i-sec .i-sec-lbl {
    margin: 0;
    padding-top: 0;
}

.ship-details-sec .fld-sec .check-sec label, 
.ship-details-sec .fld-sec br+label,
.ship-details-sec .fld-sec .metrics-label {
    position: static !important;
    margin-left: 0 !important;
}

.fld-sec .pdf-pro-ip {
    outline: none;
    border: 1px solid var(--c-pdf-pro-ip-border);
    font-size: 12px;
    font-weight: 600;
    border-radius: 2px;
    padding: 6px 10px 5px 10px;
    width: 86%;
    height: 19px;
}

.pdf-pro-sel{
    border: 1px solid var(--c-pdf-pro-ip-border);
    width: calc(86% + 20px);
}