.notifi-pop {
  position: absolute;
  top: 38px;
  right: 0;
  background: var(--notifi-pop-up-bg);
  width: 30vw;
  min-width: 440px;
  border-radius: 2px;
  box-shadow: 0 1px 3px var(--notifi-pop-up-box-shdw);
  color: var(--notifi-pop-up-color);
  font-weight: 500;
  font-size: 12px;
  cursor: default;
  overflow: hidden;
}


.notifi-pop .clear-all {
    text-align: right;
    padding: 0 10px;
    margin-top: 6px;
}
.notifi-items {
  height: 70vh;
  overflow-y: auto;
}
.notifi-item {
  padding: 6px 0;
  margin-bottom: 0px;
  /* box-shadow: 0 1px 3px #efefef; */
  border-bottom: 2px solid var(--notifi-item-b-border);
}
.notifi-bdy {
  display: flex;
}
.notifi-bdy .notifi-icon {
  width: 30px;
  height: 30px;
  background: var(--notifi-item-icn-bg);
  border-radius: 50%;  
  box-shadow: 0 1px 3px var(--notifi-item-icn-box-shdw);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  margin-top: 6px;
}
.notifi-bdy .notifi-icon img {
  width: 20px;
}
.notifi-bdy .notifi-icon.none img {
  width: 30px;
}
.notifi-bdy .notifi-close {
  width: 20px;
  height: 20px;
  padding: 4px 2px 4px 4px;
  text-align: center;
  line-height: 20px;
  font-size: 10px;
  margin-right: 10px;
  border-radius: 50%;
  font-weight: 600;
  cursor: pointer;
}
.notifi-bdy .notifi-close:hover {
  background: var(--notifi-item-icn-close-bg-hover);
  box-shadow: 0 0px 3px var(--notifi-item-icn-close-box-shdw-hover);
}
.notifi-bdy .notifi-close:active {
  background: var(--notifi-item-icn-close-bg-active);
}
.notifi-bdy .notifi-box {
  flex: 1;
  padding: 0 10px;
  cursor: pointer;
}
.notifi-bdy .notifi-arrow {
  font-size: 24px;
  transform: scaleX(0.7);
  font-weight: 300;
}
.notifi-ftr {
  border-top: 1px solid var(--notifi-fltr-t-border);
  padding: 0 10px;
}
.notifi-title {
  font-weight: 600;
  width: 70%;
  word-wrap: break-word;
}
.notifi-title .time-ago {
  padding-left: 20px;
  color: var(--notifi-item-time-ago);
  font-weight: 400;
  font-size: 12px;
  position: absolute;
  right: 40px;
}
/* .notifi-title .time-ago:after {
  content: ')';
}
.notifi-title .time-ago:before {
  content: '(';
} */
.notifi-title .time-ago.unread:before {
  content: '';
  background: var(--notifi-item-dot-time-ago-bg);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  box-shadow: 0 0 3px var(--notifi-item-dot-time-ago-box-shdw);
  top: 6px;
  left: 6px;
}

.notifi-icon.danger {
  background: var(--notifi-item-icn-danger-bg);
}
.notifi-icon.warning {
  background: var(--notifi-item-icn-warning-bg);
}
.notifi-icon.success {
  background: var(--notifi-item-icn-success-bg);
}
.notifi-icon.primary {
  background: var(--notifi-item-icn-primary-bg);
}
.notifi-icon.none {
  background: none;
}
.color-danger {
  color: var(--notifi-item-icn-danger-bg);
}
.color-warning {
  color: var(--notifi-item-icn-warning-bg);
}
.color-success {
  color: var(--notifi-item-icn-success-bg);
}
.color-primary {
  color: var(--notifi-item-icn-primary-bg);
}
/*Notification HEAT/COLD/DRY/WET COLORS*/
.color-h-danger {
  color: var(--notifi-item-color-heat-danger);
}
.color-h-warning {
  color: var(--notifi-item-color-heat-warning);
}
.color-c-danger {
  color: var(--notifi-item-color-cold-danger);
}
.color-c-warning {
  color: var(--notifi-item-color-cold-warning);
}
.color-d-danger {
  color: var(--notifi-item-color-dry-danger);
}
.color-d-warning {
  color: var(--notifi-item-color-dry-warning);
}
.color-w-danger {
  color: var(--notifi-item-color-wet-danger);
}
.color-w-warning {
  color: var(--notifi-item-color-wet-warning);
}
.uh-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 72px;
  z-index: 99999999999999;
  background: transparent;
  cursor: not-allowed;
}
.sh-overlay {
  display: flex;
  width: 71px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  z-index: 99999999999999;
  background: transparent;
  cursor: not-allowed;
}
.cntxt-overlay.s {
  display: flex;
  top: 70px;
  background-color: var(--lynx-primarary-bg);
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
.cntxt-err-pop {
  width: 35%;
  box-sizing: border-box;
  padding: 4%;
  border-radius: 2px;
  /* background: var(--notifi-contxt-err-pop-bg); */
}
.cntxt-err-pop.pos{
  position: absolute;
  top: 10px;
}
.cntxt-err-pop .h1 {
  padding-bottom: 20px;
  font-size: 24px;
  font-weight: 500;
}
.cntxt-err-pop .b {
  padding-bottom: 10px;
}
.cntxt-err-pop .f {
  text-align: right;
  padding-top: 40px;
}
.cntxt-err-pop .f .c-btn {
  font-size: 14px;
  padding: 8px 12px;
  font-weight: 500;
}

@-webkit-keyframes notifyBox {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);  
  }
}

@-moz-keyframes notifyBox {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);  
  }
}

@-o-keyframes notifyBox {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);  
  }
}

@keyframes notifyBox {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);  
  }
}
.notify-box {
  position: absolute;
  bottom: 10px;
  right: 15px;
  z-index: 9999999999;
}
.notify-item {
  display: flex;
  align-items: center;
  box-shadow: 0 1px 3px var(--notify-item-box-shdw);
  padding: 8px 15px;
  border-radius: 4px;
  background: var(--notify-item-bg);
  margin-top: 12px;
  animation: notifyBox 0.25s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}
.notify-item .n-icn, .notify-box .c-icn {
  font-size: 22px;
  color: var(--notify-box-c-icn-color);
  margin: 0 4px 0 0;
}
.notify-item img.n-icn {
  width: 22px;
}
.notify-item .c-icn {
  font-size: 18px;
  margin: 0 0 0 10px;
  color: var(--notify-item-c-icn-color);
  font-weight: 600;
  opacity: 0.75;
  cursor: pointer;
}
.notify-item .c-icn:hover {
  opacity: 1;
}
.notify-item .n-msg {
  flex-grow: 1;
  font-size: 12px;
  font-weight: 500;
}

/*user-details*/
.child-nodes.profile-pop {
  top: 34px;
  right: 0;
  min-width: 280px;
  text-align: right;
  display: block;
}

.child-nodes.profile-pop li.with-icn {
  align-items: center;
  display: flex;
}
.child-nodes.profile-pop li.lnk {
  padding: 0;
}
.child-nodes.profile-pop li.lnk a {
  padding: 8px 25px;
  width: 100%;
  align-items: center;
  display: flex;
  cursor: pointer;
  font-weight: 500;
  font-family: 'Gibson-500', sans-serif;
}
.child-nodes.profile-pop li.with-icn span,
.child-nodes.profile-pop li.with-icn a{
  cursor: pointer;
  font-weight: 500;
  font-family: 'Gibson-500', sans-serif;
}
.child-nodes.profile-pop li.with-icn svg{
  cursor: pointer;
}
.child-nodes.profile-pop li.with-icn .icn, .child-nodes.profile-pop li.with-icn svg {
  font-size: 18px;
  color: var(--primary-blue-500);
  margin-right: 10px;
}

.child-nodes.profile-pop li{
  border-bottom: 1px solid var(--primary-blue-hover);
}

.child-nodes.profile-pop li:last-child{
  border-bottom: unset;
}

.child-nodes.profile-pop li:hover .profile-pop-icon{
  fill: var(--primary-blue-500);
}

.profile-pop a {
  color: unset;
  text-decoration: none;
}
.profile-pop li:first-child {
  cursor: default;
  /* margin-top: 16px !important; */
  padding: 16px !important;
}
.u-det {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.u-det .user-name {
  position: static;
  margin: 30px auto 10px;
  font-size: 28px;
  font-weight: 400;
  padding: 0;
  height: 60px;
  width: 60px;
  line-height: 60px;
  cursor: default;
}

.u-det .redesign-user-name{
  font-size: 14px;
  padding: 12px;
}

.u-p-dt {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  /* margin-bottom: 12px; */
  color: var(--primary-neutral-600);
}
.u-sec-dt {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  /* margin-bottom: 12px; */
  color: var(--primary-neutral-400);
}
.u-p-dt, .u-sec-dt {
  max-width: 280px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  margin-left: 8px;
}

.profile-pop.child-nodes > li {
  padding: 8px 25px;
}

.l-o {
  color: var(--error-color);
  font-size: 16px !important;
  font-weight: 600 !important;
  margin-right: 14px;
}
.c-s-sp {
  padding-bottom: 20px;
  line-height: 22px;
}
.c-s-sp:last-child {
  padding-bottom: unset;
}

.redesign-usr-info{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}