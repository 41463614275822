.right-bar.pdf-p.riq {
    left: 72px !important;
    background: var(--riq-primary-bg);
}
.right-bar.pdf-p.riq.ex {
    left: 260px !important;
    background: var(--riq-primary-bg);
}
.right-bar.pdf-p::after {
    content: '';
}
.left-bar.pdf-p .new-ship {
    left: calc(24% - 60px);
}
.pdf-hdr {
    padding: 30px 30px 20px;
    position: absolute;
    top: 0;
    right: 0;
    height: 110px;
    left: 0;
    box-sizing: border-box;
    border-bottom: 1px solid var(--riq-pdf-hdr-b-bottom);
}
.pdf-p.riq .fltr-sec {
    height: 77px;
    background: var(--primary-neutral-100);
    border: none;
}
.fltr-bx {
    width: 40%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 10px;
}
.fltr-bx span {
    padding-right: 2%;
}
.riq .fltr-sec .fltr-row:first-child {
    margin: 4px 0 0 0;
}
.riq.det .fltr-bx {
    width: unset;
    justify-content: space-between;
    margin-right: 20px;
    margin-top: 6px;
}


.riq .pdf-list-view {
    padding: 0;
    top: 50px;
}
.riq .pdf-list-view.r-l-view  {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    top: 77px;
}
.riq .pdf-list-row {
    margin-left: 0px;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    /* background-color: var(--riq-primary-bg); */
    color: var(--primary-neutral-600);
    margin-top: 24px;
}
.riq .pdf-list-row .mr{
    margin-right: 18px;
}
.riq .pdf-list-row .ml{
    margin-left: 12px;
}
.riq .pdf-list-row .ml.asc {
    transform: rotate(180deg);
}

.riq .pdf-list-row .r-df{
    display: flex;
    align-items: center;
}

.riq .pdf-list-hdr {
    color: var(--riq-pdf-list-row-hdr);
    padding: 10px 0;
    border-bottom-width: 6px;
}
.pdf-list-bdy-riq {
    position: absolute;
    top: 42px;
    right: 0;
    left: 0;
    bottom: 0;
}
.riq .pdf-list-row.pdf-list-hdr{
    border-bottom: 1px solid var(--primary-neutral-200);
}
.pdf-list-row.pdf-list-hdr div{
    cursor: pointer;
}
.pdf-list-row.pdf-list-hdr div{
    font-size: 18px;
    font-weight: 500;
    color: var(--primary-neutral-400);
    line-height: 18px;
}
.riq .pdf-list-row:not(.pdf-list-hdr) {
    cursor: pointer;
    border-bottom: 3px solid var(--primary-neutral-200);
    margin-top: 0;
    /* padding: 14px; */
    margin: 0 -24px;
}
.riq .pdf-list-row.l-r-a:not(.pdf-list-hdr) {
    margin: 0;
}
.pdf-list-row.l-r-a .p-col-0 {
    padding-left: 16px;
}
.riq .pdf-list-row:not(.pdf-list-hdr):hover{
    background-color: var(--primary-blue-hover);
}
.pdf-list-row:not(.pdf-list-hdr):nth-of-type(odd){
    background-color: var(--primary-neutral-100);
}
.pdf-list-row:not(.pdf-list-hdr):nth-child(2n) {
    /* background-color: var(--riq-pdf-list-row-alt); */
}
.pdf-list-row:not(.pdf-list-hdr):not(.f):active {
    /* box-shadow: 0 0 3px var(--riq-pdf-list-row-box-shdw-active); */
}

.riq .pdf-list-row .p-col-0 {
    padding-left: 30px;
    width: 15%;
}
.riq .pdf-list-row .p-col-1 {
    width: 20%;
}
.riq .pdf-list-row .p-col-2 {
    width: 20%;
}
.riq .pdf-list-row .p-col-3 {
    width: 20%;
}
.riq .pdf-list-row .p-col-4 {
    width: 18%; /* 25% */
    display: flex;
    align-items: center;
}

.l-riq-prog {
    position: relative;
    border: 1px solid var(--riq-ship-riq-prog-border);
    background: var(--riq-ship-riq-prog-bg);
    border-radius: 10px;
    width: 280px;
    height: 20px;
    margin: 12px 0 14px;
    /* overflow: hidden; */
}
.l-riq-prog::before {
    content: '0';
    font-size: 10px;
    font-weight: 600;
    position: absolute;
    left: 0;
    bottom: -16px;
}
.l-riq-prog::after {
    content: '100';
    font-size: 10px;
    font-weight: 600;
    position: absolute;
    right: 0;
    bottom: -16px;
}
.l-riq-prog-w {
    width: 0;
    height: 100%;
    transition: all 250ms ease-in-out;
    overflow: hidden;
    border-radius: 10px;
}
.l-riq-prog-s {
    background: url('../../assets/images/risk-status-bar.png') repeat-y 0 0;
    height: 100%;
    background-size: contain;
    width: 280px;
}
.l-riq-score {
    font-size: 28px;
    font-weight: 600;
    width: 76px;
    margin: 0 30px;
}
.l-stat {
    border-radius: 50%;
    background: var(--riq-ship-stat-green-bg);
    width: 22px;
    height: 22px;
    color: var(--riq-primary-color);
    text-align: center;
}
.l-stat.r { background: var(--riq-ship-stat-red-bg); }
.l-stat.y { background: var(--riq-ship-stat-yellow-bg); }
.l-stat .l-stat-icn { font-size: 18px; margin-top: 1px; }
.l-stat.r::after {
    content: 'HIGH';
    color: var(--riq-ship-stat-red-bg);
    margin-left: 5px;
}
.l-stat.y::after {
    content: 'MEDIUM';
    color: var(--riq-ship-stat-yellow-bg);
    margin-left: 5px;
}
.det-vu .l-stat::after {
    content: '';
}
.l-stat::after {
    content: 'LOW';
    color: var(--riq-ship-stat-green-bg);
    position: absolute;
    margin-left: 15px;
    font-size: 12px;
    margin-top: 4px;
    font-weight: 500;
}
.riq .txt-i.w-risk{
    width: 26%;
    height: 36px;
}
.riq .txt-i {
    width: calc(84% - 24px);
}
.riq.det .txt-i {
    width: 64%;
}
.riq .search-btn {
    width: 20%;
}
.riq.det .txt-i + .search-btn {
    width: 36%;
}

.riq .search-bx {
    margin-left: 40px;
    max-width: 54%;
}

.riq .f-sec {
    padding: 0;
    position: absolute;
    bottom: -2px;
    width: 124px;
}
.riq .date-range-rep.custom {
    z-index: 99999;
}
.riq .date-range-rep.custom .react-datepicker-component.in-range .react-datepicker-input {
    width: 8px;
    height: 8px;
}
.riq .date-range-disp {
    position: absolute;
    bottom: 4px;
    width: 240px;
    margin-left: -56px;
    z-index: 0;
}
.riq .details-list-sec .date-range-disp {
    position: static;
    width: auto;
    margin-left: -4px;
    margin-top: -4px;
}
.riq .details-list-sec {
    bottom: unset;
    width: 110px;
    margin-top: 60px;
}
.riq-g-sel {
    margin-right: 122px;
}
.riq.det .riq-g-sel {
    margin-right: 5%;
}
.riq-g-sel, .riq-c-sel {
    width: 110px;
}
.riq-g-sel:after {
    content: '';
    position: absolute;
    top: -4px;
    height: 30px;
    border: 1px solid var(--riq-filter-sep-line);
    right: -25px;
}

.riq-g-sel:before {
    content: 'Groups: ';
    position: absolute;
    font-size: 12px;
    margin-left: -58px;
    margin-top: 4px;
}

.riq-c-sel:before {
    content: 'Companies: ';
    position: absolute;
    font-size: 12px;
    margin-left: -84px;
    margin-top: 4px;
}
.riq.det .riq-g-sel:after, .riq.det .riq-g-sel:before, .riq.det .riq-c-sel:before {
    content: unset;
}
.riq.det .riq-c-sel:before {
    
}
.high{
    color: var(--error-color);
}
.medium{
    color: var(--warning-color);
}
.low{
    color: var(--success-color)
}
.t-mode-txt {
    text-transform: capitalize;
}

/* Redesign CSS */

.right-bar.redesign-list-view {
    left: 72px;
    background-color: var(--lynx-primarary-bg);
    padding: 0 1.5% 1.5% 1.5%;
}

.right-bar.redesign-list-view.nw-pl {
    padding-left: 1%;
}

.right-bar.redesign-list-view.ex{
    left: 260px;
}

.right-bar.redesign-list-view.nw-lft{
    left: 72px !important;
}

.right-bar.redesign-list-view.nw-lft.ex{
    left: 260px !important;
}

.right-bar.redesign-list-view::after {
    content: '';
}

.redesign-fltr-sec{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.redesign-hdr-srch, .redesign-fltr{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 30%;
}
.redesign-hdr-srch {
    width: 70%;
}

.redesign-fltr {
    justify-content: flex-end;
}

.redesign-fltr > div:first-child{
    margin-right: 1.5%;
}

.redesign-hdr{
    font-size: 40px;
    font-weight: 600;
    line-height: 48px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.redesign-srch{
    margin-left: 1.5%;
    width: 50%;
    display: flex;
    position: relative;
}
.redesign-hdr + .redesign-srch, .exFont16LightGray + .redesign-srch { 
    margin-left: 3%;
}
.redesign-srch .srch-icon{
    position: absolute;
    right: 10px;
    top: 12px;
}

.redesign-srch .srch-cl-icon{
    position: absolute;
    top: 12px;
    right: 40px;
    cursor: pointer;
}

.redesign-srch .srch-cl-icon1{
    position: absolute;
    top: 12px;
    right: 40px;
    cursor: pointer;
}

.srch-cl-icon-caret {
    caret-color: transparent;
}

.redesign-search-box {
    outline: none;
    border-radius: 4px;
    font-size: 16px;
    line-height: 24px;
    padding: 8px 60px 8px 8px;
    font-weight: 400;
    color: var(--primary-neutral-600);
    border: 1px solid var(--primary-neutral-300);
    width: 100%;
}

.redesign-search-box::placeholder{
    color: var(--primary-neutral-300);
}

.redesign-risk-list .r-l-view{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 0;
    padding: inherit 24px;
}
.redesign-risk-list .redesign-list-hdr,
.redesign-list-hdr{
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    font-family: 'Gibson-600', sans-serif;
    padding: 15px 0 15px 20px;
    color: var(--primary-neutral-400);
}
.redesign-risk-list .redesign-list-bdy{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: auto;
    margin-bottom: 70px;
    min-height: 100px;

    /* position: absolute;
    top: 70px;
    bottom: 70px;
    overflow-y: auto;
    left: 1.5%;
    right: 1.5%; */
}

.redesign-list-hdr.bb{
    border-bottom: 1px solid var(--primary-blue-hover);
}

.redesign-list-hdr .col-sel{
    color: var(--primary-blue-300);
}

.redesign-list-hdr div{
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}

.redesign-list-hdr.route-list-hdr div{
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.redesign-list-hdr div .ril-lbl{
    white-space: nowrap;
    overflow: hidden;
    /* text-overflow: ellipsis; */
}

.redesign-list-hdr div:hover{
    text-decoration: underline;
    text-underline-position: under;
}

.redesign-list-hdr div.no-event:hover{
    text-decoration: unset;
    cursor: default;
}

.redesign-list-hdr.route-list-hdr div:hover{
    text-decoration: none;
}

.redesign-risk-list .redesign-list-itm,
.redesign-list-itm {
    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    font-family: 'Gibson-500', sans-serif;
    padding: 16px 0 16px 20px;
    color: var(--base);
    background-color: var(--primary-white);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
    margin-bottom: 6px;
    border: 1px solid transparent;
}
.a-top .redesign-list-itm {
    align-items: flex-start;
}

.redesign-list-itm.dis > div{
    opacity: 0.2;
}
.redesign-list-itm.dis > div:last-child {
    opacity: 1;
}

.redesign-risk-list .redesign-list-itm:hover, .redesign-list-itm:hover {
    background-color: var(--primary-blue-hover);
    border: 1px solid var(--primary-blue-75);
}

.redesign-risk-list .redesign-list-itm:hover .row-c-name.cmpy, 
.redesign-list-itm:hover .row-c-name.cmpy{
    text-decoration: underline;
    text-underline-position: under;
}

.redesign-risk-list .redesign-list-itm.rt:hover {
    border: 1px solid var(--primary-blue-200);
}

.redesign-list-itm.bb{
    border-bottom: 1px solid var(--primary-blue-hover);
    box-shadow: unset;
}

.redesign-risk-list .redesign-list-itm > div, .redesign-risk-list .redesign-list-itm > div > div:not(.toggle-btn) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.redesign-risk-list .redesign-list-itm > div:first-child, 
.redesign-risk-list .redesign-list-itm > div:first-child a{
    color: var(--primary-blue-500);
}

.redesign-risk-list .redesign-list-itm:hover > div:first-child{
    /* text-decoration: underline;
    text-underline-position: under;
    color: var(--primary-blue-500) !important; */
}

.redesign-risk-list .redesign-list-itm:hover a {
    text-decoration: underline;
    text-underline-position: under;
    color: var(--primary-blue-500) !important;   
}

.redesign-risk-list .redesign-list-itm:hover > .route-col-6 .review-btn{
    background: var(--primary-blue-600);
}

.redesign-risk-list .redesign-list-itm > div:first-child a:active, 
.redesign-risk-list .redesign-list-itm > div:first-child a:hover,
.redesign-risk-list .redesign-list-itm > div:first-child a:visited,
.redesign-risk-list .redesign-list-itm > div:first-child a:focus,
.redesign-bk-arr:active,
.redesign-bk-arr:hover,
.redesign-bk-arr:visited,
.redesign-bk-arr:focus {
    color: var(--primary-blue-500);
}

.redesign-risk-list .redesign-list-itm > div:last-child{
    
}

.redesign-risk-list .redesign-list-itm div:last-child.high, .route-col-1.high{
    color: var(--user-deactivate);
}

.redesign-risk-list .redesign-list-itm div:last-child.medium, .route-col-1.medium{
    color: var(--warning-color);
}

.redesign-risk-list .redesign-list-itm div:last-child.low, .route-col-1.low{
    color: var(--success-color);
}

.riq-col-0{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.riq-col-0, .riq-col-3{
    width: 11%;
    padding-right: 1%;
}
.riq-col-3{
    width: 9%;
    padding-right: 1%;
}
.riq-col-1, .riq-col-2, .riq-col-4, .riq-col-6{
    width: 14%;
    padding-right: 1%;
}
.riq-col-4 {
    width: 17%;
}
.riq-col-4.seg-view {
    width: 20%;
}
.riq-col-1-2.cmpy-mgmt{
    width: 30%;
    padding-right: 1%;
}
.riq-col-5{
    width: 19%;
    padding-right: 1%;
}

.riq-col-6, .route-col-1, .riq-col-5 {
    display: flex;
    align-items: center;
}

.redesign-list-hdr .sort-icon{
    margin: 0 6px 0 6px;
    cursor: pointer;
}

.redesign-list-hdr .sort-icon img{
    transform: translateY(5%);
}

.redesign-list-itm .trspt-mode-icon{
    margin-right: 5px;
}

.redesign-list-itm .trspt-mode-icon img{
    transform: translateY(15%);
}

.redesign-bk-list {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: var(--primary-blue-500);
    margin-bottom: 8px;
}

.redesign-list-btn{
    display: flex;
    align-items: center;
    border: 1px solid var(--primary-blue-300);
    padding: 4px 16px;
    border-radius: 4px;
    cursor: pointer;
}

.redesign-list-btn.edt-btn{
    background-color: var(--primary-white);
    cursor: pointer;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    padding-top: 6px;
    color: var(--primary-blue-500);
}

.redesign-bk-arr{
    display: flex;
    align-items: center;
    cursor: pointer;
}

.bk-arr-icon img, .ri-list-icon img{
    margin-right: 10px;
    transform: translateY(15%);
    margin-left: unset !important;
}

.redesign-info-box {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 16px;
    padding: 8px 16px;
    width: fit-content;
    border-radius: 4px;
    color: var(--primary-neutral-400);
    background-color: var(--selected-itm);
    margin-bottom: 8px;
    text-transform: unset !important;
    font-weight: 400 !important;
}
.redesign-info-box.m-view {
    display: none;
}
.redesign-lname-lt-updtd {
    padding-bottom: 8px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--primary-blue-hover);

    align-items: flex-end;
}

.redesign-ln-rq.m-w {
    max-width: 70%;
}

.redesign-lname-lt-updtd.no-brdr{
    border: unset;
}

.ln-dtls{
    display: none;
    font-size: 14px;
    line-height: 16px;
    color: var(--primary-neutral-400);
}

.redesign-ln-rq div:first-child{
    font-size: 40px;
    line-height: 48px;
    font-weight: 600;
    margin-right: 24px;
    /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
}

.redesign-ln-rq > div:first-child::before{
    content: 'Lane: ';
}

.redesign-ln-rq.route > div:first-child::before{
    content: 'Route: ';
}

.redesign-ln-rq.route.no-sel > div:first-child::before{
    content: '';
}

.redesign-ln-rq.route div:first-child{
    font-size: 24px;
    line-height: 32px;
}

.redesign-ln-rq.route div:first-child .route-sel-cnt{
    color: var(--primary-neutral-400);
}

.redesign-ln-rq div:last-child{
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    text-transform: uppercase;
    transform: translateY(1.5px);
    white-space: nowrap;
}

.redesign-ln-rq div:last-child span{
    margin-left: -15px;
}

.redesign-ln-rq div:last-child span.m-unset{
    margin-left: unset;
}

.redesign-ln-rq div:last-child.high{
    color: var(--user-deactivate);
    margin-right: 10px;
}

.redesign-ln-rq div:last-child.medium{
    color: var(--warning-color);
    margin-right: 10px;
}

.redesign-ln-rq div:last-child.low{
    color: var(--success-color);
    margin-right: 10px;
}

.redesign-lst-updt{
    font-size: 14px;
    line-height: 16px;
    color: var(--primary-neutral-400);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.redesign-lst-updt span{
    font-weight: 600;
    color: var(--primary-neutral-500);
}

.redesign-lbl-fltrs{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.redesign-riq-lbl{
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    margin-bottom: 8px;
}

.redesign-dur-fltrs{
    display: flex;
}

.redesign-dur-fltrs div{
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 4px 0;
    margin-right: 18px;
    color: var(--primary-neutral-500);
    white-space: nowrap;
    cursor: pointer;
}

.redesign-dur-fltrs div.sltd{
    border-bottom: 4px solid var(--primary-blue-500);
}

.redesign-dur-fltrs div.dis, .redesign-dur-fltrs div.dis:hover {
    color: var(--primary-neutral-300);
    cursor: not-allowed;
}

.redesign-dur-fltrs div:hover {
    color: var(--primary-blue-500);
}

.redesign-dur-fltrs .r-pos {
    height: 15px;
}
.redesign-dur-fltrs .t-txt {
    min-width: 1500%;
}

.redesign-isgt-box{
    display: flex;
    width: 100%;
}

.redesign-isgt-box.w-20 > div{
    width: 100%;
}

.redesign-isgt-box.w-25 > div{
    width: 100%;
}

.redesign-isgt-box.w-26r > div{
    width: 210px;
}

.redesign-isgt-box .isgt-itm{
    height: 10%;
    min-height: 120px;
    padding: 4px 2% 4px 1%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-left: 1px solid var(--primary-blue-200);
}

.isgt-lbl, .insight-dev{
    font-size: 14px;
    line-height: 16px;
    color: var(--primary-neutral-400);
}

.insight-val{
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    margin-bottom: 8px;
}

.insight-dev{
    padding: 8px;
    background-color: var(--primary-neutral-100);
    width: fit-content;
    border-radius: 4px;
}

.insight-dev.no-chgs{
    color: var(--primary-neutral-300);
    background-color: unset;
    padding-left: unset;
}

.insight-dev > span { 
    margin-left: 5px; 
}
.insight-dev::first-letter {
    margin-right: 5px; 
}
.insight-dev.na::first-letter, .insight-dev.no-chgs::first-letter {
    margin-right: 0px; 
}
.route-col-1{
    width: calc(20% - 10px);
    margin-right: 10px;
}

.route-col-0{
    width: calc(24% - 10px);
    margin-right: 10px;
}

.route-col-2, .route-col-3, .route-col-4, .route-col-5, .route-col-6{
    width: calc(10% - 10px);
    margin-right: 10px;
}
.route-col-5 {
    width: calc(15% - 10px);
}

.route-col-0{
    color: var(--primary-neutral-600) !important;
}

.review-btn{
    font-size: 16px !important;
    line-height: 24px;
    font-weight: 500 !important;
    padding: 4px 15%;
    max-width: 52px;
    border-radius: 4px;
    color: var(--primary-white) !important;
    background-color: var(--primary-blue-500);
    text-align: center;
    text-transform: capitalize !important;
    cursor: pointer;
}

.risk-list-btn {
    font-size: 14px !important;
    font-weight: 600 !important;
    height: 32px;
    padding: 8px 16px 8px 16px;
    border-radius: 4px;
    border: none;
}
.risk-high{
    width: 71px;
    color:  var(--user-deactivate);
    background-color: var(--primary-rose-500);
}

.risk-medium{
    width: 94px;
    color: #D99100;
    background-color: #FFF1D6;
}

.risk-low{
    width: 66px;
    color: #008C34;
    background: #E3FCEF;
}


.isgt-itm.route{
    height: 6%;
    min-height: 80px;
}

.isgt-itm.routeList{
    height: 6%;
    min-height: 55px;
}

.route .isgt-lbl{
    text-transform: uppercase;
}

.redesign-riq-seltn{
    width: 30%;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: var(--primary-blue-75);
    color: var(--primary-neutral-500);
    max-width: 300px;
}

.redesign-riq-seltn div{
    padding: 16px 24px;
    width: calc(100% - 48px);
    cursor: pointer;
}

.redesign-riq-seltn div.sltd{
    border-left: 4px solid var(--primary-blue-600);
    color: var(--primary-neutral-600);
    width: calc(100% - 52px);
}

.redesign-riq-seltn div:hover{
    background: var(--primary-blue-hover);
}

.redesign-detl-seltn{
    width: 70%;
    padding: 20px 0 20px 20px;
    flex-grow: 1;
}

.df-no-cen{
    display: flex;
    justify-content: flex-start;
}

.redesign-insgt-layout{
    font-size: 16px;
}

.redesign-insgt-hdr, .redesign-insgt-itm{
    display: flex;
    border-bottom: 1px solid var(--primary-blue-hover);
}

.redesign-insgt-hdr div, .redesign-insgt-itm div{
    padding: 8px 10px;
}

.redesign-insgt-hdr div.w-50, .redesign-insgt-itm div.w-50{
    width: 50%;
}

.redesign-insgt-hdr div.w-33, .redesign-insgt-itm div.w-33{
    width: 33%;
}

.redesign-insgt-hdr div.w-25, .redesign-insgt-itm div.w-25{
    width: 25%;
}

.redesign-insgt-hdr div.w-20, .redesign-insgt-itm div.w-20{
    width: 20%;
}

.redesign-insgt-hdr div.w-16, .redesign-insgt-itm div.w-16{
    width: 16.6%;
}
.redesign-insgt-layout.c-1 .r-i-col-l,
.redesign-insgt-layout.c-1 .r-i-col-v {
    width: 50%;
}
.redesign-insgt-layout.c-2 .r-i-col-l,
.redesign-insgt-layout.c-2 .r-i-col-v {
    width: 33%;
}
.redesign-insgt-layout.c-3 .r-i-col-l,
.redesign-insgt-layout.c-3 .r-i-col-v {
    width: 25%;
}
.redesign-insgt-layout.c-4 .r-i-col-l,
.redesign-insgt-layout.c-4 .r-i-col-v {
    width: 20%;
}
.redesign-insgt-layout.c-5 .r-i-col-l,
.redesign-insgt-layout.c-5 .r-i-col-v {
    width: 16.6%;
}




.redesign-insgt-hdr{
    font-size: 14px;
    line-height: 16px;
    color: var(--primary-neutral-400);
}

.redesign-insgt-itm{ 
    font-size: 16px;
    line-height: 24px;
}

.redesign-detl-seltn .redesign-insgt-itm:nth-child(even){
    background-color: var(--primary-blue-75);
}

.redesign-overlay{
    height: 100%;
    position: absolute;
    left: 72px;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: var(--lynx-overlay-bg);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    z-index: 999999;
}

.redesign-overlay.ex{
    left: 260px;
}

.redesign-lane-popup{
    width: 40%;
    min-width: 525px;
    background-color: var(--primary-white);
    box-shadow: 0px 0px 10px 0px rgba(51, 51, 51, 0.50);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
}

.popup-lbl-cls, .popup-srch-fltrs{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 16px 8px 16px;
}

.popup-lbl{
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    max-width: 80%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.popup-cls{
    cursor: pointer;
}

.popup-srch-fltrs{
    padding: 4px 16px 8px 16px;
    border-bottom: 1px solid var(--primary-blue-hover);
}

.popup-srch-fltrs > div{
    /* width: 32%; */
    margin-right: 2%;
}

.popup-srch-fltrs > div .sel-box{
    width: 100% !important;
    padding: 2px !important;
}

.popup-srch-fltrs > div .sel-box .sel-value{
    padding: 10px 20px 10px 10px;
}

.popup-srch-fltrs > div .sel-box .sel-value::after{
    top: 15px;
}

.popup-srch-fltrs > div .sel-box.open .sel-value::after{
    top: 18px;
}

.popup-srch-fltrs > div .sel-box.open .sel-box-pop{
    top: 44px;
}

.popup-srch-fltrs > div:last-child{
    margin-right: unset;
}

.popup-srch-fltrs > div:first-child{
    margin-left: unset;
    margin-right: 1%;
}

.t-t-c {
    text-transform: capitalize;
}

.popup-lane-lst {
    top: 110px;
    position: absolute;
    left: 0;
    right: 0;
    overflow-y: auto;
    bottom: 0;
}
.popup-lane-lst.s {
    position: static;
}
.popup-lane-lst.il-srch, .popup-lane-lst.no-pop {
    position: static;
    margin: 0 auto;
}
.popup-lane-lst .epty-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 8px;
    margin: 15px;
    padding: 32px;
    align-items: center;
    height: 20%;
    background: var(--lynx-primarary-bg);
}
.popup-lane-lst.il-srch .epty-list {
    border: none;
    margin: 0;
    padding: 10px;
}
.popup-lane-lst.no-pop .epty-list {
    margin-top: 2%;
}
.popup-lane-lst .epty-list .el-ctr{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.popup-lane-lst .el-ctr span{
    margin-bottom: 30px;
}

.popup-lane-lst .el-ctr .epty-ln-txt{
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: var(--primary-neutral-400);
}

.popup-lane-itm{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    border-bottom: 1px solid var(--primary-blue-hover);
    position: relative;
    border-left: 4px solid var(--primary-white);
    border-top: 1px solid transparent;
    border-right: 1px solid transparent;
}

.popup-lane-itm:hover{
    background: var(--primary-blue-75);
    border: 1px solid var(--primary-blue-hover);
    border-left: 4px solid transparent;
}

.popup-lane-itm.open{
    border-left: 4px solid var(--primary-blue-500);
}

.popup-lane-itm div:first-child{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.popup-lane-itm .itm-name{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.popup-lane-itm.rts > .itm-name{
    margin-left: 5%;
    width: 100%;
    justify-content: left;
    cursor: pointer;
}

.popup-lane-itm .itm-name span{
    font-size: 16px;
    line-height: 24px;
    color: var(--primary-neutral-500);
    font-weight: 500;
}

.popup-lane-itm:hover .itm-name span{
    color: var(--primary-blue-500);
    text-decoration: underline;
    text-underline-position: under;
}

.popup-lane-itm .r-count{
    font-size: 16px;
    line-height: 24px;
    color: var(--primary-blue-500);
    font-weight: 500;
    margin-right: 15px;
    cursor: pointer;
}

.popup-lane-itm .r-count::after{
    position: absolute;
    content: ' ';
    border-width: 7px 5px 0 5px;
    top: 22px;
    right: 12px;
    z-index: 0;
    width: 6px;
    height: 6px;
    border-top: 2px solid var(--primary-blue-500);
    border-right: 2px solid var(--primary-blue-500);
    transform: rotate(135deg);
}

.popup-lane-itm.open .r-count::after{
    top: 25px;
    transform: rotate(-45deg);
}

.rt-name{
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: var(--primary-neutral-400);
}