.riq-sub-lbl{
    font-size: 14px;
    font-weight: 500;
    color: var(--primary-neutral-600);
    margin: 0px 0px 20px 20px;
}
.shipment-list-box .shipment-list .ship-item.riq-a{
    background-color: var(--primary-blue-hover);
}
.shipment-list-box .shipment-list .ship-item.riq-a.sel-l{
    background-color: var(--primary-blue-75);
}
.shipment-list-box .shipment-list .ship-item.list-tem-flex.child-node {
    padding-left: 39px;
    font-size: 14px;
    background: var(--primary-blue-75);
    border-top: 1px solid var(--primary-neutral-100);
}
.shipment-list-box .shipment-list .ship-item.list-tem-flex:hover {
    background: var(--primary-neutral-200) !important;
}
.shipment-list-box .shipment-list .ship-item.list-tem-flex.child-node.sel-i,
.shipment-list-box .shipment-list .ship-item.list-tem-flex.child-node.sel-i:hover {
    background-color: var(--primary-blue-hover);
}
.comp-usr-ls-box > .df {
    /* margin-bottom: 24px; */
}
.comp-usr-ls-box .data-not-availble::before {
    content: 'No data available';
    margin-left: 50px;
}
.comp-usr-ls-box.rp{
    padding: 0 20px 0 0;
}
.r-i-col {
    width: 52%;
    display: flex;
    justify-content: space-between;
}
.r-i-col > .df {
    align-items: center;
}
.r-i-col > .df > img {
    position: absolute;
}
.r-i-col > .df > .risk-lbl {
    padding-left: 34px;
}
.riq-d-info{
    background: var(--error-bg-color);
    /* white-space: nowrap; */
    padding: 6px 8px;
    border-left: 4px solid var(--error-color);
    font-size: 14px;
    color: var(--primary-neutral-600);
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 44%;
    box-sizing: border-box;
}
.riq-d-info.y{
    background: var(--warning-color-bg);
    border-left: 4px solid var(--warning-color);
}
.riq-d-info.g{
    background: var(--success-color-bg);
    border-left: 4px solid var(--success-color);
}
.riq-d-info.n {
    background: var(--primary-neutral-200);
    border-left-color: var(--primary-neutral-300);
}
.riq-cntt {
    margin-bottom: 10px;
    font-size: 13px;
    font-weight: 600;
    position: relative;
}
.riq-cntt .riq-value {
    position: relative;
    display: inline-block;
    padding: 6px;
    border-radius: 4px;
    background: var(--success-color);
    color: var(--primary-white);
    text-align: center;
    width: 24px;
}
.riq-cntt .riq-value.y {
    background: var(--warning-color);
    color: var(--primary-neutral-600);
}
.riq-cntt .riq-value.r {
    background: var(--error-color);
}
.d .riq-cntt .riq-value, .riq-cntt .riq-value.n {
    background: var(--primary-neutral-300);
}
.riq-leg.orig .riq-cntt .riq-value, .riq-leg.dest .riq-cntt .riq-value {
    opacity: 0;
    font-size: 0;
    color: var(--primary-white);
}
.risk-info{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 49%;
}
.risk-lbl{
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    white-space: nowrap;
    transform: translateY(1px);
}
.box-hdr span{
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    text-transform: uppercase;
    margin-left: 10px;
    color: var(--primary-neutral-400);
}
.dur-fltrs{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.dur-fltrs div{
    padding: 9px 8px;
    font-size: 14px;
    font-weight: 500;
    color: var(--primary-blue-500);
    border: 1px solid var(--primary-blue-500);
    border-radius: 4px;
    margin-right: 12px;
    cursor: pointer;
}
.dur-fltrs div.s{
    background-color: var(--primary-blue-500);
    color: var(--primary-white);
}
.dur-fltrs div.d{
    cursor: not-allowed;
    color: var(--primary-neutral-300);
    border-color: var(--primary-neutral-300);
}
.dur-fltrs div.d:active {
    pointer-events: none;
}

.dur-info {
    font-size: 12px;
    color: var(--primary-neutral-400);
    font-weight: 400;
    margin: -18px 0 30px; 
    display: flex;
    align-items: center;
    line-height: 12px;
}
.dur-info img {
    margin-right: 10px;
}
.lane-ins {
    align-items: flex-start;
}
.lane-ins > div {
    margin-right: 2%;
    flex-grow: 1;
}
.lane-ins > div:last-child {
    margin: 0;
}
.box-hdr {
    margin-bottom: 15px;
    display: flex;
    align-items: flex-start;
}
.box-hdr.m-t-32 {
    margin-top: 32px;
}
.box-hdr.m-t-15 {
    margin-top: -15px;
}
.insight-lbl{
    font-size: 14px;
    font-weight: 400;
    color: var(--primary-neutral-400);
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 90%;
    overflow: hidden;
}
.in-val-dev{
    align-items: center;
    justify-content: flex-start;
    margin-top: 15px;
    white-space: nowrap;
}
.dwell-loc {
    display: flex;
    align-items: center;
    margin-top: -12px;
    margin-bottom: 20px;
}
.dwell-loc img {
    margin: 0 6px 0 0px;
}
.dwell-loc span {
    display: inline-block;
    max-width: 50%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.insight-val{
    font-size: 16px;
    font-weight: 500;
    color: var(--primary-neutral-600);
    margin-right: 10px;
}
.insight-deviation{
    color: var(--success-color);
    font-size: 10px;
    font-weight: 400;
    text-transform: uppercase;
}
.insight-deviation.r{
    color: var(--error-color);
}
.insight-deviation.no {
    color: var(--primary-neutral-300);
}
.insight-deviation.d-n {
    display: none;
}
.det-risk{
    font-size: 20px;
    font-weight: 500;
    margin-left: 20px;
}
.routes-list{
    padding: 0px 0 0 0;
}
.routes-list .pdf-list-hdr{
    border: none !important;
}
.routes-list .pdf-list-hdr div{
    font-size: 14px !important;
    font-weight: 500 !important;
    color: var(--primary-neutral-400);
    text-transform: uppercase;
}
.riq .pdf-list-row {
    font-size: 14px;
    display: flex;
    padding: 12px 0;
    font-weight: 400;
    /* margin-left: 16px; */
    align-items: center;
}
.riq .pdf-list-row > div {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.routes-list .pdf-list-row{
    margin-left: 0px;
    background-color: var(--primary-white) !important;
    border-bottom: 1px solid var(--primary-neutral-200) !important;
}
.routes-list .pdf-list-row:not(.pdf-list-hdr):hover {
    background: var(--primary-neutral-100) !important;
}
.r-col-0{
    width: 23%;
}
.r-col-0.pl{
    padding-left: 25px;
}
.r-col-0-1{
    width: 2%;
}
.r-col-1,.r-col-2,.r-col-3,.r-col-4,.r-col-5{
    width: 15%;
}
.routes-list .pdf-list-row:not(.pdf-list-hdr) div{
    font-size: 18px;
    font-weight: 500;
 }
.routes-list .pdf-list-row:not(.pdf-list-hdr) .r-col-0{
   font-size: 14px !important;
}
.routes-list .pdf-list-row:not(.pdf-list-hdr) .r-col-1{
    font-size: 16px !important;
    text-transform: uppercase;
}
.all-routes {
    padding: 12px 32px;
    font-size: 16px;
    font-weight: 500;
    color: var(--primary-blue-500);
    border: 1px solid var(--primary-blue-500);
    border-radius: 4px;
    margin-right: 12px;
    cursor: pointer;
}

.riq-leg-bar{
    display: flex;
    position: relative;
    width: 100%;
}
.riq-w-s-box {
    display: flex;
    justify-content: space-between;
}
.riq-w-s-box::before {
    content: '';
    border-top: 1px dashed #999999;
    position: absolute;
    left: 22px;
    right: 76px;
    top: 24.5px;
}
.riq-leg {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 60px;
    padding: 8px;
    cursor: pointer;
    min-height: 100px;
    position: relative;
    border-radius: 8px;
    margin: 0 8px 14px;
}
/* .riq-leg:before {
    content: '';
    border-top: 1px dashed #999999;
    transform: matrix(1, 0, 0, -1, 0, 0);
    left: -7px;
    right: -7px;
    position: absolute;
    top: 24px;
    z-index: 0;
}
.riq-leg.sel:before {
    border-top: 1px dashed #999;
    left: -7px;
    right: -7px;
}
.riq-leg.sel:after {
    content: '';
    position: absolute;
    border-top: 1px solid var(--primary-blue-75);
    left: 0px;
    right: 0px;
    top: 24px;
    z-index: 0;
}*/
.riq-leg.orig:before {
    left: 8px;
}

.riq-leg.dest:before {
    width: 10px;
}
.riq-leg.dest:after {
    width: 0;
    border-width: 0;
}

.riq-leg:not(.dis):hover {
    background-color: var(--primary-blue-75);
}
.riq-leg.dis:hover, .riq-leg.dis:hover .check-box label {
    cursor: not-allowed;
}
.riq-leg.sel {
    background-color: var(--primary-blue-75);
}

.riq-leg.sel:hover {
    background-color: var(--primary-blue-hover);
}
.riq-leg.sel:hover:after {
    border-color: var(--primary-blue-hover);
}

.riq-leg.orig, .riq-leg.dest, .riq-leg.orig:hover, .riq-leg.dest:hover {
    cursor: not-allowed;
    background: none;
}

.riq-leg.d, .riq-leg.d:hover, .riq-leg.dis, .riq-leg.dis:hover, .riq-leg.d .riq-value, .riq-leg.d:hover .riq-value {
    cursor: not-allowed;
}
.riq-leg.d, .riq-leg.d:hover {
    background-color: unset;
}


.riq-leg .riq-value{
    width: 24px !important;
    font-size: 14px !important; 
    line-height: 16px;
    font-weight: 600;
    padding: 5px !important;
    font-weight: 400;
}
.riq-leg .leg-name{
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    margin-top: 14px;
    margin-bottom: 28px;
    text-align: left;
    flex-grow: 1;
}
.leg-type {
    position: relative;
    z-index: 1;
    margin-bottom: 28px;
    margin-top: 8px;
}
.leg-type .origin{
    content: '';
    height: 4px;
    width: 4px;
    border-radius: 50%;
    background: var(--primary-white);
    border: 2px solid var(--primary-neutral-400);
    display: block;
    margin-bottom: 12px;
    transform: translateY(5.5px);
}
.riq-leg.dis label.disabled {
    background: var(--primary-neutral-300) !important;
}
/* --- */

.header-name {
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 0.35px;
    color: var(--riq-hdr-name);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 10px;
    margin-top: 56px;
    margin-left: 20px;
}
.header-name.n-o {
    overflow: visible;
}
.header-name-for {
    text-transform: capitalize;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.35px;
    color: var(--riq-secondary-color);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 20px 30px;
    /* border-top: 1px solid #fafafa; */
}
.first-bar {
    position: absolute;
    top: 70px;
    left: 0;
    width: 22%;
    bottom: 0;
    background: var(--riq-first-bar-bg);
    border-right: 1px solid var(--riq-first-bar-r-border);
    box-shadow: 0px 0 3px var(--riq-first-bar-box-shdw);
    z-index: 9999;
    /*animation: slideIn 100ms cubic-bezier(0, 0, 0, 0.8);*/
}
.second-bar {
    position: absolute;
    top: 180px;
    left: 22%;
    width: 34%;
    bottom: 0;
    background: var(--riq-primary-bg);
    z-index: 2;
    border-right: 1px solid var(--riq-sec-bar-r-border);
}
.third-bar {
    position: absolute;
    top: 180px;
    left: 64%;
    right: 0;
    bottom: 0;
    background: var(--riq-primary-bg);
    z-index: 0;
    animation: slideInR 100ms cubic-bezier(0, 0, 0, 0.8);
}
.risk-detail-box {
    height: 220px;
    box-sizing: border-box;    
}
.chart-display {
    height: 100px !important;
    left:0%;
    background: var(--riq-chart-display-bg);
}
.riq .config .company-detail-box {
    padding: 2% 2.6% 2% 2.6%;
    height: 110px;
    box-sizing: border-box;
    left:0%
}
 .right-bar.config {
     background: var(--riq-primary-bg);
 }

 .right-bar.config::after {
    content: '';
    height: 0;
    visibility: hidden;
    display: none;
    opacity: 0;
    font-size: 0;
    line-height: 0;
 }
 
.right-bar { 
    position: absolute;
    top: 70px;
    left: calc(20% + 72px);
    right: 0;
    bottom: 0;
    background: var(--riq-right-bar-bg);
    z-index: 0;
    animation: slideInR 100ms cubic-bezier(0, 0, 0, 0.8);
}

.right-bar.ex{
    left: calc(20% + 260px);
}

 .middle-bar + .right-bar {
    left: 34%;
}

.middle-bar.exp {
    right: 0;
    width: unset;
}

.middle-bar.exp + .right-bar {
    width: 0;
    z-index: -1;
}
.factors-chart {
    margin-right: 0%;
}
.top-box {
    position: absolute;
    top: 70px;
    left: 22%;
    right: 0;
    height: 140px;
    background: var(--riq-top-box-bg);
    z-index: 6;
    box-shadow: 0 1px 3px var(--riq-top-box-shdw);
    display: flex;
    /* transition: all 100ms ease-in; */
}
.pdf-list-row-full {
    margin: 0 -24px;
    padding: 0 24px;
}
.routes-list .pdf-list-row.s-route,
.routes-list .pdf-list-row.s-route:hover,
.pdf-list-row-full.s-route,
.pdf-list-row-full.s-route:hover {
    background: var(--primary-blue-hover) !important;
}
.riq .pdf-list-bdy {
    top: 88px;
    right: 0;
}
.riq.ad .pdf-list-bdy {
    top: 120px;
}
.riq .pdf-list-bdy .pdf-list-row .p-col-0 {
    padding: 1% 0 1% 20px;
}
.riq .pdf-list-bdy .pdf-list-row.a {
    background: var(--riq-pdf-list-row-a) !important;
}
.li-rd-v {
    flex-grow: 1 !important;
}
.li-riq-sc {
    /* color: #58FE37; */
    text-align: center;
    font-size: 16px;
    font-weight: 700;
}
.li-riq-sc.y {
    color: var(--riq-ship-stat-yellow-bg);
}
.li-riq-sc.r {
    color: var(--riq-ship-stat-red-bg);
}
.clr-sel {
    position: absolute;
    background: var(--riq-clr-sel-bg);
    font-size: 12px;
    border-radius: 2px;
    padding: 2px 10px;
    cursor: pointer;
    left: 30px;
}
.clr-sel::before {
    content: 'X';
    padding-right: 4px;
}
.clr-sel:hover {
    background: var(--riq-clr-sel-bg-hvr);
}
.comp-sel {
    position: absolute;
    background: var(--riq-compare-bg);
    font-size: 12px;
    font-weight: 500;
    border-radius: 2px;
    padding: 4px 10px 6px;
    cursor: pointer;
    right: 30px;
    color: var(--riq-primary-color);
}
.comp-sel:hover {
    box-shadow: 0 1px 3px var(--riq-compare-box-shdw-hvr);
}
#riq_tl_switch.switch-btn li {
    font-size: 12px;
}

.r-l-ship-list {
    position: absolute;
    top: 88px;
    right: 0;
    bottom: 0;
    left: 0;
}
.riq-lane-hdr {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 16px;
}
.top-box .ship-item {
    width: 34%;
    box-sizing: border-box;
    padding: 0px 36px;
}
.top-box .ship-item  .i-prog-bar::before {
    top: -4px;
}
.top-box .ship-item  .i-prog-bar::before,
.top-box .ship-item  .i-prog-bar::after  {
    background: var(--riq-i-progress-bar-befr-aftr-bg);
    font-size: 12px;
    z-index: 100;
}
.top-box .ship-item .i-prog-bar .i-progress {
    width: auto;
    right: 0;
    border-top: 1px solid var(--riq-i-progress-t-border);
    top: -1px;
}
.top-box .ship-item .i-prog-bar {
    border-color: var(--riq-i-progress-border);
}


.riq-det-sec {
    flex-grow: 1;
}
.riq-det-sec.ship-details {
    height: auto;
    background: unset;
}
.riq-det-sec.ship-details .ship-det-info .s-d-logo {
    width: 40px;
    height: 40px;
}
.riq-det-sec .ship-det-info {
    padding-top: 16px;
}
.riq-det-sec .ship-det-info li{
    width: 32%;
}
.riq-det-sec .ship-det-info .s-num-icn img {
    max-width: 20px;
    max-height: 20px;
    margin: 10px;
}
.riq-det-sec.ship-details .ship-det-info .s-d-i-txt { font-size: 12px; white-space: nowrap;}
.riq-det-sec.ship-details .ship-det-info .s-d-i-val { font-size: 16px; }
.riq-det-sec .ship-item .i-prog-bar {
    margin-bottom: 8px;
}
.riq-details-charts {
    
}
.riq-score-icn {
    position: absolute;
    top: 56px;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    color: var(--riq-primary-color);
    text-align: center;
    left: calc(50% - 12px);
    font-size: 20px;
}
.riq-score-icn.r { background: var(--riq-ship-stat-red-bg); }
.riq-score-icn.y { background: var(--riq-ship-stat-yellow-bg); }
.riq-score {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    padding: 6px 8px;
    border-radius: 1px;
    background: var(--riq-ship-stat-green-bg);
    color: var(--riq-primary-color);
    box-shadow: 0 1px 3px var(--riq-risk-score-box-shdw);
    height: 20px;
    width: 48px;
    margin-left: 6%;
    position: relative;
}
.riq-score.r { background: var(--riq-ship-stat-red-bg); }
.riq-score.r::before {
    position: absolute;
    content: "High Risk";
    font-size: 10px;
    color: var(--riq-risk-score-box-r-after);
    text-align: center;
    left: 0;
    right: 0;
    margin-top: -22px;
    white-space: nowrap;
}
.riq-score.y { background: var(--riq-ship-stat-yellow-bg); color: var(--riq-secondary-color); }
.riq-score.y::before {
    position: absolute;
    content: "Medium Risk";
    font-size: 10px;
    color: var(--riq-risk-score-box-r-after);
    text-align: center;
    left: 0;
    right: 0;
    margin-top: -22px;
    white-space: nowrap;
}
.riq-det-g-chart {
    display: flex;
    align-items: center;
    position: relative;
    margin: 0 40px 30px;
}
.risk-factors-sec {
    margin: 20px 0 0 40px;
    flex-grow: 1;
}
.r-fact {
    padding-bottom: 14px;
}
.r-cnt-sec {
    display: flex;
    align-items: flex-end;
}
.r-cnt {
    width: 80px;
    font-size: 13px;
    font-weight: 600;
    position: relative;
}
.r-cnt .r-val {
    position: relative;
    display: inline-block;
    padding: 6px 8px;
    border-radius: 1px;
    background: var(--riq-ship-stat-green-bg);
    color: var(--riq-primary-color);
    box-shadow: 0 1px 3px var(--riq-risk-score-box-r-after);
    cursor: pointer;
}
.config-modal .r-cnt .r-val {
    cursor: unset;
}
.r-cnt .r-val.y {
    background: var(--riq-ship-stat-yellow-bg);
    color: var(--riq-secondary-color);
}
.r-cnt .r-val.r {
    background: var(--riq-ship-stat-red-bg);
}
.r-cnt .r-val::after {
    content: '%';
    font-weight: 500;
    padding-left: 2px;
}
.r-cnt .risk-t-tip {
    display: none;
    position: absolute;
    white-space: nowrap;
    left: calc(60% + 18px);
    top: -2px;
    padding: 7px 8px;
    border-radius: 4px;
    background: var(--riq-ship-stat-green-bg);
    color: var(--riq-primary-color);
    font-size: 15px;
    font-weight: 500;
    box-shadow: 0 1px 3px var(--riq-risk-score-box-shdw);
}
.r-cnt .risk-t-tip::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid var(--riq-ship-stat-green-bg);
    left: 138px;
    top: 8px;
}
.r-cnt .tl::after{
    left: 172px;
}
.r-cnt .el::after{
    left: 197px;
}
.r-cnt .risk-t-tip.y::after {
    border-right: 8px solid var(--riq-ship-stat-yellow-bg);
}
.r-cnt .risk-t-tip.r::after {
    border-right: 8px solid var(--riq-ship-stat-red-bg);
}
.r-cnt .risk-t-tip.y{
    background: var(--riq-ship-stat-yellow-bg);
    color: var(--riq-secondary-color);
}
.r-cnt .risk-t-tip.r {
    background: var(--riq-ship-stat-red-bg);
}
.r-cnt:hover .risk-t-tip{
    display: block;
}
img.r-fact-icn {
    max-width: 32px;
}
.riq-details-charts img.r-fact-icn {
    max-width: 24px;
    margin-right: 12px;
}
.riq-details-charts .r-cnt-sec {
    align-items: center;
}
.r-fact-bar .l-riq-prog {
    width: 180px;
    height: 15px;
    background: var(--riq-primary-bg);
}
.r-fact-lbl {
    font-size: 12px;
    color: var(--riq-fact-lbl-color);
    font-weight: 500;
    width: 130px;
}
.r-fact-lbl .r-val {
    font-size: 14px;
    font-weight: 600;
}
.r-fact-bar {
    
}
.r-fact-bar .l-riq-prog {
    margin: 4px 0 10px;
}

.r-list {
    margin: 4px 0px 4px;
    margin-left: 30px;
    font-size: 13px;
    display: flex;
    align-items: center;
}
.list-cbox {
    height: 14px;
    width: 14px;
    border: 0.5px solid var(--riq-checkbox-border);
    margin-right: 10px;
    border-radius: 1px;
    cursor: pointer;
}

.list-cbox.check {
    background: var(--riq-checkbox-chkd-bg);
}
/* .r-list:hover .list-cbox, */.list-cbox:hover {
    box-shadow: 0 1px 3px var(--riq-risk-score-box-shdw);
}
.list-cbox.d {
    cursor: not-allowed;
    opacity: 0.75;
}
.list-lbl {
    width: 459px;
    /* cursor: pointer; */
}
.list-lbl .txt{
    cursor: pointer;
}
.r-l-ship-list .list-cbox + .list-lbl {
    font-weight: 600;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.riq-cnt-lbl {
    padding: 4px 8px;
    border-radius: 1px;
    background: var(--riq-ship-stat-green-bg);
    color: var(--riq-primary-color);
    box-shadow: 0 1px 3px var(--riq-risk-score-box-shdw);
    width: 48px;
    text-align: center;
    font-weight: 600;
    margin-left: 47px;
}
.riq-cnt-lbl.r {
    background: var(--riq-ship-stat-red-bg);
}
.riq-cnt-lbl.y {
    background: var(--riq-ship-stat-yellow-bg);
    color: var(--riq-secondary-color);
}
.list-cbox.check + .list-lbl {
    font-weight: 600;
}

.rdr-leg {
    margin: 2% 4%;
    font-size: 10px;
    font-weight: 500;
}
.rdr-leg li {
    display: flex;
    align-items: center;
    margin: 4PX 0;
}
.rdr-leg li .leg-lbl {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 2%;
}
.rdr-leg li .lane-lbl {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 14px;
    max-width: 180px;
    color: var(--riq-third-color);
}
.rdr-leg li .lane-lbl::after {
    content: '|';
    padding-left: 14px;
}
.rdr-leg li .leg-sc {
    font-size: 12px;
    font-weight: 600;
    margin-right: 2%;
    background: var(--riq-ship-stat-green-bg);
    padding: 1px 4px;
    color: var(--riq-primary-color);
}
.rdr-leg li .leg-sc.r {
    background: var(--riq-ship-stat-red-bg);
}
.rdr-leg li .leg-sc.y {
    background: var(--riq-ship-stat-yellow-bg);
    color: var(--riq-secondary-color);
}
.rdr-leg li .leg-sc.n {
    color: var(--riq-rdr-leg-li-leg-sc);
    background: var(--riq-rdr-leg-li-leg-sc-bg);
}
.rdr-leg li .leg-clr {
    width: 0px;
    border: 6px solid var(--riq-rdr-leg-li-leg-clr-border);
    height: 0;
    margin-right: 2%;
    border-radius: 2px;
    box-shadow: 0 1px 3px var(--riq-rdr-leg-li-leg-clr-box-shdw);
}
.rdr-leg li .leg-clr.clr-0 { border-color: var(--riq-chart-legend-border-0); }
.rdr-leg li .leg-clr.clr-1 { border-color: var(--riq-chart-legend-border-1); }
.rdr-leg li .leg-clr.clr-2 { border-color: var(--riq-chart-legend-border-2); }
.rdr-leg li .leg-clr.clr-3 { border-color: var(--riq-chart-legend-border-3); }
.rdr-leg li .leg-clr.clr-4 { border-color: var(--riq-chart-legend-border-4); }
.rdr-leg li.h1 {
    font-weight: 700;
    margin-top: 16px;
}
.riq .det-vu.pdf-list-row {
    display: block;
}
.riq .det-vu.pdf-list-row .l-det {
    display: flex;
}
.riq .det-vu.pdf-list-row .ship-item {
    padding: 12px 20px 0;
}
.riq .det-vu.pdf-list-row .ship-item .i-prog-bar {
    margin-bottom: 6px;
    border-color: var(--riq-i-prog-bar-border);
}
.riq .det-vu.pdf-list-row .ship-item .i-prog-bar::after {
    background-color: var(--riq-i-prog-bar-bg-after);
}
.riq .det-vu.pdf-list-row .p-col-1 {
    text-align: right;
    padding-right: 1%;
}
.det-vu.pdf-list-row .l-stat {
    margin-right: 20px;
    width: 18px;
    height: 18px;
}
.det-vu.pdf-list-row .l-stat .l-stat-icn {
    font-size: 12px;
}

/*guage --- CSS ::: START*/
/*.mask {
    position: relative;
    overflow: hidden;
    display: block;
    width: 12.5rem;
    height: 6.25rem;
    margin: 1.25rem;
  }
  .box {
    position: relative;
  }
  .box::before {
    content: '0';
    font-size: 10px;
    font-weight: 600;
    position: absolute;
    left: 1.8rem;
    bottom: 10px;
  }
  .box::after {
    content: '100';
    font-size: 10px;
    font-weight: 600;
    position: absolute;
    right: 1.8rem;
    bottom: 10px;
  }
  .semi-circle {
    position: relative;
    display: block;
    width: 12.5rem;
    height: 6.25rem;
    background: linear-gradient(to right, #89ED0C 0%, #FFCE00 50%, #FB5C4F 100%);
    border-radius: 50% 50% 50% 50%/100% 100% 0% 0%;
  }
  .semi-circle::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    z-index: 2;
    display: block;
    width: 8.75rem;
    height: 4.375rem;
    margin-left: -4.375rem;
    background: #f3f0f0;
    border-radius: 50% 50% 50% 50%/100% 100% 0% 0%;
  }
  
  .semi-circle--mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 12.5rem;
    height: 12.5rem;
    background: transparent;
    transform: rotate(120deg) translate3d(0, 0, 0);
    transform-origin: center center;
    backface-visibility: hidden;
    transition: all 0.3s ease-in-out;
  }
  .semi-circle--mask::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0%;
    z-index: 2;
    display: block;
    width: 12.625rem;
    height: 6.375rem;
    margin: -1px 0 0 -1px;
    background: #fff;
    border-radius: 50% 50% 50% 50%/100% 100% 0% 0%;
  } */
  /*guage --- CSS ::: START*/

  .geo-modal .geo-body.riq {
    bottom: 4px;
    top: 68px;
    border-top: 1px solid var(--riq-risk-t-border);
  }
  #riq_tl_pop_switch.switch-btn {
    width: 20%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 24px;
  }
  #riq_tl_pop_switch.switch-btn li {
    font-size: 12px;
    padding: 4px 6px;
  }
  .r-fact-col-sec {
    display: flex;
    border-bottom: 1px solid var(--riq-risk-t-border);
  }
  .r-fact-col {
    position: relative;
    flex-grow: 1;
    width: 33%;
    box-sizing: border-box;
    padding: 20px 1%;
    border-right: 1px solid var(--riq-risk-t-border);
    margin-top: -20px;
  }
  .r-fact-col:last-child {
    border-width: 0;
  }
  .r-fact-col.g {
    background-color: var(--riq-r-fact-col-g);
  }
  .r-fact-col .r-fact {
    padding-bottom: 5%;
    text-align: center;
  }
  .r-fact-col .r-cnt-sec {
    justify-content: center;
  }
  .r-fact-col .r-fact-lbl {
    padding-top: 2%;
  }
  .r-fact-col img.r-fact-icn {
    max-width: 36px;
    max-height: 36px;
  }

  .r-fact-col .l-riq-prog {
    margin: 4% auto 0;
    overflow: visible;
    width: 60%;
    max-width: 280px;
  }
  .r-fact-col .l-riq-prog .r-cnt {
    position: absolute;
    right: -100px;
    top: -5px;
  }
  .r-fact-col .l-riq-prog+.r-fact-lbl {
    padding: 8px 0 20px;
    text-align: center;
  }
  .r-fact-col .sel-box {
    display: block;
    width: 200px;
    margin: 8px auto 0%;
  }
  .r-fact-col .sel-box .sel-item {
    font-weight: 500;
  }
  .r-fact-col .sel-box .sel-item.n-o {
    opacity: 0.5;
  }

.rem-compare {

}
.l-c-fltr {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 30px;
    font-weight: 500;
    font-size: 14px;
}
.l-c-fltr li {
    color: var(--riq-l-c-fltr-li);
    border: 1px solid var(--riq-l-c-fltr-li-border);
    background: var(--riq-l-c-fltr-li-bg);
    border-radius: 4px;
    padding: 6px 8px;
    cursor: pointer;
    margin-right: 2%;
}
.l-c-fltr li:hover {
    box-shadow: 0 1px 3px var(--riq-l-c-fltr-li-box-shdw-hvr);
}
.l-c-fltr li.a {
    color: var(--riq-l-c-fltr-li-a-sltd-color);
    background-color: var(--riq-l-c-fltr-li-a-bg);
}
.l-c-fltr li:active {
    box-shadow: 0 1px 3px var(--riq-l-c-fltr-li-box-shdw-hvr);
}
.l-c-fltr li.d, .l-c-fltr li.d:hover, .l-c-fltr li.d.a, .l-c-fltr li.d.a:hover {
    cursor: not-allowed;
    box-shadow: none;
}
.l-c-fltr li.d:active, .l-c-fltr li.d.a:active {
    pointer-events: none;
}
.l-c-fltr li.d:not(.a), .l-c-fltr li.d:not(.a):hover {
    color: var(--primary-neutral-300);
    border-color: var(--primary-neutral-300);
    box-shadow: none;
}
.top-box.riq {
    display: unset;
    box-shadow: 0 1px 3px var(--riq-top-box-riq-box-shdw);
}
.top-box.riq.lng {
    height: 180px;
}
.h-2-sec {
    display: flex;
    align-items: center;
}
.top-box.riq .fltr-box {
    box-shadow: 0px 1px 4px var(--riq-top-box-fltr-box-shdw);
}
.top-box.riq .fltr-box.sel:hover {
    box-shadow: 0px 1px 4px var(--riq-top-box-fltr-box-shdw-hvr);
}
.fltr-box.sel.l {
    min-width: unset !important;
}
.fltr-box.sel.d {
    opacity: 0.65;
    cursor: not-allowed;
}
.top-box.riq .fltr-box.sel {
    min-width: 140px;
    border: 1px solid var(--riq-top-box-fltr-box-sel-border);
    border-radius: 3px;
}
.top-box.riq .fltr-box.a {
    background: var(--riq-view-bg);
    color: var(--riq-primary-color);
}
.riq .fltr-box .val {
    line-height: 24px;
    border-width: 2px;
}
.top-box.riq .pdf-fltrs {
    margin: 18px 0 18px 30px;
}
.riq-betails-sec {
    position: absolute;
    top: 210px;
    left: 22%;
    right: 0;
    bottom: 0;
    background: var(--riq-primary-bg);
    z-index: 2;
    /* border-right: 1px solid #aaa; */
    /* transition: all 100ms ease-in; */
}
.riq-betails-sec.lng {
    top: 250px;
}
.riq-betails-sec .riq-det-row {
    display: flex;
    justify-content: left;
    box-sizing: border-box;
    /* height: 48%;
    min-height: 320px; */
    margin: 0 30px 2%;
}
.riq-betails-sec .riq-det-row .col{
    /* background: #FFFFFF;
    border: 0.7px solid #E2E2E2;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.11); */
    border-radius: 8px;
    flex-basis: 30%;
    margin: 3px 1% 0;
}
.riq-betails-sec .riq-det-row .col:first-child {
    margin-left: 0;
}
.riq-betails-sec .riq-det-row .col:last-child {
    margin-right: 0;
}
.riq-betails-sec .riq-det-row .col.s-1-3 {
    width: 33%;
    flex-basis: 33%;
}
.riq-betails-sec .riq-det-row .col.s-2-3 {
    width: 69%;
    flex-basis: 69%;
}
.riq-betails-sec .riq-det-row .col.s-1-2 {
    width: 49%;
    flex-basis: 49%;
}
.riq-betails-sec .riq-det-row .col.s-3-3 {
    width: 99%;
    flex-basis: 99%;
}
.riq-details-charts .r-cnt .r-val {
    padding: 2px  8px;
}
.riq-details-charts .r-cnt .risk-t-tip {
    top: -6px;
    font-size: 13px;
}
.riq-details-charts .r-cnt .risk-t-tip::after {
    right: unset;
    left: -6px;
}
.sum-sec {
    padding: 10px 30px;
}
.sum-sec li {
    padding: 4px 12px;
    margin-bottom: 12px;
    border: 0.7px solid var(--riq-summary-sec-li-border);
    border-left: 2px solid var(--riq-summary-sec-li-l-border);
    box-shadow: 0px 4px 4px var(--riq-summary-sec-li-box-shdw);
    font-size: 12px;
}
.sum-sec li.sum-sec-hdr {
    padding: 0;
    border: none;
    box-shadow: none;
    font-size: 12px;
    font-weight: 600;
    color: var(--riq-secondary-color);
}
.c-c-f::after {
    content: ' Critical contributing factor(s):';
}
.c-c-f.f-0::after {
    content: '';
}
.sum-sec.c-pop .sum-sec-hdr::before {
    content: ' ';
    width: 0px;
    border: 6px solid var(--riq-summary-sec-li-border-before);
    height: 0;
    /* margin-right: 2%; */
    border-radius: 2px;
    box-shadow: 0 0 1px var(--riq-summary-sec-li-box-shdw-before);
    position: absolute;
    margin-left: -22px;
}
.sum-sec.c-pop .sum-sec-hdr.h-0::before {
    border-color: var(--riq-chart-legend-border-1);
}
.sum-sec.c-pop .sum-sec-hdr.h-1::before {
    border-color: var(--riq-chart-legend-border-2); 
}
.sum-sec.c-pop .sum-sec-hdr.h-2::before {
    border-color: var(--riq-chart-legend-border-3);
}
.sum-sec.c-pop .sum-sec-hdr.h-3::before {
    border-color: var(--riq-chart-legend-border-4);
}
.sum-sec.c-pop .sum-sec-hdr .leg-r-sc {
    background: var(--riq-summary-sec-hdr-leg-r-sc-bg);
    color: var(--riq-primary-color);
    padding: 0px 4px;
    border-radius: 2px;
    margin-left: 2%;
}
.sum-sec.c-pop .sum-sec-hdr .leg-r-sc.r {
    background: var(--riq-ship-stat-red-bg);
}
.sum-sec.c-pop .sum-sec-hdr .leg-r-sc.y {
    background: var(--riq-ship-stat-yellow-bg);
    color: var(--riq-secondary-color);
}
.sum-sec.c-pop .sum-sec-hdr .leg-r-sc.g {
    background: var(--riq-ship-stat-green-bg);
}

.sum-c:not(:empty) {
    padding: 4px 0; 
}
.sum-c li {
    border: none;
    box-shadow: none;
    margin-bottom: 0px;
    padding: 0px 10px;
}
.sum-c li::before {
    content: "\2022";
    color: var(--riq-sum-c-li-before);
    font-weight: bold;
    width: 14px;
    display: inline-block;
    font-size: 16px;
}
.sum-sec li span {
    font-weight: 600;
}
.sum-sec li span.t-tip {
    font-size: unset;
    color: unset;
    font-weight: 500;
}
.sum-sec li span.t-tip .t-txt {
    font-size: 12px;
    word-spacing: 8px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 18px;
}
.sum-sec li .i {
    color: var(--riq-ship-stat-green-bg);
}
.sum-sec li .i.y {
    color: var(--riq-ship-stat-yellow-bg);
}
.sum-sec li .i.r {
    color: var(--riq-ship-stat-red-bg);
}
.riq-betails-sec .header-name-for.p-0 {
    padding-bottom: 0px;
}
.header-name-for.p-0-0 {
    padding: 0 0 0 30px;
}
.s-h1 {
    margin-left: 473px;
}

.l-o-d {
    font-size: 12px;
    padding: 2px 20px;
    display: flex;
    align-items: center;
}
.l-o-d > span.sep {
    padding: 0 4px;
    font-weight: normal;
    font-size: 10px;
    color: var(--riq-secondary-color);
}
.l-o-d > span {
    display: inline-block;
    max-width: 120px;
    color: var(--riq-l-o-d-span);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.h-2-sec.route-box {
    padding: 20px 0;
}
.h-2-sec.route-box .ship-item {
    color: var(--riq-secondary-color);
    font-weight: 600;
    padding-left: 60px;
    padding-top: 14px;
    display: flex;
}
.clr-94 {
    color: var(--riq-clr-94);
}
.l-n-abs {
    position: absolute;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 4px;
    box-sizing: border-box;
}
.h-2-sec .ship-item .i-prog-bar { margin-bottom: 8px; }

.leg-det {
    margin: 6% 0;
}
.leg-det-sec {
    display: flex;
    width: 80%;
    margin: 2% 10%;
    position: relative;
}
.leg-det-sec::before {
    position: absolute;
    content: '';
    border-top: 1px solid var(--riq-secondary-color);
    top: 50%;
    left: 0;
    right: 0;
}
.leg-sec-orig {
    position: absolute;
    width: 16px;
    height: 16px;
    border: 1px dashed var(--riq-risk-t-border);
    left: 10%;
    border-radius: 50%;
    margin-top: -9px;
    margin-left: -6px;
}
.leg-det-sec::after {
    content: '';
    position: absolute;
    /* background-color: #272727; */
    /* width: 10px;
    height: 10px; */
    right: -14px;
    top: 50%;
    margin-top: -11px;
    background: url('../../assets/images/location.png') no-repeat 50% 50%;
    background-size: 16px 20px;
    width: 16px;
    height: 20px;
}
.l-sec {
    position: relative;
    flex-grow: 1;
    font-size: 12px;
    font-weight: 500;
}

.l-sec::before {
    content: '';
    position: absolute;
    background-color: var(--riq-primary-color);
    width: 10px;
    height: 10px;
    top: 50%;
    border: 2px solid var(--riq-secondary-color);
    border-radius: 50%;
    margin-top: -7px;
}
.l-sec.static::before {
    background: url('../../assets/images/stop_riq_leg.png') no-repeat 50% 50%;
    background-size: 14px;
    margin-left: -7px;
    margin-top: -9px;
    border: none;
    border-radius: 0;
    width: 14px;
    height: 14px;
}
/* .l-sec.a.static::before {
    background: url('../../assets/images/stop_riq_leg_a.png') no-repeat 50% 50%;
    background-size: 14px;
} */
.l-sec:first-child::before {
    border-radius: 50%;
    border: 3px solid var(--riq-primary-color);
    margin-top: -8px;
    margin-left: -5px;
    background: var(--riq-secondary-color);
    width: 10px;
    height: 10px;
}
.l-sec::after {
    content: '';
    position: absolute;
    margin-top: 0px;
    left: 0;
    right: 0;
    border-top: 1px solid var(--riq-secondary-color);
    z-index: -1;
}
.s-name {
    position: absolute;
    bottom: -22px;
    transform: translateX(-50%);
    /* max-width: 120px; */
    white-space: nowrap;
    text-overflow: ellipsis;
    /* overflow: hidden; */
    cursor: pointer;
}
.l-sec.waypoint .s-name {
    margin-left: 7px;
}
.l-path {
    position: absolute;
    top: -20px;
    width: 100%;
    display: flex;
    justify-content: center;
    cursor: pointer;
}
.s-name.d, .l-path.d {
    cursor: not-allowed;
}
.l-sec.static .t-tip, .l-sec.waypoint .t-tip {
    position: absolute;
    margin-left: -7px;
    margin-top: -9px;
    width: 14px;
    height: 14px;
}
.l-sec .t-tip .t-txt {
    padding: 6px 10px;
    transform: translateX(-50%);
    margin-left: 7px;
    bottom: 20px;
}
.l-sec.waypoint .t-tip {	
    margin-left: 0;	
}
.l-p {
    background: url('../../assets/images/riq_road_mode.png') no-repeat 50% 0px;
    width: 20%;
    position: relative;
    margin: 0 2px;
}
.l-p.air {
    background: url('../../assets/images/riq_air_mode.png') no-repeat 50% -2px;
    height: 18px;
    margin-top: -8px;
    background-size: 20px;
}
.l-p::before {
    top: 7px;
    left: 0;
    right: 0;
    position: absolute;
    border-top: 1px dotted var(--riq-l-p-before);
    content: '';
}
.l-p.air::before {
    top: 15px;
}
.l-o {
    max-width: calc(38% - 10px);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.l-d {
    max-width: calc(38% - 10px);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.l-r-s {
    position: absolute;
    margin-top: -20px;
    font-weight: 700;
    text-align: center;
    background: var(--riq-l-r-s-bg);
    color: var(--riq-primary-color);
    padding: 1px 4px;
    left: 50%;
    transform: translateX(-50%);
}
.s-name .l-s-r-s {
    font-weight: 700;
    width: 100%;
    text-align: center;
    background: var(--riq-l-r-s-bg);
    color: var(--riq-primary-color);
    padding: 1px 4px;
}
.s-name .l-s-r-s.n, .l-r-s.n {
    color: var(--riq-l-r-s-n);
}
.l-r-s.r, .s-name .l-s-r-s.r {
    background: var(--riq-ship-stat-red-bg);
}
.l-r-s.y, .s-name .l-s-r-s.y {
    background: var(--riq-ship-stat-yellow-bg);
    color: var(--riq-secondary-color);
}
.l-r-s.g, .s-name .l-s-r-s.g {
    background: var(--riq-ship-stat-green-bg);
}

.l-sec:hover .l-o, .l-sec:hover .l-d {
    
}
/* .l-sec:hover .l-o, .l-sec:hover .l-d, */
.l-sec.a .l-o, .l-sec.a .l-d {
    color: var(--riq-checkbox-chkd-bg);
}
/* .l-sec:hover .l-p::before, */
.l-sec.a .l-p::before {
    border-color: var(--riq-checkbox-chkd-bg);
}
/* .l-sec:hover .l-p, */
.l-sec.a .l-p {
    background-image: url('../../assets/images/riq_road_mode_a.png');
}
/* .l-sec:hover .l-p.air, */
.l-sec.a .l-p.air {
    background-image: url('../../assets/images/riq_air_mode_a.png');
}
/* .l-sec:hover .s-name, */
.s-name.s {
    color: var(--riq-checkbox-chkd-bg);
}
.l-sec:first-child .s-name, .s-name.dest,
.l-sec:first-child.a .s-name {
    cursor: not-allowed;
    /* color: #7B7B7B; */
    font-size: 12px;
    font-weight: 600;
}
.l-sec:first-child .s-name b { display: none; }
/* .l-sec:hover .s-name::before, */
.l-sec.a .s-name::before {
    background-image: url('../../assets/images/stop_riq_leg_a.png');
}

.r-fact-col .r-cnt-sec {
    font-weight: 700;
}
.r-fact-col .r-cnt-sec .info {
    color: var(--riq-ship-stat-green-bg);
}
.r-fact-col .r-cnt-sec .info.r {
    color: var(--riq-ship-stat-red-bg);
}
.r-fact-col-sec .r-fact-lbl {
    width: auto;
    padding-top: 4px;
    text-align: center;
    padding-bottom: 20px;
}
.r-fact-col-r-name {
    font-size: 10px;
    border: 1px solid var(--riq-r-fact-col-r-name-border);
    padding: 4px 6px;
    text-align: center;
    width: 80%;
    max-width: 300px;
    margin: 16px auto 1px;
    border-radius: 1px;
    background: var(--riq-r-fact-col-r-name-bg);
    color: var(--riq-third-color);
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.clr-r-name {
    display: inline-block;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.riq-det-col {
    display: flex;
}
.riq-det-col .col {
    width: 50%;
    box-sizing: border-box;
}

tspan[style='fill:#aaa'], tspan[style='fill:#55b2e7'], tspan[style='fill:#7C4DFF'], tspan[style='fill:#ff8f00'], tspan[style='fill:#cd4a87'] {
    font-weight: 700;
    font-size: 12px;
}
.r-f-v {
    font-weight: 700;
    margin-right: 4px;
    border-radius: 2px;
    color: var(--riq-primary-color);
    background: var(--riq-chart-legend-border-1);
    min-width: 14px;
    display: inline-block;
    text-align: center;
} 
.r-f-v:not(:empty) {
    padding: 0 2px;
}
.r-f-v.c-1 {
    background: var(--riq-chart-legend-border-2);
}
.r-f-v.c-2 {
    background: var(--riq-chart-legend-border-3);
}
.r-f-v.c-3 {
    background: var(--riq-chart-legend-border-4);
}
.r-f-v.c-4 {
    background: var(--riq-chart-legend-border-5);
}
.r-f-v.c-5 {
    background: var(--riq-chart-legend-border-5);
}

.risk-fact-compare-view {
    display: flex;
}
.risk-fact-chart {
    flex-grow: 1;
}
.risk-fact-insights {
    width: 0%;
    transition: all ease-in 750ms;
    overflow: hidden;
    background-color: var(--riq-risk-fact-insights);
}

.c-ins.geo-close-icn {
    position: absolute;
    font-size: 20px;
    right: 36px;
    font-weight: 500;
}

.ln-s{
    position: absolute;
    max-width: 300px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-left: 5px;
}

.data-not-availble > div, .data-not-availble > div * {
    opacity: 0;
    height: 0 !important;
    overflow: hidden;
}

.data-not-availble::before {
    content: 'Select a lane to show Risk Trends';
    height: 40%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* background: var(--riq-data-not-availble-before-bg); */
    transition: all 100ms ease-in;
    font-size: 18px;
    font-weight: 400;
    color: var(--primary-neutral-300)
}
.data-not-availble.radar::before {
    content: 'Select a lane to show Lane Risk Factors';
}
.data-not-availble.no::before {
    content: 'No data available';
}
.data-not-availble.r::before {
    content: 'Select a lane to show Risk Insights';
}

.riq-l-share {
    position: absolute;
    display: flex;
    font-size: 12px;
    align-items: center;
    border: 1px solid var(--riq-riq-l-share-border);
    padding: 2px 6px 2px 4px;
    right: 86px;
    top: 23px;
    border-radius: 3px;
    cursor: pointer;
    color: var(--riq-riq-l-share);
}
.riq-l-share .material-icons-outlined {
    font-size: 12px;
    margin-right: 4px;
}
.riq-l-share:hover {
    box-shadow: 0 0px 2px var(--riq-riq-l-hvr-box-shdw);
}

.riq-p {
    background: var(--primary-blue-75);
    left: 72px !important;
}
.riq-p.ex {
    background: var(--primary-blue-75);
    left: 260px !important;
}
.riq-p-bx:first-child {
    margin-top: 5%;
}
.riq-p-bx {
    padding: 24px 48px;
    margin: 3%;
    border-radius: 4px;
    background: var(--primary-white);
}
.riq-p-bx.e-s {
    padding: 24px;
}
.riq-p-fltr {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.riq-p-fltr .sel-box {
    margin-right: 36px;
    width: 192px;
}
.riq-p-row {
    border-bottom: 1px solid var(--primary-neutral-200);
    margin: 0 -24px;
    padding: 2% 24px 2%;
}
.riq-p-row .riq-p-l-r-l-bx {
    display: flex;
    align-items: center;
}
.riq-p-row:last-child {
    border-width: 0;
}
.riq-p-fltr .lbl, .riq-p-row .lbl {
    font-size: 14px;
    margin-bottom: 0.8%
}
.riq-p-row .riq-p-c {
    margin-right: 2%;
    width: 10%;
    flex-shrink: 0;
}
.riq-p-row .riq-p-c .sel-box {
    width: 99%;
}
/* .riq-p-row .riq-p-c.sel {
    background: var(--primary-neutral-200);
    position: relative;
}
.riq-p-c.c-la.sel:before, .riq-p-c.c-r.sel:before, .riq-p-c.c-s.sel:before {
    content: ' ';
    background: var(--primary-neutral-200);
    position: absolute;
    top: 0;
    left: -6%;
    right: -6%;
    bottom: -12%;
} */
.riq-p-row .c-la {
    width: 15%;
}
.riq-p-row .c-r {
    width: 26%;
    flex-grow: 1;
}
.riq-p-row .c-s {
    width: 15%;
}
.riq-p-row .c-rs {
    width: 16%;
    display: flex;
    align-items: center;
}
.riq-p-row .c-adt {
    width: 14%;
}
.riq-p-row .c-cls {
    width: 50px;
    text-align: right;
    margin: 0;
}
.riq-p-row .c-cls img {
    margin-bottom: 28px;
    cursor: pointer;
}
.p-btn.a-lane {
    margin: 3% 0;
}
.a-l-info-txt {
    margin-left: 20px;
    color: var(--primary-neutral-300);
    font-size: 14px;
    font-weight: 400;
}
.a-l-info-txt img {
    opacity: 0.45;
    margin-bottom: -4px;
}
.avg-d-t-lbl {
    font-size: 14px;
    font-weight: 400;
    color: var(--primary-neutral-400);
    padding-bottom: 14px;
}
.avg-d-t-val {
    font-size: 16px;
    font-weight: 500;
    color: var(--primary-neutral-300);
}
.avg-d-t-val span.dev {
    padding-left: 8px;
    font-size: 10px;
    font-weight: 400;
}

.riq-fact-swt {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 30px;
}
.riq-fact-swt li {
    border-bottom: 2px solid transparent;
    cursor: pointer;
    padding-bottom: 2px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
}
.riq-fact-swt li:hover {
    color: var(--primary-blue-500);
}
.riq-fact-swt li.a {
    color: var(--primary-blue-500);
    border-color: var(--primary-blue-500);
}
.riq-fact-swt li.d {
    color: var(--primary-neutral-300);
    opacity: 0.75;
    cursor: not-allowed;
}
.riq-fact-swt li.a.d {
    border-color: var(--primary-neutral-300);
}
.riq-fact-det {
    display: flex;
}
.riq-fact-det .riq-f-col {
    width: 50%;
    position: relative;
}
.riq-fact-det .riq-f-col:nth-child(2) {
    padding-left: 30px;
}
.riq-fact-det .riq-f-col .riq-f-h {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
}
.riq-fact-det .riq-f-col .riq-f-h img {
    margin-right: 8px;
}
.riq-t-leg {
    display: flex;
}
.riq-t-leg.lgd-pos{
    justify-content: flex-start;
}
.leg-itm {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 12px;
    line-height: 12px;
    color: var(--primary-neutral-400);
    font-weight: 400;
    margin-right: 12px;
    display: flex;
    align-items: center;
}
.leg-itm span {
    margin-left: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-transform: capitalize;
    text-wrap: wrap;
}
.leg-itm.m-wth span {
    max-width: 250px;
}

.graph-legend-container{
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 12px;
}

.graph-legend{
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-top: 5px;
    gap: 16px;
    margin-left: 10px;
}

.risk-item{
    display: flex;
    align-items: center;
}
.risk-item .circle{
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: var(--bar-graph-1);
    margin-right: 8px;
}
.legend-container {
    display: flex;
    align-items: center;
    font-size: 12px;
  }
  
  .blue-rectangles {
    display: flex;
    margin-right: 8px;
  }
  
  .dot {
    width: 2px;
    height: 2px; 
    background-color:#DC5F96;
    margin-right: 2px;
  }
  
  .rectangle {
    width: 5px;
    height: 2px; 
    background-color:#DC5F96;
    margin-right: 2px;
  }
  
  .rectangle:last-child {
    margin-right: 0;
  }

  .risk-trend-line{
    font-size: 14px;
    position: relative;
    left: -15px;

  }


.leg-itm.lane:before {
    content: '';
    border: 4px solid var(--bar-graph-1);
    line-height: 0;
    font-size: 0;
    width: 16px;
    height: 3px;
    background-color: var(--bar-graph-1);
}


.leg-itm.lane:nth-child(2):before {
    border: 4px solid var(--bar-graph-4);
    width: 16px;
    height: 3px;
    background-color: var(--bar-graph-4);
}



.leg-itm:before {
    content: '';
    border: 4px solid var(--bar-graph-10);
    line-height: 0;
    font-size: 0;
    width: 16px;
    height: 3px;
    background-color: var(--bar-graph-10);
}
.leg-itm:nth-child(2):before {
    border-color: var(--bar-graph-1);
    width: 16px;
    height: 3px;
    background-color: var(--bar-graph-1);
}


.leg-itm:nth-child(3):before {
    border-color: var(--bar-graph-2);
    width: 16px;
    height: 3px;
    background-color: var(--bar-graph-2);
}
.leg-itm:nth-child(4):before {
    border-color: var(--bar-graph-5);
    width: 16px;
    height: 3px;
    background-color: var(--bar-graph-5);
}
.leg-itm:nth-child(5):before {
    border-color: var(--bar-graph-4);
    width: 16px;
    height: 3px;
    background-color: var(--bar-graph-4);
}

.leg-itm:nth-child(6):before {
    border-color: var(--bar-graph-7);
    width: 16px;
    height: 3px;
    background-color: var(--bar-graph-7);
}
.riq-ins-box {
    display: flex;
    align-items: flex-start;
    flex-flow: wrap;
}
.riq-ins {
    flex-grow: 1;
    padding: 0 2% 0 0;
    width: 48%;
}
.riq-p .riq-ins-box .riq-ins {
    width: 31%;
}
.riq-ins .riq-ins-h {
    font-size: 14px;
    font-weight: 400;
    color: var(--primary-neutral-400);
    margin-bottom: 12px;
}
.riq-ins .riq-ins-h.na{
    font-size: 16px;
    font-weight: 600;
}
.riq-ins-box.m-3 .riq-ins .riq-ins-h {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}
.riq-ins .riq-ins-r {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 500;
    color: var(--primary-neutral-600);
    border-left: 4px solid var(--data-viz-teal);
    padding-left: 8px;
    display: flex;
    align-items: center;
}
.riq-ins .riq-ins-r:nth-child(2) {
    border-color: var(--data-viz-teal);
}
.riq-ins .riq-ins-r:nth-child(3) {
    border-color: var(--data-viz-lime);
}
.riq-ins .riq-ins-r:nth-child(4) {
    border-color: var(--data-viz-plum);
}
.riq-ins .riq-ins-r:nth-child(5) {
    border-color: var(--data-viz-orange);
}
.riq-ins .riq-ins-r:nth-child(6) {
    border-color: var(--data-viz-violet);
}
.riq-ins .riq-ins-r.na {
    border-width: 0;
    padding: 0;
    color: var(--primary-neutral-300)
}
.riq-ins .riq-ins-r .dev {
    font-size: 12px;
    font-weight: 500;
    padding: 1px 6px 1px 20px;
    border-radius: 4px;
    background: var(--success-color-bg);
    color: var(--success-color);
    position: relative;
    margin-left: 8px;
}
.riq-ins .riq-ins-r .dev:before{
    content: '▾';
    font-size: 18px;
    position: absolute;
    left: 3px;
    top: 2px;
}
.riq-ins .riq-ins-r .dev.u:before{
    content: '▴';
}
.riq-ins .riq-ins-r .dev.r {
    background: var(--error-bg-color);
    color: var(--error-color);
}
.riq-ins .riq-ins-r .dev.r.d:before{
    content: '▾';
}
.riq-ins .riq-ins-r .dev.r:before{
    content: '▴';
}
.riq-ins .riq-ins-r .dev.eq {
    background: var(--primary-neutral-300);
    padding-left: 6px;
    color: var(--primary-white);
}
.riq-ins .riq-ins-r .dev.n-a {
    background: none;
    padding-left: 6px;
    color: var(--primary-neutral-300);
}
.riq-ins .riq-ins-r .dev.eq::before, 
.riq-ins .riq-ins-r .dev.n-a:before,
.riq-ins .riq-ins-r .dev.r.n-a:before {
    content: '';
}
.riq-ins .riq-ins-r .dev.d-n {
    display: none;
}
.c-rs.d span {
    color: var(--primary-neutral-600)
}
.list-tem-flex{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.r-col-1 span{
    font-size: 16px;
    font-weight: 500;
}
.fltr-row.margin{
    margin: 12px 32px !important;
}
.low-s-count{
    margin-left: 30px;
    font-size: 14px;
    font-weight: 500;
    color: var(--error-color);
    border: 1.5px solid var(--error-color);
    border-radius: 4px;
    padding: 0px 10px;
}
.lbl-df{
    display: flex;
    align-items: center;
}
.cf-row{
    padding: 7px 10px;
    border-bottom: 1px solid var(--grey-1);
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.cf-color{
    height: 16px;
    width: 16px;
    border-radius: 50%;
    margin-right: 10px;
}
.cf-color.c1{
    background-color: var(--risk-c1);
}
.cf-color.c2{
    background-color: var(--risk-c2);
}
.cf-color.c3{
    background-color: var(--risk-c3);
}
.cf-color.c4{
    background-color: var(--risk-c4);
}
.cf-color.c5{
    background-color: var(--risk-c5);
}
.cf-color.c6{
    background-color: var(--risk-c6);
}
.cf-col-1{
    display: flex;
    justify-content: space-between;
    width: 50%;
}
.cb-score{
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
}
.cf-col-2{
    margin-left: 10%;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    width: 40%;
    color: var(--primary-neutral-400);
}
.mode-b{
    display: inline;
}
.mode-b > img {
    max-width: 16px;
    max-height: 16px;
}

/*trend custom bar graph*/
.bar-grph {
    display: flex; 
    flex-direction: column;
    margin-bottom: 20px;
}
.bar-grph:last-child {
    margin-bottom: 32px;
}
.bar-grph-r1{
    display: flex; 
    justify-content: space-between; 
    align-items: center; 
    margin-bottom: 10px;
}
.bar-r1-txt{
    font-size: 14px; 
    line-height: 16px; 
    columns: var(--base);
}
.bar-r1-txt.na{
    columns: var(--primary-neutral-400);
}
.bar-df{
    display: flex;
    position: relative;
}
.bar-df > div{
    display: flex; 
    flex-direction: column; 
    align-items: flex-start;
    position: relative;
    z-index: 2;
}
.bar-df::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 10px;
    z-index: 0;
    background: var(--primary-neutral-300);
}
.bar-clr-sec{
    margin-bottom: 5px; 
    height: 10px; 
    width: calc(100% - 2px); 
    border-right: 2px solid var(--primary-white);
}
.bar-grph .empty-bar {
    width: 100%;
}
.bar-grph .empty-bar .bar-clr-sec {
    height: 10px;
    background: var(--primary-neutral-300);
}
.bar-clr-sec.c1, .lgd-clr-1{
    background-color: var(--bar-graph-1);
}
.bar-clr-sec.c2, .lgd-clr-2{
    background-color: var(--bar-graph-2);
}
.bar-clr-sec.c3, .lgd-clr-3{
    background-color: var(--bar-graph-3);
}
.bar-clr-sec.c4, .lgd-clr-4{
    background-color: var(--bar-graph-4);
}
.bar-clr-sec.c5, .lgd-clr-5{
    background-color: var(--bar-graph-5);
}
.bar-clr-sec.c6, .lgd-clr-6{
    background-color: var(--bar-graph-6);
}
.bar-riq-per{
    font-size: 12px; 
    line-height: 16px; 
    color: var(--primary-neutral-400);
}

.bar-grph-lgd{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 16px;
}

.bar-lgd-1{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 4%;
}

.bar-lgd-1 .lgd-clr-1,
.bar-lgd-1 .lgd-clr-2,
.bar-lgd-1 .lgd-clr-3,
.bar-lgd-1 .lgd-clr-4,
.bar-lgd-1 .lgd-clr-5,
.bar-lgd-1 .lgd-clr-6{
    height: 8px;
    width: 16px;
    margin-right: 8px;
}

.bar-lgd-1 .lgd-txt{
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
    color: var(--primary-neutral-400);
}

.checkmark{
    width: 4px;
    height: 10px;
    border: solid var(--primary-white);
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    position: absolute;
    content: '';
    cursor: pointer;
    margin-top: 5px;
    margin-left: -16px;
    border-radius: 2px;
}
.riq-leg:hover:not(.sel) .checkmark {
    border-color: var(--primary-neutral-300);
}
.checkmark.ds{
    display: none;
}

.sel-ori-des{
    margin-top: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 96%;
}

.sel-ori-des.w-100{
    width: 100%;
}

.sel-ori-des.w-100 > div{
    width: 50%;
}

.sel-ori-des > div.in-btwn{
    width: 3%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sel-ori-des > div .redesign-srch{
    width: 100%;
    margin-left: 0px;
}

.bk-arr-icon .swap-icn {
    margin: 0;
    padding: 20px 0;
    opacity: 0.8;
    cursor: pointer;
}
.bk-arr-icon .swap-icn.dis {
    cursor: not-allowed;
}
.bk-arr-icon .swap-icn:not(.dis):hover {
    opacity: 1;
}

.sel-ori-des .redesign-dur-fltrs div {
    font-size: 24px;
    line-height: 32px;
    text-transform: capitalize;
}

.popup-lane-lst.risk-p {
    top: 240px;
}
.popup-lane-lst.risk-p.w-pts-seg {
    top: 326px;
}
.popup-lane-lst.risk-p .epty-list {
    padding: 40px;
    background-color: var(--primary-white);
    border: 1px solid var(--primary-blue-hover);
}

.popup-lane-lst.risk-p .el-ctr span {
    margin-bottom: 20px;
}

.riq-col-0.rts-view-sel{
    width: 5%;
}
.riq-col-0.cp.rts-view-sel{
    width: 16%;
}
.riq-col-0.cp.rts-view-sel.seg-view{
    width: 13%;
}
.riq-col-1.rts-view-sel{
    width: 35%;
}
.riq-col-2.rts-view-sel{
    width: 12%;
}
.riq-col-3.rts-view-sel{
    width: 15%;
}
.riq-col-3.cp.rts-view-sel{
    width: 12%;
}
.riq-col-5.rts-view-sel{
    width: 15%;
}
.popup-lane-lst.redesign-risk-list .r-l-view{
    top: 0;
    padding-top: 0;
}
.m-r-l--20 {
    margin: 0 -20px;
}

.riq-col-5.high, .riq-col-5.medium, .riq-col-5.low{
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    text-transform: uppercase;
}

.redesign-lst-updt.rp {
    margin-bottom: 32px;
    margin-top: 4px;
}
.redesign-lst-updt.t-t-n {
    text-transform: none;
    margin: 0;
    transform: none;
}

.redesign-risk-list .redesign-list-itm.sel{
    background-color: var(--primary-blue-75) !important;
    border: 1px solid var(--primary-blue-200);
}

.rp-anlyz-popup {
    position: absolute;
    bottom: 25px;
    right: 25px;
    z-index: 999;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 58%;
    max-width: 594px;
    padding: 16px 24px;
    background-color: var(--primary-blue-hover);
    border: 1px solid var(--primary-blue-200);
    border-radius: 4px;
}

.rp-anlyz-popup .rp-sel-txt {
    font-size: 18px;
    color: var(--primary-neutral-400);
    font-size: 500;
}
.rp-clr-txt {
    color: var(--app-c-btn-bg);
    line-height: 16px;
    padding: 8px 16px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    margin-right: 20px;
}
.rt-ftr-btn{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.rt-ftr-btn .ftr-lbl{
    margin-right: 16px;
    color: var(--primary-neutral-400);
}

.rt-ls-btn button.p-btn{
    background: transparent;
    color: var(--base);
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
}

.rt-ls-btn button.p-btn span::after{
    transform: rotate(135deg);
    border-color: var(--base);
}

.rt-ls-btn button.p-btn.open{
    background: var(--primary-blue-200);
    color: var(--base);
}

.rt-ls-btn button.p-btn.open span::after{
    transform: rotate(-45deg) translateY(5px);
    border-color: var(--base);
}

.rt-ls-btn{
    position: relative;
}

.rp-rt-popup{
    position: absolute;
    top: 45px;
    background-color: var(--primary-white);
    box-shadow: 0px 0px 10px 0px #33333380;
    border: 1px solid var(--primary-blue-hover);
    border-radius: 4px;
    width: 100%;
    min-width: 500px;
    max-height: 250px;
    z-index: 999999999;
    overflow: hidden;
}
.rp-rt-popup .rt-ls-bdy{
    margin: 8px;
}

.rp-rt-popup .rt-ls-itm {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    border-bottom: 1px solid var(--primary-blue-hover);
    cursor: pointer;
}
.rp-rt-popup .rt-ls-itm.sel {
    color: #270ADC;
    font-weight: 700;
}
.rp-rt-popup .rt-ls-itm:hover {
    background-color: var(--primary-blue-hover);
}
.rp-rt-popup .rt-ls-itm .rt-itm-nm {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 94%;
}

.rp-rt-popup.sgt-box{
    width: 95%;
    min-width: unset;
    padding-left: unset;
    padding-right: unset;
}

.rp-rt-popup.sgt-box .rt-ls-itm{
    padding-left: 8px;
    padding-right: 8px;
}

.rp-rt-popup.sgt-box .rt-ls-itm:hover{
    color: var(--primary-blue-500);
    background: var(--primary-blue-75);
}

.rp-rt-popup .rt-ls-itm:last-child{
    border-bottom: unset;
}

.sltd-rt-ftr{
    background-color: var(--primary-blue-75);
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 4px;
    margin-right: 8px;
    max-width: 32%;
}

.sltd-rt-ftr .itm-name {
    font-size: 14px;
    line-height: 16px;
    color: var(--primary-neutral-500);
    margin-right: 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.sltd-rt-ftr img{
    cursor: pointer;
}
.mgmt-sltd-fltr{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}
.mgmt-sltd-fltr .sltd-rt-ftr{
    padding: 4px 8px;
    border: 1px solid var(--primary-blue-200);
    margin-bottom: 8px;
}
.mgmt-sltd-fltr .sltd-rt-ftr .itm-name{
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    font-family: 'Gibson-500', sans-serif;
    color: var(--primary-neutral-600);
}
.redesign-risk-list .redesign-list-itm .riq-col-1.rts-view-sel{
    color: var(--base) !important; 
}

/* Checkbox */

.check-box {
    width: 24px;
    height: 24px;
    
}
.check-box label {
    background: var(--primary-white);
    border: 1px solid var(--primary-neutral-300);
    border-radius: 4px;
    display: block;
    position: relative;
    width: 22px;
    height: 22px;
    cursor: pointer;
}
.check-box:hover label {
    background: var(--primary-blue-hover);
}
.dis .check-box:hover label {
    background: var(--primary-white);
    cursor: not-allowed;
}
.check-box label.disabled {
    background: var(--primary-neutral-300) !important;
    cursor: not-allowed;
}
.check-box label.checked,
.check-box label.default {
    background: var(--primary-blue-500);
    border-color: var(--primary-blue-500);
}
.check-box:hover label.checked,
.check-box:hover label.default {
    background: var(--primary-blue-600);
    border-color: var(--primary-blue-600);
}
.check-box label.checked.disabled,
.check-box label.default.disabled {
    border-color: var(--primary-neutral-300);
}
.check-box label.checked::after {
    content: '';
    width: 4px;
    height: 10px;
    border: solid var(--primary-white);
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    position: absolute;
    top: 4px;
    left: 8px;
    border-radius: 2px;
    cursor: pointer;
}
.check-box label.checked.disabled::after {
    border: solid var(--primary-neutral-400);
    border-width: 0 3px 3px 0;
    cursor: not-allowed;
}
.check-box label.default::after {
    content: '';
    width: 4px;
    height: 10px;
    border: solid var(--primary-white);
    border-width: 0 3px 0 0;
    transform: rotate(90deg);
    position: absolute;
    top: 4px;
    left: 8px;
    border-radius: 2px;
    cursor: pointer;
}
.check-box label.default.disabled::after {
    cursor: not-allowed;
}
.f-14 {
    font-size: 14px;
    line-height: 16px;
    color: var(--primary-neutral-400);
}