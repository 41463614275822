html,
#root {
    height: 100%;
    overflow: hidden;
}
html,body, #root {
    min-width: 1024px;
}
body {
    position: relative;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.l-sp-175 {
    letter-spacing: 1.75px;
}

.l-sp-171 {
    letter-spacing: 1.71px;
}

.l-sp-07 {
    letter-spacing: 0.7px;
}

.l-sp-09 {
    letter-spacing: 0.9px;
}

a {
    text-decoration: none;
}

.hdr {
    height: 70px;
    background: var(--primary-blue-75);
    box-shadow: 0 1px 3px var(--app-primary-box-shdw);
    width: calc(100% - 72px);
    position: absolute;
    top: 0;
    left: 72px;
    display: flex;
    align-items: center;
    z-index: 99999;
}

.env-0 .hdr .logo {
    background: url(assets/images/contxt_logo.png) 0 0 / contain no-repeat;
    width: 180px;
    height: 20px;
    margin: 25px 0px 25px 2%;
    cursor: pointer;
}
.env-1 .hdr .logo {
    background: url(assets/images/lynx/lynx_logix.svg) 0 0 / contain no-repeat;
    width: 80px;
    height: 36px;
    margin: 25px 0px 25px 2%;
    cursor: pointer;
}

.nav-links {
    flex: 1;
    display: flex;
    align-items: flex-end;
}

.nav-links ul.nav-list {
    margin: 18px auto 0;
    padding: 0;
    display: flex;
    list-style: none;
    justify-content: center;
    white-space: nowrap;
}

.nav-links ul.nav-list > li {
    margin: 0;
    padding: 0;
    display: inline-block;
    padding: 0;
}
.nav-links ul.nav-list > li > a:hover {
    /* background: var(--app-nav-ls-li-bg-hvr); */
}

.nav-links ul.nav-list > li a {
    text-transform: uppercase;
    color: var(--app-nav-ls-li-a);
    font-weight: 500;
    font-size: 14px;
    text-decoration: none;
    padding: 16px 18px 17px;
    display: block;
    border-bottom: 3px solid transparent;
}

.nav-links ul.nav-list > li a:hover{
    color: var(--app-nav-ls-li-a-hvr);
}
.nav-links a {
    cursor: pointer;
}
/* .nav-links ul.nav-list > li:hover {
    color: var(--app-primary-color);
    background: var(--app-nav-ls-li-hvr);
} */

.nav-links ul.nav-list > li.a {
    position: relative;
}
.nav-links ul.nav-list > li.a > a {
    border-color: var(--primary-blue-500);
}

.nav-links ul.nav-list > li.a > a {
    color: var(--primary-blue-500);
    font-weight: 700;
}

.nav-links ul.nav-list .has-child-nodes,
.has-child-nodes {
    position: relative;
}

.nav-links ul.nav-list .child-nodes,
.has-child-nodes .child-nodes {
    display: none;
    position: absolute;
    background: var(--app-primary-bg);
    z-index: 9999;
    border-radius: 2px;
    box-shadow: 0 1px 3px var(--app-primary-box-shdw);
    list-style: none;
    padding: 0;
    margin: 0px 0 0;
    box-sizing: border-box;
    /* max-height: 200px; */
    min-width: 100%;
    /* padding: 2px 0; */
    width: auto;
}
.has-child-nodes .child-nodes li:first-child{
    margin-top: 2px;
}
.has-child-nodes .child-nodes li:last-child{
    margin-bottom: 2px;
}

.child-nodes::-webkit-scrollbar {
    display: none;
}

.nav-links ul.nav-list > li:hover .child-nodes,
.has-child-nodes:hover .child-nodes {
    display: block;
}

.nav-links ul.nav-list > li .child-nodes > li,
.nav-links .has-child-nodes .child-nodes > li,
.nav-links .has-child-nodes .child-nodes > li a,
.has-child-nodes .child-nodes > li,
.has-child-nodes .child-nodes > li a {
    font-size: 16px;
    font-weight: 500;
    color: var(--app-nv-ls-grand-child-nodes-li-a);
    letter-spacing: 0.39px;
    line-height: 24px;
    text-transform: unset;
}

.arrow_right{
    font-size: 19px;
    position: absolute;
    right: 15px;
}

.nav-links ul.nav-list > li .child-nodes > li:hover:not(.u-det),
.nav-links .has-child-nodes .child-nodes > li:hover:not(.u-det),
.has-child-nodes .child-nodes > li:hover:not(.u-det) {
    color: var(--primary-blue-500);
    background-color: var(--primary-blue-75);
}

.nav-links .has-child-nodes .child-nodes > li:hover a,
.has-child-nodes .child-nodes > li:hover a {
    color: var(--primary-blue-500);
    background-color: var(--primary-blue-75);
}
.has-child-nodes .child-nodes > li{
    font-weight: 400;
}
.nav-links .has-child-nodes .child-nodes > li:hover svg path,
.has-child-nodes .child-nodes > li:hover svg path,
.nav-links .has-child-nodes .child-nodes > li:hover svg path,
.has-child-nodes .child-nodes > li:hover svg path,
.nav-links .has-child-nodes .child-nodes > li:hover .icn,
.has-child-nodes .child-nodes > li:hover .icn,
.nav-links .has-child-nodes .child-nodes > li:hover .icn,
.has-child-nodes .child-nodes > li:hover .icn {
    color: var(--primary-white);
    fill: var(--primary-white);
}

.nav-links ul.nav-list .has-grand-child-nodes,
.has-grand-child-nodes {
    position: relative;
}

.nav-links ul.nav-list .grand-child-nodes,
.has-grand-child-nodes .grand-child-nodes {
    display: none;
    position: absolute;
    background: var(--app-primary-bg);
    z-index: 9999;
    border-radius: 2px;
    box-shadow: 0 1px 3px var(--app-primary-box-shdw);
    list-style: none;
    padding: 0;
    top: 0%;
    left: 100%;
    box-sizing: border-box;
    max-height: 200px;
    min-width: 160px;
    padding: 2px 0;
}

.nav-links ul.nav-list .child-nodes > .has-grand-child-nodes:hover .grand-child-nodes,
.has-grand-child-nodes:hover .grand-child-nodes {
    display: block;
}

.nav-links .has-child-nodes .child-nodes > li.has-grand-child-nodes:hover > .grand-child-nodes > li a,
.has-child-nodes .child-nodes > li.has-grand-child-nodes:hover > .grand-child-nodes > li a{
    font-size: 14px;
    font-weight: 500;
    color: var(--app-nv-ls-grand-child-nodes-li-a);
    letter-spacing: 0.39px;
    line-height: 18px;
}


.nav-links ul.nav-list > li .grand-child-nodes > li:hover,
.nav-links .has-grand-child-nodes .grand-child-nodes > li:hover,
.has-grand-child-nodes .grand-child-nodes > li:hover {
    color: var(--app-nv-ls-grand-child-nodes-li-a-hvr);
    background: var(--app-nv-ls-grand-child-nodes-li-bg);
}

.nav-links .has-child-nodes .child-nodes > li.has-grand-child-nodes:hover > .grand-child-nodes > li:hover a,
.has-child-nodes .child-nodes > li.has-grand-child-nodes:hover > .grand-child-nodes > li:hover a{
    color: var(--app-nv-ls-grand-child-nodes-li-a-hvr);
}

.auth-links {
    font-size: 9px;
    width: 180px;
    text-align: right;
    margin-right: 2%;
    position: relative;
}

.comp-name {
    color: var(--app-comp-name);
    position: absolute;
    top: 8px;
    right: 6px;
    font-size: 12px;
    letter-spacing: 1.71px;
    font-weight: 600;
}
/* .comp-name::after {
  content: ' ';
  border: 5px solid var(--app-primary-border);
  border-color: var(--app-primary-border transparent transparent transparent;
  position: absolute;
  top: 4px;
  margin-left: 6px;
} */
.user-name {
    background: var(--app-profile-bg);
    color: var(--primary-white);
    border-radius: 4px;
    position: absolute;
    top: 16px;
    right: 2%;
    font-size: 14px;
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    padding: 5px;
    cursor: pointer;
    font-weight: 400;
    border: 2px solid var(--primary-white);
    transform: translateY(-35px);
}

.user-name:after {
    height: 16px;
    width: 33px;
    content: ' ';
    display: block;
    margin-left: -4px;
}

.user-name > span { 
    text-transform: uppercase;
}
.notify-sec {
    color: var(--app-light-blue-color);
    position: absolute;
    top: 34px;
    left: 66px;
    padding-left: 30px;
    font-size: 18px;
    line-height: 24px;
    cursor: pointer;
    font-weight: 600;
    width: 42px;
    text-align: left;
}

.notify-sec::before {
    content: '!';
    color: var(--app-secondary-color);
    border-radius: 50%;
    position: absolute;
    width: 24px;
    height: 24px;
    background: var(--app-notify-sec-bg);
    left: 0px;
    font-size: 18px;
    text-align: center;
    line-height: 24px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0px;
}

.service-msg {
    position: absolute;
    top: 1%;
    left: 2%;
    background: var(--primary-blue-400);
    right: 2%;
    z-index: 10;
    padding: 6px 10px;
    color: var(--primary-white);
    font-size: 12px;
    border-radius: 4px;
    text-align: center;
    font-weight: 500;
}
.service-msg.full {
    left: 4px;
}
.service-msg.l {
    top: 4px;
}
.service-msg .close-msg {
    border-radius: 50%;
    color: var(--app-third-color);
    background: var(--app-close-msg-bg);
    position: absolute;
    right: 10px;
    font-weight: 600;
    font-size: 10px;
    display: flex;
    height: 16px;
    align-items: center;
    width: 16px;
    justify-content: center;
    top: 6px;
    cursor: pointer;
}
.service-msg .close-msg:hover {
    background: var(--app-close-msg-bg-hvr);
}
.service-msg .close-msg:active {
    box-shadow: 0 0 2px var(--app-secondary-box-shdw);
} 

.left-bar {
    position: absolute;
    top: 70px;
    left: 72px;
    width: 20%;
    bottom: 0;
    background: var(--primary-neutral-100);
    border-right: 1px solid var(--primary-neutral-300);
    /* box-shadow: 0px 0 3px var(--app-left-bar-box-shdw); */
    z-index: 9;
    animation: slideIn 100ms cubic-bezier(0, 0, 0, 0.8);
}

.left-bar.ex{
    left: 260px
}

.right-bar {
    position: absolute;
    top: 70px;
    left: calc(20% + 72px) !important;
    right: 0;
    bottom: 0;
    background: var(--app-right-bar-bg);
    z-index: 0;
    animation: slideInR 100ms cubic-bezier(0, 0, 0, 0.8);
}

.right-bar.ex{
    left: calc(20% + 260px) !important;
}

.right-bar.company {
    background: var(--selected-itm);
}

.middle-bar {
    position: absolute;
    top: 70px;
    left: 30%;
    width: 34%;
    bottom: 0;
    background: var(--app-primary-bg);
    z-index: 2;
    box-shadow: 0 0 3px var(--app-primary-box-shdw);
    transition: all 250ms ease-in-out;
}

/* div#shipments_map_contxt_map::after, .right-bar::after {
    content: 'Loading...';
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
} */

.no-data{
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 98%;
    padding: 2% 0;
    box-sizing: border-box;
}
.no-data.t-404 {
    font-size: 24px;
    text-shadow: 0 1px 0 #fff;
}
.no-data.t-404 .err-n {
    font-size: 48px;
    margin-right: 14px;
}
#root:has(.middle-bar),
.middle-bar + .right-bar {
    left: 34%;
}

.middle-bar.exp {
    right: 0;
    width: unset;
}

.middle-bar.exp + .right-bar {
    width: 0;
    z-index: -1;
}

.hide-right {
    position: absolute;
    background: var(--app-third-bg);
    width: 100px;
    height: 20px;
    border-radius: 0px 0px 5px 5px;
    content: 'Hide Map';
    color: var(--app-primary-color);
    z-index: 99999;
    text-align: center;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(-90deg);
    top: 50%;
    margin-top: -50px;
    right: -61px;
    cursor: pointer;
    box-shadow: 0 0 2px var(--app-primary-box-shdw);
}

.hide-right:hover {
    box-shadow: 0 0 2px var(--app-secondary-box-shdw);
}

.exp .hide-right {
    right: -40px;
    border-radius: 5px 5px 0px 0px;
}

.hide-right:after {
    content: 'Hide Map';
}

.exp .hide-right:after {
    content: 'Show Map';
}

.search-bar {
    display: flex;
    font-size: 12px;
    /* box-shadow: 0 0px 4px var(--app-primary-box-shdw); */
    letter-spacing: 0.33px;
    position: absolute;
    width: 100%;
    /* margin: 12px; */
}
.search-bar.ls{
    width: 30%;
    position: relative;
}

.txt-i {
    width: 60%;
    height: 36px;
    /* padding: 3px 20px; */
    padding-left: 10px;
    outline: none;
    border: 1px solid var(--primary-neutral-300);
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
    color: var(--primary-neutral-500);
    margin-right: 8px;
    margin-left: 12px;
    margin: 12px 8px 12px 12px;
}
.txt-i.ls{
    margin: 0px;
    margin-right: 8px;
    min-width: 150px;
}

.search-btn {
    background: var(--app-search-btn-bg);
    /* line-height: 38px; */
    width: 40%;
    text-align: center;
    border-radius: 4px;
    color: var(--primary-white);
    background-color: var(--primary-blue-500);
    padding-left: 22px;
    padding-top: 12px;
    margin: 12px 8px 12px 0px;
}
.search-btn.ls{
    margin: 0px;
    min-width: 100px;
}

.search-btn .search-txt {
    color: var(--app-search-txt);
    font-weight: 500;
    font-size: 16px;
    margin-left: 8px;
}

.search-btn .search-icn {
    position: absolute;
    margin: -4px 0px 0px -21px;
    color: var(--app-search-txt);
    font-size: 22px;
}

.close-btn {
    background: var(--app-primary-bg);
    justify-content: center;
    display: flex;
    flex-grow: 1;
    align-items: center;
}

.close-btn .close-icn {
    cursor: pointer;
}

.search-cat-pop,
.sel-box-pop {
    position: absolute;
    border-radius: 2px;
    box-shadow: 0 1px 3px var(--app-primary-box-shdw);
    list-style: none;
    z-index: 999999;
    padding: 0;
    margin: 2px 4px;
    box-sizing: border-box;
    left: 0;
    right: 0;
    top: 46px;
    background: var(--app-primary-bg);
    overflow: hidden;
    max-height: 204px;
    overflow-y: auto;
}

.sel-box-pop {
    top: 36px;
    right: -4px;
    width: 100%;
    min-width: 120px;
    left: unset;
    border: 1px solid var(--primary-blue-hover);
    box-shadow: 0px 0px 10px 0px rgba(51, 51, 51, 0.5);
    border-radius: 4px;
}
.config .sel-box-pop {
    top: 26px;
}
.sel-box-pop.u-sec-top {
    top: 31px;
}
.sel-box-pop.left {
    left: -4px;
    right: unset;
    width: 100%;
}
.sel-box-pop.pg-top-pos {
    top: unset;
    bottom: 35px;
}
.sel-box-pop.f-top {
    top: 38px;
}
.sel-box-pop.top.left {
    left: -4px;
    right: unset;
    top: unset;
    /*bottom: 23px;*/
}

.h-200 {
    max-height: 204px;
}
.sel-box.sort-lbl.new-h .sel-box-pop {
    top: 44px;
}
.sel-box.new-h .sel-value::after {
    top: 14px;
}
.sel-box.new-h .sel-value {
    margin: 0;
    width: unset;
    display: block;
}

.sel-box .sel-value.dft-lbl {
    color: var(--primary-neutral-300);
}

.sel-box .sel-value .exdft-lbl {
    color: var(--primary-neutral-300);
}


.sel-box.new-h.open .sel-box-pop.pg-top-pos {
    top: unset;
    bottom: 46px;
}
.sel-box-pop.h-200 {
    height: 204px;
}
.sel-box-pop.left.hs{
    height: 65px !important;
}
.sel-box-pop.h2{
    height: 45px;
}
.sel-box-pop.h-3{
    height: 80px;
}
.sel-box-pop.h-unset{
    height: unset !important;
}
.sel-box-pop.h3{
    height: 108px;
}
.w-100p {
    max-width: 100%;
}
.w-100 {
    width: 100%;
}
.sel-box.i-fld .sel-box-pop {
    right: 0;
    left: 0;
    width: unset;
    top: 34px;
}
.no-pt .sel-value {
    padding-top: unset !important;
}
.sel-box.i-fld .sel-value {
    font-size: 16px;
    padding: 7px 0px;
    font-weight: 500;
}
.sel-box.i-fld .sel-value.redesign-srch {
    margin-left: unset;
    width: unset;
    display: unset;
    position: unset;
}
.sel-box.i-fld .auto-s {
    font-size: 16px;
    margin: -3px 0 -3px;
    font-weight: 500;
}
.sel-box.i-fld.p-dc {
    width: 22%;
}
.sel-box.i-fld.p-dc .sel-box-pop {
    min-width: 62px;
}
.search-cat-pop li,
.sel-box-pop .sel-item,
.grand-child-nodes >li {
    cursor: pointer;
    padding: 8px 20px 8px 14px;
    letter-spacing: 0.44px;
    font-size: 14px;
    font-weight: 400;
}

.nav-links ul.nav-list > li .child-nodes a {
    padding: 8px 14px;
}

.sel-box-pop .sel-item {
    font-size: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    border-bottom: 1px solid var(--primary-blue-hover);
    line-height: 24px;
}
.sel-box-pop .sel-item.check {
    font-weight: 500;
}
.sel-box-pop .sel-item.n-o {
    opacity: 0.65;
    cursor: not-allowed;
}
.search-cat-pop li:not(.n-a):not(.n-o):hover,
.sel-box-pop .sel-item:not(.n-a):not(.n-o):hover {
    color: var(--primary-blue-500);
    background: var(--primary-blue-75);
}
.search-cat-pop li:not(.n-a):not(.n-o):focus,
.sel-box-pop .sel-item:not(.n-a):not(.n-o):focus {
    border: 4px solid var(--data-viz-orange);
}
.search-cat-pop li.no-item, .search-cat-pop li.no-item:hover {
    color: var(--app-search-cat-pop-li-no-item);
    opacity: 0.5;
    cursor: default;
    background: none;
    color: unset;
    cursor: default;
} 
.sel-box.i-fld .sel-value::after {
    top: 16px;
}

.sel-box-pop.mul .sel-item::before {
    background: var(--primary-white);
    border: 1px solid var(--primary-neutral-300);
    border-radius: 4px;
    display: block;
    position: absolute;
    width: 22px;
    height: 22px;
    cursor: pointer;
    content: '';
    right: 14px;
}
.sel-box-pop.mul .sel-item.n-o::before {
    cursor: not-allowed;
    background: var(--primary-neutral-300) !important;
}

.sel-box-pop.mul .sel-item.check::before {
    background: var(--primary-blue-500);
    border-color: var(--primary-blue-500);
}
.sel-item.check::after {
    content: '';
    width: 4px;
    height: 10px;
    border: solid var(--primary-blue-500);
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    position: absolute;
    margin-top: 4px;
    right: 10px;
    border-radius: 2px;
    cursor: pointer;
}
.sel-box-pop.mul .sel-item.check::after {
    border-right: solid var(--primary-white);
    border-bottom: solid var(--primary-white);
    right: 22px;
}
.sel-item.check:hover::after {
    /* border-color: transparent transparent var(--primary-blue-500) var(--primary-blue-500); */
}
.sel-item.grp-hdr.n-o {
    font-weight: 500;
    cursor: default;
    opacity: 1 !important;
    font-size: 11px;
}
.sel-box-pop .sel-item.grp-hdr:hover {
    background: none;
    color: unset;
}
.sel-item.grp-itm {
    padding: 8px 10px 8px 30px;
}

.dashboard-box.config,
.dashboard-box.config-rows,
.shipment-list-box {
    height: calc(100% - 46px);
    margin-top: 44px;
    display: flex;
    flex-flow: column;
}

.greet-msg {
    width: 90%;
    margin: 10% auto 0%;
}

.dashboard-box.config-rows .greet-msg {
    margin-bottom: 4%;
}

.dashboard-box.config .greet-msg,
.dashboard-box.config-rows .greet-msg {
    flex: 0 1 auto;
}

.greet-msg .msg-h1 {
    font-size: 24px;
    /* margin-bottom: 3%; */
    color: var(--app-greet-msg);
    letter-spacing: 0.83px;
    font-weight: 300;
}

.greet-msg .msg-h2,
.msg-h2 {
    font-size: 18px;
    letter-spacing: 0.58px;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    color: var(--app-greet-msg);
}

.greet-msg .msg-h2 .view-all,
.msg-h2 .view-all {
    font-size: 12px;
    cursor: pointer;
    color: var(--app-greet-msg-view-all);
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.33px;
}

.greet-msg .msg-h3 {
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 0.34px;
    color: var(--app-fifth-color);
}

.greet-msg .msg-h4 {
    font-size: 14px;
    text-align: right;
    text-transform: lowercase;
    margin-top: 6%;
    margin-bottom: -3.5%;
    letter-spacing: 0.39px;
    color: var(--app-fifth-color);
    font-weight: 300;
}

.config-displ-list .msg-h2 {
    display: flex;
    width: 100%;
    margin: 0;
    background: unset;
    box-shadow: unset;
    padding: 0 5%;
    box-sizing: border-box;
    text-transform: none !important;
    letter-spacing: 0.58px;
}

.vibrate {
    animation: vibrate 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
    color: var(--app-light-blue-color) !important;
}

@-webkit-keyframes vibrate {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}

@-moz-keyframes vibrate {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}

@-o-keyframes vibrate {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}

@keyframes vibrate {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}

.config-displ-list {
    text-transform: uppercase;
    list-style-position: inside;
    display: table-cell;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    /* padding: 35% 0 0 0;
    margin: -96px 0 0 0; */
    /*display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;*/
}

.config-displ-list li {
    padding: 4%;
    position: relative;
    width: 34%;
    box-shadow: 0 2px 4px var(--app-primary-box-shdw);
    list-style: none;
    margin: 10% 0 0 5%;
    cursor: pointer;
    display: inline-block;
    text-align: left;
    letter-spacing: 0;
    background: var(--app-primary-bg);
}
/*
.config-displ-list li:nth-child(1),
.config-displ-list li:nth-child(2) {
    margin-top: 0;
}
 .config-displ-list li:nth-child(2) {
    margin-left: 5%;
} */
.config-displ-list .ship-status {
    padding-left: 20px;
    position: relative;
    line-height: 14px;
    font-size: 14px;
    font-weight: 400;
    color: var(--app-config-list-ship-status);
}

.config-displ-list .ship-status::before {
    content: ' ';
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: var(--app-config-list-ship-status-bg-before);
    position: absolute;
    left: 0px;
}

.config-displ-list .ship-status.inventory::before {
    background: var(--app-config-list-ship-status-inventory-before);
}

.config-displ-list .ship-status.new::before {
    background: var(--app-config-list-ship-status-new-before);
}

.config-displ-list .ship-status.in-transit::before {
    background: var(--app-config-list-ship-status-transit-before);
}

.config-displ-list .ship-status.destination::before,
.config-displ-list .ship-status.delivered::before {
    background: var(--app-config-list-ship-status-delivered-before);
}

.config-displ-list .ship-status.completed::before {
    background: var(--app-config-list-ship-status-completed-before);
}

.config-displ-list .ship-status.at-risk::before {
    background: var(--app-config-list-ship-status-at-risk-before);
}

.config-displ-list .ship-status.critical::before {
    background: var(--app-config-list-ship-status-critical-before);
}

.config-displ-list .ship-status.on-time::before {
    background: var(--app-config-list-ship-status-ontime-before);
}

.config-displ-list .ship-status.delayed::before {
    background: var(--app-config-list-ship-status-delayed-before);
}
.config-displ-list .ship-status.high-risk::before {
    background: var(--app-config-list-ship-status-h-risk-before);
}
.config-displ-list .ship-status.medium-risk::before {
    background: var(--app-config-list-ship-status-m-risk-before);
}
.config-displ-list .ship-status.low-risk::before {
    background: var(--app-config-list-ship-status-l-risk-before);
}
.config-displ-list .ship-count {
    font-size: 40px;
    color: var(--app-config-list-ship-cnt);
    margin-top: 34px;
    line-height: 30px;
    font-weight: 300;
}

.config-displ-list .ship-view {
    font-size: 12px;
    color: var(--app-greet-msg-view-all);
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.33px;
    bottom: 14%;
    right: 7%;
    position: absolute;
}

.config-displ-list li.config-page-lnk {
    text-align: right;
    background: unset;
    box-shadow: unset;
    width: 100%;
    padding: 0;
    margin: 0;
}

.config-page-lnk .config-page-lnk-txt {
    text-transform: uppercase;
    font-size: 12px;
    margin: 10% 5% 0;
    cursor: pointer;
    color: var(--app-config-page-lnk-txt);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: 400;
    letter-spacing: 0;
}

.config-page-lnk .config-page-lnk-txt:hover {
    color: var(--app-config-page-lnk-txt-hvr);
}

.config-list {
    padding: 0;
    margin: 0% 0 4%;
}

.config-list li,
.sortableHelper {
    display: flex;
    border: 1px solid var(--app-config-list-li-border);
    border-width: 1px 0;
    padding: 3% 4%;
    background: var(--app-primary-bg);
    align-items: center;
    user-select: none;
    font-size: 16px;
    font-weight: 400;
    color: var(--app-config-list-li);
}

.config-list li .handle,
.sortableHelper .handle {
    /*border: 1px solid #979797;
  border-width: 1px 0;
  position: relative;
  width: 24px;
  height: 11px;*/
    cursor: grab;
    color: var(--app-config-list-li-handle);
}

.config-list li .handle:active {
    cursor: grabbing;
}

.config-list li .handle:after {
    /*position: absolute;
  top: 50%;
  border-top: 1px solid #979797;
  margin-top: -1px;
  width: 100%;
  left: 0;
  content: ' ';*/
}

.sortableHelper {
    z-index: 1000;
    padding: 12px 16px;
}

.config-list li .config-item,
.sortableHelper .config-item {
    flex: 1;
    text-transform: uppercase;
    padding: 0 0 0 6%;
}

.config-list li .status-bar, .status-bar,
.sortableHelper .status-bar{
    background: var(--app-config-list-li-status-bar-bg);
    border-radius: 24px;
    width: 44px;
    height: 24px;
    position: relative;
    cursor: pointer;
}

.status-bar.n-size{
    width:28px;
    height: 16px;
}

.config-list li .status-bar.a, .status-bar.a,
.sortableHelper .status-bar.a {
    background: var(--primary-blue-500);
}
.sortableHelper .status-bar.d, .status-bar.d {
    cursor: not-allowed;
    opacity: 0.7;
}
.config-list li .status-bar::after,
.sortableHelper .status-bar::after, .status-bar::after {
    content: ' ';
    background: var(--app-primary-bg);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    position: absolute;
    left: 2px;
    top: 2px;
    transition: left ease-out 200ms;
}

.status-bar.n-size::after{
    width: 12px;
    height: 12px;
}
.status-bar.n-size.a::after{
    left: 14px;
}

.config-list li .status-bar.a::after,
.sortableHelper .status-bar.a::after, .status-bar.a::after  {
    left: 22px;
}

.config-list::after {
    content: 'A';
    opacity: 0;
    position: static;
    width: 300px;
    line-height: 100px;
}

.back-lnk {
    border-radius: 5px;
    background: var(--app-back-lnk-bg);
    text-transform: uppercase;
    width: 25%;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: var(--app-fifth-color);
    padding: 16px 0;
    cursor: pointer;
    position: fixed;
    margin-left: 3%;
    bottom: 20px;
}

.loading {
    position: relative;
    overflow-x: hidden;
    height: 6px;
}
.loading.dsh {
    top: 70px;
    background: var(--lynx-primarary-bg);
}
.loading.lgn-loading{
    left: 56%;
    width: 44%;
}
.loading.abs {
    left:0.5%;
    right: 0.5%;
    position: absolute;
} 
.loading.t-0 {
    top: 0;
}
.loading.t--10 {
    top: -10px;
}
.right-bar > .loading {
    position: absolute;
    width: 100%;
}

.loading::after {
    content: ' ';
    width: 15%;
    height: 4px;
    border-radius: 5px;
    background: var(--primary-blue-500);
    position: absolute;
    animation: loading 1.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite;
    top: 2px;
}

.loading.w::after {
    background: var(--app-primary-bg);
}

@-webkit-keyframes loading {
    0% {
        left: 0;
    }

    100% {
        left: 100%;
        width: 40%;
    }
}

@-moz-keyframes loading {
    0% {
        left: 0;
    }

    100% {
        left: 100%;
        width: 40%;
    }
}

@-o-keyframes loading {
    0% {
        left: 0;
    }

    100% {
        left: 100%;
        width: 40%;
    }
}

@keyframes loading {
    0% {
        left: 0;
    }

    100% {
        left: 100%;
        width: 40%;
    }
}

/*Shipment Map View Popup*/
.mapboxgl-popup-content {
    display: flex;
    align-items: center;
    padding: 0;
    box-shadow: 0 1px 10px var(--app-mapboxgl-popup-content-box-shdw);
    background: var(--primary-neutral-600);
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip{
    border-top-color: var(--primary-neutral-600);
}

.mapboxgl-popup-close-button{
    background-color: var(--primary-neutral-600);
}

.mapboxgl-popup-content .shipment-list-pop {
    max-height: 250px;
    overflow: auto;
    margin: 16px 0 10px;
}

.mapboxgl-popup .mapboxgl-popup-tip {
    border-width: 5px 5px 0;
}

button.mapboxgl-popup-close-button{
    font-size: 26px !important;
}

.mapboxgl-popup.mapboxgl-popup-anchor-top,
.mapboxgl-popup.mapboxgl-popup-anchor-bottom {
    max-width: 220px !important;
}

.shipment-details-pop {
    border-top: 1px solid var(--app-shipment-details-pop-t-border);
    display: block;
    padding: 0px 0px;
    color: var(--app-shipment-details-pop);
    font-family: 'Montserrat', sans-serif !important;
    width: 200px;
    margin-bottom: 6px;
    padding-top: 6px;
}

.shipment-details-pop:first-child {
    border-width: 0;
    padding-top: 0;
}

.shipment-details-pop:last-child {
    margin-bottom: 0px;
}

.shipment-details-pop ul {
    padding: 0 6px;
    margin: 0;
}

.shipment-details-pop ul li {
    display: flex;
    justify-content: space-between;
    line-height: 16px;
}

.shipment-list-pop .lbl-id {
    font-weight: 700;
    font-size: 10px;
    letter-spacing: 0.36px;
    color: var(--app-shipment-details-pop-lbl-id);
}

.shipment-list-pop .lbl-stat {
    font-weight: 400;
    font-size: 8px;
    letter-spacing: 0;
    color: var(--app-shipment-details-pop-lbl-id);
}

.lbl-stat.inventory{
    color: var(--app-light-blue-color);
}
.lbl-stat.new{
    color: var(--app-lbl-stat-new);
}
.lbl-stat.in-transit{
    color: var(--app-lbl-stat-transit);
}
.lbl-stat.destination{
    color: var(--app-lbl-stat-destination);
}
.lbl-stat.at-risk{
    color: var(--app-lbl-stat-at-risk);
}
.lbl-stat.critical{
    color: var(--app-lbl-stat-critical);
}
.lbl-stat.delayed{
    color: var(--app-lbl-stat-delayed);
}

.shipment-list-pop .path {
    font-weight: 500;
    font-size: 8px;
    letter-spacing: 0.29px;
    color: var(--app-shipment-list-box-path);
}
@media only screen and (max-width: 1023px) {
    html, #root {
        overflow: auto !important;
    }
    .hide-nav{
        display: block !important;
    }
}
@media only screen and (min-height: 780px) {
    .config-displ-list {
        display: block;
        margin-top: 15%;
    }
}

.shipment-list-box .greet-msg {
    margin-top: 28px;
}

.shipment-list-box .greet-msg .msg-h5 {
    font-weight: 400;
    font-size: 18px;
    letter-spacing: 0.35px;
    color: var(--app-fifth-color);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 2px;
}

.shipment-list-box .greet-msg .msg-h6 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 12px 0;
}

.shipment-list-box .greet-msg .msg-h6 .msg-h6-subtext {
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.5px;
    color: var(--app-shipment-list-box-path);
    flex-grow: 1;
    /* text-transform: uppercase; */
}

.msg-h6-subtext.shipment-after::after {
    content: 'Shipments';
    padding-left: 4px;
}

.msg-h6-subtext.cnt-1.shipment-after::after {
    content: 'Shipment';
}

.shipment-list-box .greet-msg .msg-h6 .msg-h6-sort {
    display: flex;
    align-items: center;
    height: 26px;
}

.shipment-list-box .greet-msg .msg-h6 .msg-h6-sort label {
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.3px;
    color: var(--app-fifth-color);
    padding-right: 10px;
}

.fltr-a-btn {
    font-size: 14px;
    font-weight: 500;
    color: var(--primary-white);
    display: flex;
    align-items: center;
    width: 90px;
    justify-content: center;
    border: 1px solid var(--primary-blue-500);
    background: var(--primary-blue-500);
    padding: 7px 10px;
    border-radius: 4px;
    cursor: pointer;
}
.fltr-a-btn .material-icons {
}
.fltr-a-btn.o {
    background: var(--primary-blue-600);
    border-color: var(--primary-blue-600);
}
.fltr-a-btn.a {
    background: var(--app-fltr-a-btn-a-bg);
    color: var(--app-primary-color);
    box-shadow: 0 1px 3px var(--app-fltr-a-btn-a-boc-shdw);
}
.fltr-pop {
    position: absolute;
    left: 5%;
    width: 90%;
    margin-top: 2px;
    padding: 20px 15px;
    box-sizing: border-box;
    background: var(--app-primary-bg);
    border: 1px solid var(--app-fltr-pop-border);
    box-shadow: 0 1px 3px var(--app-fltr-pop-box-shdw);
    border-radius: 2px;
    z-index: 9;
}
.fltr-pop .close-icn {
    position: absolute;
    right: 12px;
    top: 12px;
    cursor: pointer;
    opacity: 0.8;
}
.fltr-pop .close-icn:hover {
    opacity: 1;
}
.fltr-pop .fltr-fld {
    margin-bottom: 11px;
}
.fltr-pop .fltr-lbl {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 4px;
    text-transform: none;
}
.fltr-pop .fltr-fld .sel-box {
    width: 70%;
    text-transform: none;
}
.fltr-btns {
    display: flex;
    justify-content: flex-start;
    margin-top: 10px;
}
.fltr-btns .fltr-btn {
    width: 40%;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    padding: 8px 12px;
    color: var(--primary-blue-500);
    cursor: pointer;
    border: 1px solid var(--primary-blue-500);
    border-radius: 4px;
    max-width: 120px;
    margin-right: 4%;
}
.fltr-btns .fltr-btn.m {
    background: var(--primary-blue-500);
    color: var(--primary-white);
}
.fltr-btns .fltr-btn:hover {
    box-shadow: 0 1px 3px var(--primary-neutral-400);
}
.fltr-btns .fltr-btn:active {
    box-shadow: inset 0 1px 3px var(--primary-blue-600);
}

.shipment-list-box .greet-msg .msg-h6 .msg-h6-sort .sel-box {
}

.sel-box {
    border: 1px solid var(--app-thrid-border);
    display: inline-block;
    background: var(--app-sel-box-bg);
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0.36px;
    border-radius: 3px;
    position: relative;
    cursor: pointer;
    user-select: none;
    padding: 3px 2px;
}

.sel-box.open {
    border: 2px solid var(--primary-blue-300) !important;
}

.sel-box.open .sel-value::after{
    top: 15px;
    transform: rotate(-45deg);
}

.sel-box.i-fld {
    border-radius: 0;
}
.sel-box .auto-s {
    outline: none;
    border: none;
    width: 100%;
    padding: 0;
    /* font-weight: 600; */
    box-sizing: border-box;
    background: transparent;
    position: relative;
    z-index: 1;
    cursor: pointer;
    letter-spacing: 0.36px;
    font-size: 16px;
    line-height: 16px;
    height: 15px;
}
.sel-box .auto-s:focus {
    outline: none;
}

.sel-box.disable {
    /* opacity: 0.6; */
    background: var(--primary-neutral-200) !important;
    cursor: not-allowed;
}

.sel-box.disable div.sel-value{
    color: var(--primary-neutral-300);
}

.sel-box div.sel-value {
    padding: 5px 20px 5px 10px;
    color: var(--primary-neutral-600);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    font-weight: 400;
    font-family: 'Gibson', sans-serif;
    font-size: 16px;
    line-height: 16px;
}
.sel-box.new-h  div.sel-value{
    padding: 12px 28px 12px 8px;
}
.sel-box.new-h{
    padding: 0px;
}
.sel-box.new-w{
    min-width: 220px;
}


.sel-box .sel-value[title='Group'], .sel-box .sel-value[title='Company']  {
    color: var(--primary-neutral-300);
}
/* .sel-box label.sel-value {
    padding: 0 !important;
    margin: 0 !important;
    position: static !important;
    display: block;
    height: 21px;
} */

.sel-box .sel-value::after {
    position: absolute;
    content: ' ';
    border-width: 7px 5px 0 5px;
    top: 10px;
    right: 12px;
    z-index: 0;
    width: 6px;
    height: 6px;
    border-top: 2px solid var(--primary-neutral-600);
    border-right: 2px solid var(--primary-neutral-600);
    transform: rotate(135deg);
}
.sel-box .sel-value.redesign-srch::after {
    border: none;
}
.sel-box div.sel-value.s-l:after {
    content: '';
    display: none;
}
.sel-box div.sel-value .srch-icon {
    position: absolute;
    right: 12px;
    top: 12px;
}

.sel-box .sel-value.n {
    padding: 6px 6px 5px;
    text-align: center;
    cursor: default;
}
.sel-box .sel-value.n::after {
    content: '';
    display: none;
}
.sort-lbl {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.36px;
}

.sort-lbl div.sel-value {
    padding: 10px 20px 9px 10px;
}


.ship-filters {
    background: var(--app-ship-fltrs-bg);
    display: flex;
    padding: 12px 5%;
}

.stat-cont-list {
    display: flex;
    justify-content: flex-end;
    flex-grow: 2;
    padding-right: 10px;
}

.stat-cont-list li {
    font-size: 10px;
    letter-spacing: 1px;
    font-weight: 600;
    color: var(--app-stat-cont-list-li);
    display: flex;
    align-items: center;
    padding: 2px 5.25px;
    cursor: pointer;
    border-radius: 4px;
    max-width: 59px;
}

.stat-cont-list li:hover {
    background: var(--app-stat-cont-list-li-hvr);
}

.stat-cont-list li.a {
    background: var(--app-stat-cont-list-li-a);
    box-shadow: inset 0 0 3px var(--app-primary-box-shdw);
}

.stat-cont-list li span {
    font-size: 16px;
    padding-right: 3px;
}

.stat-cont-list .filter-img {
    height: 16px;
    margin-right: 4px;
    opacity: 0.85;
}

.ship-filters .f-s-m {
    max-width: 68px;
}
.ship-filters .f-s-m .sel-value {
    font-size: 10px;
}

.shipment-list-box .shipment-list {
    margin: 0;
    padding: 0;
    position: absolute;
    top: 116px;
    overflow-y: auto;
    bottom: 0;
    left: 0;
    right: 0;
    /* background: var(--app-shipment-list-box-shipment-list-bg); */
}

.shipment-list-box .shipment-list.company-list, 
.shipment-list-box .shipment-list.conf-p-list {
    /* top: 0;
    position: relative; */
}


.shipment-list-box .shipment-list .ship-item {
    padding: 4% 15px;
    cursor: pointer;
    border-bottom: 1px solid var(--primary-neutral-200);
}

.shipment-list-box .shipment-list .ship-item.product-profile{
    min-height: 50px;
}

.shipment-list-box .shipment-list .ship-item.product-profile .i-info{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.shipment-list-box .shipment-list .ship-item.a {
    background: var(--selected-itm);
}
.shipment-list-box .shipment-list.conf-p-list .i-id {
    max-width: 300px;
    overflow: hidden;
    white-space: nowrap;
}
.shipment-list-box .shipment-list.config-list .i-status {
    position: absolute;
    right: 20px;
}

.f-j-s-b {
    display: flex;
    justify-content: space-between;
}

.f-j-s-b.address-info {
    font-size: 12px;
    color: var(--primary-neutral-600);
    font-weight: 400;
}

.f-j-s-b.address-info.mt{
    margin-top: 10px;
}

.f-j-s-b.prod-info.jc{
    justify-content: flex-end;
    align-items: center;
    font-weight: 400;
}

.f-j-s-b.count-box {
    margin-top: 5%;
}

.ship-item .i-info {
    margin-bottom: 5px;
    white-space: nowrap;
}

.ship-item .i-info .i-id {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.5px;
    color: var(--primary-neutral-600);
    width: 73%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ship-item .ship-desc {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    color: var(--app-ship-item-ship-desc);
}
/* .ship-item .ship-desc:not(:empty)::before {
    content: ' - ';
} */
.ship-item .c-s-i {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    color: var(--app-ship-item-ship-desc);
}
.ship-item .c-s-i:not(:empty)::before {
    border-left: 2px solid var(--app-ship-item-c-s-i-not-before);
    content: '';
    margin-left: 6px;
    padding-left: 6px;
}

.ship-item .i-info .i-stat-icn {
    font-size: 0px;
    line-height: 20px;
    margin-right: 4px;
}

.ship-item .i-info .i-stat-lbl, .name-role .i-stat-lbl{
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0.36px;
    color: var(--primary-neutral-600);
    background-color: var(--feedback-success);
    border: 1px solid var(--feedback-success);
    border-radius: 3px;
    padding: 2px 10px 3px;
}

.ship-item.inactive .i-info .i-stat-lbl {
    color: var(--primary-neutral-600);
    background-color: var(--user-deactivate);
    border: 1px solid var(--user-deactivate);
}

.ship-item.new .i-info .i-stat-lbl {
}

.ship-item.in-transit .i-info .i-stat-lbl {
}

.ship-item.delivered .i-info .i-stat-lbl {
}

.ship-item.at-risk .i-info .i-stat-icn,
.ship-item.major .i-info .i-stat-icn {
    color: var(--app-ship-item-major-i-stat-icn);
    font-size: 24px;
}

.ship-item.at-risk:not(.completed) .i-info .i-stat-lbl,
.ship-item.major:not(.completed) .i-info .i-stat-lbl {
    color: var(--app-ship-item-major-i-stat-icn);
    border: 1px solid var(--app-ship-item-major-i-stat-icn);
}

.ship-item.critical .i-info .i-stat-icn,
.ship-item.minor .i-info .i-stat-icn {
    color: var(--app-ship-item-minor-i-stat-icn);
    font-size: 24px;
}

.ship-item.critical:not(.completed) .i-info .i-stat-lbl,
.ship-item.minor:not(.completed) .i-info .i-stat-lbl {
    color: var(--app-ship-item-minor-i-stat-icn);
    border: 1px solid var(--app-ship-item-minor-i-stat-icn);
}

.ship-item.d .i-info .i-stat-icn{
    font-size: 24px;
    color: var(--app-ship-item-minor-i-stat-icn);
}
.ship-item.d:not(.completed) .i-info .i-stat-lbl,
.ship-item.d:not(.completed) .i-info .i-stat-lbl {
    color: var(--app-ship-item-minor-i-stat-icn);
    border: 1px solid var(--app-ship-item-minor-i-stat-icn);
}


.ship-item.completed .i-info .i-stat-lbl {
}

.ship-item .i-prog-bar {
    position: relative;
    /* height: 2px;
    background: rgb(216, 216, 216); */
    border-top: 2px solid var(--app-ship-item-i-prog-bar-t-border);
    margin-bottom: 15px;
}
.ship-item.s-t-d .i-prog-bar {
    margin-bottom: 12px;
}
.ship-item.s-t-d .c-sec {
    margin-bottom: 12px;
    overflow: hidden;
}
.ship-item .c-sec.d {
    margin-bottom: 10px;
    margin-top: -8px;
}
.ship-item.s-t-d .c-sec .c-pro {
    background: var(--app-ship-item-c-pro-bg);
    padding: 3px 10px;
    border-radius: 2px;
    font-size: 8px;
    font-weight: 600;
    color: var(--app-secondary-color);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    float: left;
    margin-right: 2%;
    max-width: 48%;
    box-sizing: border-box;
}
.ship-item .i-prog-bar::before {
    position: absolute;
    content: ' ';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--app-ship-item-i-prog-bar-bg-before);
    left: 0;
    top: -5px;
}

.ship-item .i-prog-bar::after {
    content: ' ';
    position: absolute;
    width: 8px;
    height: 8px;
    background: var(--app-ship-item-i-prog-bar-bg-after);
    right: 0;
    top: -5px;
}

.ship-item .i-prog-bar .i-progress {
    position: absolute;
    left: 0;
    width: 0;
    top: -2px;
    /* height: 2px;
    background: rgb(0, 195, 100); */
    border-top: 2px solid var(--app-ship-item-i-prog-bar-bg-before);
}

.ship-item.new .i-prog-bar .i-progress {
    width: 0;
}

.ship-item.in-transit .i-prog-bar .i-progress {
    width: 50%;
}

.ship-item.delivered .i-prog-bar .i-progress,
.ship-item.destination .i-prog-bar .i-progress {
    width: 100%;
}

.ship-item.delivered .i-prog-bar::after,
.ship-item.destination .i-prog-bar::after,
.ship-item.completed .i-prog-bar::after {
    background: var(--app-ship-item-i-prog-bar-bg-before);
}

.ship-item.at-risk .i-prog-bar .i-progress {
}

.ship-item.critical .i-prog-bar .i-progress {
}

.ship-item.delayed .i-prog-bar .i-progress {
}

.ship-item.completed .i-prog-bar .i-progress {
    width: 100%;
}

.ship-item .i-address {
}

.i-address .i-loc > * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.i-address .i-loc.l {
    text-align: left;
    max-width: 46%;
}

.i-address .i-loc.r {
    text-align: right;
    max-width: 46%;
}

.i-address .i-loc .l1 {
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0;
    color: var(--app-fourth-color);
    text-transform: uppercase;
    margin-bottom: 2px;
}
.s-t-d .i-address .i-loc .l1 {
    font-size: 0;
    margin: 0;
}
.i-address .i-loc .l2 {
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0;
    color: var(--app-fourth-color);
    margin-bottom: 2px;
}

.i-address .i-loc .l3 {
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0.28px;
    color: var(--app-fifth-color);
}

.ship-item .i-eta {
    font-weight: 600;
}

.ship-item .i-eta.o-t {
    color: var(--app-ship-item-i-prog-bar-bg-before);
}
.ship-item .i-eta.d {
    color: var(--app-ship-item-minor-i-stat-icn);
}

.ps__thumb-y {
    min-height: 20px !important;
}

.middle-bar {
    position: absolute;
    top: 70px;
    left: 30%;
    width: 34%;
    bottom: 0;
    background: var(--app-primary-bg);
    z-index: 2;
    box-shadow: 0 0 3px var(--app-primary-box-shdw);
}

.ship-details {
    background: var(--app-ship-details-bg);
    height: 154px;
    padding: 0 20px;
}
.trk .ship-details {
    height: 150px;
}

.ship-details.tracking {}

.ship-details .ship-det-info {
    display: flex;
    padding: 20px 0 0px;
}

/* .trk .ship-details .ship-det-info {
    padding-bottom: 4px;
} */

.ship-details .ship-det-info > li:last-child {
    flex-grow: 1;
}
.trk .ship-details .ship-det-info > li:last-child {
    flex-grow: unset;
}

.ship-details .ship-det-info .s-d-logo,
.ship-details .s-d-logo {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid var(--app-ship-details-s-d-logo-border);
    float: left;
    margin-right: 8px;
    text-align: center;
}

.s-num-icn img {
    margin: 6px;
}

.ship-details .ship-det-info .s-d-logo.track-icn {
    border: none;
}

.ship-details .ship-det-info .s-d-i-txt {
    font-weight: 400;
    font-size: 10px;
    color: var(--app-fourth-color);
    text-transform: uppercase;
}
.ship-details .ship-det-info .copy-icn {
    cursor: pointer;
    margin-left: 10px;
    height: 10px;
    width: 10px;
    display: inline-block;
    border: 1px solid var(--app-ship-details-copy-icn-border);
    background: var(--app-ship-details-copy-icn-bg);
    position: relative;
    border-radius: 2px;
    margin-top: -2px;
    opacity: 0.8;
    box-shadow: 1px 0 1px var(--app-ship-details-copy-icn-box-shdw);
}
.ship-details .ship-det-info .copy-icn:before {
    content: '';
    border: 1px solid var(--app-ship-details-copy-icn-border);
    margin-top: 1px;
    position: absolute;
    width: 10px;
    height: 10px;
    margin-left: -3px;
    background: var(--app-ship-details-copy-icn-bg);
    border-radius: 2px;
}


.ship-details .ship-det-info .tool-tip {
    color: var(--app-primary-color);
    text-align: center;
    border-radius: 4px;
    padding: 5px 10px;
    position: absolute;
    z-index: 1;
    background-color: var(--app-ship-details-tooltip-bg);
    font-size: 10px;
    margin-left: 17px;
    margin-top: -10px;
}

.ship-details .ship-det-info .s-d-i-val,
.ship-details .s-d-i-val {
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.5px;
    color: var(--app-third-color);
    width: 66%;
    white-space: nowrap;
    overflow: hidden;
    /* text-overflow: ellipsis;
    direction: rtl; */
}

.ship-details .ship-det-info .s-d-i-route {
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0px;
    color: var(--app-fourth-color);
}

.trk .ship-details .ship-det-info .s-d-i-val {
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.43px;
    color: var(--app-ship-details-ship-det-info-s-d-i-val);
    text-decoration: none;
}

.ship-details .ship-det-info .s-d-action {
    border-radius: 3px;
    border: 1px solid var(--app-thrid-border);
    display: flex;
    align-items: center;
    padding: 4px;
    cursor: pointer;
    width: 16px;
    user-select: none;
    justify-content: center;
}

.ship-details .ship-det-info .s-d-action.i {
    opacity: 0.4;
    cursor: not-allowed;
}

.ship-details .ship-det-info .s-d-action.w-1 {
    width: 62px;
}

.ship-details .ship-det-info .s-d-action .icn {
    font-size: 12px;
    /* margin-right: 4px; */
    color: var(--app-ship-details-ship-det-info-s-d-action-icn);
}

.ship-details .ship-det-info .s-d-action .icn + span {
    font-size: 8px;
    text-transform: uppercase;
    color: var(--app-ship-details-ship-det-info-s-d-action-icn-span);
    font-weight: 600;
    letter-spacing: 0.62px;
}

.ship-details .ship-det-info .s-d-action:hover {
    background: var(--app-secondary-bg);
    color: var(--app-primary-color);
}

.ship-details .ship-det-info .s-d-action:hover .icn,
.ship-details .ship-det-info .s-d-action:hover svg {
    color: var(--app-primary-color);
    fill: var(--app-primary-color) !important;
}

.ship-details .ship-det-info .s-d-action:active {
    box-shadow: 0 0 2px var(--app-primary-box-shdw);
}

.ship-details .share-actions {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
}

.ship-details .share-actions > li {
    margin-right: 14px;
}
.ship-details .share-actions > li:last-child {
    margin-right: 0px;
}

.i-export {
    border: 1px solid var(--app-i-export-border);
    width: 8px;
    height: 8px;
    margin: 1px 0;
    position: relative;
}

.i-export::after {
    content: ' ';
    position: absolute;
    width: 5px;
    border: 2px solid var(--app-i-export-border-after);
    height: 1px;
    background: var(--app-i-export-bg-after);
    border-width: 2px 0;
    transform: rotate(-45deg);
    right: -1px;
    top: -1px;
}

.i-export::before {
    content: ' ';
    position: absolute;
    border: 2px solid var(--app-i-export-border-before);
    border-color: var(--app-i-export-bg-after) var(--app-i-export-bg-after) transparent transparent;
    right: -3px;
    top: -3px;
}

.ship-details .ship-det-info .s-d-action:hover .i-export {
    border-color: var(--app-primary-color);
}
.ship-details .ship-det-info .s-d-action:hover .i-export::after {
    background: var(--app-primary-bg);
    border-color: var(--app-secondary-border);
}
.ship-details .ship-det-info .s-d-action:hover .i-export::before {
    border-color: var(--app-primary-border) var(--app-primary-border) transparent transparent;
}


.ship-desc-lbl {
    font-size: 9px;
    text-transform: uppercase;
    color: var(--app-fourth-color);
    font-weight: 400;
}

/* .trk .ship-desc-lbl {
    padding-top: 4px;
} */

.shipment-desc {
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.shipment-desc-sec {
    display: flex;
    align-items: center;
    padding: 10px 0 4px;
}

.shipment-desc-sec .sd-s-col-1 {
    width: calc(100% - 108px);
}
.shipment-desc-sec .sd-s-col-2 {
    width: 108px;
}
.shipment-desc-sec .sd-s-col-2 .s-d-logo{
    float: right;
    margin-right: 0px;
    margin-left: 4px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.shipment-desc-sec .sd-s-col-2 .s-d-logo .s-dev-icn {
    width: 18px;
}
.shipment-desc-sec .sd-s-col-2 .s-d-i-txt {
    font-size: 8px;
    line-height: 10px;
    width: calc(100% - 32px);
    text-align: right;
}
.shipment-desc-sec .sd-s-col-2 .s-d-i-val {
    font-size: 12px;
    width: calc(100% - 32px);
    text-align: right;
}


.ship-src-dest {
    display: flex;
    align-items: center;
    width: 100%;
}

.ship-src-dest .ship-src {
    border: 1px dashed var(--app-ship-src-dest-ship-src-border);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: relative;
}

.ship-src-dest .ship-src::before {
    position: absolute;
    width: 10px;
    height: 10px;
    content: ' ';
    background: var(--app-ship-src-dest-ship-src-bg-before);
    border-radius: 50%;
    top: 5px;
    left: 5px;
}

.ship-src-dest .ship-path {
    border-top: 1px dashed var(--app-ship-src-dest-ship-path-t-border);
    flex-grow: 1;
}

.ship-src-dest .ship-dest {
    color: var(--app-ship-src-dest-ship-dest);
}
.ship-src-dest .ship-dest .r-t {
    width: 10px;
    height: 10px;
    background: var(--app-ship-src-dest-ship-dest);
    position: absolute;
    margin-top: -5px;
    margin-left: -10px;
}

.ship-c-loc {
    padding: 16px 20px;
    align-items: center;
    box-shadow: 0 2px 5px var(--app-ship-c-loc-box-shdw);
    position: relative;
}
.f-j-s-b.ship-c-loc.dev-loc::after {
    content: ' ';
    position: absolute;
    border: 1px solid var(--app-ship-c-loc-dev-loc-border-after);
    border-width: 0 1px 0 0;
    top: 0;
    bottom: 0;
    right: 160px;
}

.c-loc-icn {
    background: var(--app-c-loc-icn-bg);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 5px solid var(--app-c-loc-icn-border);
    /* animation: pulsate 0.82s cubic-bezier(.36,.07,.19,.97) infinite;
    position: absolute; */
}

.ship-eve-icn {
    background: var(--app-ship-eve-icn-bg);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 5px;
}

.loc-icn {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url('assets/images/location.png') no-repeat 50% 50%;
    background-size: 16px 20px;
}

.metric-loc-icn {
    border-radius: 50%;
    background: var(--app-metric-loc-icn-bg);
    margin-top: 0px;
}
.metric-loc-icn.i-icn {
    background: none;
}
.metric-loc-icn div {
    content: ' ';
    width: 20px;
    height: 20px;
    background: var(--app-metric-loc-icn-bg);
    border: 5px solid var(--app-primary-border);
    border-radius: 50%;
}
.metric-loc-icn.i-icn div {
    background: none;
    border: none;
}
.metric-loc-icn div.icn-box {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0px solid var(--app-primary-border);
}

.metric-loc-icn.major div.icn-box {
    background: var(--app-metric-major-loc-icn-bg);
}
.metric-loc-icn.i-icn div.icn-box {
    background: none;
}
.metric-loc-icn div.icn-box img.eve-img {
    width: 70%;
}
.metric-loc-icn.i-icn div.icn-box img.eve-img {
    width: 20px;
    height: 20px;
    margin: 0 auto;
}
.eve-ordr {
    position: absolute;
    font-size: 12px;
    margin: 0 0px 0px -38PX;
    font-weight: 600;
    color: var(--app-fourth-color);
}
.metric-loc-icn.major {
    border-color: var(--app-metric-major-loc-icn-bg);
}

.metric-loc-icn.minor {
}

.tl-eve-i .eve-icn.metric-loc-icn {
}

/* .metric-loc-icn .icn-battery {
    background: url('assets/images/battery.svg') no-repeat center center;
}
.metric-loc-icn .icn-temp {
    background: url('assets/images/temperature.svg') no-repeat center center;
}
.metric-loc-icn .icn-shock {
    background: url('assets/images/shock.png') no-repeat center center;
}
.metric-loc-icn .icn-lid {
    background: url('assets/images/lidOpened.png') no-repeat center center;
} */

.ship-c-loc .c-loc-address {
    flex-grow: 1;
    font-weight: 600;
    font-size: 12px;
    color: var(--app-third-color);
    padding: 0 20px 0 0;
	overflow: hidden;
	max-height: 30px;
}/*
.ship-c-loc .c-loc-address > div.txt {
	float: right;
	width: 100%;
	margin-left: -5px;
}
.ship-c-loc .c-loc-address:before {
	float: left;
	width: 5px;
	height: 100%;
	content: '';
}
.ship-c-loc .c-loc-address:after {
	content: "\02026";
    float: right;
    position: relative;
    top: 15px;
    left: 100%;
    width: 3em;
    margin-left: -3em;
    padding-right: 5px;
    text-align: right;
}*/
.c-loc-toggle {
    text-align: center;
    position: relative;
    width: 124px;
    min-width: 124px;
    max-width: 124px;
}
.ship-c-loc .c-loc-ts {
    font-weight: 600;
    font-size: 12px;
    color: var(--app-third-color);
    white-space: nowrap;
}
.ship-c-loc .c-loc-label {
    font-size: 12px;
    font-weight: 400;
    color: var(--app-fourth-color);
    text-transform: uppercase;
    white-space: nowrap;
    position: relative;
    margin-top: 5px;
}
.ship-c-loc.dev-loc .c-loc-label {
    font-size: 8px;
    font-weight: 400;
    letter-spacing: 0.28px;
    padding-left: 10px;
}

.c-loc-label::before {
    content: ' ';
    border: 4px solid var(--app-c-loc-icn-bg);
    border-radius: 50%;
    position: absolute;
    margin-left: -12px;
    top: 4px;
    background: var(--app-c-loc-icn-bg);
}
.ship-c-loc.dev-loc .c-loc-label::before {
    border-width: 3px;
    top: 1px;
    margin-left: -10px;
}

.c-loc-label[loc="Selected Location"]::before {
    background: var(--app-c-loc-lnl-befoe);
    border-color: var(--app-c-loc-lnl-befoe);
}

.ship-log-info {
    /* padding: 20px; */
}

.switch-btn {
    border: 1px solid var(--primary-blue-500);
    display: flex;
    align-items: center;
    background: var(--app-primary-bg);
    border-radius: 4px;
}

.switch-btn li {
    font-weight: 500;
    font-size: 16px;
    color: var(--primary-blue-500);
    text-transform: uppercase;
    padding: 8px;
    text-align: center;
    flex-grow: 1;
    flex-basis: 0;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    user-select: none;
}
.trk .switch-btn li {
	cursor: default;
}
.switch-btn.map-styles li {
    font-size: 10px;
    min-width: 60px;
    font-weight: 500;
    text-align: center;
    background: var(--app-primary-bg);
}
.switch-btn li.a {
    background: var(--primary-blue-500);
    color: var(--app-primary-color);
}

.tracking li.btn-i[data-view='device-data'] {
    display: none;
}
/* .switch-btn li.btn-i.br {
    border-right: 1px solid var(--app-thrid-border);
} */
.switch-btn li.btn-i {
    border-right: 1px solid var(--app-thrid-border);
    user-select: none;
}
.switch-btn li.btn-i:last-child {
    border-right: none;
}
.switch-btn li.btn-i:last-child {
    border-right: none;
}
.switch-btn li.btn-i:last-child {
    border-right: none;
}

.switch-btn.map-styles {
    position: absolute;
    bottom: 50px;
    left: 20px;
    z-index: 999;
}

.tl-dd-sec {
    position: absolute;
    top: 272px;
    bottom: 0;
    overflow-y: auto;
    left: 0;
    right: 0;
}

.tl-eve {
    margin: 28px;
}

.tl-eve .tl-eve-i {
    padding-left: 40px;
    position: relative;
    padding-bottom: 24px;
    page-break-inside: avoid;
}

.tl-eve .tl-eve-i::before {
    content: ' ';
    border-left: 2px dashed var(--app-tl-eve-i-l-border-before);
    top: 6px;
    bottom: -4px;
    left: 9px;
    position: absolute;
    z-index: 0;
}
 
.tl-eve .tl-eve-i:first-child::before{
    display: none;
}

.tl-eve .tl-eve-i:last-child::before,
.tl-eve .tl-eve-i.d::before {
    display: none;
}

.tl-eve-i .eve-info {
    font-weight: 400;
    font-size: 10px;
    color: var(--app-fourth-color);
}

.tl-eve-i .eve-info .tt {
    text-transform: uppercase;
}

.tl-eve-i .eve-info .tt {
    text-transform: uppercase;
}

.eve-icn {
}

.eve-i-stat {
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 0.36px;
    color: var(--app-eve-i-stat);
    border: 1px solid var(--app-eve-i-stat-border);
    border-radius: 3px;
    padding: 2px 10px 3px;
    user-select: none;
}

.eve-i-stat.at-risk:not(.completed) {
    color: var(--app-eve-i-stat-at-risk-not-completed);
    border: 1px solid var(--app-eve-i-stat-at-risk-not-completed-border);
}

.eve-i-stat.critical:not(.completed) {
    color: var(--app-eve-i-stat-critical-not-completed);
    border: 1px solid var(--app-eve-i-stat-critical-not-completed);
}
.eve-i-stat.d:not(.completed) {
    color: var(--app-eve-i-stat-critical-not-completed);
    border: 1px solid var(--app-eve-i-stat-critical-not-completed);
}

.eve-i-stat:not(.completed) {
    cursor: pointer;
}

.eve-i-stat .i-stat-icn {
    position: absolute;
    top: 0px;
    margin-left: -34px;
    font-size: 0;
}

.eve-i-stat.at-risk .i-stat-icn {
    font-size: 20px;
    color: var(--app-eve-i-stat-at-risk-not-completed);
}

.eve-i-stat.critical .i-stat-icn, .eve-i-stat.d .i-stat-icn {
    font-size: 20px;
    color: var(--app-eve-i-stat-critical-not-completed);
}

.mark-as-comp:after{
    content: '';
    position: absolute;
    width: 5px;
    height: 5px;
    border: 2px solid var(--app-mark-as-complete-after);
    border-color: transparent var(--app-mark-as-complete-after) var(--app-mark-as-complete-after) transparent;
    transform: rotate(45deg);
    right: -14px;
}
.mark-as-comp.complete:after,
.mark-as-comp.eta:after,
.mark-as-comp.no-dd:after {
	content: unset;
}
.mark-as-comp.no-perm:after {
    content: unset;
}

.mark-as-comp.complete,
.mark-as-comp.eta,
.mark-as-comp.no-dd,
.eve-i-stat.completed,
.eve-i-stat.eta,
.eve-i-stat.no-dd {
    cursor: default;
}

.eve-i-stat-change {
    font-size: 10px;
    font-weight: 600;
    color: var(--app-third-color);
    border-radius: 2px;
    padding: 4px 10px 5px;
    user-select: none;
    position: absolute;
    right: -14px;
    top: 28px;
    border: 1px solid var(--app-eve-i-stat-change-border);
    background: var(--app-primary-bg);
    cursor: pointer;
}

.eve-i-stat-change:hover {
    border: 1px solid var(--app-eve-i-stat-change-border-hvr);
    background: var(--app-eve-i-stat-change-bg-hvr);
}

.eve-i-stat-change:active {
    box-shadow: 0 1px 3px var(--app-primary-box-shdw);
    background: var(--app-eve-i-stat-change-bg-active);
}

.ship-completed-success {
    background: var(--app-ship-completed-success-bg);
    color: var(--app-ship-completed-success);
    border: 1px solid var(--app-ship-completed-border);
    padding: 10px 0;
    width: 82%;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.6px;
    width: 82%;
    text-align: center;
    margin: 14px auto 0;
}

.tl-eve-eta-stops {
    border: 2px solid var(--app-tl-eve-eta-stops-border);
    margin: 0 -28px 24px;
    border-width: 2px 0;
    padding: 10px 28px;
}

.tl-eve-eta-stops .e-s-info {
    text-align: center;
}

.tl-eve-eta-stops .e-s-info .e-s-lbl {
    font-weight: 400;
    font-size: 10px;
    color: var(--app-fourth-color);
    text-transform: uppercase;
}

.tl-eve-eta-stops .e-s-info .e-s-cnt {
    font-weight: 600;
    font-size: 24px;
    color: var(--app-third-color);
}

.tl-eve-eta-stops .e-s-desc {
    flex: 1;
    border-left: 1px solid var(--app-thrid-border);
    padding: 0 14px;
    margin: 0 0 0 14px;
    font-weight: 400;
    font-size: 14px;
    color: var(--app-fourth-color);
    display: flex;
    /* justify-content: center; */
    align-items: center;
}

.tl-eve-eta-stops .e-s-stat {
    font-size: 8px;
    letter-spacing: 0.28px;
    font-weight: 700;
    color: var(--app-primary-color);
    padding: 1px 8px;
    border-radius: 3px;
    background: var(--app-e-s-stat);
	border: 1px solid var(--app-e-s-stat);
	text-transform: capitalize;
}

.e-s-stat.at-risk, .e-s-stat.low {
    background: var(--app-e-s-stat-low);
	border-color: var(--app-e-s-stat-low);
}

.e-s-stat.critical, .e-s-stat.high {
    background: var(--app-e-s-stat-high);
	border-color: var(--app-e-s-stat-high);
}

.tl-eve-i .eve-tot-d {
    font-weight: 600;
    font-size: 14px;
    color: var(--app-third-color);
}

.tl-eve-i .eve-icn {
    position: absolute;
    left: 0px;
    top: 0px;
}

.tl-eve .tl-eve-i.d .eve-icn {
    margin-top: 3px;
}

.track-time-info {
    margin-bottom: 2px;
}

.track-time-info .tt-hdr {
    font-weight: 400;
    font-size: 10px;
    color: var(--app-fourth-color);
    text-transform: uppercase;
}

.track-time-info .tt-hdr span {
    text-transform: lowercase;
}

.track-time-info .tt-i-stat,
.track-time-data .tt-eta-dt {
    width: 120px;
}

.track-time-info .tt-i-stat.tt-delivery,
.track-time-data .tt-eta-dt.tt-delivery {
    width: 148px;
}

.track-time-info .tt-i-stat .tt-l-lbl {
    font-weight: 600;
    font-size: 10px;
    color: var(--app-track-time-info-tt-l-lbl);
    text-transform: uppercase;
}
.track-time-info .tt-i-stat .tt-l-lbl:before {
    content: ' ';
    position: absolute;
    border: 2.5px solid var(--app-track-time-info-tt-l-lbl);
    background: var(--app-track-time-info-tt-l-lbl);
    border-radius: 50%;
    margin-left: -8px;
    margin-top: 3px;
}

.track-time-info .tt-i-stat .tt-l-lbl.d{
	color: var(--app-track-time-info-tt-l-lbl-d);
}
.track-time-info .tt-i-stat .tt-l-lbl.d:before {
    border: 2.5px solid var(--app-track-time-info-tt-l-lbl-d);
    background: var(--app-track-time-info-tt-l-lbl-d);
}
.track-time-info .tt-i-stat .tt-l-lbl.o{
	color: var(--app-track-time-info-tt-l-lbl-o);
}
.track-time-info .tt-i-stat .tt-l-lbl.o:before {
    border: 2.5px solid var(--app-track-time-info-tt-l-lbl-o);
    background: var(--app-track-time-info-tt-l-lbl-o);
}



.track-time-info .tt-i-stat.tt-delivery .tt-l-lbl {
    font-weight: 400;
    font-size: 10px;
    color: var(--app-fourth-color);
}

.track-time-info .tt-i-stat.tt-delivery .tt-l-lbl:before {
    content: unset;
}

.track-time-info .tt-i-stat .tt-l-stat {
    font-weight: 700;
    font-size: 8px;
    color: var(--app-primary-color);
    background: var(--app-track-time-info-tt-l-lbl-d);
    padding: 1px 8px;
    border-radius: 3px;
}

.track-time-info .tt-i-stat .tt-l-stat.o-t {
    background: var(--app-track-time-info-tt-l-lbl-o);
}

.track-time-data {
    font-weight: 600;
    font-size: 10px;
    color: var(--app-track-time-data);
}

.track-time-data .tt-actual-dt {
}

.track-time-data .tt-eta-dt {
    font-weight: 700;
}

.tl-eve-eta {
    padding-bottom: 24px;
}

.tl-eve-i .eve-n {
    font-weight: 600;
    font-size: 14px;
    color: var(--app-third-color);
    margin-bottom: 4px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    text-transform: uppercase;
}

.tl-eve-i .eve-n .ship-user {
    border-left: 2px solid var(--app-tl-eve-i-ship-user-l-border);
    padding-left: 18px;
    margin-left: 18px;
    font-size: 12px;
    font-weight: 400;
    color: var(--app-fourth-color);
    text-transform: none;
}

.tl-eve-i .eve-n .dd {
    cursor: pointer;
    transition: all ease-out 200ms;
    font-size: 30px;
    user-select: none;
    line-height: 18px;
    margin-top: -4px;
}

.tl-eve-i .eve-n .dd .collapse {
    display: none;
}

.tl-eve-i.view-info .eve-n .dd .collapse {
    display: block;
}

.tl-eve-i.view-info .eve-n .dd .expand {
    /* transform: rotate(180deg); */
    display: none;
}

.tl-eve-i .eve-t {
    font-weight: 500;
    font-size: 12px;
    color: var(--app-fourth-color);
    margin-bottom: 10px;
}

.eve-sub-txt {
    font-weight: 400;
    font-size: 12px;
    color: var(--app-third-color);
    margin-left: -30px;
    margin-bottom: 10px;
    width: 180%;
}

.eve-info-i-list {
    padding: 00px 20px 00px 30px;
    width: 100%;
    box-sizing: border-box;
    display: none;
}

.eve-sub-txt {
    font-weight: 400;
    font-size: 12px;
    color: var(--app-third-color);
    margin-left: -30px;
    margin-bottom: 10px;
    width: 180%;
}

.eve-info-i-list .eve-info-i {
    margin-bottom: 6px;
    margin-right: 64px;
}
.eve-info-i-list .eve-info-i.c-e:not(.has-child):not(.end-loc-event) {
    margin-bottom: 32px;
}

.eve-info-i-list .eve-info-i::before {
    content: ' ';
    width: 8px;
    height: 8px;
    position: absolute;
    border-radius: 50%;
    background: var(--app-eve-info-i-list-eve-info-i-bg-before);
    margin: 4px 0 0 -20px;
}

.eve-info-i-list .eve-info-i.desc {
    margin-left: -30px;
}
.eve-info-i-list .eve-info-i.desc::before {
    content: none;
}

.eve-info-i-list.ext-ui .eve-info-i:nth-child(2)::before {
    border-radius: 2px;
}
.eve-info-i-list.ext-ui .eve-info-i.has-child {
    position: relative;
}
.eve-info-i-list.ext-ui .eve-info-i.has-child::after {
    content: ' ';
    position: absolute;
    top: 14px;
    left: -17px;
    bottom: -10px;
    border-left: 2px dotted var(--app-eve-info-i-list-eve-info-i-has-child-l-border-after);
}

.eve-info-i-val {
    font-weight: 400;
    font-size: 12px;
    color: var(--app-third-color);
    margin-bottom: 4px;
    /* display: flex; */
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.eve-info-i-val-b {
    border-bottom: 1px solid var(--app-eve-info-i-val-b-b-border);
    padding-right: 30px;
}

.desc .eve-info-i-val {
    font-weight: 500;
    margin-bottom: 10px;
}

.tl-eve-i.view-info .eve-info-i-list.ext-ui.t-dur {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0;
    margin-top: -10px;
}
.tl-eve-i.view-info .eve-info-i-list.ext-ui.t-dur .eve-info-i {
    margin: 0;
}
.tl-eve-i.view-info .eve-info-i-list.ext-ui.t-dur .eve-info-i .eve-info-i-val{
    font-weight: 700;
    margin: 0;
}
.eve-info-i-list.t-dur .eve-info-i::before {
    content: unset;
}

.desc .eve-info-i-val {
    font-weight: 500;
    margin-bottom: 10px;
}

.tl-eve-i.view-info .eve-info-i-list.ext-ui.t-dur {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0;
    margin-top: -10px;
}
.tl-eve-i.view-info .eve-info-i-list.ext-ui.t-dur .eve-info-i {
    margin: 0;
}
.tl-eve-i.view-info .eve-info-i-list.ext-ui.t-dur .eve-info-i .eve-info-i-val{
    font-weight: 700;
    margin: 0;
}
.eve-info-i-list.t-dur .eve-info-i::before {
    content: unset;
}
.tl-eve-i.view-info .eve-info-i-list.ext-ui.t-dur .eve-exc-dur {
    padding: 10px;
    color: var(--app-primary-color);
    font-size: 12px;
    font-weight: 700;
    border-radius: 4px;
    width: 120px;
    text-align: center;
}
.tl-eve-i.view-info .eve-info-i-list.ext-ui.t-dur .time-above{
    background: var(--app-tl-eve-i-time-above-bg);
}
.tl-eve-i.view-info .eve-info-i-list.ext-ui.t-dur .time-below{
    background: var(--app-tl-eve-i-time-below-bg);
}
.tl-loc-deviation {
}

.eve-info-i .eve-info-loc {
    font-weight: 400;
    font-size: 12px;
    color: var(--app-third-color);
    /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
    margin-bottom: 4px;
}

.eve-info-i .eve-info-t {
    font-weight: 300;
    font-size: 10px;
    color: var(--app-third-color);
}

.eve-exc-details {
    position: absolute;
    border-radius: 7px;
    border: 1px solid var(--app-eve-exc-details-border);
    padding: 17px 0px 18px;
    text-align: center;
    top: -10px;
    right: 0px;
    width: 74px;
    min-height: 68px;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.eve-exc-details.has-end {
    top: 0px;
}
.eve-exc-details .eve-exc-val {
    font-size: 16px;
    font-weight: 500;
    white-space: nowrap;
}

.eve-exc-details.heat.alarm,
.eve-exc-details.heat.deviation{
    background: var(--app-eve-exc-details-heat);
    color: var(--app-primary-color);
    border-color: var(--app-eve-exc-details-heat);
}
.eve-exc-details.heat.warning {
    border-color: var(--app-eve-exc-details-heat);
}
.eve-exc-details.cold.alarm,
.eve-exc-details.cold.deviation {
    background: var(--app-eve-exc-details-cold);
    color: var(--app-primary-color);
    border-color: var(--app-eve-exc-details-cold);
}
.eve-exc-details.cold.warning {
    border-color: var(--app-eve-exc-details-cold);
}
.eve-exc-details.dry.alarm,
.eve-exc-details.dry.deviation{
    background: var(--app-eve-exc-details-heat);
    color: var(--app-primary-color);
    border-color: var(--app-eve-exc-details-heat);
}
.eve-exc-details.dry.warning {
    border-color: var(--app-eve-exc-details-heat);
}
.eve-exc-details.wet.alarm,
.eve-exc-details.wet.deviation,
.eve-exc-details.moisture.alarm,
.eve-exc-details.moisture.deviation {
    background: var(--app-eve-exc-details-cold);
    color: var(--app-primary-color);
    border-color: var(--app-eve-exc-details-cold);
}
.eve-exc-details.wet.warning,
.eve-exc-details.moisture.warning {
    border-color: var(--app-eve-exc-details-cold);
}
.eve-exc-details.low {
    border-color: var(--app-eve-exc-details-heat);
}
.eve-exc-details.critical {
    background: var(--app-eve-exc-details-critical);
    color: var(--app-primary-color);
    border-color: var(--app-eve-exc-details-critical);
}

.eve-exc-details.rsb {
    background: var(--app-eve-exc-details-heat);
    color: var(--app-primary-color);
    border-color: var(--app-eve-exc-details-heat);
}

.eve-exc-details .eve-exc-devi {
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
}
.eve-exc-details .eve-exc-devi .material-icons {
    font-size: 14px;
}
.eve-exc-details.wet .eve-exc-val, 
.eve-exc-details.moisture .eve-exc-val,
.eve-exc-details.dry .eve-exc-val{
    font-size: 14px;
}

.tl-eve-i .eve-l {
    font-weight: 500;
    font-size: 12px;
    color: var(--app-fourth-color);
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.tl-eve-i .eve-d, .tl-eve-i .eve-d .ex-time, .tl-eve-i .eve-d .ex-time:after {
    font-weight: 500;
    font-size: 12px;
    color: var(--app-fourth-color);
    display: flex;
    align-items: center;
}


.tl-eve-i .eve-l .icn,
.tl-eve-i .eve-d .icn {
    padding-right: 4px;
    min-width: 20px;
}

.s-m-sec {
    display: flex;
    margin-bottom: 16px;
    position: relative;
    padding-bottom: 6px;
}
.s-m-sec::after  {
    content: ' ';
    width: 100%;
    border-bottom: 1px solid var(--app-s-m-sec-after);
    position: absolute;
    bottom: 0;
    box-shadow: 0 1px 3px var(--app-s-m-sec-after);
 }
.s-m-sec .s-m-h1 {
    color: var(--app-secondary-color);
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    width: 108px;
}
.e-t-sec {
    position: absolute;
    top: 20px;
    color: var(--app-e-t-sec);
    text-transform: none;
    font-size: 10px;
    font-weight: 500;
    display: flex;
    align-items: center;
    line-height: 18px;
    cursor: pointer;
}
.e-t-sec .s-icn{
    margin-right: 2px;
    font-size: 14px;
    font-weight: 700;
}

.s-m-sec .s-m-cnts{
    display: flex;
    justify-content: flex-end;
    color: var(--app-primary-color);
    font-size: 14px;
    font-weight: 600;
    flex-flow: wrap;
    flex-grow: 1;
}
.s-m-sec .s-m-cnts .m-c{
    background: var(--app-primary-bg);
    /* border: 1px solid #f0525a; */
    border-radius: 15px;
    margin: 0 0px 12px 20px;
    cursor: pointer;
    display: flex;
    width: 46px;
    justify-content: flex-end;
    position: relative;
    border: 1px solid var(--app-s-m-cnts-m-c-border);
}
.s-m-sec .s-m-cnts .m-c.a {
    background: var(--app-s-m-cnts-m-c-a-border);
}
.s-m-sec .s-m-cnts .m-c.a {
    border: 1px solid var(--app-s-m-cnts-m-c-a-border);
}
.s-m-sec .s-m-cnts .m-c.a.minor {
    border: 1px solid var(--app-s-m-cnts-m-c-minor-a-bg);
    background: var(--app-s-m-cnts-m-c-minor-a-bg);
}
.s-m-sec .s-m-cnts .m-c.minor .eve-img-box {
    background: var(--app-s-m-cnts-m-c-minor-a-bg);
}

.s-m-sec .s-m-cnts .m-c .eve-img-box {
    display: flex;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    background: var(--app-s-m-cnts-m-c-a-border);
    position: absolute;
    top: -2px;
    left: -2px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.s-m-sec .s-m-cnts .m-c.a.icn-heat-major,
.s-m-sec .s-m-cnts .m-c.a.icn-dry-major,
.s-m-sec .s-m-cnts .m-c.a.icn-dry-minor,
.s-m-sec .s-m-cnts .m-c.a.icn-battery.minor {
    border: 1px solid var(--app-s-m-cnts-m-c-heat);
}
.s-m-sec .s-m-cnts .m-c.a.icn-wet-major,
.s-m-sec .s-m-cnts .m-c.a.icn-wet-minor,
.s-m-sec .s-m-cnts .m-c.a.icn-cold-minor,
.s-m-sec .s-m-cnts .m-c.a.icn-cold-major {
    border: 1px solid var(--app-s-m-cnts-m-c-cold);
}
.s-m-sec .s-m-cnts .m-c.a.icn-temp-minor-below {
    border: 1px solid var(--app-s-m-cnts-m-c-temp-minor-below);
}
.s-m-sec .s-m-cnts .m-c.a.icn-heat-minor,
.s-m-sec .s-m-cnts .m-c.a.icn-ping-rate {
    border: 1px solid var(--app-s-m-cnts-m-c-ping-rate);
}
.s-m-sec .s-m-cnts .m-c.a.icn-rsb,
.s-m-sec .s-m-cnts .m-c.a.m-c.icn-risk-alarm{
    border: 1px solid var(--app-s-m-cnts-m-c-risk-alarm);
}
.s-m-sec .s-m-cnts .m-c.a.icn-battery[title='Dry Deviation'],
.s-m-sec .s-m-cnts .m-c.a.icn-battery.minor {
    border: 1px solid var(--app-s-m-cnts-m-c-low-battery);
}

.s-m-sec .s-m-cnts .m-c.icn-heat-major .eve-img-box,
.s-m-sec .s-m-cnts .m-c.icn-heat-major.a,
.s-m-sec .s-m-cnts .m-c.icn-dry-major .eve-img-box,
.s-m-sec .s-m-cnts .m-c.icn-dry-major.a,
.s-m-sec .s-m-cnts .m-c.icn-dry-minor.a {
    background: var(--app-s-m-cnts-m-c-heat);
}
.s-m-sec .s-m-cnts .m-c.icn-wet-major  .eve-img-box,
.s-m-sec .s-m-cnts .m-c.icn-wet-minor .eve-img-box,
.s-m-sec .s-m-cnts .m-c.icn-cold-minor .eve-img-box,
.s-m-sec .s-m-cnts .m-c.icn-cold-major .eve-img-box,
.s-m-sec .s-m-cnts .m-c.icn-wet-major.a,
.s-m-sec .s-m-cnts .m-c.icn-wet-minor.a,
.s-m-sec .s-m-cnts .m-c.icn-cold-minor.a,
.s-m-sec .s-m-cnts .m-c.icn-cold-major.a {
    background: var(--app-s-m-cnts-m-c-cold);
}
.s-m-sec .s-m-cnts .m-c.icn-temp-minor-below .eve-img-box,
.s-m-sec .s-m-cnts .m-c.icn-temp-minor-below.a {
    background: var(--app-s-m-cnts-m-c-temp-minor-below);
}
.s-m-sec .s-m-cnts .m-c.icn-heat-minor .eve-img-box, 
.s-m-sec .s-m-cnts .m-c.icn-heat-minor.a ,
.s-m-sec .s-m-cnts .m-c.icn-rsb .eve-img-box, 
.s-m-sec .s-m-cnts .m-c.icn-rsb.a{
    background: var(--app-s-m-cnts-m-c-ping-rate);
    border: 1px solid var(--app-s-m-cnts-m-c-ping-rate);

}
.s-m-sec .s-m-cnts .m-c.icn-ping-rate .eve-img-box, 
.s-m-sec .s-m-cnts .m-c.icn-ping-rate.a {
    background: var(--app-s-m-cnts-m-c-cold);
    border: 1px solid var(--app-s-m-cnts-m-c-cold);
}
.s-m-sec .s-m-cnts .m-c.icn-risk-alarm .eve-img-box, 
.s-m-sec .s-m-cnts .m-c.icn-risk-alarm.a {
    background: var(--app-s-m-cnts-m-c-risk-alarm);
}
.s-m-sec .s-m-cnts .m-c.icn-battery[title='Dry Deviation'] .eve-img-box,
.s-m-sec .s-m-cnts .m-c.icn-battery[title='Dry Deviation'].a,
.s-m-sec .s-m-cnts .m-c.icn-battery.minor .eve-img-box,
.s-m-sec .s-m-cnts .m-c.icn-battery.minor.a{
    background: var(--app-s-m-cnts-m-c-low-battery);
}

.s-m-sec .s-m-cnts .m-c .eve-img {
    max-height: 22px;
    max-width: 22px;
}
.s-m-sec .s-m-cnts .m-c.icn-battery .eve-img,
.s-m-sec .s-m-cnts .m-c.icn-lid .eve-img,
.s-m-sec .s-m-cnts .m-c.icn-shock .eve-img{
    max-width: 14px;
}
.s-m-sec .s-m-cnts .m-c .eve-cnt {
    padding: 1px 8px 2px 6px;
    color: var(--app-secondary-color);
    font-size: 12px;
    font-weight: 600;
}
.s-m-sec .s-m-cnts .m-c.a .eve-cnt {
    color: var(--app-primary-color);
}

.tl-eve-i.s-m-eve {
    display: none;
}
.tl-eve-i.s-m-eve.show-m-info {
    display: block;
}
.tl-eve-i.s-m-eve.show-m-info .eve-d{
    display: none;
}
.tl-eve-i.s-m-eve.view-info .eve-d{
    display: flex;
}
.px-spin {
    height: 40px;
    width: 40px;
    margin: 100px auto;
    -webkit-animation: rotation 0.6s infinite linear;
    -moz-animation: rotation 0.6s infinite linear;
    -o-animation: rotation 0.6s infinite linear;
    animation: rotation 0.6s infinite linear;
    border-left: 6px solid var(--app-px-spin-border);
    border-right: 6px solid var(--app-px-spin-border);
    border-bottom: 6px solid var(--app-px-spin-border);
    border-top: 6px solid var(--app-px-spin-t-border);
    border-radius: 100%;
    display: inline-block;
}

.px-g-spin {
    margin: -5px 15px;
    height: 18px;
    width: 18px;
    border-left: 3px solid var(--app-px-g-spin-border);
    border-right: 3px solid var(--app-px-g-spin-border);
    border-bottom: 3px solid var(--app-px-g-spin-border);
    border-top: 3px solid var(--app-px-g-spin-t-border);
}

@-webkit-keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(359deg);
    }
}

@-moz-keyframes rotation {
    from {
        -moz-transform: rotate(0deg);
    }

    to {
        -moz-transform: rotate(359deg);
    }
}

@-o-keyframes rotation {
    from {
        -o-transform: rotate(0deg);
    }

    to {
        -o-transform: rotate(359deg);
    }
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}

.tl-eve-i .eve-l .icn,
.tl-eve-i .eve-d .icn {
    margin-right: 4px;
    font-size: 22px;
}

.tl-eve-i .eve-l span:not(.icn) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tl-eve-i.view-info .eve-info-i-list {
    display: block;
    position: relative;
}
.tl-eve-i .eve-info-i-list.ext-ui+.eve-d {
    display: none;
}
.tl-eve-i.view-info .eve-info-i-list.ext-ui+.eve-d {
    display: flex;
}

.tot-d-icn {
    background: url('assets/images/delivery-time.png') no-repeat 0 0;
    height: 34px;
    width: 34px;
    background-size: contain;
}

.tot-d-ping {
    background: url('assets/images/ping_rate_black.png') no-repeat 0 0;
    height: 34px;
    width: 34px;
    background-size: contain;
}

.dur-icn {
    background: url('assets/images/duration.png') no-repeat 4px 50%;
    height: 22px;
    width: 22px;
    background-size: 16px 16px;
    display: inline-block;
    font-size: 0 !important;
}

.s-num-icn {
    /* background: url('assets/images/box-ultrathin-outline.png') no-repeat 50% 50%;
    height: 18px;
    width: 18px;
    background-size: 18px 18px;
    display: inline-block;
    font-size: 0 !important; */
}

.carrier-icn {
    height: 18px;
    width: 18px;
    background-size: 22px;
}

.carrier-icn.fedex {
    background: url('assets/images/fedex.png') no-repeat 50% 50%;
    background-size: 22px;
}

.carrier-icn.usps {
    background: url('assets/images/usps.png') no-repeat 50% 50%;
    background-size: 22px;
}

.carrier-icn.ups {
    background: url('assets/images/ups.png') no-repeat 50% 50%;
    background-size: 20px;
}

.eve-l .loc-icn {
    background-position: 4px 50%;
}

/*.s-dev-icn {
    position: relative;
    display: inline-block;
    width: 11px;
    height: 18px;
    border: 1px solid rgb(128, 128, 128);
    top: 4px;
    border-radius: 2px;
}
.s-dev-icn::before {
    content: ' ';
    position: absolute;
    width: 2px;
    height: 2px;
    box-shadow: 0 0 3px var(--app-primary-box-shdw);
    background: #a0a0a0;
    top: 4px;
    left: 5px;
}
.s-dev-icn::after {
    content: 'X';
    position: absolute;
    top: 9px;
    left: 4px;
    font-size: 7px;
}*/
.count-display {
    display: flex;
}

.count-display.c-d {
    margin-right: 8.3%;
}

.count-display.u-d {
    margin-right: 5.8%;
}

.count-display.d-d {
    margin-right: 6%;
}

.count-display.c-d-d {
    margin-right: 4.4%;
}

.icn-container {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    border: 1px solid var(--app-icn-container-border);
    /* border-width: 0.75px; */
    position: relative;
}

.icn-position {
    position: absolute;
    top: 6px;
    left: 6px;
}

.icn-position-2 {
    position: absolute;
    top: 5px;
    left: 9px;
}

.count-info {
    display: flex;
    flex-flow: column;
    margin-left: 8px;
}

.count-txt {
    font-size: 10px;
    font-weight: 400;
    color: var(--app-count-txt);
    text-transform: uppercase;
    white-space: nowrap;
    text-align: left;
}

.count-int {
    font-size: 12px;
    color: var(--app-third-color);
    font-weight: 500;
    letter-spacing: 0.5px;
    white-space: nowrap;
    max-width: 130px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.count-int.f{
    font-size: 18px;
    margin-top: 2px;
    text-align: left;
}
.grp-admin .count-int {
    max-width: 98%;
}

.company-detail-box {
    /* background: var(--app-company-detail-box-bg); */
    padding: 2% 3.5% 1.1% 2.6%;
    display: flex;
    flex-flow: column;
    /* box-shadow: 0 1px 3px var(--app-company-detail-box-box-shdw); */
}

.comp-usr-ls-box{
    background-color: var(--primary-white);
    margin-bottom: 15px;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
}
.comp-usr-ls-box.pd{
    padding: 20px 20px;
}
.comp-usr-ls-box.pd.f-s{
    justify-content: flex-start;
}
.comp-usr-ls-box.pd.w-50{
    width: 50%;
    margin-right: 0;
}
.comp-usr-ls-box.pd .mb-24{
    margin-bottom: 24px;
}
.risk-info .at-w-mr{
    align-items: center;
    width: 40%;
    margin-right: 20px;
}
.p-o-c {
    font-size: 10px;
    font-weight: 400;
    color: var(--app-p-o-c);
    text-transform: uppercase;
}

.company-name {
    font-size: 28px;
    font-weight: 500;
    color: var(--primary-neutral-600);
    letter-spacing: 0.86px;
    white-space: nowrap;
}
.company-name .grey{
    color: var(--grey-200);
}

.company-name.el{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 28px;
}

.company-address {
    font-size: 18px;
    color: var(--app-comapny-name);
    font-weight: 400;
}
.company-address.el{
    white-space: nowrap;
    width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 18px;
}
.company-details{
    display: flex;
    position: 'absolute';
    right: '3.5%';
}

.comp-loc {
    display: flex;
    width: 30%;
    overflow-wrap: anywhere;
    margin-right: 8.3%;
}

.comp-loc.user {
    width: 16.6%;
    margin-right: 5%;
}

.comp-loc.device {
    width: 20.6%;
    margin-right: 6%;
}

.comp-email-phn {
    display: flex;
    flex-flow: column;
    /* font-size: 10px; */
    font-size: 12px;
    color: var(--app-comapny-name);
    font-weight: 400;
    text-align: right;
    width: 24%;
    margin-left: 84px;
    position: absolute;
    right: 3.5%;
}
.company-user-name{
    display: flex;
    justify-content: flex-end;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    margin-top: -8px;
}

.comp-admin-email {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.comp-edit-button {
    color: var(--app-company-edit-btn);
    margin-left: 9%;
    font-size: 10px;
    font-weight: 700;
    display: flex;
    border: none;
    outline: none;
    background: var(--app-company-edit-btn-bg);
    margin-top: 6px;
    cursor: pointer;
    text-transform: uppercase;
}

.edit-template {
    color: var(--app-company-edit-btn);
    font-size: 12px;
    font-weight: 600;
    display: flex;
    border: none;
    outline: none;
    background: none;
    margin-top: 6px;
    cursor: pointer;
    text-transform: uppercase;
}

.comp-edit-button.usr {
    margin-left: 20%;
}

.comp-edit-button.delete {
    margin-left: 10px;
}

.comp-search-box {
    margin: 3.1% 5.2% 0 5.4%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.comp-search-box.usr {
    margin: 7.5% 0px 0 8.4%;
}

.input-with-icn {
    position: relative;
}

.input-with-icn.a-d {
    margin-top: 5%;
}

.input-with-icn.c-d {
    margin: 5% 0px 4% 8%;
}

.input-with-icn #icn {
    position: absolute;
    top: 12px;
    font-size: 14px;
    color: var(--app-input-with-icn);
}

.comp-search-i {
    border: none;
    border-bottom: 1px solid var(--app-comp-search-i-b-bottom);
    outline: none;
    padding-top: 10px;
    padding-left: 18px;
    font-size: 14px;
    letter-spacing: 0.33px;
    color: var(--app-comp-search-i);
    width: 110%;
}

.comp-search-i.usr {
    width: 130%;
}

.comp-search-i.a-d {
    width: 65%;
}

.comp-search-i.c-d {
    width: 26%;
}

.invite-user {
    background: var(--app-invite-user-bg);
    color: var(--app-primary-color);
    font-size: 16px !important;
    font-weight: 500 !important;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 10px 30px !important;
    border-radius: 4px;
    font-family: Montserrat, sans-serif;
}
.invite-user.c {
    background: var(--app-invite-user-c-bg);
}
.invite-user:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.user-list {
    margin: 2% 0% 0 2.5%;
}

.user-list.usr {
    margin: 4.6% 0px 0px 5.8%;
}

.user-item {
    display: flex;
    margin-top: 1.8%;
    margin-right: 5%;
    justify-content: space-between;
    padding-bottom: 2%;
    padding-right: 3.4%;
    border-bottom: 1px solid var(--primary-neutral-200);
    align-items: center;
}

.user-item.usr {
    margin-top: 2%;
}

.user-details {
    display: flex;
    flex-flow: column;
    font-size: 12px;
    font-weight: 400;
    color: var(--app-user-details);
    width: 35%;
}

.user-details .usr-email {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.name-role {
    display: flex;
    align-items: center;
}

.usr-name {
    font-size: 18px;
    color: var(--app-third-color);
    font-weight: 400;
    white-space: nowrap;
    display: block;
    max-width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.usr-role {
    /* margin-left: 5%; */
    padding: 4px 12px;
    font-size: 14px;
    color: var(--secondary-teal);
    /* text-transform: uppercase; */
    border: 1px solid var(--secondary-teal);
    border-radius: 3px;
    font-weight: 500;
    letter-spacing: 0.36px;
    width: 50px;
    text-align: center;
}

.usr-role.s{
    color: var(--secondary-sky);
    border: 1px solid var(--secondary-sky)
}

.user-items-edit{
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 400;
    color: var(--primary-blue-500);
    border-right: 1.5px solid var(--primary-neutral-300);
    padding-right: 24px;
    cursor: pointer;
    width: 25%;
    text-align: right;
}
.user-items-edit.m{
    width: 85%;
}

.role-btns {
    display: flex;
    align-items: center;
    font-size: 12px;
    width: 40%;
    font-weight: 500;
    color: var(--app-user-btns);
}

.grp-admin .user-details {
    width: 44%;
    overflow: hidden;
}
.grp-admin .role-btns {
    width: 16%;
}

.admin-role-btn {
    color: var(--primary-blue-500);
    width: 60%;
    text-align: right;
    /* cursor: pointer; */
    font-size: 10px;
    font-weight: 400;
    border-right: 1.5px solid var(--primary-neutral-300);
    padding-right: 20px
}
/* .admin-role-btn.m{
    width: 20%;
} */
.admin-role-btn .m-ga-lbl {
    display: none;
}
.admin-role-btn span {
    cursor: pointer;
}
.admin-role-btn.d, .admin-role-btn.d span, .user-items-edit.d {
    color: rgba(0,0,0,0.5);
    cursor: not-allowed !important;
}
.m-back-nav-btn {
    width: 30px;
    height: 30px;
    background: var(--app-m-back-nav-btn-bg) url('assets/images/back.svg') no-repeat 50% 50%;
    background-size: 16px 20px;
    z-index: 999; 
    color: var(--app-secondary-color);
    border-radius: 5px;
    text-transform: uppercase;
    border: none;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 10px;
    box-shadow: 0 1px 3px var(--app-m-back-nav-btn-box-shdw);
}

.role-btns .activate {
    width: 10%;
    margin-left: 24px;
    text-align: right;
    cursor: pointer;
    font-size: 10px;
    font-weight: 400;
    text-transform: uppercase;
    color: var(--secondary-teal);
}

.role-btns .deactivate {
    font-size: 10px;
    font-weight: 400;
    margin-left: 24px;
    cursor: pointer;
    width: 10%;
    color: var(--error-color);
    text-transform: uppercase;
}

.role-btns .deactivate.d, .role-btns .deactivate.d:hover {
    cursor: not-allowed;
    color: var(--app-c-btn-box-shdw);
}

.cntxt-overlay {
    background: var(--lynx-overlay-bg);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 72px;
    z-index: 99999;
}

.cntxt-overlay.ex {
    left: 260px;
}

.cntxt-overlay.pos {
    position: absolute;
    top: 70px;
    bottom: 0;
    overflow-y: auto;
}

.cntxt-overlay .left-bar {
    background: var(--app-primary-bg);
    box-shadow: 0px 0 3px var(--app-third-color);
    border-radius: 2px 2px 2px 0;
}

.cntxt-overlay .close-icn {
    display: block;    
    margin: -3% 10% 1% 0px;
    text-align: right;
    font-weight: 600;
    color: var(--primary-neutral-500);
    cursor: pointer;
}

.cntxt-overlay .close-icn.n {
    display: block;    
    margin: 5.5% 10% 1% 0px;
    font-size: 0px;
    /* 
    text-align: right;
    font-weight: 600;
    color: #2b2b2b; */
    cursor: pointer;
    position: relative;
    transform: rotate(45deg);
    width: 16px;
    height: 16px;
    float: right;
}

.cntxt-overlay .close-icn.n::before{
    content: ' ';
    position: absolute;
    height: 16px;
    width: 0px;
    border-left: 2px solid var(--app-contxt-overlay-close-icn);
    left: 50%;
    margin-left: 0px;
}
.cntxt-overlay .close-icn.n::after{
    content: ' ';
    position: absolute;
    height: 0px;
    width: 16px;
    border-top: 2px solid var(--app-contxt-overlay-close-icn);
    top: 50%;
    margin-top: -1px;
    margin-left: -15px;
}

@-webkit-keyframes slideIn {
    0% {
        left: -20%;
    }

    100% {
        left: 0;
    }
}

@-moz-keyframes slideIn {
    0% {
        left: -20%;
    }

    100% {
        left: 0;
    }
}

@-o-keyframes slideIn {
    0% {
        left: -20%;
    }

    100% {
        left: 0;
    }
}

@keyframes slideIn {
    0% {
        left: -20%;
    }

    100% {
        left: 0;
    }
}

@-webkit-keyframes slideOut {
    0% {
        right: -20%;
    }

    100% {
        right: 0;
    }
}

@-moz-keyframes slideOut {
    0% {
        right: -20%;
    }

    100% {
        right: 0;
    }
}

@-o-keyframes slideOut {
    0% {
        right: -20%;
    }

    100% {
        right: 0;
    }
}

@keyframes slideOut {
    0% {
        right: -20%;
    }

    100% {
        right: 0;
    }
}

@-webkit-keyframes slideInR {
    0% {
        top: 0%;
    }

    100% {
        top: 70px;
    }
}

@-moz-keyframes slideInR {
    0% {
        top: 0%;
    }

    100% {
        top: 70px;
    }
}

@-o-keyframes slideInR {
    0% {
        top: 0%;
    }

    100% {
        top: 70px;
    }
}

@keyframes slideInR {
    0% {
        top: 0%;
    }

    100% {
        top: 70px;
    }
}

.a-ship.close-icn {
    float: right;
}

.n-form {
    padding: 44px 32px;
}

.n-hdr {
    font-weight: 600;
    font-size: 21px;
    letter-spacing: 0.4px;
    color: var(--app-fifth-color);
    padding-bottom: 30px;
}

.n-hdr.company {
    font-size: 18px;
    letter-spacing: 0.35px;
    padding-bottom: 18px;
    padding-top: 6px;
}

.n-hdr.device {
    display: flex;
    justify-content: space-between;
}

.n-hdr .edit-icon {
    object-fit: contain;
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    height: 16px;
    width: 18px;
    cursor: pointer;
}

.i-sec {
    margin: 4% 0 6%;
    position: relative;
}
.i-sec.mb{
    margin-top: 24px;
    margin-bottom: 24px;
}

.i-sec.company {
    margin: 6% 0 7%;
}
.i-sec.i-loc, .config .form-sec .i-sec.i-loc {
    margin: 6% 0 7% !important;
    padding: 0;
}

.i-sec label,
.i-sec div.i-fld[value=''] + label {
    position: absolute;
    font-weight: 300;
    font-size: 18px;
    color: var(--app-fifth-color);
    /* padding: 8px 4px; */
    top: 0;
    left: 0;
    transition: all ease-out 250ms;
    width: 100%;
    cursor: text;
}
.ship-det .i-sec input.i-fld[readonly] + label {
    color: var(--app-ship-det-i-fld);
}
.ship-det .i-sec label,
.ship-det .i-sec input.i-fld[value=''] + label {
    top: -4px;
    font-size: 14px;
}
.new-ship.ship-det .i-sec label, 
.new-ship.ship-det .i-sec input.i-fld[value=''] + label {
    top: 0;
    padding: 6px 4px 4px;
}
.i-sec input.i-fld[value=''] + label[for="receiver_contact"],
.i-sec input.i-fld[value=''] + label[for="receiver_contact_number"],
.i-sec input.i-fld[value=''] + label[for="receiver_mobile"] {
    left: 24%;
}
.i-sec .i-fld.contact:focus + label[for="receiver_contact"],
.i-sec .i-fld.contact:focus + label[for="receiver_mobile"],
.i-sec .i-fld.contact:focus + label[for="receiver_contact_number"],
.new-ship.ship-det .i-sec input.i-fld.contact:focus + label {
    left: 0;
    top: -18px;
    font-size: 12px;
}

.i-sec label span {
    font-size: 10px;
}

label.optional::after {
    content: 'optional';
    position: absolute;
    right: 10px;
    font-size: 12px;
}

.i-sec .i-fld {
    border: 1px solid var(--app-thrid-border);
    border-width: 0 0 1px 0;
    font-weight: 400;
    font-size: 16px;
    color: var(--app-fifth-color);
    width: 100%;
    padding: 8px 4px;
    outline: none;
}

.i-sec .wd-at{
    width: 64% !important;
}

.i-carrier {
    height: 32px;
}
/*
.i-sec .i-fld:focus + label,
.i-sec .i-fld[value]:focus + label,*/
.i-sec input.i-fld:not(:placeholder-shown) + label,
.i-sec div.i-fld[value] + label, 
.i-sec div.i-fld + label.t {
    top: -18px;
    font-size: 12px;
}
.i-sec input.i-fld:not(:placeholder-shown) + label[for=add_ship_users] {
    top: -18px;
    font-size: 14px;
}
.i-sec .selected-items + input:not(:placeholder-shown) + label[for=add_ship_users] {
    top: -4px;
    font-size: 14px;
}

.selected-items {
    display: flex;
    justify-content: space-between;
}

.i-sec div.i-fld[value='false'] + label {
    top: 10px;
    font-size: 18px;
}

.new-ship .i-sec div.i-fld + label.b {
    top: 0;
    font-size: 14px;
}

.i-sec .error_msg {
    margin: unset;
    left: 4px;
    right: -20px;
    text-align: left;
    font-size: 10px;
    width: fit-content;
}

.change-carrier-lnk {
    position: absolute;
    font-size: 10px;
    margin-top: 4px;
    color: var(--app-change-carrier-lnk);
    right: 0;
    cursor: pointer;
}

.c-btn {
    background: var(--app-c-btn-bg);
    border-radius: 3px;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.4px;
    color: var(--app-c-btn);
    text-align: center;
    padding: 16px;
    box-shadow: 0 2px 4px var(--app-c-btn-box-shdw);
    cursor: pointer;
    text-transform: uppercase;
    user-select: none;
}

.c-btn.invalid, .c-btn[disabled] {
    background: var(--app-c-btn-invalid-bg);
    cursor: not-allowed;
}

.n-form .c-btn {
    margin-top: 2%;
}

.carrier-auto {
    /* padding: 4px 4px 4px; */
    position: absolute;
    z-index: 999;
    background: var(--app-primary-bg);
    width: 100%;
    box-shadow: 0 1px 4px var(--app-primary-box-shdw);
    border-radius: 3px;
    padding: 0 4px;
}

.carrier-auto .auto-item:first-child {
    margin-top: 34px;
}

.carrier-auto .auto-item:first-child:before {
    content: 'Recommended Carriers';
    display: block;
    font-size: 14px;
    font-weight: 600;
    top: 4px;
    position: absolute;
}

.auto-item:hover:first-child:before {
    background: unset;
}

.auto-item {
    display: flex;
    justify-content: space-between;
    padding: 4px 10px;
    align-items: center;
    border-radius: 3px;
    margin: 4px;
}

.carrier-auto .auto-item {
    cursor: pointer;
}

.carrier-auto .auto-item:hover {
    background: var(--app-carrier-auto-auto-item-bg-hvr);
}

.i-fld .auto-item {
    margin: 0px;
    height: 20px;
    padding: 0px 0;
}

.auto-item .auto-item-lbl {
    font-size: 14px;
    font-weight: 400;
}

.i-fld .auto-item .auto-item-lbl {
    font-weight: 500;
    font-size: 18px;
}

.auto-item .auto-item-logo {
    display: inline-block;
    width: 54px;
    height: 54px;
    background: transparent url('assets/images/usps.png') no-repeat center center;
    background-size: contain;
}

.i-fld .auto-item .auto-item-logo {
    width: 40px;
    height: 40px;
}

.auto-item.usps .auto-item-logo {
    background-image: url('assets/images/usps.png');
}

.auto-item.fedex .auto-item-logo {
    background-image: url('assets/images/fedex.png');
}

.auto-item.ups .auto-item-logo {
    background-image: url('assets/images/ups.png');
}

.plus-link {
    user-select: none;
    border-radius: 4px;
    background: var(--primary-blue-500);
    width: 171px;
    height: 32px;
    text-align: center;
    font-weight: 500;
    color: var(--primary-white);
    cursor: pointer;
    position: fixed;
    left: 103px;
    bottom: 22px;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
}

.plus-link.ex {
    left: 293px;
}

.plus-link div{
    font-weight: 500;
    font-size: 14px;
    margin-left: 10px;
}

.plus-link .material-icons-round {
    font-size: 22px;
}

.plus-link.new-ship {
    z-index: 10000;
}

.right-contxt-bar {
    position: absolute;
    top: 70px;
    right: 0;
    width: 30%;
    bottom: 0;
    border-left: 1px solid var(--app-right-contxt-bar-r-border);
    z-index: 10;
    background: var(--app-primary-bg);
    box-shadow: 0px 0 3px var(--app-third-color);
    border-radius: 2px 0 2px 2px;
    animation: slideOut 100ms cubic-bezier(0, 0, 0, 0.8);
    height: calc(100% - 70px);
}

.i-sec.company .select-role, .select-role {
    border: 1px solid var(--app-thrid-border);
    border-width: 0 0 1px 0;
    font-weight: 300;
    font-size: 18px;
    color: var(--app-fifth-color);
    width: 100%;
    padding: 8px 4px;
    outline: none;
}

.i-sec.company .select-cc, .i-sec .select-cc {
    border: 1px solid var(--app-thrid-border);
    border-width: 0 0 1px 0;
    font-weight: 500;
    font-size: 18px;
    color: var(--app-fifth-color);
    width: 20%;
    padding: 8px 4px;
    outline: none;
}

.i-sec .i-fld.contact {
    width: 76%;
}
.i-sec .i-fld.u-p{
    padding-right: 40px;
}
.i-sec.company .i-fld.first-name {
    width: 44%;
}

.err-msg-comp {
    position: relative;
    top: 30px;
    left: -439px;
    font-size: 12px;
    margin: 0;
    padding: 0;
    color: var(--app-err-msg-comp);
}

.err-msg-comp.device {
    margin-left: 41%;
}

.devices-list-hdr {
    margin: 3.8% 3.7% 0 2.2%;
    padding: 0;
    padding-bottom: 1.4%;
    padding-left: 2.5%;
    display: flex;
    justify-content: space-between;
    font-size: 21px;
    color: var(--app-third-color);
    font-weight: 600;
    text-transform: uppercase;
    border: none;
    border-bottom: 1px solid var(--app-devices-list-hdr-b-bottom);
}

.devices-list-hdr div {
    text-align: left;
    width: 25%;
    cursor: pointer;
    display: flex;
}

.devices-list-hdr div span {
    padding-top: 1%;
    opacity: 0.6;
}
.devices-list-hdr.grp-admin div span {
    margin-top: -6px;
}

.device-item {
    margin: 1.1% 3.7% 0 2.2%;
    padding: 0;
    padding-bottom: 1.5%;
    padding-left: 2.5%;
    font-size: 16px;
    color: var(--app-third-color);
    font-weight: 500;
    display: flex;
    border: none;
    border-bottom: 1px solid var(--app-devices-list-hdr-b-bottom);
    align-items: center;
}

.device-item div {
    text-align: left;
    width: 25%;
}

.device-item .device-status {
    width: 17.6%;
    max-width: 170px;
    margin-right: 6.2%;
    padding: 2px;
    text-align: center;
}

.device-item .device-status .status-lbl {
    font-size: 10px;
    color: var(--app-device-status-lbl);
    text-transform: uppercase;
    border: 1px solid var(--app-device-status-lbl-border);
    border-radius: 3px;
    font-weight: 700;
    letter-spacing: 0.36px;
    padding: 2px;
    text-align: center;
    width: 100px;
}

.device-item .device-status.i {
    /* color: rgb(43, 43, 43);
    border: 1px solid rgba(52, 52, 52, 0.5); */
}
.device-item .device-status.i .status-lbl {
    color: var(--app-device-status-i-lbl);
    border: 1px solid var(--app-device-status-lbl-i-border);
}

.device-item .battery {
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
	max-width: 25%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.device-item .battery img {
    height: 20px;
    width: 26px;
    object-fit: contain;
}

.device-item .more-info {
    text-align: center;
    color: var(--app-device-item-more-info);
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}

.n-form .dev-status {
    font-size: 10px;
    color: var(--app-n-form-device-status);
    text-transform: uppercase;
    border: 1px solid var(--app-n-form-device-status-border);
    border-radius: 3px;
    font-weight: 700;
    letter-spacing: 0.36px;
    width: 45%;
    margin: 5.6% 0px;
    padding: 2px;
    text-align: center;
}

.n-form img {
    object-fit: contain;
    /* transform: rotate(90deg);
    -ms-transform: rotate(90deg); */
    max-height: 20px;
    max-width: 20px;
    margin-left: 5px;
}

.n-form .battery-value {
    font-size: 12px;
    font-weight: 500;
    color: var(--app-third-color);
}

.i-sec.device {
    margin: 8.6% 0px;
}

.i-sec.device .element-hd {
    width: 40%;
    padding-left: 3%;
    font-size: 18px;
    font-weight: 300;
    color: var(--app-i-sec-device-element-hd);
}

.i-sec.device .i-fld {
    width: 60%;
}

.i-sec.device .select-dev-type {
    border: 1px solid var(--app-thrid-border);
    border-width: 0 0 1px 0;
    font-weight: 300;
    font-size: 18px;
    color: var(--app-fifth-color);
    width: 60%;
    padding: 8px 4px;
    outline: none;
}

.comp-delete {
    background-color: var(--app-comp-delete-bg);
    color: var(--app-comp-delete);
    border: none;
    outline: none;
    width: 100%;
    padding: 8px 4px;
    outline: none;
}

.i-sec .i-fld:focus + label,
.i-sec .i-fld:not(:placeholder-shown) + label {
    top: -18px;
    font-size: 12px;
}

.c-btn {
    background: var(--app-c-btn-bg);
    border-radius: 3px;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.4px;
    color: var(--app-c-btn);
    text-align: center;
    padding: 16px;
    box-shadow: 0 2px 4px var(--app-c-btn-box-shdw);
    cursor: pointer;
}

.comp-delete span {
    font-size: 18px;
}

.n-form .c-btn {
    margin-top: 14%;
}

#chartdiv svg[role='group'] {
    height: 240px !important;
}

#chartdiv.data-not-availble > div {
    opacity: 0;
}

#chartdiv.data-not-availble::before {
    content: 'Data Not Available...';
    opacity: 0.5;
    text-align: center;
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--app-chartdiv-data-not-availble-bg-before);
}

.chart-hdr {
    font-size: 14px;
    font-weight: 600;
}

#chartdiv svg[role='group'] g[fill="none"] path {
    cursor: pointer;
}

.chart-i:not(:empty) {
    width: auto;
    height: 44px;
    margin: 0;
    display: flex;
    justify-content: space-between;
    color: var(--app-fourth-color);
    text-align: left;
    margin: 0 20px;
}
.chart-i:empty {
    height: 0;
    margin: 0;
}
.chart-i.s-l {
    height: 24px;
    justify-content: flex-start;
    align-items: flex-end;
}
.chart-i .i-lbl {
    font-size: 8px;
    font-weight: 600;
    text-transform: uppercase;
}

.chart-i .i-val {
    font-size: 12px;
    font-weight: 700;
}
.chart-i.s-l .i-lbl {
    font-size: 10px;
}
.chart-i.s-l .i-lbl {
    width: 240px;
}
.chart-i.s-l .i-lbl.Max, .chart-i.s-l .i-lbl.Min {
    width: 190px;
}

.ex-time::before {
    content: ':';
    font-size: 0;
    padding: 0 4px;
}

.ex-time:first-child::before {
    content: '';
    padding: 0;
}

.ex-time::after {
    font-weight: 700;
    padding-left: 2px;
    font-size: 12px;
}

.ex-time.d::after {
    content: 'days';
}

.ex-time.d.o::after {
    content: 'day';
}

.ex-time.h::after {
    content: 'hours';
}

.ex-time.h.o::after {
    content: 'hour';
}

.ex-time.m::after {
    content: 'mins';
}

.ex-time.m.o::after {
    content: 'min';
}

.ex-time.s::after {
    content: 'seconds';
}

.ex-time.s.o::after {
    content: 'second';
}

.i-radio-grp {
    display: inline-block;
    padding-left: 10px;
}

.i-radio-grp label {
    display: inline-block;
    position: relative;
    cursor: pointer;
    padding: 2px 12px 2px 0;
}

.i-radio-grp label input {
    opacity: 0;
    position: absolute;
    width: 50px;
    height: 30px;
}

/* .i-radio-grp label > span {
    position: relative;
    padding-left: 24px;
}

.i-radio-grp label input + span:before {
    width: 16px;
    height: 16px;
    border: 1px solid rgb(88, 88, 88, 0.8);
    border-radius: 50%;
    position: absolute;
    left: 2px;
    content: '';
    box-shadow: 0 0px 3px #aaa;
}

.i-radio-grp label input + span:after {
    position: absolute;
    content: '';
    width: 10px;
    height: 10px;
    background: rgba(88, 88, 88, 0.6);
    border-radius: 50%;
    left: 6px;
    top: 4px;
}

.i-radio-grp label input:checked + span:before {
    border-color: rgb(87, 179, 217);
    box-shadow: 0 0px 3px var(--app-secondary-box-shdw);
}

.i-radio-grp label input:checked + span:after {
    background: rgb(87, 179, 217);
} */

.i-radio-grp label input + span {
    display: flex;
    justify-content: center;
}
.i-radio-grp label input + span .cir {
    width: 16px;
    height: 16px;
    border: 1px solid var(--app-i-radio-grp-cir-border);
    border-radius: 50%;
    box-shadow: 0 0px 3px var(--app-i-radio-grp-cir-box-shdw);
    display: inline-block;
    margin-right: 4px;
}
.i-radio-grp label input + span .dot {
    width: 10px;
    height: 10px;
    background: var(--app-i-radio-grp-cir-border);
    border-radius: 50%;
    display: inline-block;
    border: 3px solid var(--app-primary-border);
}
.i-radio-grp label input:checked  + span .cir {
    border-color: var(--app-i-radio-grp-checked-cir-border);
    box-shadow: 0 0px 3px var(--app-secondary-box-shdw);
}
.i-radio-grp label input:checked  + span .dot {
    background: var(--app-i-radio-grp-checked-cir-border);
}

.t-a-l {
    text-align: left;
}

.three-d-container {
    height: 260px;
    width: 70%;
    min-width: 300px;
}

.three-d-container .three-d-mesh {
    height: 100%;
    background: url('assets/images/three-d-plane.png') no-repeat center center;
    background-size: 98% 98%;
    display: flex;
    justify-content: center;
    align-items: center;
}
/*3D*/
.scene {
    width: 100px;
    height: 100px;
    perspective: 360px;
}

.cube {
    width: 100%;
    height: 100%;
    position: relative;
    transform-style: preserve-3d;
    transition: all ease-out 100ms;
}

.cube__face {
    position: absolute;
    width: 100px;
    height: 100px;
    border: 2px solid var(--app-cube-face-border);
    background: var(--app-cube-face-bg);
}

.cube__face--front {
    transform: rotateY(0deg) translateZ(50px);
}

.cube__face--right {
    transform: rotateY(90deg) translateZ(50px);
    transform-style: preserve-3d;
}

.cube__face--back {
    transform: rotateY(180deg) translateZ(50px);
}

.cube__face--left {
    transform: rotateY(-90deg) translateZ(50px);
}

.cube__face--top {
    transform: rotateX(90deg) translateZ(50px);
}

.cube__face--bottom {
    transform: rotateX(-90deg) translateZ(50px);
}

.cube__face--right::after {
    background: url('assets/images/qr-code-default.png') no-repeat 50% 50%;
    background-size: contain;
    position: absolute;
    width: 25px;
    height: 50px;
    left: 50%;
    top: 50%;
    content: ' ';
    margin: -30px 0 0 -15px;
    transform: translateZ(-1px) rotate(90deg);
}

.cube__face--right::before {
    background: var(--app-third-bg);
    position: absolute;
    width: 25px;
    height: 50px;
    left: 50%;
    top: 50%;
    content: ' ';
    margin: -30px 0 0 -15px;
    transform: translateZ(0px) rotate(90deg);
    border-radius: 3px;
}

.o-m-data {
    text-align: center;
    text-transform: uppercase;
    width: 30%;
    padding-top: 50px;
}

.o-m-data .o-m-h {
    font-size: 12px;
    font-weight: 700;
    color: var(--app-fourth-color);
}

.o-m-data .o-m-lbl {
    font-size: 9px;
    font-weight: 500;
    color: var(--app-fourth-color);
    padding-top: 24px;
}

.o-m-data .o-m-val {
    font-size: 14px;
    font-weight: 700;
    color: var(--app-fourth-color);
}

.rc-slider-mark {
    display: none;
}

.d-met-data {
    background: var(--app-d-met-data-bg);
    margin: 10px 40px 20px;
    display: flex;
    justify-content: space-around;
}

.d-met-data .d-met-box {
    width: 100px;
    text-align: center;
    display: flex;
    flex-direction: column;
}

.d-met-data .d-met-box .d-met-val {
    font-size: 14px;
    font-weight: 400;
    padding: 10px 0 20px;
}

.d-met-data .d-met-box .d-met-stat {
    flex-grow: 1;
    align-items: center;
    display: flex;
    justify-content: center;
}

.d-met-data .d-met-box .d-met-stat img,
.d-met-data .d-met-box .d-met-stat svg {
    max-width: 50px;
    height: 30px;
}

.d-met-data .d-met-box .d-met-lbl {
    font-size: 12px;
    font-weight: 400;
    color: var(--app-d-met-data-d-met-lbl);
    padding: 10px 0 20px;
}

.user-body-bar {
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--app-primary-border);
}

.user-comp-dtl {
    background-color: var(--app-user-comp-dtl-bg);
    padding: 1.5% 2.5% 0.8% 3.3%;
    display: flex;
    flex-direction: column;
}

.name-poc {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.user-email-phn {
    display: flex;
    flex-flow: column;
    font-size: 12px;
    color: var(--app-user-email-phn);
    font-weight: 400;
    text-align: right;
    width: 43%;
}
.device-super-hdr {
    background-color: var(--app-device-super-hdr-bg);
    display: flex;
}
.left-device-hdr {
    width: 30%;
    border-right: 1px solid var(--app-left-device-hdr-border);
    border-bottom: 1px solid var(--app-left-device-hdr-border);
    box-shadow: 0px 1px 3px var(--app-left-device-hdr-box-shdw);
    z-index: 10;
}
.right-device-hdr {
    padding: 1.5% 0 1% 2%;
    display: flex;
    flex-direction: column;
    width: 70%;
    box-shadow: 0px 1px 3px var(--app-left-device-hdr-box-shdw);
    border-bottom: 1px solid var(--app-left-device-hdr-border);
}
.right-device-hdr .heading {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.86px;
    color: var(--app-right-device-hdr-heading);
}
.right-device-hdr .counts {
    margin-top: 2%;
    display: flex;
}
.search-bar-device {
    display: flex;
    font-size: 12px;
    box-shadow: 0 0px 4px var(--app-primary-box-shdw);
    letter-spacing: 0.33px;
    width: 100%;
}

.select-company {
    margin-top: 5.6%;
    margin-right: 5.6%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.select-company label {
    font-size: 12px;
    letter-spacing: 0.33px;
    font-weight: 400;
    color: var(--app-select-company-label);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 10px;
}
.device-btns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 3.3% 4.3% 4% 0;
}
.device-btns .action-btn {
    background-color: var(--app-device-btns-action-btn-bg);
    border-radius: 3px;
    border: none;
    color: var(--app-device-btns-action-btn);
    font-size: 14px;
    font-weight: 600;
    outline: none;
    text-transform: uppercase;
    margin-left: 3%;
    padding: 10px 24px;
    cursor: pointer;
}
.device-btns .action-btn.add {
    background-color: var(--app-device-btns-action-btn-add-bg);
    color: var(--app-primary-border);
}
.device-btns .trash-icn {
    cursor: pointer;
}
/* .d-i.header {
    display: flex;
    margin-left: 1.8%;
    padding-bottom: 1.4%;
    width: 100%;
    border-bottom: 1px solid black;
}
.d-i.header .hdr-itm {
    display: flex;
    padding-right: 7%;
}
.d-i.header .hdr-itm.status {
    padding-right: 12%;
}
.d-i.header .hdr-itm.battery {
    padding-right: 8%;
}
.d-i.header .hdr-itm .hdr-name {
    font-size: 21px;
    color: var(--app-third-color);
    font-weight: 600;
    text-transform: uppercase;
    white-space: nowrap;
}
.d-i.list-item {
    display: flex;
    width: 100%;
    border-bottom: 1px solid black;
    margin-left: 1.8%;
}
.d-i.list-item .dev-itm {
    width: 25%;
} */
.device-table {
    width: 100%;
    text-align: left;
    border-collapse: collapse;
}

.device-table .check-box {
    width: 4%;
}

.device-table .device-id {
    width: 21%;
}

.device-table .status {
    width: 23%;
}

.device-table .battery {
    width: 20%;
}

.device-table .company {
    width: 17%;
}

.device-table .more-info {
    width: 19%;
}

.device-table th {
    padding: 0;
    padding-bottom: 13px;
    border-bottom: 1px solid var(--app-device-table-th-b-bottom);
}
.device-table .check {
    border-bottom: none;
    padding: 0 !important;
}
.device-table th div {
    display: flex;
    align-items: center;
}
.device-table th div .th-name {
    font-size: 21px;
    font-weight: 600;
    color: var(--app-third-color);
    text-transform: uppercase;
    cursor: pointer;
}

.device-table th div span {
    opacity: 0.6;
}

.device-table td .dev-id {
    font-size: 16px;
    font-weight: 500;
    color: var(--app-third-color);
    text-overflow: ellipsis;
    overflow: hidden;
    width: 90%;
}
.device-table td .dev-status {
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    color: var(--app-n-form-device-status);
    letter-spacing: 0.36px;
    border: 1px solid var(--app-n-form-device-status-border);
    width: 60%;
    text-align: center;
    padding: 4px 0;
    border-radius: 3px;
    max-width: 130px;
}

.device-table td .dev-status.inventory {
    color: var(--app-device-table-dev-status-inventory);
    border: 1px solid var(--app-device-table-dev-status-inventory-border);
}

.device-table td .dev-battery {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: var(--app-third-color);
}
.device-table td .dev-battery img {
    height: 24px;
    width: 20px;
    object-fit: contain;
}
.device-table td .info {
    font-size: 14px;
    font-weight: 500;
    color: var(--app-device-table-td-info);
    text-align: center;
    cursor: pointer;
}
.device-table td .dev-comp {
    font-size: 16px;
    font-weight: 500;
    color: var(--app-third-color);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 144px;
}
.device-table td {
    padding: 1% 0 1.5% 0;
    border-bottom: 1px solid var(--app-device-table-td-b-bottom);
}
.device-table td.check {
    border-bottom: none;
    padding: 0 !important;
}
.comp-list-box {
    max-height: 280px;
    min-height: 160px;
    display: flex;
    flex-flow: column;
    margin-top: 9%;
    box-sizing: border-box;
    /* overflow-y: auto; */
}
.comp-list-box .comp-item {
    display: flex;
    align-items: center;
    padding-bottom: 5%;
}

.comp-list-box .comp-item input[type='checkbox'] {
    display: none;
}
.comp-list-box .comp-item input[type='checkbox'] + label::before {
    width: 16px;
    height: 16px;
    border: 1px solid var(--app-thrid-border);
    box-shadow: 0 1px 3px 0 var(--app-comp-item-input-label-box-shdw-before) inset;
    /* border-radius: 2px; */
    /* background: white; */
    position: relative;
    display: inline-block;
    /* margin-right: 1ex; */
    content: '';
    cursor: pointer;
}
.comp-list-box .comp-item input[type='checkbox']:checked + label::before{
    background: var(--app-comp-item-input-label-checked-bg-before);
}
.comp-list-box .comp-item .dev-comp-name {
    font-size: 16px;
    font-weight: 500;
    color: var(--app-third-color);
    padding-left: 6.6%;
}
.n-hdr.device .bulk-box {
    padding-top: 10px;
    padding-right: 15px;
}
.bulk-box .bulk-upload-btn {
    font-size: 14px;
    font-weight: 600;
    color: var(--app-primary-color);
    background-color: var(--app-bulk-upload-btn);
    cursor: pointer;
    border: none;
    outline: none;
    border-radius: 3px;
    padding: 6px 10px;
    text-transform: uppercase;
}
/* input[type='checkbox'] {
    border: 1px solid #979797;
    height: 18px;
    width: 18px;
    cursor: pointer;
    background-color: var(--app-primary-color);
    border-radius: 0px;
}
input[type='checkbox']:checked {
    background-color: #57b3e8;
} */
.device-table .check  input[type='checkbox']{
    display: none;
}
.device-table .check  input[type='checkbox'] + label::before {
    width: 16px;
    height: 16px;
    border: 1px solid var(--app-thrid-border);
    box-shadow: 0 1px 3px 0 var(--app-comp-item-input-label-box-shdw-before) inset;
    /* border-radius: 2px; */
    /* background: white; */
    position: relative;
    display: inline-block;
    /* margin-right: 1ex; */
    content: '';
    cursor: pointer;
}
.device-table .check  input[type='checkbox']:checked + label::before {
    background: var(--app-comp-item-input-label-checked-bg-before);
}
.comp-edit-button {
    color: var(--app-comp-edit-button);
    margin-left: 1%;
    font-size: 10px;
    font-weight: 700;
    display: flex;
    border: none;
    outline: none;
    margin-top: 6px;
    cursor: pointer;
    text-transform: uppercase;
}
.map-icon.icon-source {
    display: flex;
    justify-content: center;
    align-items: center;
}
.pulsing {
    /* box-shadow: 0 0 0 0 rgba(0, 0, 0, 1); */
	width: 22px;
    transform: scale(1);
    animation: pulse 2s infinite;
    border-radius: 50%;
    cursor: pointer;
}
@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 -1px 0 0 var(--app-anim-pulse-0-box-shdw);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 -1px 0 20px var(--app-anim-pulse-70-box-shdw);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 -1px 0 0 var(--app-anim-pulse-100-box-shdw);
    }
}
button.mapboxgl-popup-close-button {
    font-size: 26px !important;
}
button.mapboxgl-popup-close-button:active,
button.mapboxgl-popup-close-button:focus {
    outline: none;
    background: var(--app-mapboxgl-popup-close-button-bg);
}
.mapboxgl-popup.leg-popup, .mapboxgl-popup.log-popup {
    min-width: 320px !important;
    z-index: 999999;
}

.leg-popup .mapboxgl-popup-tip {
    opacity: 0;
    display: none;
    height: 0;
    border: none;
}

.leg-popup .mapboxgl-popup-content {
    min-width: 280px;
}

.mapboxgl-popup-content .pop-hdr {
    font-size: 9px;
    font-weight: 600;
    color: var(--app-mapboxgl-popup-content-pop-hdr);
    border-bottom: 2px solid var(--app-mapboxgl-popup-content-pop-hdr-b-border);
    margin: 0 1px;
    padding: 8px 12px 5px;
    display: flex;
}
.mapboxgl-popup-content .pop-hdr .pop-icn {
    width: 20px !important;
    height: 18px !important;
    margin-right: 6px;
}

.mapboxgl-popup-content .pop-hdr .pop-icn.departure-pop {
    height: 16px !important;
}

.facility-icn {
    z-index: 2;
    cursor: pointer;
}
.facility-icn.origin-loc.mapboxgl-marker {
    z-index: 3;
}
.fac-icn{
    border-radius: 50%;
    /*box-shadow: 0 0 5px var(--app-secondary-box-shdw);*/
    padding: 0px 0px 0px 0px;
    /*background: var(--app-primary-bg);*/
}
.mapboxgl-marker.eta-c-loc {
	margin-top: 5px;
	z-index: 4;
}    
.facility-bdy {
    display: flex;
}
.mapboxgl-marker.facility-icn {
	margin-top: 4px;
}	

.mapboxgl-marker.facility-icn.active .circle-path {
    fill: var(--app-mapboxgl-marker-active-circle-path);
}

.mapboxgl-popup-content  .pop-l-info {
    padding: 6px 12px;
}

.mapboxgl-popup-content .pop-l-info li {
    display: flex;
    justify-content: space-between;
}

.mapboxgl-popup-content .leg-stat {
    position: absolute;
    color: var(--app-primary-color);
    font-size: 8px;
    font-weight: 700;
    top: 8px;
    right: 18px;
    background: var(--app-mapboxgl-popup-content-leg-stat-bg);
    border-radius: 3px;
    padding: 2px 6px;
    line-height: 10px;
    letter-spacing: 0.29px;
}

.mapboxgl-popup-content .o-d-loc {
    font-size: 14px;
    font-weight: 600;
    color: var(--app-mapboxgl-popup-content-o-d-loc);
}

.mapboxgl-popup-content > li > div {
    max-width: 48%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.mapboxgl-popup-content > li  div:nth-child(2n) {
    text-align: right;
}
.mapboxgl-popup-content .o-d-loc-o {
    font-size: 11px;
    font-weight: 600;
    color: var(--app-secondary-color);
    max-width: 120px;
    min-width: 100px;
    position: relative;    
	line-height: 16px;
}
.mapboxgl-popup-content .o-d-loc-o.static:after,
.mapboxgl-popup-content .o-d-loc-o.stop:after{
    background: none;
}
.mapboxgl-popup-content .o-d-loc-o:after {
    content: ' ';
    width: 6px;
    height: 6px;
    border-radius: 50%;
    position: absolute;
    top: 7px;
    right: -5px;
    background: var(--app-thrid-border);
}
.mapboxgl-popup-content .air + .o-d-loc-path .flight {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    position: absolute;
    background: url(assets/images/aeroplane.svg) center center / contain no-repeat;
    opacity: 0.5;
    transform: rotate(90deg);
    position: absolute;
    margin-top: -7px;
}

.mapboxgl-popup-content .o-d-loc-d {
    text-align: right;
}
.mapboxgl-popup-content .o-d-loc-d {
    font-size: 11px;
    font-weight: 600;
    color: var(--app-secondary-color);
    max-width: 120px;
    min-width: 100px;
    position: relative;
    text-align: right;
}

.mapboxgl-popup-content .o-d-loc-d:before {
    content: ' ';
    width: 6px;
    height: 6px;
    position: absolute;
    top: 7px;
    left: -10px;
    background: var(--app-thrid-border);
}

.mapboxgl-popup-content .o-d-loc-d.air:before {
    right: -12px;
}

.mapboxgl-popup-content .o-d-loc-path {
    height: 2px;
    margin: 9px 4px 0;
    background: var(--app-mapboxgl-popup-content-o-d-loc-path);
    flex: 1;
}
/* .mapboxgl-popup-content .air + .o-d-loc-path {
    margin: 9px 10px 0 10px;
} */

.mapboxgl-popup-content .o-d-loc-full {
    font-size: 9px;
    font-weight: 400;
    color: var(--app-mapboxgl-popup-content-o-d-loc);
    line-height: 12px;
}

.mapboxgl-popup-content .o-d-loc-full.d {
    text-align: right;
}

.mapboxgl-popup-content .o-d-lbl {
    font-size: 8px;
    font-weight: 400;
    color: var(--app-mapboxgl-popup-content-o-d-loc);
    padding-top: 8px;
    line-height: 12px;
}

.mapboxgl-popup-content .o-d-t {
    font-size: 8px;
    font-weight: 600;
    color: var(--app-mapboxgl-popup-content-o-d-loc);
    line-height: 10px;
    padding-bottom: 6px;
}

.mapboxgl-popup-content .pop-l-info {
    flex: 1;
}

.mapboxgl-popup-content .f-t-s {
    width: 60px;
    margin: 10px 0 10px;
    border-left: 2px solid var(--app-mapboxgl-popup-content-f-t-s-l-border);
    font-size: 9px;
    font-weight: 400;
    color: var(--app-mapboxgl-popup-content-o-d-loc);
    padding: 0 10px 0 10px;
    text-align: center;
    display: flex;
    align-content: center;
    line-height: 12px;
    flex-flow: column;
    justify-content: center;
}

.f-t-val {
    font-size: 10px;
    font-weight: 600;
    padding-top: 4px;
}
.overlay {
    background: var(--app-overlay-bg);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    z-index: 99999;
}
.overlay .share-modal {
    background: var(--app-primary-bg);
    border-radius: 2px;
    width: 38%;
    position: absolute;
    top: 20%;
    left: 31%;
    z-index: 9999;
    box-shadow: 0 2px 4px 0 var(--app-overlay-share-modal-box-shdw);
    animation-name: modalopen;
    animation-duration: 1s;
}
.share-modal .share-header {
    display: flex;
    justify-content: space-between;
    padding: 12px 14px 8px 14px;
    border: none;
    border-bottom: 1px solid var(--app-share-modal-share-header-b-bottom);
}
.share-modal .share-header .share-txt {
    color: var(--app-share-modal-share-header-share-txt);
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.33px;
}
.share-modal .share-header .share-close {
    color: var(--app-share-modal-share-header-share-txt);
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}
.share-modal .share-header .share-close:hover {
    font-weight: 700;
}
.share-modal .share-box {
    padding: 20px 14px 0;
}
.share-modal .share-box .share-lbl {
    color: var(--app-secondary-color);
    font-size: 10px;
    font-weight: 500;
    margin-bottom: 2px;
}
.share-modal .share-box .share-body {
    margin-bottom: 20px;
    display: flex;
}
.share-modal .share-body input, .input-pop {
    border: 1px solid var(--app-share-modal-input-pop-border);
    border-radius: 2px 0 0 2px;
    outline: none;
    color: var(--app-share-modal-input-pop);
    font-size: 10px;
    font-weight: 500;
    padding: 4px;
    min-width: 75%;
    flex: 1;
}
.share-modal .share-body button {
    background: var(--app-share-modal-share-body-btn-bg);
    outline: none;
    color: var(--app-primary-color);
    text-transform: uppercase;
    border: none;
    border-radius: 0 2px 2px 0;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0.28px;
    padding: 4px 10px;
    cursor: pointer;
}
.share-modal .share-body button:hover {
    background: var(--app-share-modal-share-body-btn-bg-hvr);
}
.share-modal .share-body button:active {
    box-shadow: 0 1px 3px var(--app-share-modal-share-body-btn-box-shdw-active);
}
.share-modal .share-body button[disabled] {
    opacity: 0.7;
}
.share-modal .share-msg {
    margin-top: -10px;
    margin-bottom: 10px;
    font-size: 10px;
    padding: 0 14px;
}
.share-modal .share-msg.e {
    color: var(--app-share-modal-share-msg-e);
}
.share-modal .share-msg.s {
    color: var(--app-share-modal-share-msg-s);
}

.rc-slider-tooltip {
    z-index: 9999 !important;

}
.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
    border-top-color: var(--app-rc-slider-tooltip-arrow-t-border) !important;
}
.rc-slider-tooltip .rc-slider-tooltip-inner {
    font-size: 10px;
    padding: 8px;
    border-radius: 2px;
    background: var(--app-rc-slider-tooltip-arrow-t-border);
    color: var(--app-third-color);
    box-shadow: unset;
    letter-spacing: 0.65px;
    text-shadow: 0 1px 1px var(--app-primary-color);
    white-space: nowrap;
}

.tl-dd-sec .rc-slider:after,
.tl-dd-sec .rc-slider:before {
    content: unset;
}

.tl-dd-sec .rc-slider-dot {
    opacity: 0;
}

.tl-dd-sec .rc-slider-handle {
    background: var(--app-light-blue-color);
    border: 2px solid var(--app-light-blue-color);
    height: 14px !important;
    width: 14px !important;
}
.tl-dd-sec .rc-slider-rail {
    background: var(--app-rc-slider-rail-bg);
}
.tl-dd-sec .rc-slider-handle:hover,
.tl-dd-sec .rc-slider-handle:active,
.tl-dd-sec .rc-slider-handle-click-focused:focus {
    border-color: var(--app-light-blue-color);
}

.i-sec #eye-icon{
    position: absolute;
    top: 6px;
    right: -35px;
    color: var(--app-eye-icon);
    cursor: pointer;
}
.menu-icn {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url('assets/images/menu.svg') no-repeat 50% 50%;
    background-size: 46px 26px;
}
.card-icn{
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url('assets/images/view_stream.svg') no-repeat 50% 50%;
    background-size: 46px 26px;
}
.export-divs{
    min-width: 50% !important;
}

.facility-icn.loc {}
.facility-icn.loc:hover, .facility-icn.loc.act {
    opacity: 0.9 !important;
}
.loc-btn {
    width: 10px;
    position: absolute;
    top: 4px;
    bottom: 4px;
    border-radius: 2px;
    cursor: pointer;
}

.loc-btn.prev {
    left: -14px;
}
.loc-btn.prev::before{
    content: ' ';
    border: 2px solid var(--app-loc-btn-prev-border1-before);
    width: 5px;
    height: 5px;
    position: absolute;
    border-color: var(--app-loc-btn-prev-border2-before) var(--app-loc-btn-prev-border2-before) transparent transparent;
    transform: rotate(-135deg);
    top: 50%;
    left: 50%;
    margin-top: -4px;
    margin-left: -1px;
}
.loc-btn.nxt {
    right: -12px;
}
.loc-btn.nxt::before{
    content: ' ';
    border: 2px solid var(--app-loc-btn-prev-border1-before);
    width: 5px;
    height: 5px;
    position: absolute;
    border-color: var(--app-loc-btn-prev-border2-before) var(--app-loc-btn-prev-border2-before) transparent transparent;
    transform: rotate(45deg);
    top: 50%;
    left: 50%;
    margin-top: -4px;
    margin-left: -4px;
}
.loc-btn.prev:not(.i):hover::before {
    border-color: var(--app-loc-btn-prev-not-i-hvr-before) var(--app-loc-btn-prev-not-i-hvr-before) transparent transparent;
}
.loc-btn.nxt:not(.i):hover::before {
    border-color: var(--app-loc-btn-prev-not-i-hvr-before) var(--app-loc-btn-prev-not-i-hvr-before) transparent transparent;
}
.loc-btn.i{
    cursor: not-allowed;
}

.loc.mapboxgl-marker.act .fac-icn.nc{
    box-shadow: 0 0 4px var(--app-secondary-box-shdw);
}


.manage-geo {
    position: absolute;
    right: 16px;
    z-index: 9999;
    font-size: 12px;
    font-weight: 400;

    border-radius: 50%;
    background: var(--app-secondary-bg);
    box-shadow: 0 1px 3px var(--app-secondary-box-shdw);
    color:  var(--app-manage-geo);

    padding: 4px 6px;
    cursor: pointer;
}
.manage-geo.n-a, .n-a, .n-a *{
    opacity: 0.6;
    cursor: not-allowed !important;
}
.manage-geo img {
    margin-top: 2px;
    width: 20px;
    opacity: 0.65;
    margin-bottom: -1px;
}
.manage-geo.bck {
    right: 11px;
    border-radius: 4px;
    padding: 6px 10px;
    color: var(--app-primary-color);
    
}
#root .leaflet-control-mapbox-geocoder .leaflet-control-mapbox-geocoder-form > input {
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
}

.manage-geo, .manage-geo.bck {
    top: 26px;
}

.geo-dist-box {
    width: 218px;
    position: absolute;
    right: -29px;
    top: 32px;
}
.leaflet-control-mapbox-geocoder .leaflet-control-mapbox-geocoder-form input.geo-dist-ip, .geo-dist-ip {
    padding: 0 6px;
    font-size: 10px;
    margin-right: 6px;
    width: 150px;
    background: var(--app-geo-dist-ip-bg);
    border: 1px solid var(--app-geo-dist-ip-border);
    border-radius: 3px;
}
.add-geo {
    padding: 6px 4px;
    font-size: 12px;
    font-weight: 400;
    background:var(--app-secondary-bg);
    color: var(--app-primary-color);
    border-radius: 4px;
    padding: 6px 10px;
    cursor: pointer;
    box-shadow: 0 1px 3px var(--app-secondary-box-shdw);
}
.add-geo::after {
    content: 'Range: 100 - 10000 meters';
    position: absolute;
    top: 28px;
    background: var(--app-primary-bg);
    right: 56px;
    color: var(--app-add-geo-after);
    padding: 5px 10px;
    font-size: 8px;
    border: 1px solid var(--app-add-geo-bg-after);
    border-width: 0 1px 1px;
    border-radius: 0 0 2px 2px;
    cursor: text;
}

.geo-close {
    position: absolute;
    top: 16px;
    left: 16px;
    cursor: pointer;
    padding: 4px 8px;
    font-weight: 600;
    border: 2px solid var(--app-geo-close-border) !important;
    font-size: 12px;
    width: 26px;
    height: 26px;
    padding: 0;
    line-height: 26px;
    text-align: center;
}
.ship-completed-success.geo {
    position: absolute;
    top: 10px;
    right: 10px;
    left: 10px;
    border: none;
    background: var(--app-ship-completed-success-geo-bg);
    margin-top: 8px;
    z-index: 9999;
    border-radius: 4px;
    box-shadow: 0 0px 2px var(--app-ship-completed-success-geo-box-shdw);
}


.leaflet-control-container .leaflet-control-mapbox-geocoder.active .leaflet-control-mapbox-geocoder-wrap,
.leaflet-control-container .leaflet-control-mapbox-geocoder .leaflet-control-mapbox-geocoder-results,
.custom-tool-bar .leaflet-control-mapbox-geocoder.active .leaflet-control-mapbox-geocoder-wrap,
.custom-tool-bar .leaflet-control-mapbox-geocoder .leaflet-control-mapbox-geocoder-results {
    width: 360px;
}
.leaflet-control-mapbox-geocoder.leaflet-bar.leaflet-control.active .leaflet-control-mapbox-geocoder-wrap {
    overflow: unset;
}

.leaflet-pane.leaflet-marker-pane .leaflet-editing-icon {
    margin-left: -5px !important;
    margin-top: -5px !important;
    width: 10px !important;
    height: 10px !important;
    border-radius: 50% !important;
    background: var(--app-third-bg) !important;
    border-color: var(--app-primary-border) !important;
    box-shadow: 0 0 3px var(--app-secondary-box-shdw) !important;
}
.leaflet-pane.leaflet-overlay-pane path.leaflet-interactive,
.leaflet-pane.leaflet-marker-pane path.leaflet-interactive {
    fill: transparent;
    stroke: var(--app-light-blue-color);
    stroke-width: 1px;
    stroke-opacity: 0.9;
}

.leaflet-draw-guides .leaflet-draw-guide-dash {
    width: 2px !important;
    height: 2px !important;
    background: var(--app-secondary-bg) !important;
}

.leaflet-popup-content-wrapper {
    box-shadow: 0 1px 3px var(--app-primary-box-shdw) !important;
}

.leaflet-touch .leaflet-draw-section .leaflet-draw-toolbar .leaflet-draw-draw-polygon {
    background-position: -31px -1px;
}

.leaflet-draw-section .leaflet-draw-actions a {
    background: var(--app-leaflet-draw-actions-a);
    font-size: 10px;
}
.leaflet-draw-section .leaflet-draw-actions a:hover {
    background: var(--app-leaflet-draw-actions-a-hvr);
    color: var(--app-primary-color);
}

.leaflet-control-mapbox-geocoder.active .leaflet-control-mapbox-geocoder-results {
    z-index: 9999;
    box-sizing: border-box;
}
.leaflet-control-mapbox-geocoder-results a, .leaflet-control-mapbox-geocoder-results span {
    box-sizing: border-box;
}

.leaflet-right .leaflet-draw.leaflet-control {
    margin-top: 104px;
}
.geo-coder-visible .leaflet-right .leaflet-draw.leaflet-control {
    margin-top: 134px;
}
.select-check {
    display: none;
}

.select-check-label {
    cursor: pointer;
}

.select-check + .select-check-label:before {
    width: 14px;
    height: 14px;
    border: 1px solid var(--app-select-check-label-border-before);
    border-radius: 2px;
    background: var(--app-primary-bg);
    position: relative;
    display: inline-block;
    margin-right: 1ex;
    content: '';
}

.select-check:checked + .select-check-label:before {
    background: var(--app-light-blue-color);
}

.select-check + .select-check-label.no-before:before {
    opacity: 0;
    position: absolute;
}
.radio-group {
    font-size: 12px;
    font-weight: 600;
    display: flex;
    border: 1px solid var(--app-thrid-border);
    background: var(--app-radio-group-bg);
}
.radio-group .select-check-label {
    padding: 4px 10px 5px;
    display: block;
    background: var(--app-radio-group-bg);
}
.radio-group .select-check:checked + .select-check-label{
    background: var(--app-view-bg);
    color: var(--app-primary-color);
    box-shadow: 0 2px 3px var(--app-radio-group-select-check-checked);
}
.radio-group > div {
    border-right: 1px solid var(--app-thrid-border);
}
.radio-group > div:last-child {
    border: none;
}

.select-check-device {
    display: none;
}

.select-check-device-label {
    cursor: pointer;
}

.select-check-device + .select-check-device-label:before {
    width: 16px;
    height: 16px;
    border: 1px solid var(--app-select-check-device-label-border-before);
    box-shadow: 0 1px 3px 0 var(--app-select-check-device-label-box-shdw-before);
    border-radius: 2px;
    background: var(--app-primary-bg);
    position: relative;
    display: inline-block;
    margin-right: 1ex;
    content: '';
}

.select-check-device:checked + .select-check-device-label:before {
    background: var(--app-light-blue-color);
}
.select-check-device.disabled + .select-check-device-label:before{
    cursor:not-allowed;
    background-color: var(--app-select-check-device-disabled-bg);
}


.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--app-slider-bg);
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: '';
    height: 18px;
    width: 18px;
    left: 3px;
    top: 3px;
    bottom: 4px;
    background-color: var(--app-primary-bg);
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: var(--app-input-checked-slider);
}

input:focus + .slider {
    box-shadow: 0 0 1px var(--app-input-checked-slider);
}

input:checked + .slider:before {
    -webkit-transform: translateX(21px);
    -ms-transform: translateX(21px);
    transform: translateX(21px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
.company-status-0 {
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 0.36px;
    color: var(--app-comapny-status-0);
    border: 1px solid var(--app-company-status-0-border);
    border-radius: 3px;
    padding: 2px 10px 3px;
    margin-left: 10px;
}

.company-status-1 {
    color: var(--app-comapny-status-1);
    border: 1px solid var(--app-company-status-1-border);
    margin-left: 10px;
}

.group-selected-companies{
    /* margin-top: 14px; */
    max-height: 148px;
    overflow-y: auto;
}
.group-selected-companies.no-scroll {
    max-height: none;
}
.group-selected-companies-li{
    display: inline-block;
    margin-right: 10px;
    background-color: var(--primary-neutral-200);
    color: var(--primary-neutral-400);
    border: 1px solid var(--primary-neutral-200);
    font-size: 14px;
    font-weight: 400;
    padding: 5px 6px;
    border-radius: 4px;
    justify-content: center;
    max-width: fit-content;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 10px;
    cursor: pointer;
}
.group-selected-companies-li.c-e{
    color: var(--error-color);
    border: 1px solid var(--error-color);
    background-color: var(--primary-white);
}
.alerts .group-selected-companies-li {
    margin-top: unset;
    margin-bottom: 10px;
}
.group-selected-companies-li.d {
    cursor: not-allowed;
}
.group-selected-companies-li > span{
    margin: 0px 0px 0px 6px;
    cursor: pointer;
    color: var(--app-group-selected-companies-li-span);
    font-weight: 700;
}

.group-selected-companies-li > span:hover{
    color: var(--app-group-selected-companies-li-span-hvr);
}

/* .group-select-delete-all{
    margin-top: 10px;
    font-size: 12px;
    cursor: pointer;
    position: absolute;
    right: 20px;
    z-index: 99;
}

.group-select-delete-all:hover{
    color: var(--app-secondary-color);
} */
.group-select-delete-all{
    font-size: 14px;
    font-weight: 600;
    color: var(--primary-blue-500);
    padding: 8px 10px;
    border: 1px solid var(--primary-blue-500);
    cursor: pointer;
    border-radius: 4px;
}
.leaflet-control-mapbox-geocoder.leaflet-bar.leaflet-control {
    margin-top: 64px;
}
.leaflet-control-mapbox-geocoder.leaflet-bar.leaflet-control.active + .leaflet-draw.leaflet-control{
    margin-top: 40px;
}
.geo-fence-name-h {
    padding: 14px 10px 0;
    font-size: 11px;
    font-weight: 400;
    color:var(--app-secondary-color);
}
.geo-fence-name-txt {
    padding: 0px 10px 10px;
    font-size: 12px;
    color:var(--app-secondary-color);
    font-weight:600;
}


/*Multiple stops*/
.i-sec.sec-hdr {
    font-size: 14px;
    font-weight: 600;
    color: var(--app-secondary-color);
    margin: 10px 32px 14px;
}
.v-scroll {
    margin: 0 -32px;
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    top: 56px;
    bottom: 64px;
}
.c-ship .v-scroll {
    bottom: 71px;
}
.ship-src-dest.vert{
    padding: 0 32px;
    box-sizing: border-box;
}
.l2.t-t-u {
    text-transform: uppercase;
}

.ship-src-dest.vert .ship-path {
    border-top-width: 0px;
    border-left: 1px dashed var(--app-ship-src-dest-ship-path-l-border);
    margin-left: 10px;
    position: relative;
    width: 100%;
    display: unset;
}

.i-sec.fld {
    margin: 4% 0 24px;
}

.i-sec.fld .i-sec-cols-2 {
    display: flex;
    align-items: center;
}
.i-sec.fld .i-sec-cols-2  .i-sec-col-1{
    flex-grow: 1;
}
.i-sec.fld .i-sec-cols-2 .i-sec-col-2 {
    width: 60px;
    justify-content: flex-end;
    display: flex;
}

.ship-src-dest.vert .i-address {
    margin-top: 16px;
}

.ship-src-dest.vert .i-address:first-child {
    margin-top: 0px;
}

.ship-src-dest.vert .i-address .ship-src, 
.ship-src-dest.vert .i-address .ship-dest{
    position: absolute;
    background: var(--app-primary-bg);
    margin-left: -11px;
    width: 20px;
    height: 20px;
}
.ship-src-dest.vert .i-address.dest {
    cursor: not-allowed;
    position: relative;
    padding-top: 16px;
}
.ship-src-dest.vert .i-address.editable {
    cursor: pointer;
}
.ship-src-dest.vert .i-address.editable .i-fld-c {
    margin: 10px 20px 0 0;
}
.ship-src-dest.vert .i-address.stp.editable .done-icn {
    overflow: visible;
    top: 48px;
}
.ship-src-dest.vert .i-address.editable .done-icn {
    top: 30px;
}

.w-brdr {
    position: absolute;
    top: 36px;
    bottom: -40px;
    left: -3px;
    border-left: 4px solid var(--app-primary-border);
}
.config .w-brdr {
    bottom: -30px;
}
.ship-src-dest.vert  .i-address .i-loc{
    max-width: 100%;
    box-sizing: border-box;
    padding-left: 20px;
    position: relative;
    top: -3px;
}

.ship-src-dest.vert .i-address .i-loc .l3 {
    white-space: unset;
    margin-right: 16px;
}

.ship-src-dest.vert  .i-address .ship-dest + .i-loc {
    
}
.n-form.ship-det {
    
}

.a-a-f {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 4px 0 10px 20px;
    padding: 4px 0 10px 4px;
    width: 334px;
}
.a-a-f.show {
    background: var(--app-a-a-f-show-bg);
    box-shadow: 0 1px 2px var(--app-a-a-f-show-box-shdw);
}
.a-a-f .a-a-l {
    font-size: 12px !important;
    font-weight: 500;
    position: static;
    width: auto;
}
.a-a-f .a-a-t {
    font-size: 12px !important;
    font-weight: 700 !important;
    width: 180px !important;
    display: flex;
    align-items: center;
    padding: 2px 4px !important;
    cursor: pointer;
}
.a-a-f .a-a-t.i-fld.n, .a-a-f .a-a-t.i-fld.n * {
    cursor: not-allowed;
    opacity: 0.7;
}
.a-a-f .a-a-t .material-icons-outlined {
    font-size: 16px;
    margin-left: 20px;
}
.a-a-f  .c-a-a-t {
    margin-left: 4px;
    font-size: 14px;
    font-weight: 600;
}
.a-a-f  .c-a-a-t:hover {
    text-shadow: 0 1px 2px var(--app-c-a-a-t-hvr);
}
.cntxt-overlay .n .close-icn.n.s-m {
    visibility: hidden;
}
.n-form.ship-det .i-sec .i-fld, .ship-det .i-sec .i-fld {
    font-size: 16px;
    padding: 6px 4px 4px;
    box-sizing: border-box; 
}

.n-form.ship-det .i-sec.fld {
    margin: 2% 32px 4%;
}
.ship-det .n-hdr {
    margin-top: -24px;
    padding-bottom: 10px;
}

.i-address.stp {
    position: relative;
    cursor: pointer;
}
.i-address.stp.n-e {
    cursor: not-allowed;
}
.r-o .i-address.stp {
    cursor: auto;
}

.ship-det .i-sec .i-fld-c {
    position: relative;
}
.stop-box {
    margin-left: -7px;
    width: 11px;
    height: 11px;
    position: absolute;
    background: var(--app-stop-box-bg);
    border: 1px solid var(--app-stop-box-border);
}
.stop-box.n {
    background: var(--app-stop-box-n-bg);
    margin-top: 12px;
}
.stop-box.r-t::before {
    content: '';
    background: url(assets/images/replay.svg) no-repeat;
    background-size: 37px 37px;
    width: 37px;
    height: 37px;
    top: -13px;
    left: -13px;
    position: absolute;
    transform: rotate(90deg);
}

.ship-src-dest.vert .i-sec .i-fld-c {
    margin: 10px 20px 20px;
}

.ship-src-dest.vert .i-sec .i-fld {
    border-width: 0px 0px 1px;
    font-size: 16px;    
    box-sizing: border-box;
}

.i-sec.stat {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--app-i-sec-stat-b-bottom);
    padding-bottom: 10px;
}

.a-s-btn {
    display: flex;
    align-items: center;
    width: 96px;
    margin: 30px 0;
}
.as-icn {
    font-size: 20px;
    display: flex;
    border: 1px dashed var(--app-as-icn-border);
    margin: 0 10px 0 -12px;
    border-radius: 50%;
    background: var(--app-as-icn-bg);
    width: 20px;
    height: 20px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.a-s-btn:hover .as-icn {
    color: var(--app-secondary-color);
    border-color: var(--app-secondary-border);
}
.as-lbl { 
    font-size: 14px;
    padding: 0 10px 0 0;
    cursor: pointer;
}
.a-s-btn:hover .as-lbl {
    color: var(--app-secondary-color);   
    text-shadow: 0 0px 2px var(--app-a-s-btn-hover-as-lbl-txt-shdw);
}

.a-s-btn.d .as-icn, 
.a-s-btn.d:hover .as-icn, 
.a-s-btn.d .as-lbl,
.a-s-btn.d:hover .as-lbl {
    opacity: 0.6;
    cursor: not-allowed;
    color: unset;
    border-color: unset;
    text-shadow: unset;
}

.del-icn {
    position: absolute;
    height: 14px;
    width: 14px;
    transform: rotate(45deg);
    right: 3px;
    top: 16px;
    margin-top: -9px;
    cursor: pointer;
    z-index: 999;
}

.i-sec .del-icn {
    top: 0;
    margin-top: 0;
}
.del-icn::before {
    content: ' ';
    position: absolute;
    height: 14px;
    width: 0px;
    border-left: 2px solid var(--app-del-icn-l-border);
    left: 50%;
    margin-left: -1px;
}
.del-icn::after {
    content: ' ';
    position: absolute;
    height: 0px;
    width: 14px;
    border-top: 2px solid var(--app-del-icn-l-border);
    top: 50%;
    margin-top: -1px;
}

.del-icn:hover::before, .del-icn:hover::after,
.done-icn:hover::before, .done-icn:hover::after {
    border-color: var(--app-del-icn-l-border-hvr);
}


.done-icn {
    overflow: visible !important;
    position: absolute;
    height: 14px;
    width: 14px;
    transform: rotate(45deg);
    right: 3px;
    top: 30px;
    cursor: pointer;
    z-index: 999;
}
.done-icn::before {
    content: ' ';
    position: absolute;
    height: 14px;
    width: 0px;
    border-left: 2px solid var(--app-done-icn-l-border);
    left: 50%;
    margin-top: -6px;
}
.done-icn::after {
    content: ' ';
    position: absolute;
    height: 0px;
    width: 8px;
    border-top: 2px solid var(--app-done-icn-l-border);
    top: 50%;
    margin-top: -1px;
}

.stop-box.r-t + .done-icn {
    top: 12px;
}

.n-form-btm {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.n-form-btm {
    padding: 0 20px 10px;
}
.n-form-btm .c-btn {
    margin-top: 2px;
}
.c-ship .n-form-btm {
    padding: 10px 20px;
    border-top: 2px solid var(--app-n-form-btm-t-border);
}
.c-btn.not-allow {
    cursor: not-allowed;
    opacity: 0.25;
}

.n-form-btm .msg-s {
    font-weight: 400;
    font-size: 12px;
    color: var(--app-share-modal-share-msg-s);
    background: var(--app-primary-bg);
    padding: 10px 0;
}
.n-form-btm .msg-e {
    font-weight: 400;
    font-size: 12px;
    color: var(--app-share-modal-share-msg-e);
    background: var(--app-primary-bg);
    padding: 10px 0;
}

/*stops reference in path*/
.ship-src-dest .ship-path {
    display: flex;
    justify-content: space-around;
}
.ship-path .stop {
    position: relative;
    height: 1px;
    margin-top: -1px;
    flex-grow: 1;
}
.ship-path .stop:not(.r-t)::before {
    content: ' ';
    width: 10px;
    height: 10px;
    background: var(--app-ship-path-stop-bg);
    position: absolute;
    margin-top: -5px;
    right: 0;
}
.ship-path .stop.r-t::after {
    content: ' ';
    width: 28px;
    height: 28px;
    background: url(assets/images/replay.svg) no-repeat;
    background-size: 28px 28px;
    position: absolute;
    top: -14px;
    right: -9px;
}
.ship-path .stop.l::before {
    content: unset;
    width: 0;
    height: 0;
    border: none;
}
.ship-path .stop.c {
    background: var(--app-ship-path-stop-bg);
}

.loc-s-list {
    margin: 0px 20px 0;
    border: 1px solid var(--app-loc-s-list-border);
    box-shadow: 0 1px 2px var(--app-loc-s-list-box-shdw);
    position: relative;
    background: var(--app-primary-bg);
}
.loc-s-list.d {
    margin: 0;
    background: var(--app-primary-bg);
    z-index: 99999;
    position: relative;
}
.config .loc-s-list {
    position: absolute;
    background: var(--app-primary-bg);
    z-index: 99999;
    margin: 0;
}
.config .loc-s-list.d {
    margin-top: unset;
}

.select-ip {
    padding: 4px 10px;
    border-width: 1px;
    border-radius: 3px;
    outline: none;
}

.loc-s-list li {
    padding: 10px 8px;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.loc-s-list li:hover {
    background: var(--app-loc-s-list-li-hover);
    color: var(--app-secondary-color);    
}
.i-sec.fld .i-sec-cols-2 {
    display: flex;
    align-items: center;
}
.i-sec.fld .i-sec-cols-2  .i-sec-col-1{
    flex-grow: 1;
}
.i-sec.fld .i-sec-cols-2 .i-sec-col-2 {
    width: 60px;
    justify-content: flex-end;
    display: flex;
}
.ship-src-dest.vert .i-address.editable .i-fld-c.e{
    margin-top: 0px;
    /* position: absolute; */
}
.ship-src-dest.vert .i-fld-c.e{
    color: var(--app-share-modal-share-msg-e);
    font-size: 10px;
    margin-top: -20px;
}
.i-sec-col-1 .i-fld-c.e {
    color: var(--app-share-modal-share-msg-e);
    font-size: 12px;
}
.ship-src-dest.vert .i-address.editable .i-fld-c.e.d-d-e, 
.ship-src-dest.vert .i-fld-c.e.d-d-e {
    position: absolute;
    margin: 40px 0 0 14px;
}
.ship-src-dest.vert .i-fld-c.e::before,
.i-sec-col-1 .i-fld-c.e::before {
    content: '*';
}

.ship-details-sec .error_msg.a, .ship-det .error_msg.a {
    position: absolute;
    top: unset;
    left: unset;
    right: unset;
    bottom: unset;
}
.ship-details-sec .error_msg, .ship-det .error_msg{
    text-align: left;
    position: static;
    font-weight: normal;
    font-size: 10px;
    margin: 0;
}

.no-data-found {
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 98%;
    padding: 2% 0;
    box-sizing: border-box;
}
.cf-hdr{
    font-size: 16px;
    font-weight: 600;
    display: flex;
    justify-content: flex-end;
    margin-right: 14%;
    margin-bottom: 20px;
}

/* CWL Users */
.user-mgmt-body-bar {
    position: absolute;
    top: 70px;
    left: 72px;
    right: 0;
    bottom: 0;
    background-color: var(--primary-blue-500-2-per);
}
.user-mgmt-body-bar.ex{
    left: 260px;
}
.loading.blue::after {
    background: var(--primary-blue-500);
}
.df {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.exAlignItemsEnd {
    align-items: end !important;
}

.f-s {
    justify-content: flex-start;
}
.df-a-c {
    display: flex;
    align-items: center;
}
.user-mgmt-comp-dtl {
    /* padding: 1.5% 2.5% 0.8% 3.3%; */
    padding: 15px 32px;
    align-items: center;
}
.cwl-p-o-c-hdr {
    font-size: 14px;
    font-weight: 500;
    color: var(--primary-neutral-600);
    text-transform: uppercase;
    letter-spacing: 1px;
}
.comp-mgmt-search-box {
    background-color: var(--app-user-comp-dtl-bg);
    padding: 1.1% 2.2% ;
    padding: 10px 32px;
    align-items: center;
}
.cwl-poc{
    margin-top: 16px;
}
.cwl-poc div{
    font-size: 14px;
    font-weight: 400;
}
.cwl-poc div:first-child{
    font-weight: 500;
}
.cwl-poc div{
    margin-right: 28px;
}
.company-det{
    align-items: center;
    margin-right: 90px;
}
.cwl-c-name{
    font-size: 24px;
    font-weight: 500;
    margin-right: 24px;
}
.cwl-c-edit{
    font-size: 14px;
    font-weight: 600;
    padding: 9px 35px;
    color: var(--primary-blue-500);
    border: 1px solid var(--primary-blue-500);
    background-color: var(--primary-white);
    border-radius: 4px;
    cursor: pointer;
}
.cwl-c-loc{
    margin-top: 8px;
    justify-content: flex-start;
    align-items: center;
}
.cwl-c-loc img{
    margin-right: 8px;
}
.cwl-c-loc span{
    font-size: 12px;
    font-weight: 500;
}
.cwl-c-widgets div:first-child{
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 4px;
}
.cwl-c-widgets div:last-child{
    border: 1px solid var(--primary-white);
    padding: 11px 99px 11px 4px;
    font-size: 16px;
    font-weight: 400;
    border-radius: 4px;
}
.cwl-txt-i {
    /* padding: 13px; */
    padding: 9px 22px;
    outline: none;
    border: 1px solid var(--primary-neutral-300);
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
    color: var(--primary-neutral-500);
    margin-right: 8px;
}
.cwl-u-search div{
    font-size: 16px;
    font-weight: 500;
    /* padding: 8px 48px; */
    padding: 6px 22px;
    border-radius: 4px;
    color: var(--primary-white);
    background-color: var(--primary-blue-500);
    align-items: center;
}
.users-hdr{
    align-items: center;
}
.users-hdr div:first-child{
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 500;
    margin-right: 24px;
}
.users-hdr div:last-child{
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    color: var(--primary-white);
    background-color: var(--primary-blue-500);
    border-radius: 4px;
    /* padding: 9px 29px; */
    padding: 9px 22px;
    cursor: pointer;
}
.cwl-u-list{
    width: 80%;
    margin: 25px auto;
    color: var(--primary-neutral-600);
}
.cwl-u-list-header{
    margin-bottom: 20px;
    margin-right: 15px;
    padding: 0px 20px;
}
.cwl-u-list-header div{
    font-size: 14px;
    font-weight: 600;
}
.cwl-u-row{
    background-color: var(--primary-white);
    height: 74px;
    box-shadow: 0px 4px 10px rgba(110, 102, 102, 0.2);
    padding: 0px 20px;
}
.cwl-u-name{
    align-items: center;
}
.cwl-u-short{
    text-transform: uppercase;
    padding: 10px 8px 8px 8px;
    font-size: 14px;
    font-weight: 400;
    border-radius: 4px;
    color: var(--primary-white);
    background-color: var(--secondary-sky);
    margin-right: 17px;
}
.cwl-user-n{
    font-weight: 600;
    font-size: 18px;
    /* font-size: 17px; */
    line-height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.cwl-u-row{
    margin-bottom: 15px;
    margin-right: 15px;
}
.cwl-u-row .u-itm{
    font-weight: 400;
    font-size: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.cwl-u-role{
    width: 85px;
    font-weight: 600;
    font-size: 18px;
    padding: 5px 0px;
    color: var(--secondary-sky);
    border: 1px solid var(--secondary-sky);
    border-radius: 4px;
    text-align: center;
}
.cwl-u-role.a{
    color: var(--secondary-teal);
    border: 1px solid var(--secondary-teal);
}
.u-edit-btn{
    font-weight: 600;
    font-size: 14px;
    padding: 7px 9px;
    color: var(--primary-white);
    background-color: var(--primary-blue-500);
    border-radius: 4px;
    cursor: pointer;
    width: 75px;
    text-align: center;
}
.u-actn-btns div.sa{
    pointer-events: none;
}
.u-edit-btn.d {
    color: var(--primary-neutral-500);
    background: var(--primary-blue-200);
    cursor: not-allowed;
    pointer-events: unset !important;
}
.a-d-btn{
    font-weight: 500;
    font-size: 14px;
    color: var(--user-deactivate);
    padding: 6px 8px;
    background: var(--primary-white);
    border: 1px solid var(--error-color);
    border-radius: 4px;
    cursor: pointer;
    width: 65px;
    text-align: center;
}
.a-d-btn.a{
    color: var(--primary-blue-500);
    border: 1px solid var(--primary-blue-500);
}
.a-d-btn.d {
    opacity: 0.75;
    cursor: not-allowed;
    pointer-events: unset !important;
}
.cwl-dt-popup{
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 44px 44px 20px 44px;
    width: 48%;
    z-index: 10;
    background: var(--primary-white);
    box-shadow: 0px 8px 12px rgba(9, 30, 66, 0.15), 0px 0px 1px rgba(9, 30, 66, 0.31);
    border-radius: 2px 0 2px 2px;
    animation: slideOut 100ms cubic-bezier(0, 0, 0, 0.8);
    min-width: 680px;
    transform: translate(-50%, -50%);
    color: var(--primary-neutral-600);
    box-sizing: border-box;
    height: 80%;
}
.cwl-dt-hdr{
    font-size: 24px;
    font-weight: 400;
}
.cntxt-overlay .cwl-close-icn {
    display: block;
    text-align: right;
    font-weight: 600;
    color: var(--primary-neutral-500);
    cursor: pointer;
}
.cwl-dt-edit{
    margin-top: 5px;
    font-size: 14px;
    font-weight: 400;
}
.u-det-in{
    padding-top: 25px;
    padding-bottom: 100px;
}
.ip-fld.mb{
    margin-bottom: 24px;
}
.ip-fld.mb .lbl{
    margin-bottom: 6px;
}
.ip-fld .ip.wdth{
    width: 250px;
}
.ip-fld .ip.wdth-2{
    width: 520px;
}
.ass-txt{
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: var(--primary-neutral-400);
    margin-top: .8%;
}
.ass-txt span{
    color: var(--primary-blue-500);
    cursor: pointer;
    text-decoration: underline;
}
.ip-fld .ip.wdth-3{
    width: 324px;
}
.ip-fld .ip.wdth-4{
    width: 320px;
}
.ip-fld .ip.mr{
    margin-right: 20px;
}
.ip-fld .lbl.mb{
    margin-bottom: 0.5%;
}
.ip-fld.sep-m{
    margin-bottom: 24px;
}
.u-det-in .ip-fld {
    margin-bottom: 20px;
}
.ip-fld .optl, .optl{
    color: var(--primary-neutral-300);
}
.sel-box.i-fld.p-dc.wl.u {
    width: 80px;
    box-sizing: border-box;
    padding: 10px 16px;
    font-size: 16px;
    color: var(--primary-neutral-500);
    height: 43px;
    background: var(--primary-white);
    border: 1px solid var(--primary-neutral-300);
    border-radius: 4px;
    outline: none;
}
.ip-fld .w-160 {
    width: 160px !important;
}
.sel-box.i-fld.p-dc.r-wl {
    width: 250px;
    box-sizing: border-box;
    padding: 10px 16px;
    font-size: 16px;
    color: var(--primary-neutral-500);
    height: 43px;
    background: var(--primary-white);
    border: 1px solid var(--primary-neutral-300);
    border-radius: 4px;
    outline: none;
}
.sel-box.i-fld.p-dc.disabled {
    /* pointer-events: none; */
    background-color: var(--primary-neutral-200);
    border: 1px solid var(--primary-neutral-200);
    color: var(--primary-neutral-400);
}
.sel-box.i-fld.p-dc.r-wl.w-320 {
    width: 320px;
}
.sel-box.i-fld .sel-box-pop.top.role {
    width: 245px;
}
.sel-box.i-fld .sel-box-pop.top.temp {
    width: 316px;
}
.seprator{
    border-top: 4px solid var(--primary-neutral-100);
}

.seprator-1{
    border-top: 1px solid var(--primary-neutral-200);
    margin-bottom: 24px;
}

.submit-btn{
    font-weight: 500;
    font-size: 17px;
    padding: 12px 22px;
    color: var(--primary-white);
    background-color: var(--primary-blue-500);
    border-radius: 4px;
    cursor: pointer;
    width: fit-content;
}
.u-action-btns{
    height: 100%;
    align-items: center;
    display: flex;
    /* justify-content: space-around; */
}
.cwl-dt-pop-bdy {
    position: absolute;
    top: 80px;
    left: 44px;
    right: 44px;
    bottom: 70px;
}
.cwl-dt-pop-ftr {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    border-top: 4px solid var(--primary-neutral-100);
    height: 66px;
}
.c-edit-sub{
    margin-top: 25px;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 5px;
}
.sort-icn {
    width: 16px;
    height: 16px;
    cursor: pointer;
}
.sort-df{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
}
.fltr-sec{
    width: 80%;
    margin: 25px auto;
    color: var(--primary-neutral-600);
    display: flex;
    justify-content: flex-end;
}
.cwl-fltr-box{
    display: flex;
    align-items: center;
    height: 48px;
    width: fit-content;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--primary-white);
    margin-right: 15px;
}
.cwl-fltr-label{
    font-size: 12px;
    text-transform: uppercase;
    color: var(--primary-neutral-300);
    font-weight: 600;
    margin-left: 14px;
    margin-right: 10px;
}
.clw-fltr-r{
    font-size: 14px;
    font-weight: 600;
    color: var(--primary-neutral-600);
    padding-left: 10px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    border-right: 2px solid var(--primary-neutral-300);
    cursor: pointer;
}
.clw-fltr-r.rb{
    border: none;
}
.down-arrow {
    position: relative;
    left: 8px;
    top: 1.5px;
}
.sel-box.i-fld.p-dc.fc {
    display: flex;
    align-items: center;
}
.sel-box.i-fld.p-dc.f-w {
    width: 100px;
    box-sizing: border-box;
    /* padding: 10px 16px; */
    color: var(--primary-neutral-500);
    height: 23px;
    background: var(--primary-white);
    border: none;
    outline: none;
}
.sel-box.f-w.i-fld .sel-value {
    font-size: 14px;
    font-weight: 600;
    padding-top: 5px !important;
}
.sel-box.f-w.i-fld .sel-value::after {
    top: 6px;
}
.sel-box.f-w.i-fld .sel-box-pop.top {
    top: 36px;
    left: -3px;
    width: 100px;
    margin-top: 5px;
}

.shipment-list-box .f-lbl{
    font-size: 24px;
    font-weight: 600;
    margin: 30px 0px 20px 20px;
}
.more-optns{
    cursor: pointer;
    color: var(--more-options);
    position: relative;
    top: 5px;
    margin-left: 10px;
}
.u-icn{
    padding: 5px;
    position: relative;
    top: -9px;
}
.edit-dlt-optns{
    height: fit-content;
    width: fit-content;
    position: absolute;
    background-color: var(--primary-white);
    z-index: 999;
    border-radius: 3px;
    box-shadow: 0px 4px 10px rgba(110, 102, 102, 0.2);
    top: 38px;
    left: -95px;
    padding-top: 3px;
    padding-bottom: 3px;
}
.edit-dlt-optns.single{
    width: 121px;
}
.edit-dlt-optns div{
    padding: 10px 20px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
}
.edit-dlt-optns div:hover{
    background-color: var(--primary-blue-500);
}
.edit-dlt-optns div span{
    font-weight: 400;
    padding-top: 4px;
    padding-left: 10px;
    font-size: 16px;
}
.edit-dlt-optns div:first-child span{
    color: var(--primary-blue-500) !important;
}
.edit-dlt-optns div:last-child span{
    color: var(--error-color);
}
.more-options-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
}
.edit-dlt-optns div:hover span{
    color: var(--primary-white) !important;
}
.edit-dlt-optns div:hover .mo-edit{
    fill: var(--primary-white);
}

.actn-row{
    padding: 24px;
    display: flex;
    justify-content: space-between;
}
.actn-row.feature{
    justify-content: flex-start;
}
.actn-row.p{
    padding: 10px 0px 15px 0px;
}
.usrs-features{
    display: flex;
    align-items: center;
}
.usrs-features div{
    width: fit-content;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    cursor: pointer;
    text-align: center;
    width: 100px;
}
.usrs-features .a{
    color: var(--primary-blue-500);
    border-bottom: 1px solid var(--primary-blue-500);
    font-weight: 600;
}
.usrs-features div:first-child{
    margin-right: 10px;
}
.sel-icn{
    height: 14px;
    margin-right: 10px;
}
.no-user-ui{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: 40px;
}
.no-user-ui.t {
    transform: translate(0, 50%);
}
.no-user-ui.m-bottom-10{
    margin-bottom: 10px;
}
.no-user-ui.m-top-140{
    margin-top: 140px;
}
.no-user-ui img{
    margin-bottom: 40px;
    width: 10rem;
}
.no-user-ui .no-ui-hdr{
    font-size: 28px;
    font-weight: 500;
    color: var(--base);
    margin-bottom: 30px;
    text-align: center;
}
.no-user-ui .no-ui-hdr.l-bar{
    font-size: 24px;
}
.no-user-ui .no-ui-hdr.l-bar-na{
    font-size: 20px;
}
.no-user-ui .no-ui-txt{
    font-size: 14px;
}
.no-user-ui .no-ui-txt.l-bar{
    font-size: 14px;
    text-align: center;
    padding: 0px 5px 0px 5px;
}
.no-user-ui .no-ui-txt.mt{
    margin-top: 20px;
}

/* Alert Message*/
.cwl-alert-box {
    position: absolute;
    bottom: 25px;
    right: 25px;
    z-index: 9999999999;
}

.alrt-msg-contr {
    position: fixed;
    z-index: 9999999999;
    bottom: 25px;
    right: 25px;
}
.alrt-msg-contr .col {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.alrt-msg-contr .cwl-alert-box {
    position: static;
}

.cwl-notify-item {
    display: flex;
    align-items: flex-start;
    padding: 16px;
    border-radius: 4px;
    background: var(--primary-white);
    margin-top: 12px;
    border-left: 4px solid var(--success-color);
    box-shadow: 0px 0px 10px 0px rgba(51, 51, 51, 0.5);
    animation: notifyBox 0.25s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}
.cwl-notify-item.f-s {
    align-items: flex-start;
}
.cwl-notify-item.warning {
    border-left: 4px solid var(--warning-color);
}

.cwl-notify-item.danger {
    border-left: 4px solid var(--error-color);
}

.cwl-notify-item.info {
    border-left: 4px solid var(--info-color);
}

.cwl-notify-item .n-icn,
.cwl-alert-box .c-icn {
    font-size: 22px;
    color: var(--primary-neutral-500);
    margin: 0 4px 0 0;
}

.cwl-notify-item img.n-icn {
    /* width: 22px; */
    margin-right: 15px;
}

.cwl-notify-item .c-icn {
    font-size: 18px;
    margin-left: 15px;
    color: var(--primary-neutral-500);
    font-weight: 600;
    opacity: 0.75;
    cursor: pointer;
}

.cwl-notify-item .c-icn:hover {
    opacity: 1;
}

.cwl-notify-item .n-msg {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: var(--primary-neutral-500);
}
.cwl-notify-item .n-msg span{
    font-weight: 600;
    font-family: 'Gibson-600', sans-serif;
    margin-bottom: 8px;
}
.cwl-notify-item .n-msg .c-txt{
    font-weight: 500;
    font-family: 'Gibson-500', sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: var(--primary-blue-500);
    margin-top: 8px;
    text-align: right;
    cursor: pointer;
}
.pdf-config-hdr{
    display: flex;
    align-items: center;
}
.pdf-config-hdr span{
    font-size: 14px;
    font-weight: 500;
    color: var(--primary-neutral-400);
    text-transform: uppercase;
}
.btn.pc{
    border: 1px solid var(--primary-blue-500);
    color: var(--primary-blue-500);
    font-size: 16px;
    font-weight: 500;
    margin-right: 24px;
}
.btn.ps{
    background-color: var(--primary-blue-500);
    color: var(--primary-white);
    font-size: 16px;
    font-weight: 500;
}
.sel-box.i-fld.p-dc.wl.ap{
    width: 250px;
}

.sel-box.i-fld .sel-box-pop.top-ap {
    top: 42px;
    left: -3px;
    margin-top: 5px;
}
.comp-rmv{
    text-transform: uppercase;
    margin-left: 10px;
    margin-top: 3px;
    color: var(--error-color);
    font-size: 10px;
    font-weight: 400;
}
.comp-rmv.d{
    color: var(--primary-neutral-300);
}
.t-tip .r-pos{
    position: relative;
    top: 2px;
    left: 5px;
}

/* Progress Bar */
.p-bar {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-right: 12px;
}

.p-bar svg {
    position: relative;
    width: 40px;
    height: 40px;
    transform: rotate(-85deg);
    background: transparent;
}
.p-bar svg circle {
    width: 100%;
    height: 100%;
    fill: none;
    stroke-width: 4;
    stroke-linecap: round;
    transform: translate(2px, 2px);
}
.p-bar svg circle:nth-child(2) {
    stroke-dasharray: 110px;
    stroke-dashoffset: 110px;
}
.p-bar .p-sco {
    position: absolute;
    font-size: 14px !important;
    font-weight: 600 !important;
    color: var(--primary-neutral-500);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.p-bar svg circle:nth-child(1) {stroke: var(--primary-neutral-200);}
.p-bar svg circle:nth-child(1) {stroke: var(--primary-neutral-200);}
.p-bar.l svg circle:nth-child(1) {stroke: var(--success-color-bg);}
.p-bar.l svg circle:nth-child(2) {stroke: var(--success-color);}
.p-bar.m svg circle:nth-child(1) {stroke: var(--warning-color-bg);}
.p-bar.m svg circle:nth-child(2) {stroke: var(--warning-color);}
.p-bar.h svg circle:nth-child(1) {stroke: var(--error-bg-color);}
.p-bar.h svg circle:nth-child(2) {stroke: var(--error-color); }

/* New Header CSS */

.redesign-nav-bar-1{
    width: 72px;
    bottom: 0;
    left: 0;
    position: absolute;
    height: 100%;
    background-color: var(--primary-neutral-500);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.redesign-nav-bar-1-z-index-max {
    z-index: 9999999;
}

.redesign-nav-bar-1-z-index-0 {
    z-index: 0;
}


.redesign-nav-bar-1.ex{
    width: 260px;
    align-items: flex-start;
}

.redesign-nav-bar-p1{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.redesign-nav-bar-p1.ex{
    align-items: flex-start;
    width: -webkit-fill-available;
}

.redesign-nav-logo {
    cursor: pointer;
    padding: 8px 10px;
    border-bottom: 1px solid var(--primary-neutral-400);
}

.redesign-nav-logo.ex {
    padding-bottom: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.redesign-navbar-hdr{
    display: none;
    margin-left: 16px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    color: var(--primary-white);
    text-transform: uppercase;
}

.redesign-navbar-hdr.ex{
    display: block;
}

.redesign-nav-links{
    padding: 8px 0;
    width: -webkit-fill-available;
}

.redesign-nav-links ul li{
    padding: 16px 15px;
    cursor: pointer;
    border-left: 4px solid transparent;
}

.redesign-nav-links ul li.ex{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 14px 73px 14px 10px;
    /* border-radius: 4px; */
    white-space: nowrap;
}

.redesign-nav-links ul li:last-child.ex{
    padding: 14px 42px 14px 10px;
}

.redesign-nav-links ul .settings-brdr{
    border-top: 1px solid var(--primary-neutral-400);
}

.redesign-nav-links ul li:hover .nav-icon, .redesign-nav-links ul li:hover  .redesign-navlist-itm, 
.redesign-nav-links ul li.a .nav-icon, .redesign-nav-links ul li.a .redesign-navlist-itm,
.redesign-nav-links ul li .expanded-nbr-sub-itms .a,
.redesign-nav-links ul.child-nodes li .a {
    color: var(--primary-blue-200);
    fill: var(--primary-blue-200);
}

.redesign-nav-links ul li.a {
    border-left: 4px solid var(--primary-blue-200);
}

.redesign-nav-links ul li.a .dwn-arr{
    border-color: var(--primary-blue-200);
}

.redesign-nav-links ul li:hover .dwn-arr{
    border-color: var(--primary-blue-200);
}

.redesign-nav-links ul li:hover {
    background-color: var(--primary-neutral-400);
}

.nav-arr{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.dwn-arr{
    position: relative;
    top: -2px;
    left: 6px;
    border-width: 7px 5px 0 5px;
    z-index: 0;
    width: 5px;
    height: 5px;
    border-top: 3px solid var(--primary-neutral-300);
    border-right: 3px solid var(--primary-neutral-300);
    border-radius: 2px;
    transform: rotate(135deg);
}

.redesign-nav-links ul li.disp-childs .dwn-arr.ex{
    transform: rotate(-45deg);
    top: 2px;
}

.redesign-nav-bar-p2{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin: 8px 10px;
    border-radius: 4px;
    cursor: pointer;
    background-color: var(--primary-neutral-400);
}

.redesign-nav-bar-p2 .icon{
    fill: var(--primary-neutral-300);
    stroke: var(--primary-neutral-300);
}

.redesign-nav-bar-p2:hover .icon{
    fill:  var(--primary-blue-200);
    stroke: var(--primary-blue-200);
}

.redesign-nav-bar-p2:hover{
    background-color: var(--primary-neutral-300);
}

.redesign-nav-bar-p2:hover .right-arr{
    border-color: var(--primary-neutral-500);
}

.redesign-nav-bar-p2:active{
    background-color: var(--primary-neutral-200);
}

.redesign-nav-bar-p2:active .right-arr{
    border-color: var(--primary-neutral-500);
}

.redesign-nav-bar-p2:disabled{
    background-color: var(--primary-neutral-500);
}

.redesign-nav-bar-p2:disabled .right-arr{
    border-color: var(--primary-neutral-400);
}

.redesign-nav-bar-p2:focus{
    border: 4px solid var(--data-viz-orange);
}

.redesign-nav-bar-p2.ex{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.right-arr{
    border-width: 7px 5px 0 5px;
    z-index: 0;
    width: 6px;
    height: 6px;
    border-top: 3px solid var(--primary-neutral-300);
    border-right: 3px solid var(--primary-neutral-300);
    border-radius: 2px;
    transform: rotate(45deg);
}

.right-arr.ex{
    transform: rotate(-135deg);
}

.right-arr.ftr-path{
    border-top: 3px solid var(--primary-neutral-400);
    border-right: 3px solid var(--primary-neutral-400);
    margin-left: 10px;
    margin-right: 10px;
}

.redesign-nav-links ul.child-nodes li{
    padding: 0;
    color: var(--primary-white);
    background-color: var(--primary-neutral-500);
    box-shadow: 0px 0px 10px 0px rgba(51, 51, 51, 0.50);
    border-bottom: 1px solid var(--primary-neutral-400);
}

.redesign-nav-links .has-child-nodes.db{
    display: block;
}

.redesign-nav-links ul.child-nodes li:last-child{
    border-bottom: unset;
}

.redesign-nav-links ul.child-nodes.performance{
    width: 190px;
}

.redesign-nav-links ul.child-nodes li a{
    color: var(--primary-white);
    padding: 8px 16px;
    display: block;
}

.redesign-nav-links ul.child-nodes.settings{
    width: 240px;
}

.redesign-nav-links ul.child-nodes.settings.sa{
    width: 295px;
}

.redesign-nav-links ul.child-nodes{
    top: 0;
    left: 66px;
    background-color: var(--primary-neutral-500);
}

.redesign-nav-links ul.child-nodes.ex{
    display: none;
}

.redesign-nav-links ul.child-nodes > li:hover:not(.u-det){
    background-color: unset;
}

.redesign-nav-links ul.child-nodes > li:hover:not(.u-det) a{
    color: var(--primary-blue-200);
    background-color: unset;
}

.redesign-navlist-itm {
    display: none;
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    margin-left: 16px;
    color: var(--primary-white);
}

.redesign-navlist-itm.ex{
    display: block;
}

.carrier-lynx-logo{
    display: none;
    padding: 20px;
}

.carrier-lynx-logo.ex{
    display: block;
}

.redesign-nav-bar-p3{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.expanded-nbr-sub-itms{
    display: none;
    margin-top: 28px;
}

.redesign-nav-links ul li.disp-childs .expanded-nbr-sub-itms{
    display: block;
}

.expanded-nbr-sub-itms div{
    font-size: 14px;
    line-height: 16px;
    color: var(--primary-white);
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 16px;
    margin-left: 40px;
    /* white-space: nowrap; */
}

.expanded-nbr-sub-itms div:last-child{
    margin-bottom: unset;
}

.expanded-nbr-sub-itms div:hover{
    color: var(--primary-blue-200);
}

/* .redesign-nav-links ul li:last-child.ex {
    padding: 14px 20px 14px 10px;
} */

.redesign-nav-bar-2 {
    height: 70px;
    background: var(--lynx-primarary-bg);
    position: absolute;
    top: 0;
    right: 0;
    left: 72px;
    display: flex;
    align-items: center;
    padding-left: 1.5%;
    padding-right: 1.5%;
    box-sizing: border-box;
}

.redesign-nav-bar-2-Z-index-0 {
    z-index: 0;
}


.redesign-nav-bar-2-Z-index-max {
    z-index: 99999;
}




.redesign-nav-bar-2.ex{
    width: calc(100% - 260px);
    left: 260px;
}

.hdr-ftr-usr-name {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    width: 100%;
}

.hdr-ftr-usr-name .ftr-name{
    font-size: 16px;
    line-height: 24px;
    color: var(--primary-neutral-400);
}

.hdr-ftr-usr-name .ftr-name a, .hdr-ftr-usr-name .ftr-name span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.lane_name_with_route {
   max-width: 370px
}


.hdr-ftr-usr-name .ftr-name > a,
.hdr-ftr-usr-name .ftr-name > a:visited,
.hdr-ftr-usr-name .ftr-name > a:active{
    color: var(--primary-neutral-400);
}

.hdr-ftr-usr-name .ftr-name span:last-child{
    font-weight: 600;
    color: var(--primary-neutral-500);
    width: 350px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 10px;
}

.hi-usr-name{
    color: var(--primary-neutral-300);
    white-space: nowrap;
    width:  220px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: right;

}

.hi-usr-name span{
    color: var(--primary-neutral-400);
}

.redesign-user-name > span {
    background: var(--data-viz-plum);
    color: var(--primary-white);
    border-radius: 4px;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    cursor: pointer;
    font-weight: 400;
    border: 2px solid var(--primary-white);
    margin-left: 10px;
    padding: 10px 8px;
    width: fit-content;
}

.usr-profile{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.gp-mt-15{
    margin-top: 15px;
}

.old-ui-bx-m{
    margin: 14px 18px 0px 18px;
}

.old-ui-bx-m::after{
    content: '';
}

/* Pagination */

.pagination {
    position: absolute;
    height: 70px;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--lynx-primarary-bg);
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
    z-index: 99999;
    padding: 0 24px;
}
.up-row-3.scrl-wthn .pagination{
    left: 0px;
    right: 0px;
    width: 97%;
}

.itms-per-page{
    display: flex;
    align-items: center;
}

.itms-per-page-txt{
    margin-right: 10px;
    font-size: 14px;
    line-height: 16px;
    color: var(--primary-neutral-400);
}

.paginatn-pages{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 34%;
}

.paginatn-pages .prev-btn, .paginatn-pages .nxt-btn{
    display: flex;
    align-items: center;
    cursor: pointer;
}

.paginatn-pages .nxt-btn{
    margin-left: 10px;
}

.paginatn-pages .prev-btn{
    margin-right: 10px;
}
.paginatn-pages .nxt-btn.disable, .paginatn-pages .prev-btn.disable {
    cursor: not-allowed;
}
.paginatn-pages .prev-btn.disable .prev-txt, .paginatn-pages .nxt-btn.disable .nxt-txt,
.paginatn-pages .prev-btn.disable .bk-arr-color, .paginatn-pages .nxt-btn.disable .bk-arr-color{
    color: var(--primary-neutral-300);
    fill: var(--primary-neutral-300);
}

.paginatn-pages .prev-btn.active .prev-txt, .paginatn-pages .nxt-btn.active .nxt-txt,
.paginatn-pages .prev-btn.active .bk-arr-color, .paginatn-pages .nxt-btn.active .bk-arr-color{
    color: var(--primary-blue-500);
    fill: var(--primary-blue-500);
}

.paginatn-pages .paginatn-num{
    cursor: pointer;
    padding: 4px 12px;
    border-radius: 4px;
}

.paginatn-pages .paginatn-num:hover{
    background-color: var(--primary-neutral-200);
}

.paginatn-pages .paginatn-num.sel{
    color: var(--primary-blue-500);
    background-color: var(--primary-blue-hover);
}

.paginatn-pages .prev-txt{
    margin-left: 10px;
}

.paginatn-pages .nxt-txt{
    margin-right: 10px;
}

.paginatn-pages .nxt-btn svg{
    transform: rotate(-180deg);
}

.paginatn-pages div {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: var(--primary-neutral-400);
}

/* Terms Page Css */

.pgs-main-bg{
    padding: 110px 0 110px 72px; 
    display: flex; 
    justify-content: center; 
    background-color: var(--lynx-primarary-bg);
}
.pgs-page-bdy{
    height: calc(100% - 80px); 
    width: 58%; 
    display: flex; 
    flex-direction: column; 
    padding: 40px; 
    background-color: var(--primary-white); 
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
}
.pgs-main-hdr{
    margin-bottom: 32px; 
    font-size: 40px; 
    line-height: 48px;
    font-weight: 600; 
    padding-bottom: 10px; 
    border-bottom: 1px solid var(--primary-blue-hover);
}
.pgs-desc{
    font-size: 16px; 
    line-height: 24px;
    font-weight: 400; 
    margin-bottom: 32px;
}
.pgs-mb{
    margin-bottom: 32px;
}
.pgs-mb-unset{
    margin-bottom: unset !important;
}
.pgs-mb .pgs-ls-crl{
    margin: 16px 0;
}
.pgs-mb .pgs-ls-crl li{
    list-style-type: circle;
    margin-left: 40px;
    font-size: 16px;
    line-height: 24px;
}
.pgs-pt-hdr{
    font-size: 24px; 
    line-height: 32px;
    font-weight: 600; 
    margin-bottom: 20px;
}
.pgs-pt-ctnt{
    font-size: 16px; 
    line-height: 24px;
    font-weight: 400;
}
.pgs-pt-ls{
    font-size: 16px; 
    line-height: 24px;
    font-weight: 400; 
    margin-bottom: 4px;
    list-style: unset;
}
.pgs-pt-ex-ctnt{
    font-size: 16px; 
    line-height: 24px;
    font-weight: 400; 
    margin-bottom: 15px;
}
.pgs-lnl-clr{
    color: var(--primary-blue-500);
}

.pgs-pt-ex-ctnt-1{
    font-size: 16px; 
    line-height: 24px;
    font-weight: 400;
}
.pgs-600{
    font-weight: 600;
}

/* Management Profile*/
.dtls-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 8px;
    border-bottom: 1px solid var(--primary-blue-hover);
    align-items: flex-end;
}
.dtls-box > div:first-child {
    max-width: 54%;
}
.dtls-box > div:last-child {
    max-width: 44%;
}
.dtls-box.admin{
    padding-bottom: unset;
    border-bottom: unset;
}

.dtls-hdr-menu{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.dtls-c-name{
    font-size: 40px;
    line-height: 48px;
    font-weight: 600;
    font-family: 'Gibson-600', sans-serif;
    color: var(--primary-neutral-600);
    margin-bottom: 4px;
    margin-right: 8px;
    white-space: nowrap;
    display: flex;
    max-width: 100%;
}
.dtls-c-name .o-el {
    padding-left: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
}
.dtls-c-name.gp{
    margin-bottom: 0px;
}

.dtls-more-icon{
    display: flex;
    align-items: center;
    border-radius: 6px;
    background: var(--primary-white);
    cursor: pointer;
    border: 4px solid var(--primary-white);
}

.dlts-more-opts{
    display: none;
    border-radius: 4px;
    border: 1px solid var(--primary-blue-hover);
    box-shadow: 0px 0px 10px 0px #33333380;
    position: absolute;
    top: 76px;
    margin-left: -2px;
    z-index: 99;
}

.dlts-more-o-itms {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    color: var(--primary-neutral-500);
    background: var(--primary-white);
    border: 1px solid var(--primary-blue-hover);
    border-width: 0 1px 1px 1px;
}
.dlts-more-o-itms:first-child {
    border-radius: 4px 4px 0 0;
}
.dlts-more-o-itms:last-child {
    border-radius: 0 0 4px 4px;
    border-top-width: 1px;
}
.dlts-more-o-itms:hover{
    background-color: var(--primary-blue-hover);
}

.dlts-more-o-itms:hover span.m-itm,
.dlts-more-o-itms:hover .edit-color {
    color: var(--primary-blue-500) !important;
    fill: var(--primary-blue-500);
}

.dlts-more-o-itms span.m-itm{
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: var(--primary-neutral-500) !important;
    margin-left: 10px;
}

.dlts-more-o-itms .cross-color{
    fill: var(--primary-neutral-500);
}
.dlts-more-o-itms:hover .cross-color{
    fill: var(--primary-blue-500);
}

.dtls-more-icon.shw .dlts-more-opts{
    display: block;
}

.dtls-more-icon.shw {
    background: var(--primary-blue-200);
}

.dtls-more-icon:hover span, .dtls-more-icon.shw span {
    color: var(--primary-blue-500);
}

.dtls-more-icon span.material-icons{
    font-size: 28px;
}

.dtls-c-name::before{
    content: 'Company: ';
}

.dtls-c-name.gp::before{
    content: 'Group: ';
}

.dtls-c-name.gp.admin::before{
    content: '';
}

.dtls-c-add{
    font-size: 14px;
    line-height: 16px;
    color: var(--primary-neutral-600);
}

.dtls-c-poc{
    font-size: 12px;
    line-height: 16px;
    color: var(--primary-neutral-600);
    text-align: right;
    display: flex;
    justify-content: flex-end;
}

.dtls-c-poc.mb{
    margin-bottom: 4px;
}

.dtls-c-poc span.c-name{
    font-size: 14px;
    font-weight: 600;
    font-family: 'Gibson-600', sans-serif;
    max-width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.dtls-c-poc span.c-part {
    padding-right: 8px;
    margin-right: 8px;
    border-right: 1px solid var(--primary-blue-200);
    max-width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 60%;
}

.mgmt-ct-srch{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
}

.mgmt-ct-srch.admin{
    margin-top: 20px;
}

.mgmt-ct-srch .srch-btn{
    display: flex;
    align-items: center;
}

.mgmt-fs{
    display: flex;
}

.mgmt-fs .mgmt-lbl:first-child{
    margin-right: 24px;
}

.mgmt-lbl{
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    font-family: 'Gibson-600', sans-serif;
    color: var(--primary-neutral-500);
    cursor: pointer;
}

.mgmt-lbl.admin{
    padding-bottom: 2px;
    border-bottom: 4px solid var(--primary-blue-500);
}

.redesign-srch.mgmt{
    width: 100%;
    min-width: 375px;
    margin-right: 16px;
}

.riq-col-0.mgmt{
    width: 15%;
}

.riq-col-1.mgmt{
    width: 23%;
}

.riq-col-2.mgmt{
    width: 14%;
}

.riq-col-2.cmpy-mgmt{
    width: 19%;
}

.riq-col-3.mgmt{
    width: 15%;
    flex-shrink: 0;
}

.riq-col-3.cmpy-mgmt{
    width: 10%;
}

.riq-col-3.grp-mgmt{
    width: 14%;
}

.riq-col-4.mgmt{
    width: 15%;
}

.riq-col-5.mgmt{
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 40px;
}

.riq-col-5.grp-mgmt{
    width: 37%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 40px;
    
    flex-grow: 1;
}

.riq-col-5.mgmt div:first-child{
    margin-right: 15px;
}

.riq-col-4.mgmt{
   display: flex;
   align-items: center;
   justify-content: flex-start;
}

.redesign-list-hdr.mgmt{
    padding-left: unset;
    padding-right: unset;
    border-bottom: 1px solid var(--primary-blue-hover);
}

.redesign-list-hdr.admin{
    padding-left: 16px;
    padding-right: 16px;
    border-bottom: unset;
}

.redesign-list-itm.mgmt{
    padding-left: unset;
    padding-right: unset;
    box-shadow: unset;
    border-bottom: 1px solid var(--primary-blue-hover);
}

.redesign-list-itm.admin{
    padding-left: 16px;
    padding-right: 16px;
    box-shadow: 0px 0px 10px 0px #00000014;
    /* border-bottom: unset; */
    border: 1px solid transparent;
    min-height: 60px;
}

.redesign-list-itm .t-txt {
    display: none;
    position: absolute;
    background: var(--app-third-color);
    color: var(--c-tooltip-txt-color);
    font-size: 14px;
    border-radius: 4px;
    padding: 10px;
    top: 60px;
    left: 15px;
	z-index: 9999999;
    white-space: normal;
    min-width: 240px;
    box-shadow: 0 1px 3px var(--c-tooltip-txt-box-shdw);
    line-height: 16px;
    font-weight: 400;
    cursor: auto;
    text-transform: none;
}
.redesign-list-itm .t-txt:before {
    content: ' ';
    border: 5px solid var(--c-tooltip-txt-bg);
    border-color: transparent transparent var(--app-third-color) transparent;
    left: 8px;
    position: absolute;
    top: -5px;
    transform: translateY(-50%);
}
.redesign-list-itm .check-box:hover .t-txt {
    display: block;
}

.redesign-nav-links .t-tip .redesign-navlist-itm.t-txt {
    color: var(--c-tooltip-txt-color);
    min-width: auto;
    white-space: nowrap;
    font-weight: 400;
    left: 56px;
    letter-spacing: 0.75px;
}

.redesign-nav-links .t-tip:hover .t-txt {
    display: block;
}
.redesign-nav-links ul li.disp-childs.t-tip .t-txt {
    display: none;
}

.row-c-name {
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    font-family: 'Gibson-500', sans-serif;
    color: var(--primary-neutral-600);
    word-break: break-all;
    padding-right: 20px;
}
.row-c-name span.c-admin-u-n {}
.row-c-name span.c-admin-u-n::after {
    content: ',';
    margin-right: 6px;
}
.row-c-name span.c-admin-u-n:last-child::after {
    content: '';
    margin: 0;
}

.row-c-name.cmpy,
.row-c-name.cmpy a:active,
.row-c-name.cmpy a:target,
.row-c-name.cmpy a:focus,
.row-c-name.cmpy a:visited {
    color: var(--primary-blue-500);
    cursor: pointer;
}

.row-c-name.cmpy-add{
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    font-family: 'Gibson', sans-serif;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: unset;
}

.row-c-status{
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    font-family: 'Gibson-600', sans-serif;
    color: var(--success-color);
    background: var(--success-color-bg);
    padding: 8px 16px;
    border-radius: 4px;
    width: fit-content;
    text-transform: uppercase;
}

.row-c-status.in{ 
    color: var(--user-deactivate);
    background: var(--error-bg-color);
}

.edit-txt{
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: var(--primary-blue-500);
    margin-left: 10px;
}

.edit-t-df{
    display: flex;
    align-items: center;
    cursor: pointer;
}

.edit-t-df .edit-color{
    fill: var(--primary-blue-500);
}
.edit-t-df.n-alwd, .edit-t-df.n-alwd .edit-txt, .edit-t-df.n-alwd svg path{
    fill: var(--primary-neutral-300);
    color: var(--primary-neutral-300);
    cursor: not-allowed;
}

.form-pd{
    padding: 16px;
    position: absolute;
    top: 56px;
    bottom: 0;
    left: 0;
    right: 0;
}

.form-df{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.w-48{
    width: 48%;
}

.ph-df{
    justify-content: flex-start;
    align-items: unset;
    width: 100%;
}

.ph-lbl{
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: var(--primary-neutral-400);
}

.ph-lbl.blue{
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: var(--primary-blue-500);
    font-family: 'Gibson-500', sans-serif;
    cursor: pointer;
}

.cp-df{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.cp-lbl{
    margin-left: 10px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
}

.form-btns{
    display: flex;
    align-items: center;
    padding: 16px;
    position: absolute;
    justify-content: flex-end;
    bottom: 0;
    right: 0;
    border-top: 1px solid var(--primary-blue-hover);
    width: calc(100% - 32px);
    background-color: var(--primary-white);
    z-index: 9;
}

.c-created-by{
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: var(--primary-neutral-400);
    padding: 0px 16px 8px 16px;
}

.c-created-by span{
    font-weight: 600;
    color: var(--primary-neutral-500);
}

.c-poc-lbl{
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    color: var(--primary-neutral-400);
    text-transform: uppercase;
    margin-top: 40px;
    margin-bottom: 24px;
}

.mr-15-px{
    margin-right: 15px;
}

.chart-fs{
    display: flex;
    flex-direction: row;
    /* align-items: baseline; */
    justify-content: flex-start;
}
.chart-fs > div:first-child{
    width: 70%;
}
.chart-fs > div:last-child{
    width: 30%;
    display: flex;
    flex-direction: column;
    /* justify-content: flex-start; */
    align-items: flex-start;
    position: relative;
    bottom: 30px;
}

.chart-fs div:last-child div{
    margin-bottom: 7px;
}

.up-row-3 .pdf-list-view.r-l-view{
    position: absolute;
    top: 105px;
    right: 24px;
    left: 24px;
    bottom: 0;
}

.up-row-3 .pdf-list-view.r-l-view.scrl-wthn{
    top: 170px;
}

.up-row-3 .redesign-list-bdy{
    position: absolute;
    left: 0;
    right: 0;
    top: 52px;
    bottom: 72px;
    /* overflow-y: auto; */
}

.not-allowed{
    cursor: not-allowed !important;
}

.exInfoTextWidthLaneOverview {
    max-width: 500px;
}

@media (min-width: 1200px) {
    .hdr-ftr-usr-name .ftr-name span:last-child{
        width: fit-content;
    }

    .tablet1250 {
        display: none;
    }

    .tablet1250Visible {
        display: block;

    }
  
} 


@media (max-width: 1200px) {
    .exInfoTextWidthLaneOverview {
        max-width: 350px !important;
    }
    
    .tablet1250 {
        display: block;
    }

    .tablet1250Visible {
        display: none;
    }
}

@media screen and (max-width: 1128px) { 

    .route-name-width {
        width:  370px !important;
    }

    .hi-usr-name, .ftr-name a:last-child, .hdr-ftr-usr-name .ftr-name span:last-child {
        white-space: nowrap;
        width:  220px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .hi-usr-name {
        text-align: right;
    }

}
@media screen and (max-width: 1120px) {
    .hdr-ftr-usr-name .ftr-name span:last-child{
        width: 370px;
    }

    .hdr-ftr-usr-name .ftr-name a{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .hi-usr-name, .hdr-ftr-usr-name .ftr-name a{
        width: 150px;
    }

    /*risk planning info*/
    .redesign-srch.dsk-view {
        display: none !important;
    }
    .popup-lane-lst.risk-p.w-pts-seg {
        top: 370px !important;
    }
    .popup-lane-lst.risk-p {
        top: 275px !important;
    }
    .popup-lane-lst.redesign-risk-list .r-l-view {
        padding-top: 0px !important;
        top: 0 !important;
    }
    .redesign-info-box.m-view {
        display: flex !important;
    }

    .redesign-srch.mgmt {
        min-width: 280px;
    }
    .redesign-riq-seltn{
        width: 15% !important;
    }
} 
.p-btn.a-new-txt {
    white-space: nowrap;
    box-sizing: unset;
    min-width: 190px;
}
.a-new-txt:before {
    content: "+ Add New ";
}



@media screen and (max-width: 1088px) {
    .sel-box.new-exW{
        min-width: 170px !important;
    }
}

@media screen and (max-width: 1024px) {
    .exInfoTextWidthLaneOverview {
        max-width: 350px !important;
    }
    .ln-dtls{
        display: block !important;
    }
    .sel-box.new-w{
        width: 170px;
    }
    .ip-fld.lbl.sel-lbl{
        width: 170px;
    }
    .hdr-ftr-usr-name .ftr-name span:last-child{
        width: 250px;
    }
    .redesign-riq-seltn{
        width: 15% !important;
    }
    .redesign-detl-seltn {
        width: 85% !important;
    }
    .redesign-ln-rq > div:first-child{
        min-width: 400px;
    }
    .redesign-ln-rq > div:first-child::before{
        content: '' !important;
    }
    .redesign-ln-rq.route > div:first-child::before{
        content: '' !important;
    }
    .pgs-page-bdy {
        width: 80% !important;
    }
    .edit-txt{
        display: none;
    }
    .riq-col-1.mgmt{
        width: 30%;
    }
    
    .riq-col-2.mgmt{
        width: 17%;
    }
    
    .riq-col-5.mgmt{
        width: 8%;
    }

    .riq-col-0.cmpy-mgmt{
        width: 18%;
    }

    .riq-col-1.cmpy-mgmt{
        width: 20%;
    }

    .riq-col-2.cmpy-mgmt{
        width: 22%;
    }

    .riq-col-4.cmpy-mgmt{
        width: 17%;
    }
    .p-btn.a-new-txt {
        min-width: 150px;
    }
    .a-new-txt:before {
        content: "+ Add ";
    }
} 

@media screen and (max-width: 920px) {
    .sel-box.new-w{
        width: 140px;
    }
    .ip-fld.lbl.sel-lbl{
        width: 140px;
    }
} 
@media screen and (max-width: 1180px) {
    .alert-body{
        padding: 5px 90px 15px 25px !important;
    }
}

input::placeholder {
    color: var(--primary-neutral-300) !important;
    opacity: 1; /* Firefox */
    font-weight: 400;
}
.sel-box-pop.h-200 .scrollbar-container {
    height: 206px !important;
}
.sel-box-pop.h-200 {
    overflow: hidden !important;
}

.simplebar-wrapper .simplebar-content {
    min-height: 40px;
}


.simplebar-content-wrapper{
    outline: none !important;
}

.simplebar-scrollbar:hover:before, .simplebar-scrollbar:active:before {
    background: #666;
    opacity: 0.65;
}
.simplebar-track.simplebar-vertical.simplebar-hover {
    background: rgba(0, 0, 0, 0.08)
}

.ca-pop-bx {
    position: relative;
}
.ca-pop-bx .ca-pop-more {
    color: #fff;
    background: #000;
    border-radius: 4px;
    padding: 0px 6px 4px;
    font-size: 12px;
    line-height: 4px;
    height: 6px;
    display: inline-block;
    cursor: pointer;
}
.ca-pop-bx .ca-pop-list {
    display: none;
    position: absolute;
    background: #000;
    color: #fff;
    top: -44px;
    max-height: 120px;
    left: 32px;
    border-radius: 4px;
    width: 300px;
    z-index: 99999999;
}
.ca-pop-bx .ca-pop-list > div {
    width: 100%;
    height: 100px;
    overflow: hidden;
}
.ca-pop-bx .ca-pop-list::before {
    content: ' ';
    border: 7px solid #000;
    border-color: transparent #000 transparent transparent;
    position: absolute;
    top: 50%;
    left: -12px;
}
.ca-pop-bx:hover .ca-pop-list {
    display: block;
}

.simplebar-content-wrapper{
    outline: none !important;
}

.redesign-list-itm:last-child .ca-pop-bx .ca-pop-list::before {
    content: ' ';
    border: 7px solid #000;
    border-color: #000 transparent transparent transparent;
    position: absolute;
    top: 179px;
    left: 124px;
}
.redesign-list-itm:last-child .ca-pop-bx .ca-pop-list {
    transform: translate(-50%, -200px);
    margin-bottom: 10px;
}


.ca-pop-bx .ca-pop-li-itm {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 6px 8px;
    max-width: 300px;
}
.ca-pop-bx .ca-pop-li-itm:first-child {
    border-radius: 4px 4px 0 0;
}
.ca-pop-bx .ca-pop-li-itm:last-child {
    border-radius: 4px 4px 0 0;
}

.ca-pop-bx .ca-pop-list .simplebar-scrollbar:before,
.ca-pop-bx .ca-pop-list .simplebar-scrollbar:hover:before, .ca-pop-bx .ca-pop-list .simplebar-scrollbar:active:before {
    background: #fff;
}
.redesign-nav-links .has-child-nodes.db.hide-nav,
.hide-nav{
    display: none;
}
.cwl-alert-box.info{
    display: none;
}


/* external event css  */


.riskMap-flex-container, .inTransitSearchContainer, .inTransitSearchFooter {
    display: flex;
    flex-direction: row;
  }

.inTransitSearchContainer {
    justify-content: space-between;
}

.inTransitSearchFooter {
    position: fixed;
    bottom: 0px;
}

.inTransitSearchFooter img {
    width: 192px;
    height: 23px;
}

.inTransitRelativePos {
    position: relative;
    top: -2px;
    left: 25px;
}

.riskMap-flex-item {
  padding-top: 10px;
  padding-bottom: 10px;
}

.riskMap-flex-item, .riskMap-tab-flex-item {
    flex: 1;
    width: 50%;
    box-sizing: border-box;
}

.riskMap-tab-flex-item  {
    padding: 10px;
}


@media (max-width: 1024px) {
  .flex-container, .riskMap-flex-container
  {
    flex-direction: column;
  }
.riskMap-flex-item, .riskMap-tab-flex-item {
    width: 100%;
}
.riskMap-tab-flex-item  {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
    padding-left: 0px;
}

  .redesign-isgt-box.w-26r > div{
    width: 100% !important;
  }
}

  /* Tabs.css */
.tabs-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-top: 16px;
  }
  
  .tab-buttons, .event-list-tab-buttons {
    display: flex;
  }
  
  

 
  
  .tab-buttons .laneTabStyle, .tab-buttons .routeTabStyle, .event-list-tab-buttons .event-list-tabStyle  {
    border: none;
    cursor: pointer;
    background: none;
    color: #333333;
    font-family: 'Gibson-600';
    font-weight: 600;
    font-size: 14px;

  }

  .tab-buttons .laneTabStyle {
    padding: 5px 10px 5px 0px;
    /* padding: 5px 10px; */
    margin: 0px;
  }

  
 .event-list-tab-buttons .event-list-tabStyle {
    padding: 5px 10px;
    margin: 0 5px;
  }

  .tab-buttons .routeTabStyle{
    padding: 5px 10px;
    margin: 0 10px;
  }

  
  .event-list-tab-buttons .event-list-tabStyle.active  {
    border-bottom: 4px solid #270ADC;
    /* padding: 5px 0px;
    margin: 0px; */
  }

  .tab-buttons .laneTabStyle.active,  .tab-buttons .routeTabStyle.active {
    border-bottom: 4px solid #270ADC;
  }

  .tab-content {
    margin-top: 10px;
    width: 100%;
    height: 450px;
  }
  
  .marginWaypoint {
    margin-right: 20px;
  }

  .marginCardTop {
    margin-top: 15px;

  }

  .map-redesign-srch {
    width: 250px !important;
  }
  

  .map-redesign-search-box {
    padding: 8px 30px 8px 8px !important;
  }


  /* Card.css */
.mapCard {
    border-radius: 8px;
    padding: 10px;
    background: #F0F1F9;
    border : 0px, 0px, 1px, 0px;
  }
  
  .map-card-header {
    border-bottom: 1px solid #AEA5E4;
    padding-bottom: 8px;
    display: flex;
    justify-content: space-between;
  }

  .map-card-header-subtxt1, .map-tab-box-content {
    color: #181818;
    font-family: 'Gibson-500';
    font-weight: 500;
  }

  .map-tab-box-content {
    font-size: 16px;
    line-height: 24px;
  }

  .map-card-header-subtxt1 {
    font-size: 18px;
    padding: 8px 16px 8px 16px;
  }

  .mapReviewBtn {
    color: #270ADC;
    font-family: 'Gibson-500' !important;
    background-color: #F0F1F9;
    font-weight: 500;
    font-size: 16px;
    height: 32px;
    border-radius: 4px;
    border: none;
  }

  .c-text-align-r, .mapReviewBtn {
    text-align: right;
  }


  .mapReviewBtn:hover {
    background: var(--primary-blue-hover);
  }

  .c-margin-top {
    margin-top: 10px;
  }

  .c-flex-space-between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    line-height: 24px;
  }

  .c-text-align-l{
    text-align: left;
  }
  
  .map-card-content {
    padding: 5px;
  }
  
  .map-card-footer {
    padding: 10px;
    background-color: #f0f0f0;
    border-top: 1px solid #ccc;
  }

  .c-tabCardDate, .exRouteCardTxt {
    font-family: 'Gibson';
    font-weight: 400;
    color: #525252;
  }


  .c-tabCardDate {
    font-size: 16px;
  }


  .exRouteCardTxt {
    font-size: 14px;
    line-height: 16px;
  }

  
  .c-tabCardDate {
    line-height: 24px;
  }

  .c-dateSecondaryColor {
    color: #181818;
  }


.redCircle {
    width: 8px;
    height: 8px;
    margin-top: -2px;
    background-color:  #BE442A !important; /* Change color here */
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    box-shadow: 0px 0px 1px 4px #FDDBD4;


}

.mediumCircle {
    width: 8px;
    height: 8px;
    margin-top: -2px;
    background-color: #D99100 !important; /* Change color here */
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    box-shadow: 0px 0px 1px 4px #FFF1D6;
}

.lowCircle {
    box-shadow: 0px 0px 1px 4px #E3FCEF;
    width: 8px;
    margin-top: -2px;
    height: 8px;
    background-color:  #008C34 !important; /* Change color here */
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
}
  
.tabStyle, .event-list-tabStyle {
    display: flex;
    align-items: center;
    padding: 10px;
};

.geofenceMapsection {
    height: 414px;
    width: 100%;
    border-radius: 4px;
    margin-top: 20px;
}

.interactGText {
    color: #525252;
    font-family: "Gibson";
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    width: 192px;
    height: 32px;
    margin-top: 10px;
    border-radius: 4px 0px 0px 0px;
    background: #F0F1F9;
}

.clickMapText {
}

.marginTopCardSection {
    margin-top: 20px;
}


.tooltips {
    position: relative;
    display: inline-block;
}
  
.tooltips .tooltips-text {
    visibility: hidden;
    min-width: 185px;
    background-color:  var(--c-tooltip-txt-bg);
    box-shadow: 0 1px 3px var(--c-tooltip-txt-box-shdw);
    color: var(--c-tooltip-txt-color);
    font-size: 12px;
    border-radius: 4px;
    text-align: center;
	z-index: 9999999;
    white-space: normal;
    padding: 10px;
    cursor: pointer;
    position: absolute;
    /* bottom: 125%; */
    left: 50%;
    margin-left: -120px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltips:hover .tooltips-text {
    visibility: visible;
    opacity: 1;
    cursor: pointer;

}

.riskDetailsTabBottom {
    margin-bottom: 20px
}

.riskDetailsTabContent {
    font-size: 24px;
    font-weight: 600;
    color: #333333;
    line-height: 25px;
}


.extHeader {
    font-family: "Gibson-600";
    font-weight: 600;
    font-size: 40px;
    Line-height: 48px;
    margin-right: 18px;
}

.exButtonTop {
    margin-top: 9px;
}

.extMl {
    margin-left: 4px
}


.route-collapseButton {
    Width : 170px;
    border: 1px solid #887ADE;
    background-color: #fff;
    color: #270ADC;
}

.route-collapseButton, .route-expandButton  {
    Height: 32px;
    border-radius : 4px;
    font-family: "Gibson-500" !important;
    font-weight: 500;
    font-size: 16px;
    padding:  4px 16px 4px 16px;
}

.route-expandButton {
    Width : 162px;
    background: #270ADC;
    color: #fff;
    border: none;
}

.exFlexContainer {
    display: flex;
}


.borderNoneFlow {
    border: none !important;
    padding-left:   0px !important ;
}

.route-up-arrow {
    position: relative;
    left: 5px;
    top: -1px
}



.simplebar-content-wrapper::before {
    border: none !important;
}

.simplebar-content-wrapper::after {
    border: none !important;
}


/* .marker {
    background-image: url('assets/images/ping_rate_black.png');
    background-size: cover;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
} */

.exRiskInsightText {
    font-family: "Gibson-600";
    font-weight:  600;
    font-size: 14px;
    color: #525252
}
.marker_low {
    background-image:  url('assets/images/externalEventIcon/greenMarker.svg');
    background-size: cover;
    cursor: pointer;
}

.marker_no_risk {
    background-image:  url('assets/images/externalEventIcon/blueMarker.svg');
    background-size: cover !important;
    cursor: pointer;
}

.marker_medium {
    background-image:  url('assets/images/externalEventIcon/yellowMarker.svg');
    background-size: cover !important;
    cursor: pointer;

}

.marker_high {
    background-image: url('assets/images/externalEventIcon/redMarker.svg');
    background-size: cover !important;
    cursor: pointer;
}

.marker_origin {
    background-image: url('assets/images/externalEventIcon/markerOrgin.svg');
    background-size: cover !important;
    cursor: pointer;
    padding: 7px;
}

.marker_destination {
    background-image: url('assets/images/externalEventIcon/markerDestination.svg');
    background-size: cover !important;
    cursor: pointer;
    padding: 10px;
}

.mapboxgl-marker.i-icn:not(.marker_path){
    background-image: url('assets/images/externalEventIcon/gray_waypoint.svg');
    background-size: cover !important;
    cursor: pointer;
}

.mapboxgl-marker.marker_orginDest_low.a-icn {
    background-image: url('assets/images/externalEventIcon/greenPin.svg');
    background-size: cover !important;
    cursor: pointer;
    padding: 10px;
}


.mapboxgl-marker.marker_orginDest_norisk.a-icn {
    background-image: url('assets/images/externalEventIcon/bluepin.svg');
    background-size: cover !important;
    cursor: pointer;
    padding: 10px;
}



.mapboxgl-marker.marker_orginDest_high.a-icn {
    background-image: url('assets/images/externalEventIcon/markerOrgin.svg');
    background-size: cover !important;
    cursor: pointer;
    padding: 10px;
}


.mapboxgl-marker.marker_orginDest_medium.a-icn {
    background-image: url('assets/images/externalEventIcon/markerDestination.svg');
    background-size: cover !important;
    cursor: pointer;
    padding: 10px;
}



.mapboxgl-marker.marker_origin.i-icn, .mapboxgl-marker.marker_destination.i-icn, .mapboxgl-marker.marker_orginDest_low.i-icn, .mapboxgl-marker.marker_orginDest_medium.i-icn, .mapboxgl-marker.marker_orginDest_high.i-icn {
    background-image: url('assets/images/externalEventIcon/gray_location.svg');
    padding: 10px;
}

.marker_path.mapboxgl-marker {
    margin-top: 9px;
    margin-left: -9px;
}
.evnt-info {
    position: absolute;
    right: -6px;
    display: flex;
    width: 26px;
    justify-content: center;
}
.marker_path.mapboxgl-marker .evnt-info {
    
}
.evnt-info .e {
    position: relative;
    width: 8px;
    height: 8px;
    border: 1px solid #fff;
    border-radius: 50%;
    background: #999999;
}
.evnt-info .e.h {
    background: #BE442A;
    z-index: 2;
}
.evnt-info .e.m {
    background: #D99100;
    margin-left: -2px;
    z-index: 1;
}
.evnt-info .e.l {
    background: #008C34;
    margin-left: -2px;
    z-index: 1;
}
.mapboxgl-marker.i-icn .evnt-info .e {
    background: #999999;
}
.evnt-cnt-sec {
    background: #181818; 
    display: flex; 
    justify-content: space-between; 
    padding: 8px 14px;
    border-radius: 4px;
    opacity: 0;
    transform: translate(calc(-50% + 14px), -46px);
    position: absolute;
    z-index: 99999999;
}
.mapboxgl-marker {
    z-index: 0;
}
.marker_path.mapboxgl-marker .evnt-cnt-sec {
    transform: translate(calc(-50% + 30px), -46px);
}
.mapboxgl-marker:before {
    content: '';
    position: absolute;
    border: 10px solid #181818;
    border-color: #181818 transparent transparent transparent;
    transform: translate(5px, -10px);
    opacity: 0;
}
.marker_path.mapboxgl-marker:before {
    transform: translate(20px, -10px);
}
.mapboxgl-marker.n-eve:before {
    opacity: 0;
    display: none;
}
.mapboxgl-marker:hover .evnt-cnt-sec, .mapboxgl-marker:hover:before {
    opacity: 1;
}
.evnt-cnt-sec .e {
    background: #999999; 
    border-radius: 12px;
    margin: 0 2px;
    text-align: center;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    padding: 0 6px;
}
.evnt-cnt-sec .e.h {
    background: #BE442A; 
}
.evnt-cnt-sec .e.m {
    background: #D99100; 
}
.evnt-cnt-sec .e.l {
    background: #008C34; 
}

#exCustomTable {
    border-collapse: collapse;
    width: 100%;
  }
  
  #exCustomTable td, #exCustomTable th {
    padding: 8px;
    vertical-align: baseline;
    border-bottom: 1px solid #AEA5E4;
  }
  
  #exCustomTable tr td:nth-child(1) {
   width: 30px
  }
  
  #exCustomTable th {
    padding-top: 12px;
    padding-bottom: 12px;
    color: #525252;
    font-family: "Gibson-600";
    font-size: 14px;
    font-weight: 600;
    text-align: left;

  }

  #exCustomTable .exDescTxt {
    font-family: "Gibson";
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    line-height: 24px;
    color: #181818;
  }

  #exCustomTable .exWBoldTxt {
    font-family: "Gibson-500";
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    line-height: 24px;
    color: #181818;

  }

  .exBlueBtn {
    width: 81px;
    background: #270ADC;
    color: #fff;
  }

  .exBtnRadius {
    border-radius: 4px !important;
  }

  #chartContainer {
    width: 100%;
    height: 200px;
    margin: 20px 0;
  }
  
  canvas {
    width: 100%;
    height: 100%;
    font-family: 'YourCustomFont', Arial, sans-serif; /* Add your custom font family */
    font-size: 14px; /* Customize font size */
    display: block; /* Ensure the canvas spans the full width */
  }
  

  .exRouteDetailBtn {
    width: 108px !important;
  }

  .exRouteDetailMediumBtn {
    width: 130px !important;
  }

  .exRouteDetailLowBtn {
    width: 115px !important;
  }


  .exLowColor {
    background-color: #008C34;
    width: 30px;
  }


.exHighColor,  .exLowColor, .exMediumColor {
    font-size: 12px;
    font-family: "Gibson-500";
    font-weight: 500;
    border-radius: 4px;
    padding: 2px 4px 2px 4px;
    border: none;
    color: #fff;
    height: 20px;
}

  .exHighColor {
    background-color: #DF5031;
    width: 34px;

  }

.exMediumColor {
    background-color: #D99100;
    width: 53px;
}

.exMR3 {
    margin-right: 3px;
}

.exMR6 {
    margin-right: 6px;
}

.exMR12 {
    margin-right: 12px;
}

.borderImpactRoute {
  border-color: #DEDBF0 !important;  
}

.exLaneOverViewWrapper {
    height: calc(100% - 250px); 
}

.ex-small-btn {
    font-size: 12px !important;
    font-weight: 500 !important;
    font-family: "Gibson-500" !important;
    height: 20px;
    width: 19px;
    padding: 2px 4px 2px 4px;
    border-radius: 4px;
    border: none;
}


.small-btn-high{
    color:  var(--user-deactivate);
    background-color: var(--primary-rose-500);
}

.small-btn-medium{
    color: #D99100;
    background-color: #FFF1D6;
}

.small-btn-low{
    color: #008C34;
    background: #E3FCEF;
}


.lOverView-bg-transparent{
        width: 103px !important;
        height: 34px !important;
        border-radius: 4px;
        padding :8px 16px 8px 16px;
        background: #181818;
        color: #fff;

}

.overViewSubText {
    padding: 4px 0px 4px 0px !important;
}

.exWeatherEventSection {
    display: flex;
    flex-wrap: wrap;
}

.exWeathersubContent{
    flex: 1;
}

.exWeather-flex-container, .exWeather-forecast-flex-container {
    display: flex;
    text-align: left;
}

.exWeather-flex-container  {
    flex-direction: row;
}

.exWeather-forecast-flex-container {
    flex-direction: row;
    justify-content: space-between;

}

  
.exWeather-flex-item{
    flex: 25%;
}

.exWeather-forecast-flex-item {
    flex: 20%;
}


.exWeatherPageHeader, .exWForeCastDate, .exWForeCastRemaingLabel {
    font-family: "Gibson-600";
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    text-align: left;
    color: #525252;
}

.exWForeCastLabel {
    font-family: "Gibson";
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #999999;
}

.exWForeCastValue {
    font-family: "Gibson";
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
}

.exMl3 {
    margin-left: 3px;
}


.exWeatherPageHeader {
    text-transform: uppercase;
}

.exWForeCastDate, .exTxtClr {
    color: #181818 !important;
}

.exWeatherForcastInfoValue {
    color: #999999;

}

.exMtSmall{
    margin-top: 6px;
}
.exWeatherDateInfo, .exNodata {
    font-family: "Gibson";
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    color: #525252;
}

.exMt25{
    margin-top: 25px;
}

.exWeatherDateInfoValue {
    color: #181818 !important;

}

.exDisplayNone {
    display: none;
}

.exLH24 {
    line-height: 24px;
}


.exWeatherPageTextContent {
    font-family: "Gibson";
    font-weight: 400;
    margin-top: 15px;
}

.exPadd {
    padding: 20px;
}

.exWeatherPageTextContent, .exWeatherPageSubHeader,
.exReadMoreBtn
{
    color: #181818;
    line-height: 24px;
    font-size: 16px;
    margin-top: 10px;
}

.exWeatherPageSubHeader {
    font-family: "Gibson-500";
    font-weight: 500;
    text-align: left;

}
.exReadMoreBtn {
    color: #270ADC;
    font-family: "Gibson-500" !important;
    font-weight: 500;
    border: none;
    background-color: #fff;
    cursor: pointer;
    padding: 0px;
}

.exMt {
 margin-top: 30px
}

.exMt20 {
    margin-top: 20px
}

.exMb40 {
    margin-bottom: 80px !important;
}

.exMb20 {
    margin-bottom: 20px !important;
}

.exMt7 {
    margin-top: 7px
}

.exMb4 {
    margin-bottom: 4px
}

.exMt10 {
    margin-top: 10px
}


.expadt10 {
    padding-top: 10px;
}

.exWRouteheading, .exRiskMapHeader {
    font-family: "Gibson-600";
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #181818;
}

.external-right-popup{
    width: 1000px !important;
}

.redesign-overlay.externalPopup{
    left: 70px;
}

.exSeverityBtn {
    width: 145px !important;
    margin-left: 10px;
    position: relative;
    top: -3px;
}

.exSeverityMedium {
    margin-left: 10px;
    position: relative;
    top: -3px;
    width: 158px !important;
}

.exTopSection {
    top: 65px !important;   
}

.exTopBorderBottom {
    border-bottom:  1px solid #DEDBF0;
}

.exLastUpdateddateValue {
    font-family: "Gibson-600";
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    color: #181818;
    margin-right: 25px;

}



.exRedirectionLabel {
    font-family: "Gibson";
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: #270ADC;
    text-decoration-color: #270ADC;
    text-decoration: underline;
    padding-left: 1px;
}

.exLastUpdateddate, .ex16pxGibson400 {
    font-family: "Gibson";
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: #525252;
}

.exDelayInDays {
    font-family: "Gibson";
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #181818;
}

.exMarineEventDesc {
 font-family: "Gibson" !important;
 font-weight: 400 !important;
}
  
/* datePickerStyle  */
/* .react-datepicker-wrapper input {
    height: 30px;
    border-radius: 4px;
    border: 1px solid #999999;
    padding-left: 9px;
    width: 200px;
} */

/* .react-datepicker-wrapper input:focus {
 outline: none;
} */

.exTable {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 302px;
    margin: auto;
    border-collapse: collapse;
}

.exHeader, .exRow {
    display: flex;
    width: 100%;
}

.exDescTxt {
    font-family: "Gibson";
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    line-height: 24px;
    color: #181818;
    }

.exBody {
    height: 290px;
}

.exActiveShipmentListTable {
    height: 40px;
}

.exMLMR {
 margin-left: 5px;
 margin-right: 5px;
 position: relative;
 top: 3px;
}

.exMT12 {
    margin-top: 12px;
}

.exMT1 {
    margin-left: 1px;
}

.exMRMT5 {
    margin-right: 5px;
    margin-top: 5px;


}

.exCell {
    padding: 8px;
    vertical-align: baseline;
    border-bottom: 1px solid #AEA5E4;
    word-wrap: break-word
}

.exCellActiveShipment {
    padding: 8px 8px 8px 0px !important;
}


.exCellRouteImpact1 {
    width: 10%
}

.exCellRouteImpact2 {
    width: 40%
}

.exCellRouteImpact3 {
    width: 40%
}

.exCellRouteImpact4 {
    width: 10%
}

.exCell1 {
    width: 14%
}

.exTridIdWidth {
    width: 20%
}

.exCell2 {
    width: 40%
}

.exCell3 {
    width: 17%
}

.exCell4 {
    width: 17%
}

.exCell5 {
    width: 14%
}

.exWBoldTxt {
    font-family: "Gibson-500";
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    line-height: 24px;
    color: #181818;

  }

.exTextALignEnd {
    text-align: center;
}

.exButEnd {
    text-align: end;
    margin-right: 5px;
}

.exHeader {
    padding-top: 12px;
    padding-bottom: 12px;
    color: #525252;
    font-family: "Gibson-600";
    font-size: 14px;
    font-weight: 600;
    text-align: left;
}

.exTabletView {
    display: none;
}




@media screen and (max-width: 1024px) {
    .tooltips .tooltips-text { 
        margin-left: -160px;
    }

    .extHeader {
        max-width: 527px !important;
        margin-right: 4px;
    }

    .exCell3 {
        width: 22%
    }

    .exCell2 {
        width: 49%
    }

    .exCell5 {
        width: 17%
    }

    .tabletHidden {
        display: none;
    }

    .exTabletView {
        display: block;
    }

}


@media screen and (max-width: 1120px) {
    .exTable, .exBody {
        height: 380px;
    }

    .extHeader {
        max-width: 540px;
    }
}

@media screen and (max-width: 1120px) {
    .exTable, .exBody {
        height: 380px;
    }

    .external-right-popup{
        width: 950px !important;
    }
}

.exTextAlignCenter {
    text-align: center;
}


.exRouteImpactTable {
    height: 129px !important;
}

.exRouteImpactTableBody {
    height: 129px !important;
}

.exCursor {
    cursor: pointer;
}

.exFontFamily600 {
    font-family: 'Gibson-600' !important;
}

.exFontFamily500 {
    font-family: 'Gibson-500' !important;
}

.exFontFamily400 {
    font-family: 'Gibson' !important;
}


.exFontWeight400 {
    font-weight: 400 !important;
}

.exFlexSpaceBetween {
    display: flex;
    justify-content: space-between;
}

.exDateMarginRight {
    margin-right: 3.5% !important;
}

.exHeightFitContent {
    height: fit-content !important;
}

.exMarginTopNegative35 {
    margin-top: -35px;
}

.exWidth80 {
    width: 80px;
}

.exWidth120 {
    width: 120px;
}

.exWidth140 {
    width: 140px;
}

.exWidth100 {
    width: 100px;
}

.exNoEventCard {
   text-align: center;
   background: #F8F8F8;
   height: 144px;
   padding: 24px 0px 0px 0px;
   margin-top: 12px;
   border-radius: 8px 0px 0px 0px;
   border: 1px 0px 0px 0px;
}

.exEventSidebar {
    padding: 16px 0px 16px 0px;
    border-radius: 8px;
    border: 0px 1px 0px 0px;
}

.exRoutecontainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 20px 0px 0px 0px;
}
  
.exColorBox {
    height: 8px;
    width: 16px;
    margin: 5px;
}
  
.exRouteText {
    margin: 5px 15px 5px 5px;
    font-family: "Gibson";
    font-weight:  400;
    font-size: 14px;
    color: #525252;
    line-height: 12px;
}
  
.tabStyle:hover .riskDetailsTabContent, .event-list-tabStyle:hover {
 color: #270ADC;
}

@media (max-width: 1024px) {
    .tabletListViewHdr {
        padding: 15px 0 15px 8px !important;
    }

    .exRouteHeader {
        font-size: 32px;
        line-height: 32px;
        padding: 0px 0px 16px 0px !important;

    }

    .exRedesign-lname-lt-updtdBorder {
        border-bottom: none !important;
        padding: 0px ;
        margin-bottom: 0px;
    } 

    .exDesktopBorder {
        padding-bottom: 0px !important;
        margin-bottom: 0px !important;
        border-bottom: none
    }

    .tabletRedesign-list-itm {
        padding: 15px 0 15px 8px !important;
    }

    .riq-col-1, .riq-col-2 { 
        width: 14% !important;
    }

    .riq-col-6 {
        width: 15% !important;
    }

    .riq-col-4 {
        width: 18.5% !important;

    }
    .riq-col-5 {
        width: 18.5% !important;

    }

    .riq-col-0 {
        width: 12% !important;
    }

    .riq-col-5 .ril-lbl {
        max-width: 117px !important;
    }

}


.exRedesign-lname-lt-updtd {
    padding: 0px 0px 16px 0px;
    margin-bottom: 20px;
    align-items: flex-end;
}

.exRedesign-lname-lt-updtdBorder {
    border-bottom: 1px solid var(--primary-blue-hover);
} 

.overviewTableVeticalAlign {
    margin-left: 10px;
    vertical-align: middle;
}

.exCursorDisabled {
    cursor: not-allowed;
}

.exMaxWidth {
    width: max-content;
}

.exMt16 {
    margin-top: 16px;
}

.exBoxShadowLaneList {
    /* box-shadow: 0px 10px 0px 0px rgba(0, 0, 0, 0.08); */
}

.calendarIconPosition {
    right: 19px;
    top: 5px;
}

.calendarIconPositionSecondary {
    right: 5px;
    top: 5px;

}

.exMargin5 {
    margin: 5px
}

.exMarginLaneList{
    margin-left: 5px;
    margin-right: 5px;
}

.exMl2 {
    margin-left: 2px;
}

.exMr15 {
    margin-right: 15px;
}

.exMb15 {
    margin-bottom: 15px;
}

.exMb10 {
    margin-bottom: 10px;
}

.exMb7 {
    margin-bottom: 7px;
}

.exWordBreak {
    word-break: break-word;
}

.exRouteInsight-val{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: var(--primary-neutral-600);
    margin-right: 10px;
}

.exDisabled {
    cursor: not-allowed !important;
    opacity: 0.7;
}

.exStableColor:hover {
    color: #333333 !important;
}

.mapboxgl-canvas { z-index: 0 !important }


.exDisabledColor {
    background: #999999 !important;
    color: #ffffff !important;

}


/* styles.css */
.mapboxgl-ctrl-fullscreen {
    background-color: #fff !important; /* Your desired hover background color */
  }
  

  /* styles.css */
/* .mapboxgl-ctrl-zoom-in,
.mapboxgl-ctrl-zoom-out { */
  /* background-color: #fff !important;  */
  /* Your desired background color */
/* } */

/* .mapboxgl-ctrl-zoom-in::before,
.mapboxgl-ctrl-zoom-out::before {
  color: green !important;  */
  /* Your desired icon color */
/* } */


.exFont14 {
    font-family: "Gibson";
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #525252;
}

.exColorMajorBlk {
    color: #181818;
}

.exFont16 {
    font-family: "Gibson";
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #181818;
}

.exClearAll {
    color: #270ADC !important;
    border: none;
    position: relative;
    top: 4px;
    left: 10px;
    background-color: var(--lynx-primarary-bg);
    cursor: pointer;
}

.exPointerEvent {
    pointer-events: none;
    color: #999999 !important;
}

.barTextDisabled {
    color: #999999 !important;

}

.exListPage {
    padding-top: 0px !important;
    margin-top: 55px;

}
.exFont16LightGray {
    font-family: "Gibson";
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #525252;
}


.flexColumn {
    display: flex;
    flex-direction: column;
}

.flexJustifySpaceBetween {
    display: flex;
    justify-content: space-between;
}


.exPlainDropDown {
    background: var(--lynx-primarary-bg);
    border: none
}


.exbadgeColor {
    padding: 8px 14px 8px 8px;
    border-radius: 5px;
    background: #F0F1F9;
    width: max-content;
    color: #333333 !important;
}



.crossMarkPos {
    position: relative; 
    top: 4px;
    left: 8px;
    cursor: pointer;
}

.openFilter {
    border-bottom: 1px solid var(--primary-neutral-300);;
}

.expadding11 {
    padding: 11px 28px 12px 8px;
}

.expaddingRight10 {
    padding-right: 10px;
}

.exEllipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.custom-date-picker-riskIQ .date-filter button.absolute.right-0.h-full.px-3.text-gray-400 {
    display: none !important;
}