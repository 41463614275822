.left-bar.pdf-p {
    width: 18%;
    background: var(--primary-neutral-100);
}
.right-bar.pdf-p {
    left: calc(18% + 72px) !important;
    background: var(--primary-white);
}
.right-bar.pdf-p.ex {
    left: calc(18% + 260px) !important;
    background: var(--primary-white);
}
.left-bar.pdf-p.p-det {
    width: 32%;
    background: var(--primary-neutral-100);
}
.right-bar.pdf-p.p-det {
    left: calc(32% + 72px) !important;
    background: var(--primary-blue-75);
}
.right-bar.pdf-p.p-det.ex {
    left: calc(32% + 260px) !important;
}
.right-bar.pdf-p::after {
    content: '';
}
.left-bar.pdf-p .new-ship {
    left: calc(24% - 60px);
}
.pdf-p .pdf-hdr {
    padding: 30px 30px 20px;
    position: absolute;
    top: 0;
    right: 0;
    height: 220px;
    left: 0;
    box-sizing: border-box;
    border-bottom: unset;
}
.p-h1 {
    font-size: 24px;
    font-weight: 600;
    color: var(--primary-neutral-600);
    margin-bottom: 28px;
}
.pdf-hdr .fld-sec {
    /* margin: 10px 0;
    display: flex;
    justify-content: space-between; */
}
.pdf-hdr .fld-sec .pdf-p-sel {
    /* width: 48% */
}
.pdf-hdr .fld-sec .pdf-p-sel.w-90 {
    width: 90%;
}
.bulk-ul-t-tip {
    position: absolute;
    margin-top: -1px;
    margin-left: 36px;
}
.bulk-ul-t-tip .t-txt {
    min-width: 190px;
    text-transform: unset;
    margin-top: -2px;
    margin-left: calc(-14% + -10px);
}
.pdf-p-sec {
    /* position: absolute;
    top: 220px;
    right: 0;
    bottom: 72px;
    left: 0; */
    margin-top: 40px;
}
.pdf-hdr .fld-sec {
    margin: 10px auto 20px;
}
.pdf-p-sec .fld-sec {
    margin: 10px 0px 20px;
}
.pdf-p-lbl {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 4px;
}
.pdf-p-lbl span {
    float: right;
    text-transform: lowercase;
    padding-right: calc(14% - 10px);
}
.pdf-p-sel {
    width: calc(60% + 20px);
}
.pdf-p-sel + .material-icons {
    font-size: 16px;
    cursor: pointer;
    margin-left: 10px;
    margin-top: 5px;
    position: absolute;
}
.pdf-p-sel + .material-icons:not(.d):hover {
    text-shadow: 0 0px 2px var(--pp-selectbox-txt-shadow);
}
.pdf-p-sel .sel-box-pop {
    width: 150%;
}
.view-pdf-parser-pop {
    margin-left: 16px;
}
.view-pdf-parser-pop.d {
    cursor: not-allowed;
    opacity: 0.45;
}
/* Not Used
.fld-sec .pdf-ip {
    outline: none;
    border: 1px solid rgb(151, 151, 151);
    font-size: 12px;
    font-weight: 600;
    border-radius: 3px;
    padding: 6px 10px 5px 10px;
    width: 86%;
}
.pdf-p-add {
    font-weight: 700;
    font-size: 12px;
    color: rgba(85, 178, 231, 1);
    cursor: pointer;
    margin: 0 40px;
    user-select: none;
}
.pdf-p-add:hover {
    text-shadow: 0 0 1px #ddd;
}
*/
.pdf-upload-ftr {
    /* position: absolute;
    bottom: 0;
    left: 0;
    right: 0; */
    height: 72px;
    margin: 40px 0px 0 0px;
}
.pdf-upload-files:before {
    /* cursor: default;
    width: 62px;
    content: '';
    border: 1px solid var(--pp-primary-border-color);
    position: absolute;
    top: -14px;
    left: 50%;
    transform: translate(-50%, 0); */
}
.pdf-upload-files {
    position: relative;
    background: var(--primary-blue-500);
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    color: var(--primary-white);
    margin: 0 auto 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 0;
}
.pdf-upload-files .upload-file-icon {
    font-size: 16px;
    margin-right: 16px;
}
.pdf-upload-files .upload-file-ip, .pdf-upload-files.d .upload-file-ip {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    cursor: pointer;
    opacity: 0 !important;
}
.pdf-upload-files.d, .pdf-upload-files.d .upload-file-ip {
    cursor: not-allowed !important;
    opacity: 0.75;
}
.pdf-p .fltr-sec {
    position: absolute;
    top: 0;
    right: 0;
    height: 110px;
    left: 0;
    background: var(--pp-fltr-sec-background);
    /* border-bottom: 1px solid var(--pp-primary-border-color); */
    padding: 0px;
    margin: 16px 14px;
    width: 100%;
    box-sizing: border-box;
    flex-flow: column;
    display: block;
}
.search-bx {
    margin-left: 30px;
    flex-grow: 1;
}
.fltr-sec .search-bar {
    position: static;
    margin: 0;
}
.fltr-sec .search-bar .txt-i {
    width: 32%;
    font-size: 16px;
    color: var(--pp-primary-font-color);
    font-weight: 400;
    height: 32px;
    margin: 0;
}
.fltr-sec .search-btn{
    width: 15%;
    max-width: 180px;
    line-height: 36px;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
}
.fltr-sec .search-btn .search-icn {
    position: unset;
    margin: 0;
}
.fltr-sec .search-bar .close-btn {
    margin-left: 10px;
}
.f-h1 {
    font-size: 18px;
    font-weight: 500;
}
.fltr-sec .fltr-row {
    display: flex;
    align-items: center;
    margin-top: 24px;
    border-top: 1px solid var(--pp-fltr-row-t-border);
    padding-top: 4px;
}
.fltr-sec .fltr-row:first-child {
    margin: 0;
    padding: 0;
    border: none;
}
.pdf-fltrs {
    display: flex;
    margin: 0 0 0 30px;
    flex-grow: 1;
}
.fltr-box {
    box-shadow: 0px 2px 6px var(--lgn-card-shadow);
    min-width: 150px;
    box-sizing: border-box;
    margin-right: 30px;
    display: flex;
    padding: 6px 8px;
    align-items: center;
    justify-content: space-between;
}
.fltr-box.sel {
    cursor: pointer;
}
.fltr-box.sel:hover {
    box-shadow: 0px 2px 6px var(--pp-fltr-box-shadow-hover);
}
.fltr-box.sel.a {
    background: var(--pp-fltr-box-selected);
}
.fltr-box.a .lbl{
    border-right: 1px solid var(--primary-blue-600);
}
.fltr-box.d {
    opacity: 0.75;
    cursor: not-allowed;
}
.fltr-box .lbl{
    font-weight: 500;
    font-size: 16px;
    padding: 6px 10px 6px 6px;
    border-right: 1px solid var(--grey-300);
}
.fltr-box .val{
    font-size: 24px;
    font-weight: 500;
    padding: 0 10px;
    line-height: 30px;
}
.fltr-a-btn.p {
    padding: 1px 10px 1px 16px;
    width: 66px;
    justify-content: space-between;
}
.fltr-a-btn.p .material-icons {
    font-size: 28px;
    margin: 0;
}
.fltr-a-btn.d {
    cursor: not-allowed;
    opacity: 0.75;
}
.fltr-sel-li {
    position: absolute;
    top: 42px;
    display: flex;
    align-items: center;
}
.fltr-sel-li .fltr-sel-i {
    position: relative;
    padding: 0 12px 0 6px;
    font-size: 10px;
    color: var(--primary-white);
    background: var(--primary-neutral-300);
    border-radius: 4px;
    margin-right: 10px;
    cursor: pointer;
    max-width: 100px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 14px;
}
.fltr-sel-li .fltr-sel-i::after {
    content: 'X';
    color: var(--pp-sel-fltr-lbl-color);
    position: absolute;
    right: 4px;
    font-size: 6px;
}
.fltr-sel-li .fltr-sel-i:hover {
    background: var(--pp-sel-fltr-lbl-hvr-background);
}
.fltr-sel-li .fltr-sel-i:hover::after {
    color: var(--pp-sel-fltr-lbl-hvr-color);
}
.fltr-sel-li .c-all {
    font-size: 10px;
    color: var(--pp-clr-fltr-color);
    background: none;
    cursor: pointer;
    user-select: none;
    cursor: pointer;
    width: 80px;
}
.fltr-sel-li .c-all.d {
    cursor: not-allowed;
    opacity: 0.75;
}
.fltr-sel-li .c-all:not(.d):hover {
    font-weight: 500;
}


.pdf-list-view-n {
    position: absolute;
    top: 130px;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
}
.pdf-list-view {
    position: absolute;
    top: 0px;
    right: 0;
    /* height: 50%; */
    left: 0;
    padding: 20px 14px 0;
    box-sizing: border-box;
}
.pdf-list-view.t {}
.pdf-list-view.t::before {
    content: ' ';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -0.5%;
    border: 1px solid var(--pp-resize-panel-border);
    border-width: 1px 0 1px;
    height: 4px;
    background: var(--pp-resize-panel-background);
    cursor: ns-resize;
    color: var(--pp-primary-font-color);
    z-index: 1;
}
.pdf-list-view.t::after {
    content: '...';
    text-align: center;
    bottom: -1%;
    left: 0;
    right: 0;
    position: absolute;
    z-index: 2;
}
.custom-handle {}
.pdf-list-view.r-l-view {
    position: relative;
    top: unset;
    right: unset;
    bottom: unset;
    flex-grow: 1;
}
.pdf-list-view.r-l-view.t {
    height: 100%;
}
.pdf-list-bdy {
    position: absolute;
    top: 51px;
    right: 16px;
    bottom: 0;
    left: 0px;
    background-color: var(--pp-primary-background-color);
}
.pdf-list-bdy.l-ing {
    opacity: 0.75;
}
.pdf .pdf-list-row {
    font-size: 14px;
    display: flex;
    padding: 12px 0;
    font-weight: 400;
    margin-left: 16px;
    align-items: center;
    background-color: var(--primary-white);
}
.pdf .pdf-list-row:not(.pdf-list-hdr):nth-of-type(odd){
    /* background-color: var(--primary-white); */
}
.pdf .pdf-list-row > div {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.pdf .pdf-list-hdr {
    font-weight: 500;
    font-size: 16px;
    display: flex;
    padding: 0 0 12px;
    margin: 0;
    border-bottom: 1px solid var(--primary-neutral-600);
}
.pdf .pdf-list-row.pdf-list-hdr div{
    font-size: 16px;
    font-weight: 500;
    color: var(--base);
    line-height: 16px;
}
.pdf-list-row:not(.pdf-list-hdr) {
    cursor: pointer;
}
/* .pdf-list-row:not(.pdf-list-hdr):nth-child(2n) {
    background-color: var(--primary-blue-200);
} */
.pdf .pdf-list-row:not(.pdf-list-hdr):not(.pdf-list-row.f):not(.pdf-list-row.a):hover {
    background-color: var(--selected-itm) !important;
}
.pdf-list-row:not(.pdf-list-hdr):not(.f):active {
    /* box-shadow: 0 0 3px var(--primary-blue-400); */
}
.up-err {
    position: absolute;
    color: var(--pp-pdf-failed-logo-color);
    font-size: 12px;
    margin-left: -14px;
}
.pdf-list-row .p-col-0 {
    width: 8%;
    margin-right: 1%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex-grow: 0;
    flex-shrink: 0;
}
.pdf-list-row .p-col-1 {
    width: 10%;
    margin-right: 1%;
    flex-grow: 0;
    flex-shrink: 0;
}
.pdf-list-row .p-col-2 {
    width: 9%;
    margin-right: 1%;
    flex-grow: 0;
    flex-shrink: 0;
}
.pdf-list-row .p-col-3 {
    width: 16%;
    margin-right: 1%;
    flex-grow: 1;
    flex-shrink: 0;
}
.pdf-list-row .p-col-fn {
    width: 20%;
    margin-right: 1%;
    flex-grow: 1;
    flex-shrink: 0;
}
.pdf-list-row .p-col-4 {
    width: 7%;
    margin-right: 1%;
    flex-grow: 0;
    flex-shrink: 0;
}
.pdf-list-row .p-col-5 {
    width: 9%;
    margin-right: 1%;
    flex-grow: 0;
    flex-shrink: 0;
}
.pdf-list-row .p-col-6 {
    width: 12%;
    margin-right: 1%;
    flex-grow: 0;
    flex-shrink: 0;
}
.pdf-list-row .p-col-7 {
    width: 7%;
    margin-right: 1%;
    flex-grow: 0;
    flex-shrink: 0;
}
.pdf-list-row .p-col-8 {
    width: 20%;
    margin-right: 1%;
    flex-grow: 0;
    flex-shrink: 0;
}
.p-col-4-5-6-7 {
    width: 38%;
    margin-right: 1%;
    font-size: 10px;
    color: var(--pp-pdf-err-color);
    flex-shrink: 0;
}
.p-col-1-2 {
    width: 20%;
    margin-right: 1%;
    font-size: 10px;
}
.pdf-list-hdr {
    /* text-indent: 6%; */
}
.f-fl-cnt {
    color: var(--pp-failed-pdf-cnt);
}
.s-fl-cnt {
    color: var(--pp-success-pdf-cnt);   
    display: inline-block;
    width: 32%;
}
.s-fl-cnt:first-child {
    text-align: right;
}

.pdf-prog-bar {
    position: relative;
    height: 8px;
    background: var(--pp-progress-bar-background);
    border-radius: 5px;
    width: 70%;
    margin-top: 2px;
}
.p-col-1-2 .pdf-prog-bar {
    width: 98%;
}
.pdf-prog-bar::after, .pdf-prog-bar .after {
    content: ' ';
    border-radius: 10px;
    background-color: var(--pp-progress-bar-bg-processing);
    width: 0%;
    max-width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    transition: all 500ms cubic-bezier(0, 0, 0, 0.8);
}
.pdf-prog-bar.UPLOADED::after {
    width: 15%;
}
.pdf-prog-bar.PARSED::after {
    width: 90%;
}
.pdf-prog-bar.PROCESSED::after {
    width: 100%;
}
.dwn-icns {
    background: var(--primary-blue-200);
    border-radius: 4px;
    display: flex;
    margin-left: 20%;
    justify-content: center;
    align-items: center;
    color: var(--pp-download-icon-color);
    width: 10px;
    font-size: 20px;
    padding: 2px 6px;
    cursor: not-allowed;
}
.dwn-icns.PROCESSED {
    background: var(--primary-blue-500);
    cursor: pointer;
}
.dwn-icns.PROCESSED:hover {
    box-shadow: 0 1px 4px var(--pp-download-icon-processed-sdw-hover);
}
.dwn-icns.PROCESSED:active {
    /* box-shadow: 0 0px 4px var(--primary-blue-400); */
}
.s-box {
    font-size: 12px;
    font-weight: 600;
    padding: 4px;
    text-align: center;
    border-radius: 4px;
    line-height: 14px;
    display: inline-block;
    margin-top: 0 !important;
}
.brand-c {
    background: var(--pp-brand-c);
}
.i-stat-icn {
    color: var(--pp-i-stat-icn);
    font-size: 0px;
}
.alarm-stat {
    background: var(--pp-alarm-box-background);
    color: var(--pp-sec-font-color);
    padding: 6px 10px;
    border-radius: 4px;
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}
.alarm-stat:hover {
    box-shadow: 0 1px 3px var(--pp-alarm-box-hover-shadow);
}
.alarm-stat.alarm{
    background: var(--pp-alarm-box-alarm-background);
}
.alarm-stat.assess{
    background-color: var(--pp-alarm-box-assess-background);
    color: var(--pp-primary-font-color);
}
.alarm-stat span {
    float: right;
    font-weight: 600;
}
.alarm-lbl {
    font-size: 14px;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 4px;
}
.alarm-lbl:last-child {
    margin: 0;
}
.pdf-stat-sec > .pdf-p-stat {
    padding-top: 0px;
    margin-bottom: 0 !important;
}
.pdf-stat-sec > .pdf-p-stat::before {
    content: 'STR ALARM';
    color: var(--pp-primary-font-color);
    font-size: 14px;
    font-weight: 500;
    box-sizing: border-box;
    text-align: left;
    color: var(--pp-primary-font-color);
    margin-bottom: 16px;
    display: block;
}
.pdf-stat-sec > .pdf-p-stat.ptr::before {
    content: 'PTR ALARM';
}
.ptr-str-stat-sec {
    display: flex;
}
.ptr-str-stat-sec .ptr-str-stat-box {
    border-right: 2px solid var(--primary-neutral-400);
    padding: 0px 8px;
    display: flex;
    flex-direction: column;
    width: 22%;
    flex-shrink: 0;
}
.ptr-str-stat-sec .ptr-str-stat-box.s {
    width: 28%;
}
.pdf-p-stat .ptr-str-stat-sec .ptr-str-stat-box {
    flex-grow: 0.5;
    width: 25%;
    margin-bottom: 1%;
}
.pdf-p-stat .ptr-str-stat-box .p-p-name,
.pdf-p-stat .ptr-str-stat-box .p-p-name {
    font-size: 10px;
}
.ptr-str-stat-sec .ptr-str-stat-box:last-child, .pdf-p-stat .ptr-str-stat-sec .ptr-str-stat-box:nth-child(3) {
    border-width: 0;
}
.r-l-view .ptr-str-stat-sec .ptr-str-stat-box:first-child {
    padding-left: 0;
}
.r-l-view .ptr-str-stat-sec .ptr-str-stat-box:last-child {
    padding-right: 0;
}
.pdf-p-stat {
    color: var(--pp-sec-font-color);
    white-space: nowrap;
    font-size: 12px;
    padding: 3px;
}
.ptr-str-stat-box .p-p-name{
    text-align: center;
    font-size: 0px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.pdf-stat-sec .pdf-p-stat {
    margin-bottom: 3px;
}
.pdf-stat-sec .pdf-p-stat, .pdf-stat-sec .p-p-name {
    font-size: 10px;
    line-height: unset;
}
.pdf-p-stat.alarm-REJECT, .pdf-p-stat.alarm-ALARM {
    background-color: var(--pp-alarm-box-alarm-background);
}
.pdf-p-stat.alarm-APPROVE, .pdf-p-stat.alarm-NOALARM  {
    background-color: var(--pp-alarm-box-background);
}
.pdf-p-stat.alarm-ASSESS, .pdf-p-stat.alarm-1 {
    background-color: var(--pp-alarm-box-assess-background);
    color: var(--pp-primary-font-color);
}
.pdf-p-stat.no-alarm {
    background-color: var(--pp-alarm-box-background);
}

/*list view in left nav: START*/
.pdf-p-list-view .txt-i {width: 70%;}
.pdf-p-list-view .search-btn {width: 30%;}
.pdf-p.p-det .pdf-hdr {
    padding-bottom: 0;
    bottom: 0;
    height: unset;
}
.pdf-p-list-view {
    position: absolute;
    top: 150px;
    right: 0;
    bottom: 0;
    left: 0;
}
.pdf-p-list {
    position: absolute;
    top: 0px;
    right: 0;
    bottom: 0;
    left: 0;
}
.pdf-p-n-fltr .sel-box-pop.h2 {
    height: unset;
}
.p-det .pdf-p-n-fltr {
    display: flex;
    justify-content: flex-start;
}
.p-det .pdf-p-n-fltr .fld-sec {
    margin: 10px 24px 10px 0; 
    width: 42%;
}
.p-det .pdf-p-n-fltr .fld-sec .pdf-p-sel {
    width: 98%;
}
.pdf-p-list.l-ing {
    opacity: 0.75;
}
.pdf-p-list .pdf-list-row {
    display: block;
    padding: 16px 24px;
    border-bottom: 1px solid var(--primary-neutral-200);
    position: relative;
    background: var(--primary-neutral-100);
    font-size: 14px;
    font-weight: 400;
}
.pdf .pdf-list-row.a, .pdf .pdf-list-row.a:hover {
    background: var(--primary-blue-200) !important;
}
.pdf-list-row.l, .pdf-list-row.l:hover {
    /* background-image: url('../../assets/images/icons8-iphone-spinner.gif'); */
    cursor: not-allowed;
    background-color: var(--pp-pdf-list-row-l-backgorund);
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: 0% center;
}
/* .pdf-p-list .pdf-list-row.l {
    background-position: calc(100% - 10px) 68px;
} */
/* .pdf-list-row.b.l, .pdf-list-row.b.l:hover {
    cursor: pointer;
} */
.pdf-list-row.l:before {
    background: url('../../assets/images/lynx/spinner2.svg') no-repeat 50% 50%;
    -webkit-animation: spin 1s infinite linear;
    -moz-animation: spin 1s infinite linear; 
    -ms-animation: spin 1s infinite linear; 
    animation: spin 1s infinite linear; 
    height: 24px;
    width: 24px;
    content: '';
    position: absolute;
    margin-left: 8px;
}
.pdf-list-row.a.l:before {
    background: url('../../assets/images/lynx/spinner.svg') no-repeat 50% 50%;
}

.pdf-list-row.f, .pdf-list-row.f:hover, .pdf-list-row.d, .pdf-list-row.d:hover {
    cursor: not-allowed;
    background-color: var(--pp-pdf-failed-process-row-bg) !important;
}
.pdf-list-row.d, .pdf-list-row.d:hover {
    background-color: var(--pp-pdf-process-row-disable-bg) !important;
}
.pdf-list-row.f .f-lbl {
    font-size: 11px;
    display: flex;
    align-items: center;
    right: 0;
    position: absolute;
    margin-top: -18px;
}
.pdf-list-row.f .f-lbl .i-stat-icn {
    font-size: 14px;
    color: var(--pp-primary-font-color);
}
.pdf-p-list .pdf-list-row {
    margin-left: 0;
}
.pdf-p-list .pdf-list-row .up-err {
    font-size: 12px;
    left: 20px;
    top: 18px;
}
.pdf-p-list .pdf-list-row .p-col-0 {width: 70%; margin-bottom: 15px;display: none;}
.pdf-p-list .pdf-list-row .p-col-1 {width: 70%; white-space: nowrap;color: var(--pp-pdf-p-list-row-col1-color);font-size: 12px;margin-bottom: 12px;display: none;}
.pdf-p-list .pdf-list-row .p-col-2 {width: 70%; position: absolute;top: 40px;}
.pdf-p-list .pdf-list-row .p-col-2::before {content: 'Device Id: '; }
.pdf-p-list .pdf-list-row .p-col-7 {width: auto;position: absolute;right: 24px;top:40px;margin: 0;}
.pdf-p-list .pdf-list-row .p-col-3 {
    width: 70%; 
    margin-bottom: 32px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
/* .pdf-p-list .pdf-list-row .s-box {font-size: 12px; } */
.pdf-p-list .pdf-list-row .p-col-8, .pdf-p-list .pdf-list-row .p-col-4 {display: none;}
.pdf-p-list .pdf-list-row .p-col-5 {width: 20%;position: absolute;right: calc(24px - 1% - 8px);top:13px;display: flex;align-items: center;justify-content: flex-end;}
.pdf-p-list .pdf-list-row .p-col-5 .i-stat-icn {font-size: 16px;margin-right: 4px;}
.pdf-p-list .pdf-list-row .p-col-7 .s-box, .pdf-p-list .pdf-list-row .p-col-6 .s-box {padding:3px 11px;}
.pdf-p-list .pdf-list-row .p-col-6 {width: 100%;}
.pdf-p-list .pdf-list-row .ptr-str-stat-sec {margin: 0;}
.pdf-p-list .pdf-list-row .ptr-str-stat-box {
    max-width: calc(33% - 8px);
    padding: 0 8px 0 0;
    width: 8%;
    margin-right: 8px;
}
.pdf-p-list .p-col-5 .ptr-str-stat-sec {
    width: 70%;
    justify-content: flex-end;
}
.pdf-p-list .p-col-5 .ptr-str-stat-sec .ptr-str-stat-box.s {
    width: 50%;
    max-width: 50%;
}
.pdf-p-list .p-col-5 .ptr-str-stat-sec .ptr-str-stat-box.s:last-child {
    padding-right: 0;
}
.pdf-p-list .pdf-list-row .s-box.pdf-p-stat + .p-p-name {
    color: var(--pp-sec-font-color);
    padding: 2px 10px;
    border-radius: 2px;
    line-height: 10px;
    font-size: 8px;
    width: auto;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.pdf-p-list .pdf-list-row .s-box.pdf-p-stat.reject + .p-p-name {background-color: var(--pp-alarm-box-alarm-background);}
.pdf-p-list .pdf-list-row .s-box.pdf-p-stat.approve + .p-p-name {background-color: var(--pp-alarm-box-background);}
.pdf-p-list .pdf-list-row .s-box.pdf-p-stat.no-alarm + .p-p-name {background-color: var(--pp-alarm-box-background);}
.pdf-p-list .pdf-list-row .s-box.pdf-p-stat.assess + .p-p-name {background-color: var(--pp-alarm-box-assess-background); color: var(--pp-primary-font-color);}
.pdf-p-list .pdf-list-row .pdf-stat { width: 36px; }
/*list view in left nav: END*/

/*details-view:START*/
.close-p-details {
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: Pointer;
    z-index: 99999;
    opacity: 0.75;
}
.close-p-details:hover {
    opacity: 1;
}
.pdf-s-preview {
    /* position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    top: 110px; */
    background: var(--primary-white);
    margin: 26px 44px;
    padding: 48px;
}
.pdf-s-details {
    /* position: absolute;
    height: 100px;
    right: 0;    
    left: 0;
    top: 0px;
    background: var(--pp-pdf-details-hdr-bg);
    display: flex;*/
}
.pdf-det-sec {
    /* padding: 20px 0 20px 30px;
    width: 76%;*/
    box-sizing: border-box;
    /* margin-right: 2%; */
    /* border-right: 1px solid var(--pp-pdf-details-sec-r-border); */
}
.pdf-det-info {
    padding: 0px 0 0px 0px;
    display: flex;
    color: var(--pp-primary-font-color);
    margin-bottom: 36px;
    padding-bottom: 26px;
    border-bottom: 1px solid var(--primary-neutral-200);
}
.pdf-det-info.h {
    /* background: var(--pp-pdf-details-upper-sec-bg); */
}
.pdf-det-info .det-info {
    width: 30%;
    margin-right: 1%;
    flex-grow: 1;
}
.pdf-det-info .det-info:nth-child(4) {
    width: 14%;
    flex-grow: 1;
}
.det-info .p-d-i-lbl {
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    color: var(--label-color);
    padding-bottom: 16px;
}
.det-info .p-d-i-val {
    font-size: 16px;
    font-weight: 400;
    color: var(--primary-neutral-600);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.pdf-stat-sec {
    padding-right: 50%;
    box-sizing: border-box;
    padding-bottom: 16px;
    margin: 0% 0 36px 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--primary-neutral-200);
}
/* .pdf-stat-h{
    padding: 32px 7% 18px;
    background: #c4c4c4;
    margin: 0 -4%;
} */
.pdf-stat-sec .pdf-stat {
    width: 50%;
    text-align: center;
    box-sizing: border-box;
    height: 100%;
    position: relative;
}
/* .pdf-stat-sec .pdf-stat::before {
    content: 'STR ALARM';
    position: absolute;
    margin-top: -40px;
    color: var(--pp-primary-font-color);
    font-size: 10px;
    box-sizing: border-box;
    text-align: left;
    left: 0;
    right: 0;
}
.pdf-stat-sec .pdf-stat.ptr::before {
    content: 'PTR ALARM';
} */
.pdf-stat-sec .pdf-stat.alarm {
    background-color: var(--pp-alarm-box-alarm-background);
}
.pdf-stat-sec .pdf-stat.noalarm {
    background-color: var(--pp-alarm-box-background);
}
.pdf-stat-sec .pdf-p-stat {
    width: 48%;
    box-sizing: border-box;
    overflow: hidden;
}
.pdf-stat-sec .ptr-str-stat-sec {
    margin: 0;
    flex-flow: wrap;
}
.pdf-stat-sec .pdf-p-stat .p-p-name {
    color: var(--pp-primary-font-color);
}

.temp-data-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /* margin-right: 5%; */
}
.chart-table-switch {
    width: 84px;
    margin-right: 2%;
}
.chart-table-switch.switch-btn li {
    padding: 4px 5px 3px;
}
.chart-table-switch svg path { fill: var(--primary-blue-500);}
.chart-table-switch svg rect { stroke: var(--primary-blue-500); }
.chart-table-switch .a svg path { fill: var(--primary-white);}
.chart-table-switch .a svg rect { stroke: var(--primary-white);}

.exp-csv-action {
    border: 1px solid var(--primary-blue-500);
    border-radius: 4px;
    padding: 6px 8px 6px 12px;
    cursor: pointer;
    color: var(--primary-blue-500);
    display: flex;
    align-items: center;
    width: 106px;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 500;
}
.exp-csv-action:active {
    box-shadow: 0 1px 3px var(--pp-export-csv-sdw-active);
}
.exp-csv-icn {
    
}
.exp-csv-action:hover {    
    background: var(--primary-blue-500);
    color: var(--primary-white);
}
.exp-csv-action:hover .exp-csv-icn * {
    fill: var(--primary-white);
}
/* .exp-csv-icn:hover, .exp-csv-icn:hover * {
    stroke: rgba(85, 178, 231, 1);
} */

/*temp table*/
.pdf-s-table {
    margin: 2% 0% 4%;
    font-size: 16px;
    color: var(--primary-neutral-400);
    box-sizing: border-box;
    border: 1px solid var(--primary-neutral-200);
}
.pdf-s-table, .th-config-info {
    /* width: 94%; */
}
.pdf-s-table-bdy{
    height: 280px;
}
.pdf-s-table .pdf-r{
    font-weight: 400;
    font-size: 16px;
    display: flex;
    border-bottom: 1px solid var(--primary-neutral-200);
}
.pdf-s-table .pdf-r:last-child {
    
}
.pdf-s-table .pdf-r.t-hdr {
    color: var(--primary-neutral-400);
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    background: var(--primary-white);
    border-width: 2px;
}
.pdf-s-table .pdf-r > div{
    width: 50%;
    padding: 10px 19%;
    box-sizing: border-box;
    white-space: nowrap;
    border-left: 1px solid var(--primary-neutral-200);
}
.pdf-s-table .pdf-r .p-c-1{
    text-align: left !important;
    padding-left: 10% !important;
}
.pdf-s-table .pdf-r.t-hdr div {
    text-align: center;
    padding: 10px 0;
}
.pdf-s-table .pdf-r > div:first-child {
    border-width: 0;
}


/*Threshold table*/
.pdf-s-table.th-config .pdf-r .p-c-0 {
    width: 15%;
    text-align: center;
    padding: 6px 0;
}
.pdf-s-table.th-config .pdf-r .p-c-1, 
.pdf-s-table.th-config .pdf-r .p-c-2,
.pdf-s-table.th-config .pdf-r .p-c-3 {
    width: 30%;
    padding: 6px 0;
    white-space: nowrap;
    text-align: center;
}
.pdf-s-table.th-config .pdf-r.t-hdr .p-c-1, 
.pdf-s-table.th-config .pdf-r.t-hdr .p-c-2,
.pdf-s-table.th-config .pdf-r.t-hdr .p-c-3 {
    padding: 6px 0;
}
.pdf-s-table.th-config .pdf-r .no-data {
    padding: 6px 20px;
    display: block;
    white-space: nowrap;
    width: 100%;
    text-align: center;
}
.th-config-info {
    display: flex;
    justify-content: space-between;
    margin: 2% auto -2%;
    padding: 10px 0px;
    box-sizing: border-box;
    font-size: 18px;
    font-weight: 500;
}
.th-config-info .th-config-lbl {
}
.th-config-info .th-config-val {
}
.fltr-row-li {
    display: flex;
}
.fltr-row-li .fltr-fld {
    width: 32%;
    margin-right: 1%;
    flex-grow: 1;
}
.fltr-row-li .fltr-fld .sel-box {
    width: 84%;
}

/*new alram view*/
.alarm-sec {
    width: 90%;
    overflow: hidden;
    background-color: var(--primary-white);
    padding: 0 5% 4%;
    border-bottom: 2px solid var(--primary-neutral-200);
    margin-bottom: 6%;
}
.alarm-sec:last-child {
    border-width: 0;
}
.alarm-detail-view {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
}
.al-col-0 { width: 40%; padding: 10px 20px; flex-grow: 1; }
.al-col-1 { width: 30%; padding: 10px 20px; }
.al-col-2 { width: 40%; padding: 10px 20px; }
.al-col-3 { width: 100px; flex-shrink: 0; padding: 10px 20px; text-align: right; }
.p-p-name {
    font-size: 24px;
    font-weight: 500;
    padding-bottom: 18px;
    line-height: 24px;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 70%;
    max-width: 250px;
}
.p-p-desc{
    font-size: 12px;
    font-weight: 500;
    padding-bottom: 18px;
}
.p-p-ing {}
.p-a-i {
    font-size: 15px;
    font-weight: 500;
    display: inline-block;
    padding: 10px;
    background-color: var(--primary-neutral-100);
}
.temp-range {
    font-size: 16px;
    color: var(--primary-neutral-600);
    font-weight: 400;
}
.temp-range::before {
    font-weight: normal;
    display: block;
    content: "TEMPERATURE RANGE";
    font-size: 14px;
    font-weight: 500;
    color: var(--label-color);
    padding-bottom: 18px;
    white-space: nowrap;
}
.p-p-status {
    background-color: var(--pp-alarm-box-background);
    padding: 8px 12px;
    border-radius: 2px;
    color: var(--pp-sec-font-color);
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    max-width: 110px;
}
.p-p-status.bubble {
    background: unset !important;
    color: var(--primary-neutral-500) !important;
    font-size: 16px;
    white-space: nowrap;
}
.p-p-status.bubble::before {
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: var(--success-color);
    border: 4px solid var(--feedback-success); 
    margin: -4px 0 0 -30px; 
}
.p-p-status.bubble.alarm::before {
    background-color: var(--error-color);
    border-color: var(--error-bg-color);    
}
.p-p-status.alarm, .p-p-status.reject {
    background: var(--pp-alarm-box-alarm-background);
}
.p-p-status.assess{
    background-color: var(--pp-alarm-box-assess-background);
    color: var(--pp-primary-font-color);
}
.view-alarm {
    color: var(--pp-view-alarm);
    font-size: 12px;
    cursor: pointer;
}
.collapse-alarm {
    background: var(--pp-collapse-alram-background);
    color: var(--pp-sec-font-color);
    border-radius: 15px;
    cursor: pointer;
    box-shadow: 0px 1px 3px var(--pp-collapse-alram-shadow);
}
.collapse-alarm:hover {
    background: var(--pp-collapse-alram-background-hover);
    box-shadow: 0px 1px 3px var(--pp-collapse-alram-shadow-hover);
}
.alarm-list-view {
    padding: 10px 0px;
    border-top: 1px solid var(--primary-neutral-200);
}

.pp-stat-icn {
    font-size: 14px;
    position: absolute;
    margin-left: -28px;
    margin-top: 2px;
    color: var(--pp-alarm-box-background);
}
.pp-stat-icn.APPROVE, .pp-stat-icn.s-0 { color:var(--pp-alarm-box-background); }
.pp-stat-icn.s-1.REJECT { color: var(--pp-alarm-box-alarm-background); }
.pp-stat-icn.s-1.ASSESS { color: var(--pp-alarm-box-assess-background); }


.temp-ranges-legend {
    margin-top: -41px;
    width: 80%;
}
.rdr-leg.temp {
    margin-left: 9%;
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
}
.rdr-leg.temp li .leg-clr {
    margin-right: 3%;
}
.rdr-leg.temp li .leg-sc {
    margin-right: 1%;
    min-width: 90px;
    padding: 3px 4px 1px;
    font-weight: 400;
}

.rdr-leg.temp li {
    width: 44%;
    flex-shrink: 0;
}

.pdf-p-p-sec {}
.pdf-p-p-sec .list-t-r.r-h {
    font-size: 12px;
}
.ip-p-m.p-p .ip {
    width: 48px !important;
}
.pdf-p-p-sec .ip.M, .pdf-p-p-sec .ip.D, .pdf-p-p-sec .ip.H {
    padding-right: 5%;
}
.pdf-p-p-sec .ip.M .ptr-dur-dhm {
    width: 42px;
}
.pdf-p-p-sec .ip.D .ptr-dur-dhm, .pdf-p-p-sec .ip.H .ptr-dur-dhm, .pdf-p-p-sec .ip.Mi .ptr-dur-dhm {
    width: 32px;
}

.switch-btn.pdf-p-s li {
    text-transform: unset;
}