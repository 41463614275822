@font-face {
  font-family: 'Gibson';
  src: url('assets/fonts/Gibson-Regular.otf');
}
@font-face {
  font-family: 'Gibson-500';
  src: url('assets/fonts/Gibson-Medium.otf');
}
@font-face {
  font-family: 'Gibson-600';
  src: url('assets/fonts/Gibson-Semibold.otf');
}

@font-face {
  font-family: 'Gibson-italic';
  src: url('assets/fonts/Gibson-Thin-Italic.otf');
}
* {
  -webkit-font-smoothing: antialiased;
}
body {
  margin: 0;
  font-family: 'Gibson', sans-serif !important;
  font-size: 16px;
  line-height: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--base);
  font-weight: 400;
}
*[font-family] {
  font-family: 'Gibson', sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input, textarea, select, .mapboxgl-map, button, input[type], .leaflet-container *, .leaflet-draw-actions a{
  font-family: 'Gibson', sans-serif !important;
}

ul, li {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.mapboxgl-ctrl-bottom-left, .mapboxgl-ctrl-bottom-right, .mapboxgl-ctrl-top-left, .mapboxgl-ctrl-top-right {
	z-index: 9;
}

.mapboxgl-map .mapboxgl-control-container .mapboxgl-ctrl-bottom-left,
.mapboxgl-map .mapboxgl-control-container .mapboxgl-ctrl-bottom-right .mapboxgl-ctrl.mapboxgl-ctrl-attrib {
  display: none;
}

/* .mapboxgl-map .mapboxgl-ctrl-bottom-right {
  bottom: 40px;
}
.mapboxgl-map .mapboxgl-control-container .mapboxgl-ctrl.mapboxgl-ctrl-group {
  background: none;
  box-shadow: none;
}
.mapboxgl-map .mapboxgl-control-container .mapboxgl-ctrl.mapboxgl-ctrl-group button, .re-center
{
  border-radius: 50%;
  margin: 10px 0 0 0;
  background: var(--i-mapbox-recenter-bg);
  box-shadow: 0 1px 3px var(--i-mapbox-recenter-box-shdw);
  color: var(--i-mapbox-recenter);
}
.mapboxgl-map .mapboxgl-control-container .mapboxgl-ctrl.mapboxgl-ctrl-group button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon,
.mapboxgl-map .mapboxgl-control-container .mapboxgl-ctrl.mapboxgl-ctrl-group button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
  background: none !important;
}
.mapboxgl-map .mapboxgl-control-container .mapboxgl-ctrl.mapboxgl-ctrl-group button .mapboxgl-ctrl-icon::before {
  content: ' ';
  font-size: 20px;
  font-weight: 500;
  line-height: 29px;
  text-align: center;
}
.mapboxgl-map .mapboxgl-control-container .mapboxgl-ctrl.mapboxgl-ctrl-group button:hover, .re-center:hover {
  background: var(--i-mapbox-recenter-bg-hvr);
}
 
.mapboxgl-map .mapboxgl-control-container  .mapboxgl-ctrl.mapboxgl-ctrl-group button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon::before{
  content: '+'
}

.mapboxgl-map .mapboxgl-control-container  .mapboxgl-ctrl.mapboxgl-ctrl-group button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon::before{
  content: '-';
  font-weight: 600;
  line-height: 26px;
} */

/* .mapboxgl-map .mapboxgl-control-container .mapboxgl-ctrl-compass{
  display: none;
} */

.mapboxgl-map .map-style {
  position: fixed;
  display: flex;
  bottom: 10px;
  right: 10px;
  z-index: 999;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 1px 3px var(--i-mapboxgl-map-style-box-shdw);
}
.mapboxgl-map .map-style > div {
  background: var(--i-mapboxgl-map-style-div-bg);
  padding: 4px 8px 2px;
  text-transform: uppercase;
  color: var(--i-mapboxgl-map-style-div);
  font-size: 10px;
  font-weight: 500;
  cursor: pointer;
  letter-spacing: 0.72px;
}
.mapboxgl-map .map-style > div.a {
  color: var(--i-mapboxgl-map-style-div-a);
  background: var(--i-mapboxgl-map-style-div-a-bg);
}

.mapboxgl-map .mapboxgl-control-container  .mapboxgl-ctrl-group button+button {
  border: none;
}

.re-center {
  transform: rotate(45deg);
  line-height: 30px !important;
  text-align: center;
  font-size: 24px;
  font-weight: 100;
  cursor: pointer;
  height: 29px;
  width: 29px;
  margin-bottom: 0 !important;
  user-select: none;
}

.mapboxgl-ctrl-bottom-right .mapboxgl-ctrl {
  margin-right: 16px;
}

.mapboxgl-control-container .mapboxgl-ctrl-top-left:not(:empty) {
  right: 0;
  bottom: 0;
}
.mapboxgl-control-container .no-data-popup {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  text-shadow: 0 1px 0 var(--i-mapboxgl-no-data-popup-txt-shdw);
  box-shadow: unset;
  left: 0;
  right: 0;
  background: var(--i-mapboxgl-no-data-popup-bg);
  top: 0;
  bottom: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}

.leaflet-bottom.leaflet-left, 
.leaflet-control-attribution.leaflet-control.leaflet-compact-attribution {
  display: none;
}

.leaflet-zoom-animated path.leaflet-interactive[stroke-opacity="0.75"] {
  stroke-dasharray: 8 8;
}

@import 'App.css';

/* Components */
@import './components/Auth/login.css';
@import './components/AssetTracking/asset_tracking.css';
@import './components/Configuration/configuration.css';
@import './components/Header/notifications.css';
@import './components/Modal/Modal.css';
@import './components/Pdf/iframe.css';
@import './components/Pdf/pdfparser.css';
@import './components/RiskIQ/riskdetailview.css';
@import './components/RiskIQ/riskiq.css';
@import './components/SweetAlert/SweetAlert.css';
@import './components/UserProfile/UserProfile.css';
@import './components/ToggleButton/ToggleButton.css';

.ui-error {
  border: 1px solid var(--i-ui-err-border);
  border-radius: 2px;
  max-height: 100px;
  overflow-y: auto;
  padding: 10px;
  font-size: 12px;
  color: var(--i-ui-err);
}

body #hubspot-messages-iframe-container.widget-align-right {
  right: 50px !important;
}
@import 'Responsive.css';