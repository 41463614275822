.alert {
    display: none;
    position: fixed;
    z-index: 9999999999;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    overflow: auto;
    background-color: var(--lynx-overlay-bg);
}

.alert-content {
    margin: 14% auto;
    width: 36%;
    /* box-shadow: 0 5px 8px 0 var(--sa-alert-content-box-shdw1), 0 7px 20px 0 var(--sa-alert-content-box-shdw2); */
    box-shadow: 0px 8px 12px rgba(9, 30, 66, 0.15), 0px 0px 1px rgba(9, 30, 66, 0.31);
    animation-name: alertopen;
    animation-duration: 1s;
    border-radius: 5px;
}
.sm {
    width: 40%;
}
.md {
    width: 60%;
}
.lg {
    width: 80%;
}

.alert-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 25px 15px 25px;
    background: var(--primary-white);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.alert-header .alert-hdr-txt {
    color: var(--primary-neutral-600);
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    font-family: 'Gibson-600', sans-serif;
}
.sa-close-icn{
    display: block;
    text-align: right;
    font-weight: 600;
    color: var(--primary-neutral-500);
    cursor: pointer;
}
.alert-body {
    padding: 5px 150px 15px 25px;
    /* text-align: center; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 16px;
    background: var(--primary-white);
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}
.alert-body.bdr {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}
.alert-body .alert-text {
    /* padding-left: 30px; */
    color: var(--primary-neutral-600);
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    box-sizing: border-box;
}
.alert-footer {
    background: var(--primary-white);
    padding: 15px 25px 15px 25px;
    color: var(--sa-alert-footer);
    text-align: center;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top: 1px solid var(--primary-blue-hover);
}
.alert-btns {
    display: flex;
    justify-content: flex-end;
}
.alert-button {
    background-color: var(--primary-blue-500);
    color: var(--primary-white);
    text-transform: capitalize;
    font-weight: 600;
    font-weight: 500;
    font-size: 16px;
    border: none;
    cursor: pointer;
    border-radius: 3px;
    padding: 10px 15px !important;
    opacity: 0.9;
    outline: none;
}
.alert-button.ok {
    margin-right: 20px;
}
.negative {
    background-color: var(--error-color);
}
.alert-button.cancel {
    background-color: var(--primary-white);
    color: var(--primary-blue-500);
    border: 1px solid var(--primary-blue-500);
}
.alert-button.cancel.ext {
    margin-right: auto;
}
.alert-button:hover {
    opacity: 1;
}
.alert-button.s-spin {
    position: relative;
}
.alert-button.s-spin .spin {
    position: absolute;
    left: 50%;
    margin-left: -12px;
    margin-top: -4px;
}
.alert-button.s-spin .txt {
    opacity: 0;
}
@keyframes alertopen {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.alert svg {
    width: 40px;
    /* display: block;
    margin: 0 auto; */
}
.path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
}
.path.circle {
    -webkit-animation: dash 0.9s ease-in-out;
    animation: dash 0.9s ease-in-out;
}
.path.line {
    stroke-dashoffset: 1000;
    -webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
    animation: dash 0.9s 0.35s ease-in-out forwards;
}
.path.check {
    stroke-dashoffset: -100;
    -webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
    animation: dash-check 0.9s 0.35s ease-in-out forwards;
}
.alert-body .warning {
    display: flex;
    width: 40px;
    height: 40px;
    /* padding: 5px; */
    font-size: 30px;
    justify-content: center;
    /* margin-left: 45%; */
    border: 3px solid var(--sa-alert-body-warning);
    color: var(--sa-alert-body-warning);
    border-radius: 50%;
}
@media only screen and (max-width: 500px) {
    .alert-content {
        width: 80%;
    }
}
@-webkit-keyframes dash {
    0% {
        stroke-dashoffset: 1000;
    }
    100% {
        stroke-dashoffset: 0;
    }
}
@keyframes dash {
    0% {
        stroke-dashoffset: 1000;
    }
    100% {
        stroke-dashoffset: 0;
    }
}
@-webkit-keyframes dash-check {
    0% {
        stroke-dashoffset: -100;
    }
    100% {
        stroke-dashoffset: 900;
    }
}
@keyframes dash-check {
    0% {
        stroke-dashoffset: -100;
    }
    100% {
        stroke-dashoffset: 900;
    }
}
