.left-bar.a-t {
    width: 25%;
}
.right-bar.a-t {
    left: 25%;
    top: 163px;
}

.a-t .txt-i {
    width: 70%;
}
.a-t .search-btn {
    width: 30%;
}
.g-c-fltr {
    position: absolute;
    top: 46px;
    width: 100%;
    background: var(--at-list-fiter-section-bg);
    height: 46px;
    border-bottom: 1px solid var(--at-list-fiter-section-b-border);
    box-shadow: 0px 1px 3px var(--at-list-fiter-section-box-shdw);
    z-index: 99999;
}
.g-c-fltr-box {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: 10px auto;
}
.g-c-fltr-l, .g-c-fltr-r{
    width: 46%;
}
.g-c-fltr-box .sel-box {
    width: 100%;
}

.a-list {
    position: absolute;
    top: 93px;
    left: 0;
    width: 100%;
    bottom: 0;
}
.a-list.no-g-c-fltr {
    top: 46px;
}
.a-list .a-li-item{
    margin-bottom: 4px;
    padding: 14px 12px;
    background: var(--at-list-item-bg);
    /* animation: list_item_in 5000ms; */
}
/* @-webkit-keyframes list_item_in {
    0% {
        transform: translate(-50%, 0);
        opacity: 0;
    }
    100% {
        transform: translate(0, 0);
        opacity: 1;
    }
} */
.a-list .a-li-item.a {
    background: var(--at-list-item-selected-bg);
}
.a-list .a-li-item .r-0, .a-list .a-li-item .r-0 * {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.a-list .a-li-item .r-0 .a-info .material-icons {
    font-size: 20px;
    color: var(--at-list-item-icn-color);
    cursor: pointer;
}
.a-list .a-li-item .a-id {
    font-size: 14px;
    font-weight: 700;
    color: var(--at-primary-color);
}
.a-list .a-li-item .a-id > span {
    width: 94px;
}
.a-list .a-li-item .r-1{
    margin-top: 5px;
}
.a-list .a-li-item .r-2{
    font-size: 12px;
    font-weight: 500;
    margin-top: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.a-list .a-li-item .r-3{
    color: var(--at-secondary-color);
    font-weight: 400;
    font-size: 10px;
    margin-top: 12px;
}
.a-list .a-li-item .r-3, .a-list .a-li-item .r-3 .a-loc {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.a-list .a-li-item .r-3 .a-loc .material-icons {
    font-size: 16px;
    color: var(--at-list-item-loc-icn-color);
}

/*CONNECTION STATUS*/
.a-stat, .a-batt {
    padding-right: 22px;
    font-size: 8px;
    color: var(--at-secondary-color);
    line-height: 16px;
}
.a-batt {
    padding-right: 20px;
}
.a-batt > svg {
    transform: rotate(90deg);
    margin-left: 5px;
}
.a-stat::before {
    width: 70px;
    text-align: right;
}
.a-stat.c-1::before {
    content: 'Connected'
}
.a-stat::before {
    content: 'Disconnected'
}
.a-t-pop .a-stat:before {
    content: '';
    opacity: 0;
    font-size: 0;
}
.a-stat::after{
    content: ' ';
    width: 12px;
    height: 12px;
    margin-left: 6px;
    background: var(--at-active-status-bg);
    border-radius: 50%;
    box-shadow: 0 0 4px var(--at-active-status-box-shdw);
    display: inline-block;
}
.a-stat.c-1::after {
    background: var(--at-inactive-status-bg);
}

/*PRODUCT TYPES*/
.p-type {
    font-size: 8px;
    font-weight: 600;
    color: var(--at-primary-color);
    padding: 3px 10px;
    border-radius: 2px;
}
.p-type.p-X {
    background: var(--at-p-type-x-bg);
}
.p-type.p-0 {
    background: var(--at-p-type-0-bg);
}
.p-type.p-1 {
    background: var(--at-p-type-1-bg);
}
.p-type.p-2 {
    background: var(--at-p-type-2-bg);
}
.p-type.p-3 {
    background: var(--at-p-type-3-bg);
}
.p-type.p-4 {
    background: var(--at-p-type-4-bg);
}
.d-info-sec .p-type.p-X {
    background: var(--at-d-info-sec-x-bg);
}
.d-info-sec .p-type.p-0 {
    background: var(--at-d-info-sec-0-bg);
}
.d-info-sec .p-type.p-1 {
    background: var(--at-d-info-sec-1-bg);
}
.d-info-sec .p-type.p-2 {
    background: var(--at-d-info-sec-2-bg);
}
.d-info-sec .p-type.p-3 {
    background: var(--at-d-info-sec-3-bg);
}
.d-info-sec .p-type.p-4 {
    background: var(--at-d-info-sec-4-bg);
}

/*POP UP STYLES*/
.a-t-pop {
    position: fixed;
    z-index: 9999;
    bottom: 40px;
    left: 40%;
    right: 10%;
    background: var(--at-primary-bg);
    border-radius: 2px;
    box-shadow: 0 2px 4px var(--at-box-shadow-color);
    height: 140px;
    animation: pop_in 250ms ease-in;
    background: #efefef;
}
@-webkit-keyframes pop_in {
    from {
        bottom: -200px;
        opacity: 0;
    }
    to {
        bottom: 40px;
        opacity: 1;
    }
}
.a-t-pop .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 18px;
    font-weight: 600;
    z-index: 999999;
    cursor: pointer;
    color: var(--at-close-icon-color);
}
.a-t-pop .close-btn:hover {
    color: var(--at-thrid-color);
}
.a-t-pop .d-sec {
    
}
.a-t-pop .d-info-sec {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 180px;
    padding: 16px 20px;
    background: var(--at-primary-color);
    color: var(--at-primary-bg);
    box-sizing: border-box;
    box-shadow: 1px 0 6px var(--at-scondary-box-shdw);
    z-index: 99999;
}

.a-t-pop .d-info-sec .a-id {
    font-weight: 700;
    display: flex;
    align-items: center;
}
.a-t-pop .d-info-sec .a-id svg {
    transform: rotate(90deg);
    margin-left: 6px;
}
.a-t-pop .d-info-sec .a-stat, .a-t-pop .d-info-sec .a-batt {
    padding-right: 0;
}
.a-t-pop .d-info-sec .a-batt {
    margin-left: 6px;
}
.a-t-pop .d-info-sec .r-2 {
    margin-top: 8px;
    font-weight: 500;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.a-t-pop .d-info-sec .r-3 {
    margin-top: 12px;
    font-weight: 600;
    font-size: 12px;
}
.a-t-pop .d-info-sec .sel-box  {
    color: var(--at-primary-color);
    margin-top: 2px;
    width: 100%;
}
.a-t-pop .d-info-sec .sel-box .sel-value {
    padding: 4px 20px 4px 10px;
}

.a-t-pop .d-log-sec {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 180px;
}
.a-t-pop .d-loc-sec {
    background: var(--at-primary-bg);
    display: flex;
    padding: 10px 18px;
    box-shadow: 0 1px 3px var(--at-box-shadow-color);
    height: 26px;
}

.a-t-pop .d-loc-sec .active-loc-sec {
    display: flex;
    align-items: center;
    flex-grow: 1;
}
.a-t-pop .d-loc-sec .active-loc-sec .loc {
    color: var(--at-active-loc-color);
}
.a-t-pop .d-loc-sec .active-loc-sec .active-loc{
    font-size: 10px;
    font-weight: 600;
    color: var(--at-primary-color);
}
.a-t-pop .d-loc-switch {
    display: flex;
    align-items: center;
    margin-right: 40px;
    color: var(--at-a-pop-switch-color);
}
.a-t-pop .d-loc-switch .arr {
    color: var(--at-box-shadow-color);
    cursor: pointer;
    user-select: none;
}
.a-t-pop .d-loc-switch .arr.d {
    cursor: not-allowed;
    opacity: 0.5;
}
.a-t-pop .d-loc-switch .prev-loc{}
.a-t-pop .d-loc-switch .nxt-loc{}
.a-t-pop .d-loc-switch .a-time{
    font-size: 10px;
}
.a-t-pop .d-loc-switch .a-loc{
    font-size: 9px;
    font-weight: 600;
    margin-top: 1px;
}

.a-t-pop .d-loc-switch .active-loc-details {
    text-align: center;
    width: 110px;
}

.a-t-pop .d-log {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 48px;
    color: var(--at-primary-color);
}
.a-t-pop .d-log .d-log-h{
    font-size: 12px;
    font-weight: 500;
    padding: 6px 0 0 18px;
    text-transform: uppercase;
}
.a-t-pop .d-log .d-log-b{
    position: absolute;
    top: 26px;
    right: 0;
    bottom: 0;
    left: 0;
}
.a-t-pop .d-log-item {
    padding: 0 18px 10px;
    font-size: 10px;
    display: flex;
    justify-content: space-between;
}
.a-t-pop .d-log-item *{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.a-t-pop .d-log-item .d-evnt-n {
    color: var(--at-thrid-color);
    font-weight: 500;
    width: 27%;
    padding-right: 3%;
}
.a-t-pop .d-log-item .d-evnt-loc {
    color: var(--at-a-pop-timeline-color);
    width: 47%;
    padding-right: 3%;
}
.a-t-pop .d-log-item .d-evnt-t {
    color: var(--at-a-pop-timeline-evnt-t-color);
    width: 100px;
    text-align: right;
}

.close-pop-btn {
    position: absolute; 
    top: 20px;
    right: 20px;
    cursor: pointer;
    color: var(--at-close-pop-btn);
}
.close-pop-btn:hover {
    color: var(--at-primary-color);
    font-weight: 600;
}
.d-info {
    position: relative;
    max-width: 360px;
    width: 80%;
    margin: 10% auto;
    background: var(--at-primary-bg);
    border-radius: 2px;
    box-shadow: 0 1px 3px var(--at-scondary-box-shdw);
    padding: 20px 32px 64px;;
}

.d-info .fld-lbl {
    font-size: 12px;
    font-weight: 300;
    padding: 8px 0;
    color: var(--at-d-info-fld-lbl);
}

.d-info .n-hdr {
    padding-bottom: 10px;
}

.asset-track-map {
    height: 100%;
}
.leaflet-container .leaflet-control-attribution {
    visibility: hidden;
    opacity: 0;
}

.ll-info.d-name {
    position: absolute;
    top: -50px;
    padding: 4px 10px;
    font-size: 8px;
    font-weight: 700;
    min-width: 80px;
    text-align: center;
    background: var(--at-primary-bg);
    box-shadow: 0 1px 3px var(--at-box-shadow-color);
    border-radius: 3px;
    white-space: nowrap;
}
.d-t-bat-xl .ll-info.d-name {
    display: none;
    top: -40px;
}
.d-t-bat-xl:hover .ll-info.d-name {
    display: block;
}
.asset-track-map .leaflet-marker-icon.map-icon.icon-source.leaflet-zoom-animated.leaflet-interactive:hover {
    z-index: 9999999 !important;
}

.asset-track-map .leaflet-map-pane .a-t-icon {
    transform: translate(-50%, -50%);
}
.asset-track-map .leaflet-map-pane .a-t-icon.bxl {
    width: 16px;
    background: var(--at-map-a-t-icn);
    height: 16px;
    z-index: 99999;
    border: 2px solid var(--at-primary-bg);
    border-radius: 50%;
    box-shadow: 0 1px 2px var(--at-map-a-t-icn-shdw);
    position: absolute;
    transform: translate(0, -5px);
}
.asset-track-map .leaflet-pane.leaflet-overlay-pane path.leaflet-interactive.route-path-a-t,
.asset-track-map .leaflet-pane.leaflet-marker-pane path.leaflet-interactive.route-path-a-t {
    fill: transparent;
    stroke: var(--at-map-a-t-icn);
    stroke-width: 6px;
    stroke-opacity: 0.74;
}

.leaflet-draw-toolbar.leaflet-bar.leaflet-draw-toolbar-bottom.recenter {
    position: absolute;
    bottom: 96px;
    right: 10px;
    border: 2px solid var(--at-map-receter-border);
    display: flex;
    align-items: center;
}
.leaflet-draw-toolbar.leaflet-bar.leaflet-draw-toolbar-bottom.recenter .material-icons {
    transform: rotate(45deg);
    margin: 1px;
    cursor: pointer;
}

.flt-sec.a-t {
    position: absolute;
    top: 70px;
    right: 0;
    left: 25%;
    height: 93px;
    background: var(--at-fltr-sec-bg);
    border-bottom: 1px solid var(--at-fltr-sec-b-bottom);
    box-shadow: 0 1px 3px var(--at-fltr-sec-box-shdw);
    box-sizing: border-box;
    padding: 0 50px;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.flt-sec.a-t .sep{
    border-left: 1px solid var(--at-fltr-sec-b-left);
    height: 70%;
    margin: 0 50px;
}
.flt-dd {
    width: 140px;
}
.flt-dd .sel-box{
    width: 100%;
}
.flt-dd .sel-box .sel-value {
    font-weight: 700;
}
.flt-dd .sel-box .sel-pop {
    width: 100%;
}
.dev-cnt {
    width: 100px;
    position: relative;
    text-align: center;
}
.dev-cnt .cnt{
    font-size: 24px;
    font-weight: 700;
    padding: 0px 0 4px;
    text-shadow: 0 1px 1px var(--at-device-cnt-txt-shdw);;
}
.dev-cnt .cnt-lbl, .battery-sec .flt-lbl {
    font-size: 12px;
    text-transform: uppercase;
}
.battery-sec {

}
.bat-bar {
    display: flex;
    width: 300px;
    height: 15px;
    border-radius: 2px;
    position: relative;
    box-shadow: 0 1px 3px var(--at-box-shadow-color);
    font-size: 8px;
    line-height: 15px;
    font-weight: 700;
    text-align: center;
    margin: 6px 0 8px;
    overflow: hidden;
}
.bat-bar .bar {
    animation: all ease-in-out 250ms;
    overflow: hidden;
    margin: 0;
}
.bat-bar .b-0-25 {
    background: var(--at-bar-red);
    width: 33.33%;
}
.bat-bar .b-25-50 {
    background: var(--at-bar-yellow);
    width: 33.33%;
}
.bat-bar .b-50-100 {
    background: var(--at-bar-green);
    width: 33.33%;
}

.bar-bar-leg {
    display: flex;
    border-radius: 2px;
    font-size: 10px;
    line-height: 15px;
    font-weight: 600;
}
.bar-bar-leg .l {
    flex-grow: 1;
    padding-left: 16px;
    position: relative;
    cursor: pointer;
}
.bar-bar-leg .l.a {
    font-weight: 800;
}
.bar-bar-leg .l::before {
    content: ' ';
    position: absolute;
    left: 0;
    width: 12px;
    height: 12px;
    background: var(--at-bar-bar-leg-l);
    border-radius: 2px;
    box-shadow: 0 1px 3px var(--at-box-shadow-color);
}

.bar-bar-leg .l.l-0-25::before {
    background: var(--at-bar-red);
}
.bar-bar-leg .l.l-25-50::before {
    background: var(--at-bar-yellow);
}
.bar-bar-leg .l.l-50-100::before {
    background: var(--at-bar-green);
}
.bar-bar-leg .l.a::after {
    content: ' ';
    position: absolute;
    width: 6px;
    height: 6px;
    background: var(--at-primary-bg);
    left: 3px;
    top: 3px;
    border-radius: 1px;
    box-shadow: 0 0 2px var(--at-box-shadow-color);
}


.a-t .switch-btn.map-styles {
    bottom: 30px;
}

.prod-t-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 6px 6px 15px;
}
.prod-t-item:hover, .prod-t-item.a {
    background: var(--at-prod-item-a);
}
.prod-t-item .prod-lbl {
    flex-grow: 1;
    cursor: pointer;
}
.prod-t-item .prod-action {
    width: 20px;
    margin-left: 10px;
    cursor: pointer;
}
.prod-t-item .prod-action.e:hover > svg {
    fill: var(--at-prod-action-svg) !important;
}
.prod-t-item .prod-action.d:hover > svg {
    stroke: var(--at-prod-action-svg) !important;
}

.add-prod-type {
    position: absolute;
    background: var(--at-add-prod-type-bg);
    padding: 4px 6px;
    border-radius: 2px;
    font-size: 8px;
    font-weight: 600;
    right: 64px;
    cursor: pointer;
}
.add-prod-type:hover {
    background: var(--at-add-prod-type-bg-hvr);
    box-shadow: 0 1px 2px var(--at-add-prod-type-box-shdw-hvr);
}

.p-c-box {
    width: 150px;
    margin-left: 34px;
    position: relative;
    /* padding: 6px 24px 6px 6px; */
    border-radius: 4px;
    box-shadow: 0 1px 3px var(--at-box-shadow-color);
    cursor: pointer;
    user-select: none;
}
.p-c-box.p-p-t {
    box-shadow: none;
    border: 1px solid var(--primary-neutral-300);
}
.p-c-box .c-arr {
    position: absolute;
    top: 9px;
    right: 6px;
    font-size: 18px;
}
.p-c-sel {
    width: 24px;
    height: 24px;
    margin: 6px 24px 6px 6px;
    border-radius: 2px;
    box-shadow: 0 1px 2px var(--at-p-c-b-ox-shdw);
}
.p-c-box.p-p-t .p-c-sel{
    margin: 9px 24px 9px 14px;
    width: 22px;
    height: 22px;
}
.p-c-box.p-p-t .c-arr {
    top: 10px;
}
.p-c-pop {
    position: absolute;
    z-index: 9999;
    background: var(--at-primary-bg);
    box-shadow: 0 1px 3px var(--at-box-shadow-color);
    height: 200px;
    left: 0;
    top: 38px;
    border-radius: 2px;
}
.p-c-box.p-p-t .p-c-pop {
    top: 42px;
    width: 135px;
}
.color-lbl{
    position: relative;
    top: 5px;
    left: 30px;
    font-size: 16px;
    font-weight: 400;
    color: var(--primary-neutral-500);
}
.p-c-b {
    width: 24px;
    height: 24px;
    margin: 10px 15px;
    border-radius: 2px;
    box-shadow: 0 1px 2px var(--at-p-c-b-ox-shdw);
    cursor: pointer;
}
.p-c-b:hover {
    box-shadow: 0 1px 2px var(--at-p-c-b-ox-shdw-hvr);
}